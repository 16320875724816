import React from "react"
import { Text, View } from "react-native"
import Svg, { Path } from "react-native-svg"
import styles from "./styles"
import { TabBarIconProps } from "components/types"

interface Props extends TabBarIconProps {
  unseenCount: number
}

const Icon = (props: Props) => {
  return (
    <View style={{ height: 24, width: 24 }}>
      <Svg height={24} width={24} viewBox="0 0 512 512">
        <Path
          fill={props.color ? props.color : undefined}
          d="M496.5 258.2c-8.5 0-15.3 6.8-15.3 15.3v139.3c0 37.9-30.8 68.6-68.6 68.6H99.2c-37.9 0-68.6-30.9-68.6-68.6V271.2c0-8.5-6.8-15.3-15.3-15.3S0 262.7 0 271.2v141.6C0 467.5 44.6 512 99.2 512h313.3c54.8 0 99.2-44.6 99.2-99.2V273.5c.1-8.4-6.7-15.3-15.2-15.3zm0 0"
        />
        <Path
          fill={props.color ? props.color : undefined}
          d="M277.9 4.5c-3-2.9-6.9-4.5-10.8-4.5-3.9 0-7.8 1.5-10.8 4.5L159 101.9c-6 6-6 15.7 0 21.7s15.7 6 21.7 0l71.1-71.2V363c0 8.5 6.8 15.3 15.3 15.3s15.3-6.8 15.3-15.3V52.3l71.2 71.2c6 6 15.7 6 21.7 0s6-15.7 0-21.7L277.9 4.5zm0 0"
        />
      </Svg>
      {props.unseenCount ? (
        <View style={styles.indicatorContainer}>
          <Text style={styles.indicatorText}>{props.unseenCount}</Text>
        </View>
      ) : null}
    </View>
  )
}

export default Icon
