import firebase from "firebase"
import { getEventChannelForFirebaseRef } from "utilities/Redux/sagaHelpers"
import { all, call, takeEvery, fork, put, take } from "redux-saga/effects"
import * as actions from "./actions"
import { CATEGORIES_SUBSCRIBE } from "./actionTypes"
import { FIREBASE_PATH } from "./constants"
import { CategoriesDB } from "database_types/category"

export function* subscribeWorker() {
  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
  const channel = yield call(getEventChannelForFirebaseRef, ref)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const { values: categories }: { values: CategoriesDB } = yield take(channel)
    yield put(actions.categoriesUpdated(categories))
  }
}

export function* subscribeSaga() {
  yield takeEvery(CATEGORIES_SUBSCRIBE, subscribeWorker)
}

export default function*() {
  yield all([fork(subscribeSaga)])
}
