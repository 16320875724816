import _ from "lodash"
import React from "react"
import { Text } from "react-native"
import { List, Separator } from "native-base"
import I18n from "i18n"
import EmptyList from "components/views/Shared/EmptyList"
import BookingsListItem from "../BookingsListItem"
import styles from "./styles"
import { Navigation } from "components/types"

interface Props {
  bookedOfferRequestIds: string[]
  navigation: Navigation
}

export default class BookingsList extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !_.isEqual(
      nextProps.bookedOfferRequestIds,
      this.props.bookedOfferRequestIds,
    )
  }

  render() {
    const { bookedOfferRequestIds = [], navigation } = this.props
    return (
      <List style={styles.list}>
        <Separator style={styles.separator}>
          <Text style={styles.separatorText}>
            {I18n.t("title_buyer_orders_bookings_tab").toUpperCase()}
          </Text>
        </Separator>
        {bookedOfferRequestIds.length ? (
          bookedOfferRequestIds.map((item, idx) => (
            <BookingsListItem
              last={bookedOfferRequestIds.length === idx + 1}
              key={`bookings_list_item_${item}`}
              id={item}
              navigation={navigation}
            />
          ))
        ) : (
          <EmptyList emptyTextMessage={I18n.t("empty_accepted_offers_list")} />
        )}
      </List>
    )
  }
}
