export const QUESTION_CHECKBOX_OPTION_CHECKED = `questions/QUESTION_CHECKBOX_OPTION_CHECKED`
export const QUESTION_RADIO_OPTION_CHECKED = `questions/QUESTION_RADIO_OPTION_CHECKED`
export const QUESTION_TEXT_CHANGED = `questions/QUESTION_TEXT_CHANGED`
export const QUESTION_DURATION_CHANGED = `questions/QUESTION_DURATION_CHANGED`
export const QUESTION_NUMBER_CHANGED = `questions/QUESTION_NUMBER_CHANGED`
export const QUESTION_SUITABLE_TIME_SELECTED = `questions/QUESTION_SUITABLE_TIME_SELECTED`
export const QUESTION_PREFFERED_TIME_CHANGED = `questions/QUESTION_PREFFERED_TIME_CHANGED`
export const QUESTION_IMAGE_ADDED = `questions/QUESTION_IMAGE_ADDED`
export const QUESTION_IMAGE_REMOVED = `questions/QUESTION_IMAGE_REMOVED`
export const SET_AS_SELECTED_DATE_QUESTION = `questions/SET_AS_SELECTED_DATE_QUESTION`
export const SET_SUCCESS_MODAL_VISIBILITY = `questions/SET_SUCCESS_MODAL_VISIBILITY`
export const CLOSE_INPUT_MODAL = `questions/CLOSE_INPUT_MODAL`
export const OPEN_INPUT_MODAL = `questions/OPEN_INPUT_MODAL`
export const SCROLL_TO_BOTTOM = `questions/SCROLL_TO_BOTTOM`
export const TOGGLE_SCROLL_ENABLED = `questions/TOGGLE_SCROLL_ENABLED`
export const RESET_SUITABLE_TIMES = `questions/RESET_SUITABLE_TIMES`
export const UPDATE_ERROR_VISIBILITY = `questions/UPDATE_ERROR_VISIBILITY`
export const UPDATE_SCROLL_POSITION = `questions/UPDATE_SCROLL_POSITION`
export const UPDATE_QUESTION_POSITION = `questions/UPDATE_QUESTION_POSITION`
