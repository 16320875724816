import React from "react"
import { View } from "react-native"
import { createStackNavigator } from "@react-navigation/stack"
import Colors from "constants/Colors"
import routeNames from "../constants"
import I18n from "i18n"
import Logo from "../Logo"
import Landing from "components/views/CreateSeller/Landing"
import CreateSellerProfile from "components/views/CreateSeller/CreateSellerProfile"
import FieldOfExpertise from "components/views/CreateSeller/FieldOfExpertise"
import SellerCompanyInfo from "components/views/CreateSeller/SellerCompanyInfo"
import { headerStyles } from "../headerStyles"

const { Navigator, Screen } = createStackNavigator()
export default () => (
  <Navigator
    initialRouteName={routeNames.SELLER_CREATE.INDEX}
    screenOptions={{
      headerBackTitle: undefined,
      headerTintColor: Colors.brandYellow,
      headerTitleStyle: headerStyles.headerTitleStyle,
      headerStyle: headerStyles.headerStyleNoBorder,
      headerRight: () => <View />, // needed to center the title on android
      cardStyle: {
        backgroundColor: Colors.containerBackground,
      },
    }}
  >
    <Screen
      name={routeNames.SELLER_CREATE.INDEX}
      component={Landing}
      options={{
        gestureEnabled: false,
        headerLeft: undefined,
        headerRight: undefined,
        headerStyle: headerStyles.headerStyleNoBorder,
        headerTitle: () => <Logo />,
      }}
    />
    <Screen
      name={routeNames.SELLER_CREATE.CREATE_SELLER_PROFILE}
      component={CreateSellerProfile}
      options={{
        title: I18n.t("title_sign_in_create_seller_profile"),
        headerStyle: headerStyles.headerStyle,
      }}
    />
    <Screen
      name={routeNames.SELLER_CREATE.FIELD_OF_EXPERTISE}
      component={FieldOfExpertise}
      options={{
        title: I18n.t("title_sign_in_field_of_expertise"),
        headerStyle: headerStyles.headerStyle,
      }}
    />
    <Screen
      name={routeNames.SELLER_CREATE.COMPANY_INFO}
      component={SellerCompanyInfo}
      options={{
        title: I18n.t("Company info"),
        headerStyle: headerStyles.headerStyle,
      }}
    />
  </Navigator>
)
