import {
  SEND_CHAT_MESSAGE,
  CHAT_MESSAGE_SENT,
  CHAT_MESSAGE_UPDATED,
} from "./actionTypes"
import { ChatMessage } from "./types"

export interface SendChatMessageProps {
  chatId: string
  content: string
}
export interface SendChatMessage extends SendChatMessageProps {
  type: typeof SEND_CHAT_MESSAGE
}
export const sendChatMessage: (
  props: SendChatMessageProps,
) => SendChatMessage = props => {
  return {
    type: SEND_CHAT_MESSAGE,
    ...props,
  }
}

export interface ChatMessageSent {
  type: typeof CHAT_MESSAGE_SENT
  message: ChatMessage
}
export const chatMessageSent: (
  message: ChatMessage,
) => ChatMessageSent = message => {
  return {
    type: CHAT_MESSAGE_SENT,
    message,
  }
}

export interface ChatMessageUpdated {
  type: typeof CHAT_MESSAGE_UPDATED
  message: ChatMessage
}
export const chatMessageUpdated: (
  message: ChatMessage,
) => ChatMessageUpdated = message => {
  return {
    type: CHAT_MESSAGE_UPDATED,
    message,
  }
}
