import {
  OFFER_REQUEST_SELECTED,
  OPEN_OFFER_REQUEST_UPDATED,
} from "./actionTypes"
import { OfferRequest } from "Buyer/OfferRequests/types"

export interface OfferRequestSelected {
  type: typeof OFFER_REQUEST_SELECTED
  offerRequestId: string
}
export const offerRequestSelected: (
  offerRequestId: string,
) => OfferRequestSelected = offerRequestId => {
  return {
    type: OFFER_REQUEST_SELECTED,
    offerRequestId,
  }
}

export interface OfferRequestUpdated {
  type: typeof OPEN_OFFER_REQUEST_UPDATED
  offerRequest: OfferRequest
}
export const offerRequestUpdated: (
  offerRequest: OfferRequest,
) => OfferRequestUpdated = offerRequest => {
  return {
    type: OPEN_OFFER_REQUEST_UPDATED,
    offerRequest,
  }
}
