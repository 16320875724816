import { StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.containerBackground,
  },
  content: {
    backgroundColor: Colors.containerBackground,
    flex: 1,
  },
  buttonWrap: {
    bottom: 15,
    left: 15,
    right: 15,
    position: "absolute",
  },
  button: {
    backgroundColor: Colors.brandYellow,
  },
  textStyle: {
    color: Colors.noteColor,
    fontSize: 14,
  },
  activeTextStyle: {
    color: Colors.brandYellow,
    fontSize: 14,
    fontWeight: "400",
  },
  tabStyle: {
    backgroundColor: Colors.contentBackground,
  },
  activeTabStyle: {
    backgroundColor: Colors.contentBackground,
  },
  tabBarUnderlineStyle: {
    backgroundColor: Colors.brandYellow,
    height: 2,
  },
})
