import _ from "lodash"
import { connect } from "react-redux"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { getMessagesForChat } from "modules/ChatMessages/selectors"

interface OwnProps {
  chatId: string
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => ({
  messages: getMessagesForChat(state, ownProps.chatId),
})

const mapDispatchToProps = null

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: null,
  ownProps: OwnProps,
) => ({
  ...stateProps,
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
