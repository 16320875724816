import { StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  thumbnail: {
    alignSelf: "flex-start",
    marginRight: 15,
  },
  textWrap: {
    marginLeft: -11,
  },
  title: {
    color: Colors.textColor,
  },
  titleHighlighted: {
    fontWeight: "700",
  },
  info: {
    flex: 1,
    flexDirection: "row",
    marginTop: 2,
  },
  infoWrap: {
    flexDirection: "row",
  },
  infoText: {
    color: Colors.noteColor,
    fontSize: 13,
    marginRight: 0,
  },
  infoTextHighlighted: {
    color: Colors.textColor,
    fontWeight: "700",
  },
  infoSeparator: {
    color: Colors.noteColor,
    fontSize: 8,
    marginTop: 4,
    lineHeight: 8,
    marginRight: 0,
  },
  infoIcon: {
    color: Colors.starYellow,
    fontSize: 13,
  },
  rightWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
  notification: {
    backgroundColor: Colors.green,
    marginRight: 14,
    minWidth: 28,
  },
  notificationText: {
    fontSize: 12,
    fontWeight: "700",
  },
  lastItem: {
    borderBottomWidth: 0,
  },
  icon: {
    color: Colors.brandYellow,
  },
})
