import React from "react"
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs"
import Colors from "constants/Colors"
import routeNames from "components/navigators/constants"
import { headerStyles } from "components/navigators/headerStyles"
import Feed from "components/views/Buyer/Feed"
import OfferRequests from "components/views/Buyer/OfferRequests"
import History from "components/views/Buyer/History"
import OfferRequestsLabel from "./OfferRequestsLabel"
import HistoryLabel from "./HistoryLabel"
import DiscoverLabel from "./DiscoverLabel"

const { Navigator, Screen } = createMaterialTopTabNavigator()
export default () => (
  <Navigator
    tabBarOptions={{
      inactiveTintColor: Colors.noteColor,
      activeTintColor: Colors.brandYellow,
      indicatorStyle: { ...headerStyles.tabIndicatorStyle },
      style: {
        ...headerStyles.headerStyle,
        height: "auto",
      },
      tabStyle: { ...headerStyles.tabStyle },
    }}
    initialRouteName={routeNames.BUYER.DISCOVER_TAB}
    swipeEnabled
  >
    <Screen
      component={Feed}
      name={routeNames.BUYER.DISCOVER_TAB}
      options={{
        tabBarLabel: DiscoverLabel,
      }}
    />
    <Screen
      component={OfferRequests}
      name={routeNames.BUYER.TAB_REQUESTS}
      options={{
        tabBarLabel: OfferRequestsLabel,
      }}
    />
    <Screen
      component={History}
      name={routeNames.BUYER.TAB_HISTORY}
      options={{
        tabBarLabel: HistoryLabel,
      }}
    />
  </Navigator>
)
