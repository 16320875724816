import * as sellerOfferRequestSelectors from "modules/Seller/OfferRequests/selectors"
import * as buyerProfileSelectors from "modules/BuyerProfiles/selectors"
import { OFFER_STATUS } from "constants/Status"
import _ from "lodash"
import { createSelector } from "reselect"
import moment from "moment"
import { Store } from "utilities/Redux/store"
import { Offer } from "modules/Buyer/Offers/types"
import { getCategoryById } from "modules/Buyer/Categories/selectors"
import { sortByStartTime } from "utilities/sortBy"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

export const getSellerOffers = (state: Store) => state.sellerOffers.offers

export const getSellerOfferById = (state: Store, id: string) =>
  state.sellerOffers.offers[id] ? state.sellerOffers.offers[id] : undefined

export const getSellerOffersWithStatus = createSelector(
  [getSellerOffers, (state: Store, offerStatus: OFFER_STATUS) => offerStatus],
  (offers, offerStatus: OFFER_STATUS) =>
    Object.values(offers).filter(offer => offer.status === offerStatus),
)

const getOrderedSellerOfferIds = (offers: Offer[]) => {
  const orderedOffers = sortByStartTime(offers, "desc")
  return orderedOffers.map(offer => offer.id)
}

export const getOpenSellerOfferIds = createSelector(
  [(state: Store) => getSellerOffersWithStatus(state, "open")],
  offers => {
    return getOrderedSellerOfferIds(offers)
  },
)

export const getAcceptedSellerOfferIds = createSelector(
  [(state: Store) => getSellerOffersWithStatus(state, "accepted")],
  offers => {
    return getOrderedSellerOfferIds(offers)
  },
)

export const getCompletedSellerOfferIds = createSelector(
  [(state: Store) => getSellerOffersWithStatus(state, "completed")],
  offers => {
    return getOrderedSellerOfferIds(offers)
  },
)

export const getSelectedSellerOffer = (state: Store) =>
  state.sellerOffers.selected
    ? getSellerOfferById(state, state.sellerOffers.selected)
    : undefined

export const getUnseenAcceptedOffersTotalForSeller = createSelector(
  (state: Store) => state.sellerOffers.offers,
  offers =>
    Object.values(offers).filter(
      ({ status, seenBySeller }) => status === "accepted" && !seenBySeller,
    ).length,
)

export const getAcceptedAndOpenSellerOffers = createSelector(
  getSellerOffers,
  offers =>
    Object.values(offers).filter(
      ({ status }) => status === "open" || status === "accepted",
    ),
)

const getAcceptedAndOpenSellerOffersForDate = createSelector(
  [
    getAcceptedAndOpenSellerOffers,
    (state: Store, selectedDate?: string | null) => selectedDate,
  ],
  (offers, selectedDate) => {
    const dateFormat = "YYYY-MM-DD"
    return _.pickBy(
      offers,
      ({ agreedStartTime: startTime }) =>
        selectedDate === moment(startTime).format(dateFormat),
    )
  },
)

export const getOpenAndAcceptedSellerOffersForSelectedDate = createSelector(
  [getAcceptedAndOpenSellerOffersForDate, (state: Store) => state],
  (offers, state) => {
    const offersWithCategoryName = _.map(offers, offer => {
      const offerRequest = sellerOfferRequestSelectors.getOfferRequestByIdForSeller(
        state,
        offer.offerRequest,
      )
      const category =
        offerRequest && getCategoryById(state, offerRequest.category)
      const buyerProfile =
        offerRequest &&
        buyerProfileSelectors.getBuyerProfileForId(
          state,
          offerRequest.buyerProfile,
        )
      return {
        offer,
        name: category ? getLocaLizedName(category.name) : "",
        buyer: buyerProfile ? buyerProfile.displayName : "",
      }
    })
    return offersWithCategoryName
  },
)

export const getUpcomingSellerJobs = createSelector(
  [(state: Store) => getSellerOffersWithStatus(state, "accepted")],
  offers =>
    _(offers)
      .filter(offer => {
        const startTime = moment(offer.agreedStartTime, "x")
        const endTime = startTime
          .clone()
          .add(offer.original.purchaseDuration, "hours")
        return (
          // is not already started
          !_.has(offer, "actualStartTime") &&
          // current time no more than 1h before startTime
          moment()
            .add(1, "hours")
            .isAfter(startTime) &&
          // current time no more than 30min after assumed ending time (starTime + duration)
          moment()
            .subtract(30, "minutes")
            .isBefore(endTime)
        )
      })
      .value(),
)

export const getOnGoingSellerJobs = createSelector(
  [(state: Store) => getSellerOffersWithStatus(state, "accepted")],
  offers =>
    _(offers)
      .filter(offer => offer.actualStartTime !== undefined)
      .value(),
)

export const getPastSellerJobsNotStarted = createSelector(
  [(state: Store) => getSellerOffersWithStatus(state, "accepted")],
  offers =>
    _(offers)
      .filter(
        offer =>
          offer.actualStartTime === undefined &&
          // current time is more than 30min after assumed ending time
          moment(offer.agreedStartTime, "x")
            .add(offer.original.purchaseDuration, "hours")
            .add(30, "minutes")
            .isBefore(),
      )
      .value(),
)

export const getSellerOfferReminderCount = createSelector(
  [getUpcomingSellerJobs, getOnGoingSellerJobs, getPastSellerJobsNotStarted],
  (upcomingJobs, onGoingJobs, pastJobsNotStarted) => {
    return (
      Number(upcomingJobs.length) +
      Number(onGoingJobs.length) +
      Number(pastJobsNotStarted.length)
    )
  },
)
