import React, { useEffect, useRef } from "react"
import { Button, Container, Text, Toast } from "native-base"
import {
  View,
  ScrollView,
  NativeSyntheticEvent,
  NativeScrollEvent,
} from "react-native"
import I18n from "i18n"
import QuestionList from "../Questions/QuestionList"
import styles from "./styles"
import { Category } from "modules/Buyer/Categories/types"
import { Navigation } from "components/types"

interface Props {
  scrollToBottom: boolean
  category?: Category
  hasErrors?: boolean
  scrollEnabled: boolean
  onButtonClicked: (category: Category) => void
  updateBottomScrollStatus: () => void
  updateScrollPosition: (position: number) => void
  navigation: Navigation
}

const QuestionsStep = (props: Props) => {
  const scrollView: {
    current: ScrollView | null
  } = useRef(null)

  const scrollToEnd = (): void => {
    if (scrollView.current) {
      window.setTimeout(() => {
        if (scrollView.current) {
          scrollView.current.scrollToEnd()
        }
        props.updateBottomScrollStatus()
      }, 250)
    }
  }

  const updateScrollPosition = (
    scrollEvent: NativeSyntheticEvent<NativeScrollEvent>,
  ): void => {
    const scrollPosition =
      scrollEvent.nativeEvent.contentOffset.y > 0
        ? scrollEvent.nativeEvent.contentOffset.y +
          scrollEvent.nativeEvent.layoutMeasurement.height / 1.1
        : 0
    props.updateScrollPosition(scrollPosition)
  }

  useEffect(() => {
    if (props.scrollToBottom) {
      scrollToEnd()
    }
  }, [props.scrollToBottom])

  useEffect(() => {
    props.updateBottomScrollStatus()
    props.updateScrollPosition(0)
  }, [])

  const {
    category,
    hasErrors,
    onButtonClicked,
    navigation,
    scrollEnabled,
  } = props

  if (!category) {
    return null
  }

  return (
    <Container style={styles.container}>
      <ScrollView
        ref={scrollView}
        scrollEnabled={scrollEnabled}
        keyboardShouldPersistTaps="always"
        onScrollEndDrag={updateScrollPosition}
      >
        <QuestionList category={category} navigation={navigation} />
        <View style={styles.buttonWrap}>
          {hasErrors ? (
            <Button
              rounded
              block
              bordered
              style={styles.errorButton}
              onPress={() => {
                Toast.show({
                  text: I18n.t("fill_missing_details"),
                  position: "bottom",
                  duration: 2000,
                  style: styles.toast,
                  textStyle: styles.toastText,
                })
              }}
            >
              <Text style={styles.errorButtonText}>{I18n.t("continue")}</Text>
            </Button>
          ) : (
            <Button
              rounded
              block
              onPress={() => onButtonClicked(category)}
              style={styles.button}
            >
              <Text style={styles.buttonText}>{I18n.t("continue")}</Text>
            </Button>
          )}
        </View>
      </ScrollView>
    </Container>
  )
}

export default QuestionsStep
