import React, { useEffect } from "react"
import { View } from "react-native"
import { Content } from "native-base"
import OfferRequestDetails from "components/views/Shared/OfferRequestDetails"
import I18n from "i18n"
import _ from "lodash"
import { OFFER_REQUEST_STATUS } from "constants/Status"
import EmptyList from "components/views/Shared/EmptyList"
import styles from "./styles"
import Form from "./Form"
import { TempOffer } from "modules/Seller/CreateOffer/types"
import { Navigation } from "components/types"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import SendOfferButton from "./Button"
import { DefaultValue } from "modules/Seller/CreateOffer/actions"

interface Props {
  tempOffer?: TempOffer
  offerRequest?: OfferRequest
  navigation: Navigation
  setDefaultValue: (props: DefaultValue) => void
  defaultValue?: DefaultValue | null
}

const CreateOffer = (props: Props) => {
  const { offerRequest, navigation } = props

  if (!offerRequest) {
    return null
  }

  let scroll: undefined | (Content & { _root: { scrollToEnd?: () => void } }) // a way to get around this https://github.com/GeekyAnts/NativeBase/issues/2714

  const scrollToBottom = (): void => {
    if (scroll && scroll._root && scroll._root.scrollToEnd) {
      scroll._root.scrollToEnd()
    }
  }

  const setDefaultOfferValue = (): void => {
    if (props.defaultValue) {
      props.setDefaultValue(props.defaultValue)
    }
  }

  useEffect(() => {
    setDefaultOfferValue()
  }, [])

  useEffect(() => {
    window.setTimeout(() => {
      scrollToBottom()
    }, 0)
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [props.tempOffer])

  if (offerRequest.status !== OFFER_REQUEST_STATUS.OPEN) {
    return (
      <EmptyList
        fullPage
        emptyTextMessage={I18n.t("offer_request_not_available")}
      />
    )
  }

  return (
    <View style={styles.container}>
      <Content
        ref={ref => {
          const temp = ref || ({} as any)
          // this should never happen - just a way to get around this https://github.com/GeekyAnts/NativeBase/issues/2714
          // tslint:disable-next-line:no-unsafe-any
          if (!temp._root) {
            // tslint:disable-next-line:no-unsafe-any
            temp._root = {
              // ...
            }
          }
          // tslint:disable-next-line:no-unsafe-any
          scroll = temp
        }}
      >
        <View style={styles.content}>
          <OfferRequestDetails />
          <Form {...{ navigation }} />
          <View style={styles.buttonWrap}>
            <SendOfferButton {...{ navigation }} />
          </View>
        </View>
      </Content>
    </View>
  )
}

export default CreateOffer
