import React from "react"
import _ from "lodash"
import { View } from "react-native"
import styles from "./styles"
import Addresses from "../OrderDetails/Addresses"
import Questions from "../OrderDetails/Questions"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import { sortByOrder } from "utilities/sortBy"

interface Props {
  offerRequest?: OfferRequest
}

export default ({ offerRequest }: Props) => {
  if (!offerRequest) {
    return null
  }
  const orderedQuestions = sortByOrder(
    Object.values(offerRequest.questions),
    "asc",
  )
  return (
    <View style={styles.content}>
      <Questions questions={orderedQuestions} />
      <Addresses addresses={offerRequest.addresses} />
    </View>
  )
}
