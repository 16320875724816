import Colors from "constants/Colors"
import { separatorText } from "constants/Fonts"
import { StyleSheet, ViewStyle, TextStyle, PixelRatio } from "react-native"
import FontSizes from "constants/FontSizes"

interface Styles {
  listItem: ViewStyle
  iconContainer: ViewStyle
  icon: ViewStyle
  textarea: TextStyle
  separator: ViewStyle
  separatorText: TextStyle
}

export default StyleSheet.create<Styles>({
  listItem: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: 50,
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 0,
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: Colors.contentBackground,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
  },
  iconContainer: {
    justifyContent: "center",
    marginLeft: 15,
    minHeight: 47,
  },
  icon: {
    color: Colors.grey,
  },
  textarea: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    fontSize: FontSizes.questionPlaceholderSize,
    lineHeight: FontSizes.questionPlaceholderSize,
    paddingLeft: 15,
    paddingBottom: 0,
    borderBottomWidth: 0,
  },
  separator: {
    backgroundColor: Colors.containerBackground,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
    marginTop: 15,
  },
  separatorText: {
    ...separatorText,
  },
})
