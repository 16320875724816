import { getUser } from "../User/selectors"
import { Store } from "utilities/Redux/store"

export const isFetchingBuyerProfile = (state: Store) =>
  state.buyerProfiles.fetching

export const getBuyerProfiles = (state: Store) => state.buyerProfiles.profiles

export const getBuyerProfileForUser = (state: Store) => {
  const user = getUser(state)
  return user ? state.buyerProfiles.profiles[user.id] : undefined
}

export const getBuyerProfileForId = (state: Store, id: string) =>
  state.buyerProfiles.profiles[id]
    ? state.buyerProfiles.profiles[id]
    : undefined
