import { connect } from "react-redux"
import moment from "moment"
import routeNames from "components/navigators/constants"
import {
  createOffer,
  offerValueChanged,
  calendarDateSelected,
  startTimeSelected,
} from "modules/Seller/CreateOffer/actions"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import { getTempOffer } from "modules/Seller/CreateOffer/selectors"
import { getSelectedOfferRequestForSeller } from "modules/Seller/OfferRequests/selectors"
import { OfferRequestDateQuestion } from "database_types/offerRequestQuestions"
import { CommonActions } from "@react-navigation/native"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const offerRequest = getSelectedOfferRequestForSeller(state)
  if (!offerRequest) {
    return {}
  }
  return {
    offerRequest,
    tempOffer: getTempOffer(state, offerRequest.id),
  }
}

const mapDispatchToProps = {
  createOffer,
  offerValueChanged,
  calendarDateSelected,
  startTimeSelected,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onPriceChanged: (pricePerHour: number, offerRequest: OfferRequest) => {
    dispatchProps.offerValueChanged({
      key: "pricePerHour",
      value: pricePerHour,
      offerRequestId: offerRequest.id,
    })
  },
  onDurationChanged: (duration: number, offerRequest: OfferRequest) => {
    dispatchProps.offerValueChanged({
      key: "duration",
      value: duration,
      offerRequestId: offerRequest.id,
    })
  },
  openCalendar: (question: OfferRequestDateQuestion) => {
    const { suitableTimes } = question
    const suitableDays = suitableTimes
      ? Object.values(suitableTimes).map(item => item.day)
      : []
    const suitableDaysUnique = suitableDays.filter(
      (day, index) => index === suitableDays.indexOf(day),
    )
    if (suitableDaysUnique.length === 1) {
      // Reset selected start time
      const day = suitableDays[0]
      dispatchProps.startTimeSelected(null)
      dispatchProps.calendarDateSelected(day)
      ownProps.navigation.dispatch(
        CommonActions.navigate(routeNames.SELLER.CREATE_OFFER_TIMETABLE, {
          name: moment(day).format("dddd D.M."),
        }),
      )
    } else {
      // Reset selected start date
      dispatchProps.calendarDateSelected(null)
      ownProps.navigation.dispatch(
        CommonActions.navigate(routeNames.SELLER.CREATE_OFFER_CALENDAR),
      )
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
