import React from "react"
import { View } from "react-native"
import { Body, ListItem, Text } from "native-base"
import { Ionicons } from "@expo/vector-icons"
import QuestionList from "components/views/Buyer/Questions/QuestionList"
import styles from "./styles"
import { Navigation } from "components/types"

const Icon = ({
  type,
  checked,
}: {
  type: Props["type"]
  checked?: boolean
}) => {
  if (type === "checkbox") {
    return (
      <Ionicons
        name={checked ? "ios-checkbox" : "ios-square-outline"}
        size={20}
        style={checked ? styles.checked : styles.unchecked}
      />
    )
  }
  return (
    <Ionicons
      name={checked ? "ios-checkmark-circle" : "ios-radio-button-off"}
      size={20}
      style={checked ? styles.checked : styles.unchecked}
    />
  )
}

interface Props {
  name?: string
  type: "radio" | "checkbox"
  last: boolean
  isSubQuestion?: boolean
  subQuestions?: string[] | null
  checked?: boolean
  onChecked: () => void
  navigation: Navigation
}

const OptionView = ({
  name,
  type,
  last,
  isSubQuestion,
  subQuestions,
  checked,
  onChecked,
  navigation,
}: Props) => {
  return (
    <View>
      <ListItem button onPress={onChecked} style={styles.item}>
        <View style={styles.iconContainer}>
          <Icon type={type} checked={checked} />
        </View>
        <Body
          style={[
            styles.itemBody,
            last ? styles.itemBodyLast : null,
            isSubQuestion ? styles.itemBodyIsSubQuestion : null,
            checked && subQuestions ? styles.itemBodyWithSubQuestions : null,
          ]}
        >
          <Text
            style={[styles.itemText, checked ? styles.checkeditemText : null]}
          >
            {name}
          </Text>
        </Body>
      </ListItem>
      {subQuestions && checked ? (
        <ListItem
          style={[
            styles.subQuestionsContainer,
            last ? styles.subQuestionsContainerLast : null,
          ]}
        >
          <QuestionList
            questionIds={subQuestions}
            isSubQuestion
            navigation={navigation}
          />
        </ListItem>
      ) : null}
    </View>
  )
}

export default OptionView
