import React from "react"
import _ from "lodash"
import moment from "moment"
import { View } from "react-native"
import { Text } from "native-base"
import I18n from "i18n"
import styles from "./styles"
import { Offer } from "modules/Buyer/Offers/types"
import { formatDateAndTime, getMoment } from "utilities/time"

const SectionName = ({ name }: { name: string }) => (
  <View style={styles.detailsTitle}>
    <Text style={styles.detailsTitleText}>{I18n.t(name)}</Text>
  </View>
)

const OfferSubRow = (props: { name?: string; value?: string }) => (
  <View style={styles.offerSubRow}>
    <View style={styles.offerSubRowTitleContainer}>
      <Text style={styles.offerSubRowTitle}>
        {props.name && I18n.t(props.name)}
      </Text>
    </View>
    <View style={styles.offerSubRowValueContainer}>
      <Text style={styles.offerSubRowValue}>{props.value}</Text>
    </View>
  </View>
)

const DateRow = ({ name, value }: { name: string; value: string }) => (
  <View style={styles.detailsRow}>
    <SectionName name={name} />
    <View style={styles.detailsContent}>
      <Text style={styles.detailsContentText}>{value}</Text>
    </View>
  </View>
)

const OrderDetails = (props: { duration: number; pricePerHour: number }) => (
  <View style={styles.detailsContent}>
    <OfferSubRow
      name="duration"
      value={`${props.duration} ${I18n.t("hour_short")}`}
    />
    <OfferSubRow
      name="hour_price"
      value={`${props.pricePerHour} €/${I18n.t("hour_short")}`}
    />
  </View>
)

const OriginalOrder = ({ offer }: { offer: Offer }) => (
  <View style={styles.detailsRow}>
    <View style={styles.detailsTitle}>
      <Text style={styles.detailsTitleText}>{I18n.t("original")}</Text>
    </View>
    <OrderDetails
      duration={
        offer.original.actualDuration || offer.original.purchaseDuration
      }
      pricePerHour={offer.original.pricePerHour}
    />
  </View>
)

const Extensions = ({ offer }: { offer: Offer }) => {
  if (!offer.extensions) {
    return null
  }
  return (
    <View style={styles.detailsRow}>
      <View style={styles.detailsTitle}>
        <Text style={styles.detailsTitleText}>{I18n.t("extensions")}</Text>
      </View>
      <View style={styles.detailsContent}>
        {offer.extensions.map((extension, index) => (
          <React.Fragment key={`extension_${index}`}>
            <OrderDetails
              duration={extension.actualDuration || extension.purchaseDuration}
              pricePerHour={offer.pricePerHour}
            />
            <View style={styles.detailsRow} />
          </React.Fragment>
        ))}
      </View>
    </View>
  )
}

const WorkHours = ({ offer }: { offer: Offer }) => {
  if (!offer.workTime) {
    return null
  }
  return (
    <View style={styles.detailsRow}>
      <View style={styles.detailsTitle}>
        <Text style={styles.detailsTitleText}>{I18n.t("workTime")}</Text>
      </View>
      <View style={styles.detailsContent}>
        {offer.workTime.map((work, index) => (
          <View style={styles.offerSubRow}>
            <View style={styles.offerSubRowTitleContainer}>
              <Text style={styles.offerSubRowTitle}>
                {work.startTime - work.endTime}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}

const TotalDuration = ({ offer }: { offer: Offer }) => {
  const { original, extensions } = offer
  const totalDuration =
    (original.actualDuration || original.purchaseDuration) +
    (extensions
      ? extensions.reduce(
          (extensionsDuration, extension) =>
            extensionsDuration +
            (extension.actualDuration || extension.purchaseDuration),
          0,
        )
      : 0)

  return (
    <View style={styles.detailsRow}>
      <SectionName name="total_duration" />
      <View style={styles.detailsContent}>
        <Text style={styles.detailsContentText}>
          {totalDuration} {I18n.t("hour_short")}
        </Text>
      </View>
    </View>
  )
}

const TotalCost = ({ offer }: { offer: Offer }) => {
  const { original, extensions } = offer
  const cost =
    original.pricePerHour *
      (original.actualDuration || original.purchaseDuration) +
    (extensions
      ? extensions.reduce(
          (extensionCost, extension) =>
            extensionCost +
            extension.pricePerHour *
              (extension.actualDuration || extension.purchaseDuration),
          0,
        )
      : 0)

  return (
    <View style={styles.detailsRow}>
      <SectionName name="total_price" />
      <View style={styles.detailsContent}>
        <Text style={styles.detailsContentText}>{cost} €</Text>
      </View>
    </View>
  )
}

export default (props: { offer: Offer }) => {
  const { offer } = props
  const starts = moment(
    offer.actualStartTime || offer.agreedStartTime,
    "x",
  ).format("dddd D.M. kk:mm")
  const ends = offer.actualCompletedTime
    ? formatDateAndTime(offer.actualCompletedTime, "dateAndTimeFormat")
    : formatDateAndTime(
        getMoment(offer.agreedStartTime).add(
          offer.original.purchaseDuration,
          "hour",
        ),
        "dateAndTimeFormat",
      )

  return (
    <View>
      <DateRow
        name="date_and_time"
        value={_.capitalize(`${starts} - ${ends}`)}
      />
      <OriginalOrder offer={offer} />
      <Extensions offer={offer} />
      <WorkHours offer={offer} />
      <TotalDuration offer={offer} />
      <TotalCost offer={offer} />
    </View>
  )
}
