import { connect } from "react-redux"
import { Linking } from "expo"
import { getStripeSession } from "modules/Payment/Stripe/actions"
import routeNames from "components/navigators/constants"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { Offer } from "modules/Buyer/Offers/types"
import { Category } from "modules/Buyer/Categories/types"
import { CommonActions } from "@react-navigation/native"
import {
  getReviews,
  getSellerProfileById,
} from "modules/SellerProfiles/selectors"
import { getUser } from "modules/User/selectors"
import { getCategoryById } from "modules/Buyer/Categories/selectors"
import { getSelectedOfferForBuyer } from "modules/Buyer/Offers/selectors"
import { markOfferAsSeen } from "modules/Buyer/Offers/actions"
import { getSelectedOfferRequestForBuyer } from "modules/Buyer/OfferRequests/selectors"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const offerRequest = getSelectedOfferRequestForBuyer(state)
  const selectedOffer = getSelectedOfferForBuyer(state)
  const reviews = selectedOffer
    ? getReviews(state, selectedOffer.sellerProfile)
    : undefined
  return {
    offer: selectedOffer,
    offerRequest,
    category: offerRequest && getCategoryById(state, offerRequest.category),
    sellerProfile: selectedOffer
      ? getSellerProfileById(state, selectedOffer.sellerProfile)
      : undefined,
    reviews: reviews ? Object.values(reviews) : undefined,
    user: getUser(state),
  }
}

const mapDispatchToProps = {
  markOfferAsSeen,
  getStripeSession,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  proceedToCheckout: (offer: Offer, category: Category) => {
    if (!stateProps.user) {
      return
    }
    dispatchProps.getStripeSession({
      user: stateProps.user,
      categoryName: getLocaLizedName(category.name),
      offerId: offer.id,
      offerRequestId: offer.offerRequest,
      isExtending: false,
      successUrl: Linking.makeUrl("stripe/success"), // tslint:disable-line:no-unsafe-any
      cancelUrl: Linking.makeUrl("stripe/cancel"), // tslint:disable-line:no-unsafe-any
    })
    ownProps.navigation.dispatch(
      CommonActions.navigate(routeNames.ROOT.CHECKOUT_STRIPE),
    )
  },
  markOfferAsSeen: (offer: Offer) => {
    dispatchProps.markOfferAsSeen({
      offerId: offer.id,
      seenByBuyer: true,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
