import React from "react"
import _ from "lodash"
import { View } from "react-native"
import { Separator, Text } from "native-base"
import CategoryListItem from "../CategoryListItem"
import styles from "./styles"
import { Feed } from "modules/Buyer/Feed/types"
import { sortByOrder } from "utilities/sortBy"

interface Props {
  toggleCategorySelection: (id: string) => void
  feed: Feed
}

export default ({ feed, toggleCategorySelection }: Props) => {
  const groups = sortByOrder(Object.values(feed), "desc")
  return (
    <View style={styles.container}>
      {groups.map(({ name, items }) => (
        <View key={name}>
          <Separator style={styles.separator}>
            <Text style={styles.separatorText}>{name}</Text>
          </Separator>
          <View style={styles.groupItems}>
            {Object.values(items).map((item, idx) => (
              <CategoryListItem
                key={`${item.categoryId}_category_list_item`}
                last={idx === Object.values(items).length - 1}
                first={idx === 0}
                item={{
                  categoryId: item.categoryId,
                  name: item.name,
                }}
                toggleCategorySelection={toggleCategorySelection}
              />
            ))}
          </View>
        </View>
      ))}
    </View>
  )
}
