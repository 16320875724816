import React from "react"
import _ from "lodash"
import { View } from "react-native"
import { Button, Text } from "native-base"
import OffersList from "./OffersList"
import I18n from "i18n"
import styles from "./styles"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import { Navigation } from "components/types"

interface Props {
  offerRequest?: OfferRequest
  navigation: Navigation
  onRemoveClicked: (offerRequest: OfferRequest) => void
}

export default ({ offerRequest, navigation, onRemoveClicked }: Props) => {
  return (
    <View style={styles.container}>
      <OffersList navigation={navigation} />
      {offerRequest &&
      (!offerRequest.offers || Object.keys(offerRequest.offers).length < 1) ? (
        <Button
          bordered
          rounded
          block
          onPress={() => onRemoveClicked(offerRequest)}
          style={styles.button}
        >
          <Text style={styles.buttonText}>
            {I18n.t("remove_offer_request")}
          </Text>
        </Button>
      ) : null}
    </View>
  )
}
