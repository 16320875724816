import { StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  container: {
    marginTop: 40,
  },
  title: {
    textAlign: "center",
    color: Colors.red,
    fontSize: 40,
    fontFamily: "open-sans-bold",
  },
  message: {
    textAlign: "center",
    color: Colors.red,
    fontSize: 20,
    fontFamily: "open-sans-bold",
    marginTop: 60,
    padding: 10,
  },
  instruction: {
    textAlign: "center",
    color: Colors.noteColor,
    fontSize: 18,
    fontFamily: "open-sans-regular",
    marginTop: 60,
    padding: 10,
  },
})
