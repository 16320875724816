import { StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  date: {
    fontSize: 10,
    color: Colors.noteColor,
    marginTop: 15,
    marginBottom: 10,
    textAlign: "center",
  },
  messageContent: {
    fontSize: 16,
    borderRadius: 20,
    overflow: "hidden",
    padding: 10,
    marginTop: 0,
    marginBottom: 5,
  },
  meMessageContent: {
    backgroundColor: Colors.brandGreen,
    color: "white",
    alignSelf: "flex-end",
    marginLeft: 20,
  },
  otherMessageContent: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: Colors.borderColor,
    alignSelf: "flex-start",
    marginRight: 20,
  },
})
