import _ from "lodash"
import { all, call, takeEvery, fork, put, take } from "redux-saga/effects"
import { getEventChannelForFirebaseRef } from "utilities/Redux/sagaHelpers"
import { uploadImages } from "modules/Images/actions"
import firebase from "firebase"
import { FIREBASE_PATH } from "./constants"
import {
  offerRequestCreated,
  offerRequestUpdated,
  OfferRequestReviewed,
  OfferRequestCreate,
  OfferRequestStatusUpdate,
} from "./actions"
import {
  OFFER_REQUEST_REVIEWED,
  OFFER_REQUEST_CREATE,
  OFFER_REQUEST_STATUS_UPDATED,
} from "./actionTypes"
import { OfferStatusUpdated } from "../Offers/actions"
import { OFFER_STATUS_UPDATED } from "../Offers/actionTypes"
import { USER_UPDATED } from "modules/User/actionTypes"
import { UserUpdated } from "modules/User/actions"
import { OfferRequestDB } from "database_types/offerRequest"
import { OfferRequestQuestion } from "database_types/offerRequestQuestions"

const subscribed = {}
export function* subscribeToOfferRequestWorker(offerRequestId: string) {
  if (subscribed[offerRequestId]) {
    return
  }
  yield (subscribed[offerRequestId] = true)

  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(offerRequestId)
  const channel = yield call(getEventChannelForFirebaseRef, ref)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const { values: offerRequest }: { values: OfferRequestDB } = yield take(
      channel,
    )
    if (offerRequest) {
      yield put(
        offerRequestUpdated({
          ...offerRequest,
          id: offerRequestId,
        }),
      )
    }
  }
}

export function* subscribeToAllOfferRequestsWorker(action: UserUpdated) {
  if (!action.user.offerRequests) {
    return
  }
  yield all(
    Object.keys(action.user.offerRequests).map(id =>
      call(subscribeToOfferRequestWorker, id),
    ),
  )
}

export function* subscribeToOfferRequestSaga() {
  yield takeEvery(USER_UPDATED, subscribeToAllOfferRequestsWorker)
}

export function* saveStatusUpdatedWorker(
  action: OfferRequestStatusUpdate | OfferStatusUpdated,
) {
  // tslint:disable-next-line:no-unsafe-any
  const offerRequestRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offerRequestId)

  yield offerRequestRef.update({ status })
}

export function* saveStatusUpdatedSaga() {
  yield all([
    takeEvery(OFFER_REQUEST_STATUS_UPDATED, saveStatusUpdatedWorker),
    takeEvery(OFFER_STATUS_UPDATED, saveStatusUpdatedWorker),
  ])
}

const findImageIds = (questions: OfferRequestQuestion[]) => {
  const imageIds: string[] = []
  questions.forEach(question => {
    if (question.type === "image" && question.images) {
      imageIds.concat(Object.keys(question.images))
    } else if (question.type === "checkbox" || question.type === "radio") {
      question.options.forEach(option => {
        if (option.questions) {
          imageIds.concat(findImageIds(option.questions))
        }
      })
    }
  })
  return imageIds
}

export function* saveOfferRequestWorker(action: OfferRequestCreate) {
  const offerRequest: Omit<OfferRequestDB, "createdAt"> = {
    buyerProfile: action.buyerProfileId,
    category: action.categoryId,
    status: "open",
    questions: action.questions,
    addresses: action.addresses,
  }

  if (offerRequest.questions.length < 1) {
    console.error(
      "OfferRequest questions can not be empty array:",
      offerRequest,
    )
    return
  }

  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .push()

  yield ref.set({
    createdAt: firebase.database.ServerValue.TIMESTAMP,
    ...offerRequest,
  })

  if (!ref.key) {
    return
  }
  const savedOfferRequestSnapshot: firebase.database.DataSnapshot = yield ref.once(
    "value",
  ) // tslint:disable-line:no-unsafe-any
  const savedOfferRequest: OfferRequestDB = yield savedOfferRequestSnapshot.val() // tslint:disable-line:no-unsafe-any
  // after saving
  yield put(
    offerRequestCreated({
      id: ref.key,
      ...savedOfferRequest,
    }),
  )

  const imageIds = findImageIds(action.questions)

  yield put(
    uploadImages({
      imageIds,
      offerRequestId: ref.key,
    }),
  )
}

export function* createOfferRequestSaga() {
  yield takeEvery(OFFER_REQUEST_CREATE, saveOfferRequestWorker)
}

export function* offerRequestReviewedWorker(action: OfferRequestReviewed) {
  // tslint:disable-next-line:no-unsafe-any
  const offerRequestRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offerRequestId)

  yield offerRequestRef.update({ reviewed: true })
}

export function* offerRequestReviewedSaga() {
  yield takeEvery(OFFER_REQUEST_REVIEWED, offerRequestReviewedWorker)
}

export default function*() {
  yield all([
    fork(createOfferRequestSaga),
    fork(subscribeToOfferRequestSaga),
    fork(saveStatusUpdatedSaga),
    fork(offerRequestReviewedSaga),
  ])
}
