import { SellerOffersState } from "./types"
import { OfferUpdated, OfferSelected } from "./actions"
import { OFFER_UPDATED, OFFER_SELECTED } from "./actionTypes"

export const INITIAL_STATE: SellerOffersState = {
  offers: {},
  selected: undefined,
}

export default function sellerOffersReducer(
  state = INITIAL_STATE,
  action: OfferUpdated | OfferSelected,
): SellerOffersState {
  switch (action.type) {
    case OFFER_UPDATED:
      return {
        ...state,
        offers: {
          ...state.offers,
          [action.offer.id]: action.offer,
        },
      }
    case OFFER_SELECTED:
      return {
        ...state,
        selected: action.offerId,
      }
    default:
      return state
  }
}
