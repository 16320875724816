import { LOAD_SETTINGS, SETTINGS_LOADED } from "./actionTypes"
import { Settings } from "./types"

export interface LoadSettings {
  type: typeof LOAD_SETTINGS
}

export interface SettingsLoaded {
  type: typeof SETTINGS_LOADED
  payload: Settings
}

export const loadSettings: () => LoadSettings = () => ({
  type: LOAD_SETTINGS,
})

export const settingsLoaded: (data: Settings) => SettingsLoaded = data => ({
  type: SETTINGS_LOADED,
  payload: data,
})
