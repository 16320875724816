import { connect } from "react-redux"
import {
  startTimeSelected,
  startTimeIsOverlapping,
} from "modules/Seller/CreateOffer/actions"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { getOpenAndAcceptedSellerOffersForSelectedDate } from "modules/Seller/Offers/selectors"
import { getSelectedDate } from "modules/Seller/CreateOffer/selectors"

const mapStateToProps = (state: Store) => {
  const selectedDate = getSelectedDate(state)
  return {
    openAndAcceptedOffersForSelectedDate: getOpenAndAcceptedSellerOffersForSelectedDate(
      state,
      selectedDate,
    ),
  }
}

const mapDispatchToProps = {
  startTimeSelected,
  startTimeIsOverlapping,
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
