import React from "react"
import { View } from "react-native"
import { Button, Tab, Tabs, Text } from "native-base"
import Reviews from "components/views/Shared/Reviews"
import OrderDetails from "components/views/Shared/OrderDetails"
import Profile from "components/views/Shared/SellerProfile"
import I18n from "i18n"
import _ from "lodash"
import styles from "./styles"
import { SellerProfile, Review } from "modules/SellerProfiles/types"
import { Offer } from "modules/Buyer/Offers/types"
import { Category } from "modules/Buyer/Categories/types"
import { OfferRequestDB } from "database_types/offerRequest"

interface Props {
  markOfferAsSeen: (offer: Offer) => void
  category?: Category
  offer?: Offer
  offerRequest?: OfferRequestDB
  sellerProfile?: SellerProfile
  reviews?: Review[]
  proceedToCheckout: (offer: Offer, category: Category) => void
}

class OfferView extends React.Component<Props> {
  componentDidMount() {
    if (this.props.offer) {
      this.props.markOfferAsSeen(this.props.offer)
    }
  }

  render() {
    const {
      offer,
      offerRequest,
      category,
      sellerProfile,
      reviews,
      proceedToCheckout,
    } = this.props
    if (!offer || !offerRequest || !sellerProfile || !category) {
      return null
    }
    return (
      <View style={styles.container}>
        <Profile sellerProfile={sellerProfile} price={offer.pricePerHour} />
        <Tabs
          tabBarUnderlineStyle={styles.tabBarUnderlineStyle}
          scrollWithoutAnimation={false}
        >
          <Tab
            heading={I18n.t("offer_details")}
            tabStyle={styles.tabStyle}
            activeTabStyle={styles.activeTabStyle}
            textStyle={styles.textStyle}
            activeTextStyle={styles.activeTextStyle}
          >
            <OrderDetails offerRequest={offerRequest} offer={offer} />
          </Tab>
          <Tab
            heading={I18n.t("reviews")}
            tabStyle={styles.tabStyle}
            activeTabStyle={styles.activeTabStyle}
            textStyle={styles.textStyle}
            activeTextStyle={styles.activeTextStyle}
          >
            {reviews ? (
              <Reviews reviews={reviews} sellerProfile={sellerProfile} />
            ) : null}
          </Tab>
        </Tabs>
        {offer.status === "open" ? (
          <View style={styles.buttonWrap}>
            <Button
              block
              rounded
              onPress={() => proceedToCheckout(offer, category)}
              style={styles.button}
            >
              <Text style={styles.buttonText}>
                {I18n.t("proceed_to_checkout")}
              </Text>
            </Button>
          </View>
        ) : null}
      </View>
    )
  }
}
export default OfferView
