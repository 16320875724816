import { Dimensions } from "react-native"

export const windowDivider = 10
export const windowHeight = Dimensions.get("window").height
export const stepInHour = windowHeight / windowDivider
export const HOURS = 24

export const convertTimeToIndex = (time: string) => {
  const arr = time.split(":")
  const hours = parseInt(arr[0], 10)
  const mins = parseInt(arr[1], 10)
  const result = hours * 2 + (mins === 30 ? 1 : 0)
  return result
}
