import Constants, { AppOwnership } from "expo-constants"

const DEVELOPMENT = "development"
const PRODUCTION = "production"
const DEVELOPMENT_DATABSE_URL ='https://kolf-mp.firebaseio.com/' // "https://kolf.com" // old "https://helpdor-staging.firebaseio.com"
const PRODUCTION_DATABASE_URL = 'https://kolf-mp.firebaseio.com/'//"https://kolf.com" // old "https://helpdor-production.firebaseio.com"
//todo : add second db

export const isProductionEnvironment = () => {
  const { releaseChannel } = Constants.manifest
  if (releaseChannel && (releaseChannel as string).indexOf("prod") !== -1) {
    return true
  }
  return false
}

export const getEnvironment = () => {
  const isProduction = isProductionEnvironment()
  return isProduction ? PRODUCTION : DEVELOPMENT
}

export const getDatabaseUrl = async () => {
  const isProduction = isProductionEnvironment()
  return isProduction ? PRODUCTION_DATABASE_URL : DEVELOPMENT_DATABSE_URL
}

export interface VersionData {
  expoVersion: string
  appOwnership: AppOwnership
  releaseChannel: any
  versionCode: any
}

const getVersionCode = () => {
  if (Constants.platform) {
    if (Constants.platform.android) {
      return Constants.platform.android.versionCode
    } else if (Constants.platform.ios) {
      return Constants.platform.ios.versionCode
    }
  }
  return "unknown"
}
export const getVersion: () => { appOwnership: AppOwnership | null; releaseChannel: string | undefined; expoVersion: string; versionCode: number | any | string } = () => {
  return {
    expoVersion: Constants.expoVersion || "unknown",
    appOwnership: Constants.appOwnership,
    releaseChannel: Constants.manifest.releaseChannel,
    versionCode: getVersionCode(),
  }
}
