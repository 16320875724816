import { Platform } from "react-native"
import { connect } from "react-redux"
import * as Google from "expo-google-app-auth"
import * as GoogleSignIn from "expo-google-sign-in"
import Constants from "expo-constants"
import * as Facebook from "expo-facebook"
import firebase from "firebase"
import { authIds } from "config"
import routeNames from "components/navigators/constants"
import View from "./view"
import ViewWeb from "./view.web"
import { selectComponent } from "utilities/crossPlatform"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { StackActions } from "@react-navigation/native"
import {
  getAuthErrorMessage,
  isUserAuthenticating,
  isUserLoggedIn,
} from "modules/User/selectors"
import {
  authenticating,
  authenticateWithCredential,
  authenticationFailed,
} from "modules/User/actions"
import { AuthSessionResult } from "expo-auth-session/src/AuthSession.types"

const mapDispatchToProps = {
  authenticating,
  authenticateWithCredential,
  authenticationFailed,
}

const googleSignInWeb = async (dispatchProps: typeof mapDispatchToProps) => {
  // tslint:disable-next-line
  
  var provider = new firebase.auth.GoogleAuthProvider()
  try {
    console.log('cursor1 ==================')
     await firebase.auth().signInWithPopup(provider)
  } catch (e) {
    console.log('google auth err ==================', e)
  }
}
const googleSignInExpo = async (dispatchProps: typeof mapDispatchToProps) => {
  // tslint:disable-next-line
  
  const result = await Google.logInAsync({
    iosClientId: authIds.iosClientId,
    androidClientId: authIds.androidClientId,
    scopes: ["profile", "email"],
    clientId: authIds.webClientId,
  })
  if (result.type === "success") {
    const credential = firebase.auth.GoogleAuthProvider.credential(
      result.idToken,
      result.accessToken,
    )
    dispatchProps.authenticateWithCredential(credential)
  } else {
    dispatchProps.authenticationFailed(
      new Error("googleSignInExpo: Google signin failed with: " + result.type),
    )
  }
}



  // if (result.type === "success") {
  //   const credential = firebase.auth.GoogleAuthProvider.credential(
  //     result.idToken,
  //     result.accessToken,
  //   )
  //   dispatchProps.authenticateWithCredential(credential)
  // } else {
  //   dispatchProps.authenticationFailed(
  //     new Error("googleSignInWeb: Google signin failed with: " + result.type),
  //   )
  // }


const signInSilentlyAsync = async (
  dispatchProps: typeof mapDispatchToProps,
) => {
  const user = await GoogleSignIn.signInSilentlyAsync()
  if (user && user.auth) {
    const credential = firebase.auth.GoogleAuthProvider.credential(
      user.auth.idToken,
      user.auth.accessToken,
    )
    dispatchProps.authenticateWithCredential(credential)
    return true
  }
  return false
}

const googleSignInNative = async (dispatchProps: typeof mapDispatchToProps) => {
  await GoogleSignIn.initAsync({ clientId: authIds.iosStandaloneAppClientId })
  const signedIn = await signInSilentlyAsync(dispatchProps)
  if (!signedIn) {
    await GoogleSignIn.askForPlayServicesAsync()
    const { type, user } = await GoogleSignIn.signInAsync()
    if (type === "success" && user) {
      await signInSilentlyAsync(dispatchProps)
    } else {
      dispatchProps.authenticationFailed(
        new Error("googleSignInNative: Google signin failed with: " + type),
      )
    }
  }
}

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => ({
  isLoggedIn: isUserLoggedIn(state),
  isAuthenticating: isUserAuthenticating(state),
  authErrorMessage: getAuthErrorMessage(state),
})

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  googleAuth: async () => {
    dispatchProps.authenticating()
    try {
      if ( Constants.appOwnership === "expo" ) {
        await googleSignInExpo(dispatchProps)
      } else if (Platform.OS == 'web') {

        await googleSignInWeb(dispatchProps)
      } else {
        await googleSignInNative(dispatchProps)
      }
    } catch (error) {
      dispatchProps.authenticationFailed(error as Error)
    }
  },
  fbAuth: async () => {
    dispatchProps.authenticating()

    try {
      await Facebook.initializeAsync(authIds.facebookAppId)
      const result = await Facebook.logInWithReadPermissionsAsync({
        permissions: ["public_profile", "email"],
      })
      if (result.type === "success" && result.token) {
        const credential = firebase.auth.FacebookAuthProvider.credential(
          result.token,
        )
        dispatchProps.authenticateWithCredential(credential)
      } else {
        dispatchProps.authenticationFailed(
          new Error("Facebook authentication error: " + result.type),
        )
      }
    } catch (error) {
      dispatchProps.authenticationFailed(error as Error)
    }
  },
  fbAuthWeb: async (response: AuthSessionResult) => {
    try {
      if (response?.type === "success") {
        // eslint-disable-next-line @typescript-eslint/camelcase
        const { access_token } = response.params;
        const credential = firebase.auth.FacebookAuthProvider.credential(
          access_token,
        )
        firebase.auth().signInWithCredential(credential);
        dispatchProps.authenticateWithCredential(credential)
      }
    } catch (error) {
      dispatchProps.authenticationFailed(error as Error)
    }
  },
  ready: () => {
    ownProps.navigation.dispatch(StackActions.replace(routeNames.SIGN_IN.INDEX))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(selectComponent({ base: View, web: ViewWeb }))
