import { Dimensions, StyleSheet, PixelRatio } from "react-native"
import Colors from "constants/Colors"
import { separatorText } from "constants/Fonts"

export default StyleSheet.create({
  listWrap: {
    flex: 1,
  },
  list: {
    backgroundColor: Colors.contentBackground,
    borderColor: Colors.borderColor,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  separator: {
    backgroundColor: Colors.containerBackground,
    borderColor: Colors.borderColor,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    height: "auto",
  },
  separatorText: {
    ...separatorText,
    paddingBottom: 10,
    paddingTop: 30,
  },
  headerBorder: {
    width: Dimensions.get("window").width,
    backgroundColor: Colors.headerBackground,
    borderColor: Colors.borderColor,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  subHeaderWrapper: {
    justifyContent: "center",
    paddingBottom: 15,
    width: Dimensions.get("window").width,
    backgroundColor: Colors.headerBackground,
    borderColor: Colors.borderColor,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  fetchingOfferText: {
    color: Colors.noteColor,
    fontSize: 13,
    fontFamily: "open-sans-regular",
    alignSelf: "center",
  },
  detailsWrapper: {
    paddingTop: 45,
  },
})
