import validator from "validator"

export const validateName = (name = "") => {
  return name.trim().length > 0
}

export const validateEmail = (email = "") => validator.isEmail(email)

export const validateBirthdate = (birthdate: Date | string) => {
  //https://stackoverflow.com/a/50827764
  const age = Math.floor(
    (new Date().getTime() - new Date(birthdate).getTime()) / 3.15576e10,
  )
  return age >= 18
}
