import { connect } from "react-redux"
import _ from "lodash"
import routeNames from "components/navigators/constants"
import { getTempSellerProfile } from "modules/SellerProfiles/selectors"
import { getUser } from "modules/User/selectors"
import * as UserActions from "modules/User/actions"
import * as SellerProfilesActions from "modules/SellerProfiles/actions"
import idGenerator from "utilities/idGenerator"
import { SellerCompanyInfo, Props } from "./view.web"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { SellerProfileCompanyInfo } from "modules/SellerProfiles/types"
import { getWorkAreas } from "modules/Settings/selectors"
import { CommonActions } from "@react-navigation/native"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const user = getUser(state)
  const tempSellerProfile = getTempSellerProfile(state)
  const workAreaOptions = getWorkAreas(state)
  return {
    id: (user && user.id) || "",
    companyName: (tempSellerProfile && tempSellerProfile.companyName) || "",
    companyVAT: (tempSellerProfile && tempSellerProfile.companyVAT) || "",
    workAreas: (tempSellerProfile && tempSellerProfile.workAreas) || [],
    tempSellerProfile,
    user,
    workAreaOptions,
    postalCode: (tempSellerProfile && tempSellerProfile.postalCode) || "",
    city: (tempSellerProfile && tempSellerProfile.city) || "Helsinki",
    iban: (tempSellerProfile && tempSellerProfile.iban) || "",
    streetAddress: (tempSellerProfile && tempSellerProfile.streetAddress) || "",
  }
}

const mapDispatchToProps = {
  createSellerProfile: SellerProfilesActions.createSellerProfile,
  updateUser: UserActions.updateUser,
  uploadFile: SellerProfilesActions.uploadFile,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
): Props => ({
  ...stateProps,
  ...ownProps,
  finish: (data: SellerProfileCompanyInfo) => {
    const profile = _.clone(stateProps.tempSellerProfile)
    if (!profile) {
      return
    }
    profile.id = stateProps.id

    // Upload images
    if (data.certificate && data.certificate.cancelled === false) {
      const id = idGenerator()
      dispatchProps.uploadFile(
        id,
        stateProps.id,
        "certificate",
        data.certificate.uri,
        data.certificate.width,
        data.certificate.height,
      )
      profile.certificate = id // save file id on firebase
    }

    if (data.insurance && data.insurance.cancelled === false) {
      const id = idGenerator()
      dispatchProps.uploadFile(
        id,
        stateProps.id,
        "insurance",
        data.insurance.uri,
        data.insurance.width,
        data.insurance.height,
      )
      profile.insurance = id // save file id on firebase
    }

    profile.companyVAT = data.companyVAT
    profile.workAreas = data.workAreas || []
    profile.city = data.city
    profile.streetAddress = data.streetAddress
    profile.postalCode = data.postalCode
    profile.iban = data.iban

    dispatchProps.createSellerProfile(profile)
    dispatchProps.updateUser(
      _.merge(stateProps.user, { id: stateProps.id, hasSellerProfile: true }),
    )
    ownProps.navigation.dispatch(
      CommonActions.navigate(routeNames.SELLER_SWITCHER.SWITCH),
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(SellerCompanyInfo)
