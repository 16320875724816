import React from "react"
import _ from "lodash"
import { View, ScrollView, TouchableOpacity } from "react-native"
import { Text } from "native-base"
import moment, { Moment } from "moment"
import I18n from "i18n"
import styles from "./styles"
import CalendarDay from "./CalendarDay"
import { DateQuestion, SuitableTimes } from "modules/Buyer/Questions/types"

const dateFormat = "YYYY-MM-DD"
const daysOffset = 7

interface PeriodPickerProps {
  question: DateQuestion
  isSelectionAllowed?: boolean
  isSubQuestion?: boolean
  onSuitableTimeSelected: (props: {
    dateKey: string
    day: string
    startTime: Moment
    endTime: Moment
  }) => void
  shouldScrollToBottom: (should: true) => void
}

interface State {
  showCalendar: boolean
}

export default class PeriodPicker extends React.Component<PeriodPickerProps> {
  state: State
  scrollView?: ScrollView | null
  constructor(props: PeriodPickerProps) {
    super(props)
    this.state = {
      showCalendar: false,
    }
  }
  componentDidMount() {
    this.scrollToEnd()
  }
  componentDidUpdate(prevProps: PeriodPickerProps) {
    if (
      prevProps.question.preferredTime !== this.props.question.preferredTime
    ) {
      this.scrollToEnd()
    }
  }
  scrollToEnd() {
    const scroll = () => {
      const scrollView = this.scrollView
      if (scrollView) {
        const numberOfItems = React.Children.toArray(scrollView.props.children)
          .length
        const preferredIndex =
          numberOfItems > 0 ? numberOfItems - daysOffset : -1
        if (preferredIndex >= 0) {
          scrollView.scrollTo({ x: preferredIndex * 130 })
        }
      }
    }
    setTimeout(() => scroll, 1)
  }
  getDefaultDates(now: Moment) {
    const afterdates = [...Array(daysOffset + 1)].map((x, idx) =>
      now.clone().add(idx, "days"),
    )
    const preferredDay = moment(moment(now, "x").format(dateFormat))
    return { preferredDay, flexible: afterdates }
  }
  getDatesWithPreferredDay(now: Moment, preferredTime: number) {
    const preferredDay = moment(moment(preferredTime, "x").format(dateFormat))
    const dayBeforePreferredDayByOffset = moment(preferredDay).subtract(
      daysOffset,
      "days",
    )
    const adjustedDayBeforePreferredDayByOffset = dayBeforePreferredDayByOffset.isSameOrBefore(
      now,
    )
      ? now
      : dayBeforePreferredDayByOffset
    const diffInDays = preferredDay.diff(
      adjustedDayBeforePreferredDayByOffset,
      "days",
    )
    const adjustedDiffInDays =
      diffInDays < daysOffset ? diffInDays + 1 : diffInDays
    // Logic for getting rest of the dates between two dates("FromDate" to "EndDate")
    const beforedates = [...Array(adjustedDiffInDays)].map((x, idx) =>
      adjustedDayBeforePreferredDayByOffset.clone().add(idx, "days"),
    )
    const afterdates = [...Array(daysOffset)].map((x, idx) =>
      preferredDay.clone().add(idx + 1, "days"),
    )
    const dates = beforedates.concat(preferredDay).concat(afterdates)
    return { preferredDay, flexible: dates }
  }
  getDates(preferredTime?: number | null) {
    const now = moment()
    if (preferredTime) {
      return this.getDatesWithPreferredDay(now, preferredTime)
    }
    return this.getDefaultDates(now)
  }
  getDatesIFSelectionNotAllowed(
    preferredTime?: number | null,
    suitableTimes?: SuitableTimes | null,
  ) {
    const selectedDays = _.uniq(
      _.map(suitableTimes, suitableTime => {
        return suitableTime.day
      }),
    )

    const dates = _.map(selectedDays, selectedDay => {
      return moment(moment(selectedDay).format(dateFormat))
    })

    const preferredDay = moment(
      moment(preferredTime || undefined, "x").format(dateFormat),
    )

    return { preferredDay, flexible: dates }
  }
  renderCalendar(isSelectionAllowed?: boolean) {
    const { question } = this.props
    const { preferredTime, suitableTimes } = question
    const dates = isSelectionAllowed
      ? this.getDates(preferredTime)
      : this.getDatesIFSelectionNotAllowed(preferredTime, suitableTimes)
    return (
      <View style={styles.calendar}>
        {isSelectionAllowed ? (
          <View style={{ margin: 20 }}>
            <Text style={styles.flexibleTimeDescription}>
              {I18n.t("flexible_times_description")}
            </Text>
          </View>
        ) : null}
        <ScrollView
          ref={ref => {
            this.scrollView = ref
          }}
          style={[styles.grid]}
          horizontal
        >
          {dates.flexible.map((x, idx) => {
            return (
              <CalendarDay
                key={x.toString()}
                day={x}
                selectedDates={_.keys(question.suitableTimes)}
                onTimeSelected={this.props.onSuitableTimeSelected}
                preferredDay={dates.preferredDay}
                preferredTimeHour={moment(
                  question.preferredTime || undefined,
                  "x",
                ).hour()}
                isFirst={idx === 0}
              />
            )
          })}
        </ScrollView>
      </View>
    )
  }
  onPress = () => {
    this.setState({ showCalendar: true })
    this.props.shouldScrollToBottom(true)
  }
  render() {
    const { isSelectionAllowed, isSubQuestion } = this.props
    const { showCalendar } = this.state
    return (
      <View
        style={[
          styles.container,
          isSubQuestion ? styles.isSubQuestionContainer : null,
        ]}
      >
        {isSelectionAllowed && !showCalendar ? (
          <TouchableOpacity
            onPress={this.onPress}
            style={styles.flexibleTimeView}
          >
            <Text style={styles.flexibleTime}>
              {I18n.t("select_flexible_times")}
            </Text>
          </TouchableOpacity>
        ) : null}
        {showCalendar || !isSelectionAllowed
          ? this.renderCalendar(isSelectionAllowed)
          : null}
      </View>
    )
  }
}
