import {
  OFFER_REQUEST_SELECTED,
  OFFER_REQUEST_CREATE,
  OFFER_REQUEST_CREATED,
  OFFER_REQUEST_UPDATED,
  OFFER_REQUEST_STATUS_UPDATED,
  OFFER_REQUEST_REVIEWED,
} from "./actionTypes"
import { OfferRequest } from "./types"
import { OFFER_REQUEST_STATUS } from "constants/Status"
import { AddressesAnswered } from "database_types/offerRequest"
import { OfferRequestQuestion } from "database_types/offerRequestQuestions"

export interface OfferRequestSelected {
  type: typeof OFFER_REQUEST_SELECTED
  offerRequestId: string
}
export const offerRequestSelectedByBuyer: (
  offerRequestId: string,
) => OfferRequestSelected = offerRequestId => {
  return {
    type: OFFER_REQUEST_SELECTED,
    offerRequestId,
  }
}

interface OfferRequestCreateProps {
  categoryId: string
  buyerProfileId: string
  addresses: AddressesAnswered
  questions: OfferRequestQuestion[]
}
export interface OfferRequestCreate extends OfferRequestCreateProps {
  type: typeof OFFER_REQUEST_CREATE
}
export const offerRequestCreate: (
  props: OfferRequestCreateProps,
) => OfferRequestCreate = props => {
  return {
    type: OFFER_REQUEST_CREATE,
    ...props,
  }
}

export interface OfferRequestCreated {
  type: typeof OFFER_REQUEST_CREATED
  offerRequest: OfferRequest
}
export const offerRequestCreated: (
  offerRequest: OfferRequest,
) => OfferRequestCreated = offerRequest => {
  return {
    type: OFFER_REQUEST_CREATED,
    offerRequest,
  }
}

export interface OfferRequestUpdated {
  type: typeof OFFER_REQUEST_UPDATED
  offerRequest: OfferRequest
}
export const offerRequestUpdated: (
  offerRequest: OfferRequest,
) => OfferRequestUpdated = offerRequest => {
  return {
    type: OFFER_REQUEST_UPDATED,
    offerRequest,
  }
}

interface OfferRequestStatusUpdateProps {
  offerRequestId: string
  status: OFFER_REQUEST_STATUS
}
export interface OfferRequestStatusUpdate
  extends OfferRequestStatusUpdateProps {
  type: typeof OFFER_REQUEST_STATUS_UPDATED
}
export const offerRequestStatusUpdate: (
  props: OfferRequestStatusUpdateProps,
) => OfferRequestStatusUpdate = props => {
  return {
    type: OFFER_REQUEST_STATUS_UPDATED,
    ...props,
  }
}

export interface OfferRequestReviewed {
  type: typeof OFFER_REQUEST_REVIEWED
  offerRequestId: string
}
export const offerRequestReviewed: (
  offerRequestId: string,
) => OfferRequestReviewed = offerRequestId => {
  return {
    type: OFFER_REQUEST_REVIEWED,
    offerRequestId,
  }
}
