import React from "react"
import _ from "lodash"
import { View } from "react-native"
import { Body, Icon, Right, ListItem, Text } from "native-base"
import moment from "moment"
import ProfileImage from "components/views/Shared/ProfileImage"
import styles from "./styles"
import { Offer } from "modules/Buyer/Offers/types"
import { BuyerProfile } from "modules/BuyerProfiles/types"
import { Category } from "modules/Buyer/Categories/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface Props {
  offer?: Offer
  buyerProfile?: BuyerProfile
  category?: Category
  onPress: () => void
  last: boolean
}

export default ({ offer, buyerProfile, category, onPress, last }: Props) => {
  if (!buyerProfile || !category || !offer || !offer.actualStartTime) {
    return null
  }
  const date = moment(offer.actualStartTime)
  const completedDate = date.isValid() ? date.format("D.M. [klo] HH:mm") : ""
  const photoUrl = buyerProfile.photoURL
  return (
    <ListItem onPress={onPress} style={last ? styles.lastItem : null}>
      <ProfileImage style={styles.thumbnail} small source={photoUrl} />
      <Body style={styles.textWrap}>
        <Text style={styles.title}>{buyerProfile.displayName}</Text>
        <View style={styles.info}>
          <Text style={styles.infoText}>{getLocaLizedName(category.name)}</Text>
          <Text style={styles.infoSeparator}>•</Text>
          {completedDate ? (
            <Text style={styles.infoText}>{completedDate}</Text>
          ) : null}
        </View>
      </Body>
      <Right>
        <View style={styles.rightWrap}>
          <Icon name="arrow-forward" style={styles.icon} />
        </View>
      </Right>
    </ListItem>
  )
}
