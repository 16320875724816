import { connect } from "react-redux"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { getOpenOffersForRequestForBuyer } from "modules/Buyer/Offers/selectors"
import { getSelectedOfferRequestForBuyer } from "modules/Buyer/OfferRequests/selectors"

const mapStateToProps = (state: Store) => {
  const offerRequest = getSelectedOfferRequestForBuyer(state)
  return {
    offerRequest,
    offers: offerRequest
      ? getOpenOffersForRequestForBuyer(state, offerRequest)
      : [],
  }
}

export default connect(mapStateToProps)(View)
