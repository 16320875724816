import { connect } from "react-redux"
import _ from "lodash"
import View from "./view.web"
import { Store } from "utilities/Redux/store"
import { Question } from "modules/Buyer/Questions/types"
import { Navigation } from "components/types"
import { getSelectedCategory } from "modules/Buyer/Categories/selectors"
import {
  getQuestionById,
  isNotValid,
  getInputModalQuestionId,
  getErrorsForCategory,
  getScrollPosition,
} from "modules/Buyer/Questions/selectors"
import { updateErrorVisibility } from "modules/Buyer/Questions/actions"

interface OwnProps {
  questionId: string
  isSubQuestion?: boolean
  navigation: Navigation
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => {
  const selectedCategory = getSelectedCategory(state)
  return {
    question: getQuestionById(state, ownProps.questionId),
    isInvalid: isNotValid(state, ownProps.questionId),
    inputModalQuestionId: getInputModalQuestionId(state),
    errorsForCategory: selectedCategory
      ? getErrorsForCategory(state, selectedCategory)
      : undefined,
    scrollPosition: getScrollPosition(state),
  }
}

const mapDispatchToProps = {
  updateErrorVisibility,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  navigation: ownProps.navigation,
  question: stateProps.question,
  isSubQuestion: ownProps.isSubQuestion || false,
  errorsForCategory: stateProps.errorsForCategory || [],
  inputModalQuestionId: stateProps.inputModalQuestionId,
  updateErrorVisibility: (question: Question, isSubQuestion?: boolean) => {
    if (!isSubQuestion) {
      setTimeout(() => {
        const { id, position, showError } = question
        const { errorsForCategory, scrollPosition } = stateProps
        if (
          !showError &&
          _.includes(errorsForCategory, id) &&
          (position || 0) < scrollPosition
        ) {
          dispatchProps.updateErrorVisibility({
            questionId: id,
            showError: true,
          })
        }
        if (showError && !_.includes(errorsForCategory, id)) {
          dispatchProps.updateErrorVisibility({
            questionId: id,
            showError: false,
          })
        }
      }, 3000)
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
