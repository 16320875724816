import { connect } from "react-redux"
import {
  toggleScrollEnabled,
  closeInputModal,
  textChanged,
} from "modules/Buyer/Questions/actions"
import View from "./view"
import { Store } from "utilities/Redux/store"
import {
  getScrollEnabled,
  getInputModalQuestionId,
  getQuestionById,
} from "modules/Buyer/Questions/selectors"

const mapStateToProps = (state: Store) => {
  const modalQuestionId = getInputModalQuestionId(state)
  const question = modalQuestionId
    ? getQuestionById(state, modalQuestionId)
    : undefined
  const numberInputQuestion =
    question && question.type === "number_input" ? question : undefined
  const textAreaQuestion =
    question && question.type === "textarea" ? question : undefined
  return {
    question: numberInputQuestion || textAreaQuestion || undefined,
    scrollEnabled: getScrollEnabled(state),
  }
}

const mapDispatchToProps = {
  toggleScrollEnabled,
  closeInputModal,
  textChanged,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  closeInputTextModal: () => {
    dispatchProps.closeInputModal()
    setTimeout(() => {
      dispatchProps.toggleScrollEnabled(stateProps.scrollEnabled)
    }, 100)
  },
  updateText: (value: string) => {
    const { question } = stateProps
    if (question) {
      dispatchProps.textChanged({ questionId: question.id, value })
    }
  },
  confirm: () => {
    dispatchProps.closeInputModal()
    setTimeout(() => {
      dispatchProps.toggleScrollEnabled(stateProps.scrollEnabled)
    }, 100)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
