import React from "react"

interface Props {
  notification?: any
  type?: any
  navigateToNotification: () => void
}

class Navigator extends React.Component<Props> {
  componentDidMount() {
    this.navigate()
  }

  componentDidUpdate() {
    this.navigate()
  }

  navigate() {
    if (this.props.notification && this.props.type) {
      this.props.navigateToNotification()
    }
  }

  render() {
    return null
  }
}

export default Navigator
