import React, { useState, useEffect } from "react"
import moment from "moment"
import _ from "lodash"
import { Toast } from "native-base"
import { View, ScrollView } from "react-native"
import I18n from "i18n"
import styles from "./styles"
import { SuitableTime } from "modules/Buyer/Questions/types"
import { windowHeight, windowDivider, stepInHour, HOURS } from "./helpers"
import Slots from "./Slots"
import OpenOrConfirmedSlots from "./OpenOrConfirmedSlots"
import PreSelectedSlots from "./PreSelectedSlots"
import SelectedSlot from "./SelectedSlot"
import { TempOffer } from "modules/Seller/CreateOffer/types"

interface TimeSlotsProps {
  selectedDate?: string | null
  duration?: number
  offer?: TempOffer
  suitableTimesForTheDay?: SuitableTime[]
  selectedStartTime?: string | null
  selectStartTime: (startTime: string, duration: number) => void
  categoryName?: string
  buyerName?: string
  isOverlapping?: boolean
}

export default function TimeSlots(props: TimeSlotsProps) {
  const [scrolled, setScrolled] = useState<boolean>(false)
  const [scrollView, setScrollView] = useState<null | ScrollView>(null)

  const {
    suitableTimesForTheDay,
    categoryName,
    selectedDate,
    selectedStartTime,
    buyerName,
    offer,
    isOverlapping,
  } = props

  useEffect(() => {
    if (!scrolled && scrollView && suitableTimesForTheDay) {
      setScrolled(true)
      setTimeout(() => {
        const startTime = suitableTimesForTheDay[0].startTime
        const startTimeHour = Number(moment(startTime, "x").format("HH"))
        const scrollPosition =
          (windowHeight / windowDivider) * startTimeHour + 30
        scrollView.scrollTo({ y: scrollPosition, animated: true })
      }, 50)
    }
  })

  if (!offer) {
    return null
  }
  const duration = offer.duration
  if (!suitableTimesForTheDay || !duration) {
    return null
  }

  return (
    <ScrollView
      ref={ref => {
        setScrollView(ref)
      }}
    >
      <View style={[styles.timetable, { height: stepInHour * HOURS }]}>
        <Slots
          suitableTimesForTheDay={Object.values(suitableTimesForTheDay)}
          selectTimeSlot={(startTime: string) => {
            const startTimeMoment = moment(`${props.selectedDate}T${startTime}`)
            if (startTimeMoment.isBefore(moment())) {
              Toast.show({
                text: I18n.t("time_in_past_not_allowed"),
                position: "bottom",
                duration: 1000,
                style: styles.toast,
                textStyle: styles.toastText,
              })
              return
            }
            props.selectStartTime(startTime, duration)
          }}
        />
        <OpenOrConfirmedSlots />
        {categoryName && selectedDate && selectedStartTime ? (
          <PreSelectedSlots
            selectedStartTime={selectedStartTime}
            selectedDate={selectedDate}
            offer={offer}
            categoryName={categoryName}
          />
        ) : null}
        {categoryName && selectedStartTime && offer && buyerName ? (
          <SelectedSlot
            selectedStartTime={selectedStartTime}
            offer={offer}
            buyerName={buyerName}
            categoryName={categoryName}
            isOverlapping={isOverlapping}
          />
        ) : null}
      </View>
    </ScrollView>
  )
}
