import _ from "lodash"
import { connect } from "react-redux"
import moment from "moment"
import View from "./view"
import { Store } from "utilities/Redux/store"
import {
  shouldScrollToBottom,
  resetSuitableTimes,
  preferredTimeChanged,
  onSuitableTimeSelected,
} from "modules/Buyer/Questions/actions"
import { getSelectedDateQuestion } from "modules/Buyer/Questions/selectors"

const mapStateToProps = (state: Store) => ({
  question: getSelectedDateQuestion(state),
})

const mapDispatchToProps = {
  shouldScrollToBottom,
  resetSuitableTimes,
  preferredTimeChanged,
  onSuitableTimeSelected,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
) => ({
  date:
    stateProps.question && stateProps.question.preferredTime
      ? new Date(Number(stateProps.question.preferredTime))
      : new Date(),
  dateChanged: (date: Date) => {
    const { question } = stateProps
    if (question) {
      dispatchProps.resetSuitableTimes(question.id)

      dispatchProps.preferredTimeChanged({
        questionId: question.id,
        date: moment(date),
      })
      const time = moment(date.getTime())
      const hours = date.getHours()
      const dateFormat = "YYYY-MM-DD"
      const preferredTime = moment(date)
      const preferredDay = moment(moment(preferredTime, "x").format(dateFormat))
      const formattedDate = preferredDay.clone().format(dateFormat)
      const dateKey = `${formattedDate}_${hours}_${hours}`
      dispatchProps.onSuitableTimeSelected({
        questionId: question.id,
        dateKey,
        day: formattedDate,
        startTime: time,
        endTime: time,
      })
    }
  },
  updateBottomScrollStatus: () => {
    dispatchProps.shouldScrollToBottom(true)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
