import _ from "lodash"
import moment, { Moment } from "moment"
import "moment/min/locales"
import I18n from "i18n"
import { Offer } from "../modules/Buyer/Offers/types"

export const getMoment = (date: string | number | Date | Moment) => {
  if (moment.isMoment(date)) {
    return date
  } else if (moment.isDate(date)) {
    return moment(date)
  } else if (Number.isInteger(Number(date))) {
    // timestamp
    return moment(date, "x") // timestamp
  } else {
    return moment(date)
  }
}

type Format =
  | "dateAndTimeFormat"
  | "dateAndTimeFormatWithDay"
  | "timeFormatToday"
  | "timeFormatYesterday"
  | "timeFormatWeek"

// Convert i18n locale to something that moment.js can work with
// For instance, en-RU turns into en-gb. Es-es will turn into 'es'.
const momentLocale = (locale: string) => {
  const arr = locale.toLowerCase().split("-")
  if (arr[0] !== "en") {
    return arr[0]
  } else {
    return "en-gb"
  }
}

export const formatDateAndTime = (
  date: string | number | Date | Moment,
  format: Format,
) => {
  moment.locale(momentLocale(I18n.locale))
  return getMoment(date).format(I18n.t(format))
}

export const getTimeRemainingInMinutes = (time: number | Moment | Date) => {
  const currentTime = moment()
  const deadlineTime = getMoment(time)
  return deadlineTime.diff(currentTime, "minutes")
}

export const getTimeRemainingString = (minutesRemaining: number) => {
  let diffInMinutes
  let ago
  if (minutesRemaining > 0) {
    diffInMinutes = minutesRemaining
    ago = ""
  } else {
    diffInMinutes = -1 * minutesRemaining
    ago = ` ${I18n.t("ago")}`
  }

  const hours = Math.floor(diffInMinutes / 60)
  const minutes = diffInMinutes - hours * 60
  if (hours > 0) {
    return `${hours}h ${minutes}min${ago}`
  }
  return `${minutes}min${ago}`
}

export const getOfferStartDateString = (offer: Offer) => {
  return formatDateAndTime(
    offer.actualStartTime || offer.agreedStartTime,
    "dateAndTimeFormat",
  )
}

export const getTimePassedInHours = (time: number) => {
  const currentTime = moment()
  const timeInPast = getMoment(time)
  return currentTime.diff(timeInPast, "hours")
}

export const getOfferDuration = (offer: Offer) => {
  const duration =
    (offer.original.actualDuration || offer.original.purchaseDuration) +
    (offer.extensions
      ? offer.extensions.reduce((extensionsDuration, extension) => {
          return extension.actualDuration || extension.purchaseDuration
        }, 0)
      : 0)
  return duration
}
