import * as userActionTypes from "../User/actionTypes"
import { BuyerProfileUpdated, BuyerProfileAdd } from "./actions"
import { AuthStatusChanged, LogOut } from "../User/actions"
import { BuyerProfilesState } from "./types"
import { BUYER_PROFILE_UPDATED, BUYER_PROFILE_ADD } from "./actionTypes"

export const INITIAL_STATE: BuyerProfilesState = {
  profiles: {},
}

export default function buyerProfilesReducer(
  state = INITIAL_STATE,
  action: BuyerProfileUpdated | BuyerProfileAdd | AuthStatusChanged | LogOut,
): BuyerProfilesState {
  switch (action.type) {
    case BUYER_PROFILE_UPDATED:
      return {
        ...state,
        fetching: false,
        profiles: {
          ...state.profiles,
          [action.buyerProfile.id]: action.buyerProfile,
        },
      }
    case BUYER_PROFILE_ADD:
      return {
        ...state,
        fetching: false,
        profiles: {
          ...state.profiles,
          [action.buyerProfile.id]: action.buyerProfile,
        },
      }
    case userActionTypes.AUTH_STATUS_CHANGED:
      return {
        ...state,
        fetching: action.user ? true : false,
      }
    case userActionTypes.LOG_OUT:
      const profiles = Object.keys(state.profiles).reduce(
        (filteredProfiles, id) => {
          if (id !== action.userId) {
            filteredProfiles[id] = state.profiles[id]
          }
          return filteredProfiles
        },
        {} as BuyerProfilesState["profiles"],
      )
      return {
        ...state,
        fetching: true,
        profiles: {
          ...profiles,
        },
      }
    default:
      return state
  }
}
