import { ChatMessagesState } from "./types"
import { ChatMessageSent, ChatMessageUpdated } from "./actions"
import { CHAT_MESSAGE_SENT, CHAT_MESSAGE_UPDATED } from "./actionTypes"

export const INITIAL_STATE: ChatMessagesState = {}

export default function chatMessagesReducer(
  state = INITIAL_STATE,
  action: ChatMessageSent | ChatMessageUpdated,
): ChatMessagesState {
  switch (action.type) {
    case CHAT_MESSAGE_SENT:
      return {
        ...state,
        [action.message.id]: action.message,
      }
    case CHAT_MESSAGE_UPDATED:
      return {
        ...state,
        [action.message.id]: action.message,
      }
    default:
      return state
  }
}
