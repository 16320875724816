import _ from "lodash"
import { IMAGE_UPDATED } from "./actionTypes"
import { ImageUpdated } from "./actions"
import {
  QUESTION_IMAGE_ADDED,
  QUESTION_IMAGE_REMOVED,
} from "../Buyer/Questions/actionTypes"
import { ImageAdded, ImageRemoved } from "../Buyer/Questions/actions"
import { ImagesState } from "./types"

export const INITIAL_STATE: ImagesState = {}

export default function imagesReducer(
  state = INITIAL_STATE,
  action: ImageAdded | ImageRemoved | ImageUpdated,
): ImagesState {
  switch (action.type) {
    case QUESTION_IMAGE_ADDED:
      return {
        ...state,
        [action.id]: {
          id: action.id,
          localUri: action.uri,
          width: action.width,
          height: action.height,
          base64: action.base64,
        },
      }
    case QUESTION_IMAGE_REMOVED:
      return _.omit(state, action.imageId)
    case IMAGE_UPDATED:
      return {
        ...state,
        [action.image.id]: {
          id: action.image.id,
          downloadURL: action.image.downloadURL,
          height: action.image.height,
          width: action.image.width,
        },
      }
    default:
      return state
  }
}
