import React from "react"
import { Button, Container, Content, Text } from "native-base"
import I18n from "i18n"
import styles from "./styles"

export default ({ createNow }: { createNow: () => void }) => {
  return (
    <Container style={styles.container}>
      <Content>
        <Text style={styles.text}>
          {I18n.t("start_creating_seller_profile")}
        </Text>
        <Button style={styles.button} block rounded onPress={createNow}>
          <Text style={styles.buttonText}>
            {I18n.t("request-to-sell-label")}
          </Text>
        </Button>
      </Content>
    </Container>
  )
}
