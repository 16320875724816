import { Moment } from "moment"
import idGenerator from "utilities/idGenerator"
import {
  QUESTION_CHECKBOX_OPTION_CHECKED,
  QUESTION_RADIO_OPTION_CHECKED,
  QUESTION_TEXT_CHANGED,
  QUESTION_DURATION_CHANGED,
  QUESTION_NUMBER_CHANGED,
  QUESTION_SUITABLE_TIME_SELECTED,
  QUESTION_PREFFERED_TIME_CHANGED,
  QUESTION_IMAGE_ADDED,
  QUESTION_IMAGE_REMOVED,
  SET_SUCCESS_MODAL_VISIBILITY,
  OPEN_INPUT_MODAL,
  CLOSE_INPUT_MODAL,
  SCROLL_TO_BOTTOM,
  SET_AS_SELECTED_DATE_QUESTION,
  TOGGLE_SCROLL_ENABLED,
  RESET_SUITABLE_TIMES,
  UPDATE_ERROR_VISIBILITY,
  UPDATE_SCROLL_POSITION,
  UPDATE_QUESTION_POSITION,
} from "./actionTypes"
import { DateQuestion } from "./types"

interface CheckboxOptionCheckedProps {
  questionId: string
  optionId: string
  checked: boolean
}
export interface CheckboxOptionChecked extends CheckboxOptionCheckedProps {
  type: typeof QUESTION_CHECKBOX_OPTION_CHECKED
}
export const checkboxOptionChecked: (
  props: CheckboxOptionCheckedProps,
) => CheckboxOptionChecked = props => {
  return {
    type: QUESTION_CHECKBOX_OPTION_CHECKED,
    ...props,
  }
}

interface RadioOptionCheckedProps {
  questionId: string
  optionId: string
}
export interface RadioOptionChecked extends RadioOptionCheckedProps {
  type: typeof QUESTION_RADIO_OPTION_CHECKED
}
export const radioOptionChecked: (
  props: RadioOptionCheckedProps,
) => RadioOptionChecked = props => {
  return {
    type: QUESTION_RADIO_OPTION_CHECKED,
    ...props,
  }
}

interface TextChangedProps {
  questionId: string
  value: string
}
export interface TextChanged extends TextChangedProps {
  type: typeof QUESTION_TEXT_CHANGED
}
export const textChanged: (props: TextChangedProps) => TextChanged = props => {
  return {
    type: QUESTION_TEXT_CHANGED,
    ...props,
  }
}

interface DurationChangeProps {
  questionId: string
  value: number
}

export interface DurationChange extends DurationChangeProps {
  type: typeof QUESTION_DURATION_CHANGED
}

export const durationChange: (
  props: DurationChangeProps,
) => DurationChange = props => {
  return {
    type: QUESTION_DURATION_CHANGED,
    ...props,
  }
}

interface NumberChangedProps {
  questionId: string
  value: number
}
export interface NumberChanged extends NumberChangedProps {
  type: typeof QUESTION_NUMBER_CHANGED
}
export const numberChanged: (
  props: NumberChangedProps,
) => NumberChanged = props => {
  return {
    type: QUESTION_NUMBER_CHANGED,
    ...props,
  }
}

interface OnSuitableTimeSelectedProps {
  questionId: string
  dateKey: string
  day: string
  startTime: Moment // Unix Millisecond Timestamp
  endTime: Moment
}
export interface OnSuitableTimeSelected {
  type: typeof QUESTION_SUITABLE_TIME_SELECTED
  questionId: string
  dateKey: string
  day: string
  startTime: string
  endTime: string
}
export const onSuitableTimeSelected: (
  props: OnSuitableTimeSelectedProps,
) => OnSuitableTimeSelected = props => {
  return {
    type: QUESTION_SUITABLE_TIME_SELECTED,
    ...props,
    startTime: props.startTime.format("x"), // Unix Millisecond Timestamp
    endTime: props.endTime.format("x"),
  }
}

export interface PreferredTimeChanged {
  type: typeof QUESTION_PREFFERED_TIME_CHANGED
  questionId: string
  timestamp: number
}
export const preferredTimeChanged: (props: {
  questionId: string
  date: Moment
}) => PreferredTimeChanged = props => {
  return {
    type: QUESTION_PREFFERED_TIME_CHANGED,
    questionId: props.questionId,
    timestamp: Number(props.date.format("x")), // Unix Millisecond Timestamp
  }
}

interface ImageAddedProps {
  questionId: string
  uri: string
  base64: string
  width: number
  height: number
}
export interface ImageAdded extends ImageAddedProps {
  type: typeof QUESTION_IMAGE_ADDED
  id: string
}
export const imageAdded: (props: ImageAddedProps) => ImageAdded = props => {
  return {
    type: QUESTION_IMAGE_ADDED,
    id: idGenerator(),
    ...props,
  }
}

interface ImageRemovedProps {
  questionId: string
  imageId: string
}
export interface ImageRemoved extends ImageRemovedProps {
  type: typeof QUESTION_IMAGE_REMOVED
}
export const imageRemoved: (
  props: ImageRemovedProps,
) => ImageRemoved = props => {
  return {
    type: QUESTION_IMAGE_REMOVED,
    ...props,
  }
}

export interface SetSuccessModalVisibility {
  type: typeof SET_SUCCESS_MODAL_VISIBILITY
  visible: boolean
}
export const setSuccessModalVisibility: (
  visible: boolean,
) => SetSuccessModalVisibility = visible => {
  return {
    type: SET_SUCCESS_MODAL_VISIBILITY,
    visible,
  }
}

export interface OpenInputModal {
  type: typeof OPEN_INPUT_MODAL
  questionId: string
}
export const openInputModal: (
  questionId: string,
) => OpenInputModal = questionId => {
  return {
    type: OPEN_INPUT_MODAL,
    questionId,
  }
}

export interface CloseInputModal {
  type: typeof CLOSE_INPUT_MODAL
}
export const closeInputModal: () => CloseInputModal = () => {
  return {
    type: CLOSE_INPUT_MODAL,
  }
}

export interface ShouldScrollToBottom {
  type: typeof SCROLL_TO_BOTTOM
  scrollStatus?: boolean
}
export const shouldScrollToBottom: (
  scrollStatus?: boolean,
) => ShouldScrollToBottom = scrollStatus => {
  return {
    type: SCROLL_TO_BOTTOM,
    scrollStatus,
  }
}

export interface SetAsSelectedDateQuestion {
  type: typeof SET_AS_SELECTED_DATE_QUESTION
  questionId: string
}
export const setAsSelectedDateQuestion: (
  question: DateQuestion,
) => SetAsSelectedDateQuestion = question => {
  return {
    type: SET_AS_SELECTED_DATE_QUESTION,
    questionId: question.id,
  }
}

export interface ToggleScrollEnabled {
  type: typeof TOGGLE_SCROLL_ENABLED
  scrollEnabled: boolean
}
export const toggleScrollEnabled: (
  scrollEnabled: boolean,
) => ToggleScrollEnabled = scrollEnabled => {
  return {
    type: TOGGLE_SCROLL_ENABLED,
    scrollEnabled,
  }
}

export interface ResetSuitableTimes {
  type: typeof RESET_SUITABLE_TIMES
  questionId: string
}
export const resetSuitableTimes: (
  questionId: string,
) => ResetSuitableTimes = questionId => {
  return {
    type: RESET_SUITABLE_TIMES,
    questionId,
  }
}

export interface UpdateErrorVisibility {
  type: typeof UPDATE_ERROR_VISIBILITY
  questionId: string
  showError: boolean
}
export const updateErrorVisibility: (props: {
  questionId: string
  showError: boolean
}) => UpdateErrorVisibility = props => {
  return {
    type: UPDATE_ERROR_VISIBILITY,
    ...props,
  }
}

export interface UpdateScrollPosition {
  type: typeof UPDATE_SCROLL_POSITION
  scrollPosition: number
}
export const updateScrollPosition: (
  scrollPosition: number,
) => UpdateScrollPosition = scrollPosition => {
  return {
    type: UPDATE_SCROLL_POSITION,
    scrollPosition,
  }
}

export interface UpdateQuestionPosition {
  type: typeof UPDATE_QUESTION_POSITION
  questionId: string
  position: number
}
export const updateQuestionPosition: (props: {
  questionId: string
  position: number
}) => UpdateQuestionPosition = props => {
  return {
    type: UPDATE_QUESTION_POSITION,
    ...props,
  }
}
