import Colors from "constants/Colors"
import { PixelRatio, StyleSheet, ViewStyle, TextStyle } from "react-native"

interface Styles {
  container: ViewStyle
  buttonText: TextStyle
  button: ViewStyle
  codeInput: ViewStyle
}

export default StyleSheet.create<Styles>({
  container: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: Colors.containerBackground,
    padding: 30,
  },
  buttonText: {
    color: "#ffffff",
  },
  button: {
    backgroundColor: Colors.brandYellow,
    borderWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
    marginTop: 50,
  },
  codeInput: {
    fontSize: 30,
    color: Colors.textColor,
    width: "100%",
    textAlign: "center",
  },
})
