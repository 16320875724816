import { ChatUpdated, IsComposingContent } from "./actions"
import { CHAT_UPDATED, IS_COMPOSING_CONTENT } from "./actionTypes"
import { ChatsState } from "./types"

export const INITIAL_STATE: ChatsState = {}

export default function chatReducer(
  state = INITIAL_STATE,
  action: ChatUpdated | IsComposingContent,
): ChatsState {
  switch (action.type) {
    case CHAT_UPDATED:
      return {
        ...state,
        [action.chat.id]: action.chat,
      }
    case IS_COMPOSING_CONTENT:
      return {
        ...state,
        [action.chatId]: {
          ...state[action.chatId],
          composedContent: action.content,
        },
      }
    default:
      return state
  }
}
