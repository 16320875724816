import { StyleSheet, PixelRatio } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  item: {
    alignItems: "flex-start",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderBottomWidth: 0,
  },
  itemBody: {
    flex: 1,
    justifyContent: "center",
    minHeight: 47,
    marginLeft: 15,
    borderColor: Colors.borderColor,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  itemBodyFirst: {
    borderTopWidth: 0,
  },
  itemBodyLast: {
    borderBottomWidth: 0,
  },
  iconContainer: {
    justifyContent: "center",
    minHeight: 47,
  },
  itemText: {
    color: Colors.darkerGrey,
    marginLeft: 0,
  },
  checkeditemText: {
    color: Colors.textColor,
  },
  checked: {
    color: Colors.brandGreen,
  },
  unchecked: {
    color: Colors.grey,
  },
})
