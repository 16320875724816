import React from "react"
import _ from "lodash"
import { View } from "react-native"
import { Content } from "native-base"
import styles from "./styles"
import Questions from "./Questions"
import Addresses from "./Addresses"
import OfferDetails from "./Offer"
import { Offer } from "modules/Buyer/Offers/types"
import { sortByOrder } from "utilities/sortBy"
import { OfferRequestDB } from "database_types/offerRequest"

interface OrderDetailProps {
  offer?: Offer
  offerRequest: OfferRequestDB
}

export default class OrderDetails extends React.Component<OrderDetailProps> {
  scroll: undefined | (Content & { _root: { scrollToEnd?: () => void } }) // a way to get around this https://github.com/GeekyAnts/NativeBase/issues/2714
  componentDidMount() {
    setTimeout(() => {
      if (
        this.props.offer &&
        this.scroll &&
        this.scroll._root &&
        this.scroll._root.scrollToEnd
      ) {
        this.scroll._root.scrollToEnd()
      }
    }, 50)
  }

  render() {
    const {
      offerRequest: { questions, addresses },
      offer,
    } = this.props
    let orderedQuestions = sortByOrder(Object.values(questions), "asc")

    if (offer) {
      orderedQuestions = _.filter(
        orderedQuestions,
        question => question.type !== "date",
      )
    }

    return (
      <Content
        keyboardShouldPersistTaps="always"
        style={styles.content}
        ref={ref => {
          const temp = ref || ({} as any)
          // this should never happen - just a way to get around this https://github.com/GeekyAnts/NativeBase/issues/2714
          // tslint:disable-next-line:no-unsafe-any
          if (!temp._root) {
            // tslint:disable-next-line:no-unsafe-any
            temp._root = {
              // ...
            }
          }
          // tslint:disable-next-line:no-unsafe-any
          this.scroll = temp
        }}
      >
        <Questions questions={orderedQuestions} />
        <Addresses addresses={addresses} />
        {offer && !_.isEmpty(offer) && (
          <View>
            <View style={styles.separator}>
              <View style={styles.separatorNoBorder} />
              <View style={styles.separatorWithBorder}>
                <View style={styles.separatorBorder} />
              </View>
            </View>
            <OfferDetails offer={offer} />
          </View>
        )}
        <View style={styles.emptyBlock} />
      </Content>
    )
  }
}
