import _ from "lodash"
import { Store } from "utilities/Redux/store"
import { AddressFields, AddressTypes } from "./constants"
import { Category } from "./types"
import { Address, AddressesAnswered } from "database_types/offerRequest"

export const getCategoryById = (state: Store, id: string) =>
  state.categories.categories[id] ? state.categories.categories[id] : undefined

export const getSelectedCategory = (state: Store) =>
  state.categories.selected
    ? getCategoryById(state, state.categories.selected)
    : undefined

export const getCategories = (state: Store) => state.categories.categories

export const getAddressValuesForAddressType: (
  state: Store,
  props: { category: Category; addressType: AddressTypes },
) => Address | undefined = (state, props) => {
  const emptyAddress: Address = {
    city: "",
    postalCode: "",
    streetAddress: "",
  }
  if (props.category.addresses.type === "basic") {
    if (props.addressType === "main") {
      return props.category.addresses.main === true
        ? emptyAddress
        : props.category.addresses.main
    }
  }

  if (props.category.addresses.type === "delivery") {
    if (props.addressType === "to") {
      return props.category.addresses.to === true
        ? emptyAddress
        : props.category.addresses.to
    }
    if (props.addressType === "from") {
      return props.category.addresses.from === true
        ? emptyAddress
        : props.category.addresses.from
    }
  }

  return undefined
}

export const getAddressValuesForCategory: (
  state: Store,
  category: Category,
) => AddressesAnswered | undefined = (state, category) => {
  if (category.addresses.type === "basic") {
    const mainAddress = getAddressValuesForAddressType(state, {
      category,
      addressType: "main",
    })
    return mainAddress
      ? {
          type: "basic",
          main: mainAddress,
        }
      : undefined
  } else {
    const fromAddress = getAddressValuesForAddressType(state, {
      category,
      addressType: "to",
    })
    const toAddress = getAddressValuesForAddressType(state, {
      category,
      addressType: "to",
    })
    return fromAddress && toAddress
      ? {
          type: "delivery",
          from: fromAddress,
          to: toAddress,
        }
      : undefined
  }
}

const validateAddress = (address: Address | true | undefined) => {
  if (!address || address === true) {
    return {
      city: true,
      postalCode: true,
      streetAddress: true,
    }
  } else {
    return {
      city: address.city.length < 1,
      postalCode: address.postalCode.length < 1,
      streetAddress: address.streetAddress.length < 1,
    }
  }
}

interface AddressTypeMainValidated {
  main: {
    [field in AddressFields]: boolean
  }
}

interface AddressTypeDeliveryValidated {
  from: {
    [field in AddressFields]: boolean
  }
  to: {
    [field in AddressFields]: boolean
  }
}

export const invalidAddressValuesForCategory: (
  state: Store,
  addresses: Category["addresses"],
) => AddressTypeMainValidated | AddressTypeDeliveryValidated = (
  state,
  addresses,
) => {
  if (addresses.type === "basic") {
    return {
      main: validateAddress(addresses.main),
    }
  } else {
    return {
      from: validateAddress(addresses.from),
      to: validateAddress(addresses.to),
    }
  }
}

export const hasInvalidAddressForCategory: (
  state: Store,
  addresses: Category["addresses"],
) => boolean = (state, addresses) => {
  const validated = invalidAddressValuesForCategory(state, addresses)
  if (addresses.type === "basic") {
    return (
      Object.values((validated as AddressTypeMainValidated).main).filter(
        value => value === true,
      ).length > 0
    )
  } else {
    return (
      Object.values((validated as AddressTypeDeliveryValidated).to).filter(
        value => value === true,
      ).length > 0 ||
      Object.values((validated as AddressTypeDeliveryValidated).from).filter(
        value => value === true,
      ).length > 0
    )
  }
}
