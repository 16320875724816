import { StyleSheet, PixelRatio } from "react-native"
import Colors from "constants/Colors"
import FontSizes from "constants/FontSizes"

export default StyleSheet.create({
  container: {
    borderColor: Colors.borderColor,
    backgroundColor: Colors.containerBackground,
    borderTopWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  isSubQuestionContainer: {
    backgroundColor: "white",
    marginBottom: 30,
  },
  guide: {
    color: Colors.noteColor,
    fontSize: FontSizes.guideTextSize,
    paddingTop: 15,
    paddingBottom: 5,
    paddingLeft: 15,
  },
  grid: {
    flexDirection: "row",
  },
  column: {
    paddingLeft: 15,
    paddingBottom: 15,
    paddingTop: 15,
  },
  lastColumn: {
    paddingRight: 15,
  },
  row: {
    marginBottom: 5,
  },
  rowTitle: {
    color: Colors.darkerGrey,
    paddingBottom: 10,
    textAlign: "center",
  },
  buttonSelected: {
    backgroundColor: Colors.brandGreen,
  },
  buttonSelectedText: {
    color: "#ffffff",
  },
  buttonUnselected: {
    borderColor: Colors.darkerGrey,
  },
  buttonUnselectedText: {
    color: Colors.darkerGrey,
  },
  flexibleTimeView: {
    paddingLeft: 15,
    paddingTop: 10,
  },
  flexibleTimeDescription: {
    color: Colors.darkerGrey,
    fontSize: FontSizes.guideTextSize,
  },
  flexibleTime: {
    color: Colors.brandGreen,
    fontSize: FontSizes.guideTextSize,
  },
  calendar: {
    backgroundColor: Colors.contentBackground,
  },
})
