import React from "react"
import _ from "lodash"
import { View, Linking } from "react-native"
import {
  Container,
  Content,
  List,
  ListItem,
  Text,
  Separator,
  Left,
} from "native-base"
import ProfileImage from "../Shared/ProfileImage"
import I18n from "i18n"
import styles from "./styles"
import { BuyerProfile } from "modules/BuyerProfiles/types"
import { VersionData } from "api/environment"

interface Props {
  buyerProfile?: BuyerProfile
  logOut: () => void
  versionData: VersionData
}

export default ({ buyerProfile, logOut, versionData }: Props) => {
  const openSupportEmail = () => {
    return Linking.openURL("mailto:support@kolf.com")
  }
  const openSupportPhoneNumber = () => {
    return Linking.openURL("tel:+358505050505")
  }
  if (!buyerProfile) {
    return null
  }
  return (
    <Container style={styles.container}>
      <Content>
        <View style={styles.profile}>
          <ProfileImage
            style={styles.profileImage}
            large
            source={buyerProfile.photoURL}
          />
          <Text style={styles.profileName}>{buyerProfile.displayName}</Text>
        </View>
        <List style={styles.list}>
          <ListItem style={styles.listItem} onPress={logOut}>
            <Text>{I18n.t("log_out")}</Text>
          </ListItem>
        </List>
        <Separator style={styles.separator}>
          <Text style={styles.label}>
            {I18n.t("helpdor_support").toUpperCase()}
          </Text>
        </Separator>
        <List style={styles.list}>
          <ListItem style={styles.listItem} onPress={openSupportEmail}>
            <Left>
              <Text>{I18n.t("email")}</Text>
            </Left>
            <Text style={styles.contactInfoText}>support@kolf.com</Text>
          </ListItem>
        </List>
        <List style={[styles.list, { borderTopWidth: 0 }]}>
          <ListItem style={styles.listItem} onPress={openSupportPhoneNumber}>
            <Left>
              <Text>{I18n.t("phone_number")}</Text>
            </Left>
            <Text style={styles.contactInfoText}>+358505018205</Text>
          </ListItem>
        </List>
        <View style={{ margin: 16, marginTop: 32, bottom: 0 }}>
          <Text style={styles.label}>{`${versionData.appOwnership}`}</Text>
          <Text style={styles.label}>{`${versionData.expoVersion}`}</Text>
          <Text style={styles.label}>{`${versionData.releaseChannel ||
            "unknown"}`}</Text>
          <Text style={styles.label}>{`${versionData.versionCode}`}</Text>
        </View>
      </Content>
    </Container>
  )
}
