import { connect } from "react-redux"
import View from "./view"
import { Store } from "../Redux/store"
import {
  getReceivedInAppNotification,
  getSelectedPushNotification,
} from "modules/Notifications/selectors"
import {
  clearInAppNotification,
  clearPushNotification,
  navigateToNotification,
} from "modules/Notifications/actions"
import { PushNotificationData } from "database_types/notification"

interface OwnProps {
  children: JSX.Element
}

const mapStateToProps = (state: Store) => ({
  inAppNotification: getReceivedInAppNotification(state),
  pushNotification: getSelectedPushNotification(state),
})

const mapDispatchToProps = {
  clearInAppNotification,
  clearPushNotification,
  navigateToNotification,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  pushNotificationSelected: (pushNotification: PushNotificationData) => {
    dispatchProps.navigateToNotification(pushNotification)
    dispatchProps.clearPushNotification()
  },
  inAppNotificationSelected: (inAppNotification: PushNotificationData) => {
    dispatchProps.navigateToNotification(inAppNotification)
    dispatchProps.clearInAppNotification()
  },
  inAppNotificationDismissed: () => {
    dispatchProps.clearInAppNotification()
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
