import { connect } from "react-redux"
import { addReview } from "modules/SellerProfiles/actions"
import { offerRequestReviewed } from "modules/Buyer/OfferRequests/actions"
import { getUser } from "modules/User/selectors"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { getSelectedSellerProfile } from "modules/SellerProfiles/selectors"
import { getSelectedOfferRequestForBuyer } from "modules/Buyer/OfferRequests/selectors"
import { getCompletedOfferForOfferRequest } from "modules/Buyer/Offers/selectors"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const sellerProfile = getSelectedSellerProfile(state)
  const offerRequest = getSelectedOfferRequestForBuyer(state)
  const offer = offerRequest
    ? getCompletedOfferForOfferRequest(state, offerRequest)
    : undefined
  const user = getUser(state)
  return {
    sellerProfile,
    offerRequest,
    offer,
    user,
  }
}

const mapDispatchToProps = {
  addReview,
  offerRequestReviewed,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  sellerProfile:
    typeof stateProps.sellerProfile === "object"
      ? stateProps.sellerProfile
      : undefined,
  submitReview: (rating: number, text: string) => {
    if (
      typeof stateProps.sellerProfile === "object" &&
      stateProps.offer &&
      stateProps.offerRequest &&
      stateProps.user
    ) {
      // const { sellerProfile: { id }, user: { name }, offerRequest, offer } = stateProps
      dispatchProps.addReview({
        sellerProfileId: stateProps.sellerProfile.id,
        name: stateProps.user.name,
        offerRequestId: stateProps.offerRequest.id,
        offerId: stateProps.offer.id,
        text,
        rating,
      })
      dispatchProps.offerRequestReviewed(stateProps.offerRequest.id)
      ownProps.navigation.goBack()
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
