import { connect } from "react-redux"
import moment from "moment"
import { offerValueChanged } from "modules/Seller/CreateOffer/actions"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import {
  getSelectedDate,
  getSelectedStartTime,
  getIsOverlapping,
} from "modules/Seller/CreateOffer/selectors"
import { getSelectedOfferRequestForSeller } from "modules/Seller/OfferRequests/selectors"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => ({
  offerRequest: getSelectedOfferRequestForSeller(state),
  selectedDate: getSelectedDate(state),
  selectedStartTime: getSelectedStartTime(state) || undefined,
  isOverlapping: getIsOverlapping(state),
})

const mapDispatchToProps = {
  offerValueChanged,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  confirmStartTime: (offerRequest: OfferRequest) => {
    const value = Number(
      moment(
        `${stateProps.selectedDate}T${stateProps.selectedStartTime}`,
      ).format("x"),
    )
    dispatchProps.offerValueChanged({
      key: "startTime",
      value,
      offerRequestId: offerRequest.id,
    })

    ownProps.navigation.pop()
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
