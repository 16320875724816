import { StyleSheet, PixelRatio, Dimensions } from "react-native"
import Colors from "constants/Colors"
import { separatorText, listItemText } from "constants/Fonts"
import FontSizes from "constants/FontSizes"

const { width } = Dimensions.get("window")

export default StyleSheet.create({
  form: {
    backgroundColor: Colors.containerBackground,
  },
  separator: {
    backgroundColor: Colors.containerBackground,
    marginTop: 15,
  },
  separatorText: {
    ...separatorText,
  },
  formItem: {
    width,
    backgroundColor: Colors.contentBackground,
    height: 50,
    marginLeft: 0,
    paddingLeft: 15,
    borderRadius: 0,
    borderColor: Colors.borderColor,
    borderTopWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  formItemDisabled: {
    backgroundColor: Colors.containerBackground,
  },
  input: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    height: 50,
  },
  inputText: {
    ...listItemText,
    fontSize: FontSizes.questionPlaceholderSize,
    color: Colors.textColor,
  },
  inputPlaceholder: {
    ...listItemText,
    fontSize: FontSizes.questionPlaceholderSize,
  },
  pickerInput: {
    width,
    paddingLeft: 0,
  },
})
