import React from "react"
import { View } from "react-native"
import { List, Text, Separator } from "native-base"
import _ from "lodash"
import I18n from "i18n"
import OfferListItem from "../OfferListItem"
import styles from "./styles"
import { Navigation } from "components/types"

interface Props {
  offerIds: string[]
  navigation: Navigation
}

export default class OfferList extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !_.isEqual(nextProps.offerIds, this.props.offerIds)
  }

  render() {
    const { offerIds = [], navigation } = this.props
    return offerIds.length ? (
      <List style={styles.list}>
        <View>
          <Separator style={styles.separator}>
            <Text style={styles.separatorText}>
              {I18n.t("title_seller_offers").toUpperCase()}
            </Text>
          </Separator>
          {offerIds.map((item, idx) => (
            <OfferListItem
              last={offerIds.length === idx + 1}
              key={`offer_list_item_${item}`}
              id={item}
              navigation={navigation}
            />
          ))}
        </View>
      </List>
    ) : null
  }
}
