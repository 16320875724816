import { isProductionEnvironment } from "../api/environment"

export default {
  borderColor: "#c5c5c5",
  containerBackground: "#f4f4f4",
  contentBackground: "#ffffff",
  headerBackground: "#ffffff",
  noteColor: "#aaaaaa",
  tabBackground: "#323232",
  textColor: "#323232",
  brandYellow: isProductionEnvironment() ? "#ffac30" : "red",
  starYellow: "#fed060",
  lightestGrey: "#f4f4f4",
  lighterGrey: "#d9d9d9",
  grey: "#c5c5c5",
  green: "#c6de3d",
  brandGreen: "#04d8a8",
  red: "#fe7831",
  darkerGrey: "#aaaaaa",
  black:"	#000000",
  white:"#FFFFFF"
}
