import React from "react"
import { View } from "react-native"
import { Button, Text } from "native-base"
import BasicImage, { OwnProps as BasicImageProps } from "../BasicImage"

interface Props extends BasicImageProps {
  remove: () => void
  height?: number
  width?: number
}

export default class RemovableImage extends React.Component<Props> {
  state: {
    removeButtonVisible: boolean
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      removeButtonVisible: false,
    }
    this.onPress = this.onPress.bind(this)
  }

  onPress() {
    this.setState({
      removeButtonVisible: !this.state.removeButtonVisible,
    })
  }

  render() {
    return (
      <View>
        <BasicImage
          imageId={this.props.imageId}
          onPress={this.onPress}
          height={this.props.height}
          width={this.props.width}
        />
        {this.state.removeButtonVisible ? (
          <Button
            onPress={this.props.remove}
            light
            style={{ position: "absolute", right: 0, top: 0 }}
          >
            <Text style={{ fontSize: 10 }}>X</Text>
          </Button>
        ) : null}
      </View>
    )
  }
}
