import React from "react"
import moment from "moment"
import _ from "lodash"
import Colors from "constants/Colors"
import Block from "./Block"
import { convertTimeToIndex, stepInHour } from "./helpers"
import { TempOffer } from "modules/Seller/CreateOffer/types"

export interface SelectedSlotProps {
  selectedStartTime: string
  offer: TempOffer
  categoryName: string
  buyerName: string
  isOverlapping?: boolean
}

export default ({
  selectedStartTime,
  offer,
  categoryName,
  buyerName,
  isOverlapping,
}: SelectedSlotProps) => {
  if (!offer.duration) {
    return null
  }
  return (
    <Block
      style={{
        backgroundColor: isOverlapping ? "red" : Colors.brandYellow,
        height: offer.duration * stepInHour - 2,
        top: (convertTimeToIndex(selectedStartTime) * stepInHour) / 2 + 1,
      }}
      styleName={{
        color: isOverlapping ? "white" : "black",
      }}
      styleDetails={{
        color: isOverlapping ? "white" : "black",
      }}
      name={`${categoryName} ${selectedStartTime} - ${moment(
        selectedStartTime,
        "HH:mm",
      )
        .add(offer.duration * 60, "minutes")
        .format("HH:mm")}`}
      details={buyerName}
    />
  )
}
