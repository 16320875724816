import React from "react"
import { View } from "react-native"
import MessageList from "./MessageList"
import MessageEditor from "./MessageEditor"
import styles from "./styles"

interface Props {
  chatId?: string
  chatOpenedByUser: () => void
  chatClosedByUser: () => void
}

export default class Chat extends React.Component<Props> {
  componentDidMount() {
    this.props.chatOpenedByUser()
  }

  componentWillUnmount() {
    this.props.chatClosedByUser()
  }

  render() {
    const { chatId } = this.props
    if (chatId) {
      return (
        <View style={styles.container}>
          <View style={styles.discussionWrapper}>
            <MessageList chatId={chatId} />
          </View>
          <MessageEditor chatId={chatId} />
        </View>
      )
    } else {
      return null
    }
  }
}
