// import Sentry from "sentry-expo"
import React, { useRef } from "react"
import { Provider as ReduxProvider } from "react-redux"
import {
  AppState,
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  StatusBar,
} from "react-native"
// import { Root } from "native-base"
import firebase from "firebase"
import I18n from "i18n"
import { subscribeToCategories } from "modules/Buyer/Categories/actions"
import { subscribeToFeed } from "modules/Buyer/Feed/actions"
import { authStatusChanged, userUpdatePresence } from "modules/User/actions"
import {
  addPushToken,
  listenToPushNotifications,
} from "modules/Notifications/actions"
import NotificationHandler from "utilities/NotificationHandler"
import RootNavi from "../navigators/Root"
import NaviReduxListener from "../navigators/ReduxListener"
import styles from "./styles"
import "moment/locale/fi" // tslint:disable-line:no-import-side-effect
import { NavigationContainerRef } from "@react-navigation/core"
import { CommonActions, StackActionType } from "@react-navigation/native"
import { createReduxStore } from "utilities/Redux/store"
import { loadSettings } from "modules/Settings/actions"
import { User } from "modules/User/types"
import { NavigationState } from "@react-navigation/routers"
import * as WebBrowser from "expo-web-browser"

// @ts-ignore
import { ToastContainer } from '../../../node_modules/native-base/dist/src/basic/ToastContainer'

const verticalOffest = Platform.OS === "ios" && Dimensions.get("window").height === 812 ? -84 : -50

if (Platform.OS == "web") {
  WebBrowser.maybeCompleteAuthSession()
}

// This is a way to handle all errors in the app.
class ErrorBoundary extends React.Component {
  componentDidCatch(error: any, errorInfo: any) {
    // tslint:disable-next-line:no-unsafe-any
    //todo
    // Sentry.captureException(error, {
    //   extra: errorInfo,
    // })
  }

  render() {
    const { children } = this.props
    return children
  }
}

const Application = (props: {
  reduxStore: ReturnType<typeof createReduxStore>
  expoPushToken?: string
}) => {
  const navigator = useRef<NavigationContainerRef>(null)
  const { reduxStore, expoPushToken } = props
  // load initial data from Firebase
  reduxStore.dispatch(subscribeToCategories())
  reduxStore.dispatch(subscribeToFeed())
  reduxStore.dispatch(loadSettings())

  // start handling notifications
  reduxStore.dispatch(listenToPushNotifications())
  // add push token to state
  if (expoPushToken) {
    reduxStore.dispatch(addPushToken(expoPushToken))
  }

  // listen to Auth events from Firebase and update User
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
      const helpdorUser: User = {
        id: authUser.uid,
        photoURL: authUser.photoURL || "",
        emailVerified: authUser.emailVerified,
        email: authUser.email || "",
        name: authUser.displayName || "",
        language: I18n.locale,
        deviceLanguage: I18n.locale,
        phone: "",
      }
      if (expoPushToken) {
        helpdorUser.pushToken = { [expoPushToken]: true }
      }
      reduxStore.dispatch(authStatusChanged(helpdorUser))
      if (AppState.currentState === "active") {
        reduxStore.dispatch(userUpdatePresence(true))
      }
    } else {
      reduxStore.dispatch(authStatusChanged(null))
    }
  })

  AppState.addEventListener("change", nextAppState => {
    if (nextAppState === "active") {
      reduxStore.dispatch(userUpdatePresence(true))
    } else {
      reduxStore.dispatch(userUpdatePresence(false))
    }
  })

  const navigate = (action: CommonActions.Action | StackActionType) => {
    if (navigator.current) {
      navigator.current?.dispatch(action)
    }
  }
  let currentRoute: string | undefined
  const getCurrentRoute = () => currentRoute
  return (
    <ReduxProvider store={reduxStore}>
      <ErrorBoundary>
        <KeyboardAvoidingView
          style={styles.container}
          behavior="padding"
          keyboardVerticalOffset={verticalOffest}
        >
          {/*
              For Toast to work, you need to wrap your topmost component inside <Root> from native-base.
              https://docs.nativebase.io/Components.html#Toast
          */}
          {/* <Root> TEMP: fix for bottom space , https://github.com/GeekyAnts/NativeBase-KitchenSink/issues/81*/}
          <>
            <NotificationHandler navigate={navigate}>
              <NaviReduxListener
                navigate={navigate}
                getCurrentRoute={getCurrentRoute}
              >
                <RootNavi
                  ref={navigator}
                  onStateChange={(state: NavigationState | undefined) => {
                    if (state) {
                      console.log(
                        "------------------ navigated to: --------------------",
                        state.routes[state.index].name,
                      )
                      currentRoute = state.routes[state.index].name
                    }
                  }}
                />
              </NaviReduxListener>
            </NotificationHandler>
            <ToastContainer
              ref={(c:any) => {
                if (c) ToastContainer.toastInstance = c;
              }}
            />
          </>
          {/* </Root> */}
          {Platform.OS === "ios" && <StatusBar barStyle="dark-content" />}
        </KeyboardAvoidingView>
      </ErrorBoundary>
    </ReduxProvider>
  )
}

export default Application
