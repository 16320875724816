import _ from "lodash"
import { API_URL } from "./constants"
import { getEnvironment } from "./environment"
import { User } from "../modules/User/types"

const getUserLocalForPayment = (deviceLanguage: string) => {
  const userLocale = _.startsWith(deviceLanguage, "fi") ? "fi_FI" : "en_FI"
  return userLocale
}

export interface GetStripeSessionProps {
  user: User
  offerId: string
  offerRequestId: string
  categoryName: string
  isExtending: boolean
  successUrl: string
  cancelUrl: string
}
export const getStripeSession = async (props: GetStripeSessionProps) => {
  const environment = await getEnvironment()
  const res = await fetch(`${API_URL}/stripe/session/get`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      environment,
      offerId: props.offerId,
      offerRequestId: props.offerRequestId,
      categoryName: props.categoryName,
      isExtending: props.isExtending,
      successUrl: props.successUrl,
      cancelUrl: props.cancelUrl,
      buyerInformation: {
        buyerId: props.user.id,
        email: props.user.email,
        userLocale: getUserLocalForPayment(
          props.user.deviceLanguage || "en_FI",
        ),
        name: `${props.user.firstName} ${props.user.lastName}`,
      },
    }),
  })
  return res
}

export const getStripePublicKey = async () => {
  const environment = await getEnvironment()
  if (environment === "production") {
    return "pk_live_DlY37k7SzbVnJYjgyZBgsxJx"
  }
  return "pk_test_IQm8aOfPTSEgJrRufc2C4MEP"
}

export const getStripePaymentPageUrl = async (sessionId: string) => {
  const environment = await getEnvironment()
  if (environment === "production") {
    return `${API_URL}/pay/stripe?${sessionId}`
  }
  return `${API_URL}/pay/stripe/dev?${sessionId}`
}
