import React from "react"
import { View, Text, TouchableOpacity } from "react-native"
import { Button } from "native-base"
import { SimpleLineIcons } from "@expo/vector-icons"
import I18n from "i18n"
import {
  getTimeRemainingString,
  getTimeRemainingInMinutes,
} from "utilities/time"
import _ from "lodash"
import ReminderStyles from "../styles"
import styles from "./styles"
import { Offer } from "modules/Buyer/Offers/types"
import { Category } from "modules/Buyer/Categories/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface CurrentJobProps {
  offer: Offer
  startJob: () => void
  category?: Category
  onPress: () => void
}

export default class CurrentJob extends React.PureComponent<CurrentJobProps> {
  state: {
    timeRemaining: number | false
    timeIntervalId: number
    interValId?: number
  }
  constructor(props: CurrentJobProps) {
    super(props)
    this.state = {
      timeRemaining: false,
      timeIntervalId: -1,
    }
  }

  componentDidMount() {
    const { offer } = this.props
    if (offer) {
      this.setTimeLeftToStartJob(offer.agreedStartTime)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: CurrentJobProps) {
    const { offer } = this.props
    const { offer: updatedOffer } = nextProps
    if (!_.isEqual(offer, updatedOffer) && updatedOffer) {
      this.setTimeLeftToStartJob(updatedOffer.agreedStartTime)
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interValId)
  }

  updateTimeRemaining = (startTime: number) => {
    if (this.state.timeIntervalId !== -1) {
      clearInterval(this.state.timeIntervalId)
    }

    return setInterval(() => {
      this.setState({ timeRemaining: getTimeRemainingInMinutes(startTime) })
    }, 60000)
  }

  setTimeLeftToStartJob = (startTime: number) => {
    if (startTime) {
      const interValId = this.updateTimeRemaining(startTime)
      this.setState({
        timeIntervalId: interValId,
        timeRemaining: getTimeRemainingInMinutes(startTime),
      })
    }
  }

  startJob = () => {
    clearInterval(this.state.timeIntervalId)
    this.props.startJob()
  }

  render() {
    const { category, onPress } = this.props
    if (this.state.timeRemaining === false || !category) {
      return null
    }
    return (
      <View style={ReminderStyles.reminderContainer}>
        <View style={styles.contentContainer}>
          <View style={styles.textContainer}>
            <Text style={ReminderStyles.title}>
              {getLocaLizedName(category.name)}{" "}
              {this.state.timeRemaining > 0
                ? I18n.t("gig_starts_in")
                : I18n.t("gig_should_have_started")}
            </Text>
            <Text style={styles.timeRemainingText}>
              {getTimeRemainingString(this.state.timeRemaining)}
            </Text>
          </View>
          <TouchableOpacity
            onPress={onPress}
            style={ReminderStyles.iconContainer}
          >
            <SimpleLineIcons
              name="arrow-right-circle"
              size={30}
              style={ReminderStyles.icon}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.buttonWrap}>
          <Button
            success
            rounded
            block
            onPress={this.startJob}
            style={ReminderStyles.button}
          >
            <Text style={ReminderStyles.buttonText}>{I18n.t("start_job")}</Text>
          </Button>
        </View>
      </View>
    )
  }
}
