import React from "react"
import { connect } from "react-redux"
import routeNames from "../constants"
import { ActivityIndicator } from "react-native"
import { Container, Content } from "native-base"
import styles from "./styles"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import {
  isFetchingUser,
  hasUserSellerProfile,
  isUserApprovedSeller,
  isUserLoggedIn,
} from "modules/User/selectors"

interface Props {
  isFetching?: boolean
  hasSellerProfile?: boolean
  isApprovedSeller?: boolean
  navigation: Navigation
}

class Switcher extends React.Component<Props> {
  componentDidMount() {
    this.choose()
  }
  // prevents unnecessary rendering and flashing
  shouldComponentUpdate(nextProps: Props) {
    return !!nextProps.isFetching
  }
  choose() {
    const { isFetching, hasSellerProfile, isApprovedSeller } = this.props
    if (isFetching) {
      this.choose()
      return
    }
    let route
    if (hasSellerProfile && isApprovedSeller) {
      route = routeNames.SELLER_SWITCHER.SELLER_TABS
    } else if (hasSellerProfile) {
      route = routeNames.SELLER_SWITCHER.WAIT_FOR_APPROVAL
    } else {
      route = routeNames.SELLER_SWITCHER.SELLER_CREATE
    }
    this.props.navigation.navigate(route)
  }
  render() {
    return (
      <Container style={styles.container}>
        <Content>
          <ActivityIndicator style={styles.spinner} />
        </Content>
      </Container>
    )
  }
}

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const isLoggedIn = isUserLoggedIn(state)
  return {
    hasSellerProfile: isLoggedIn && hasUserSellerProfile(state),
    isApprovedSeller: isLoggedIn && isUserApprovedSeller(state),
    isFetching: isFetchingUser(state),
  }
}
const mapDispatchToProps = null
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...ownProps,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Switcher)
