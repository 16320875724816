import _ from "lodash"
import { connect } from "react-redux"
import {
  toggleScrollEnabled,
  openInputModal,
  textChanged,
} from "modules/Buyer/Questions/actions"
import View from "./view"
import {
  TextAreaQuestion,
  NumberInputQuestion,
} from "modules/Buyer/Questions/types"
import { Store } from "utilities/Redux/store"
import { getScrollEnabled } from "modules/Buyer/Questions/selectors"

interface OwnProps {
  question: TextAreaQuestion | NumberInputQuestion
  value?: string | number | null
}

const mapStateToProps = (state: Store) => ({
  scrollEnabled: getScrollEnabled(state),
})

const mapDispatchToProps = {
  toggleScrollEnabled,
  openInputModal,
  textChanged,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  openInputTextModal: () => {
    dispatchProps.toggleScrollEnabled(stateProps.scrollEnabled)
    setTimeout(() => {
      dispatchProps.openInputModal(ownProps.question.id)
    }, 50)
  },
  setDefaultValue: () => {
    const {
      question: { defaultValue, id },
    } = ownProps

    if (defaultValue) {
      dispatchProps.textChanged({
        questionId: id,
        value: String(defaultValue),
      })
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
