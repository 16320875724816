import React from "react"
import { Thumbnail } from "native-base"
import { ImageRequireSource, ImageStyle } from "react-native"

// tslint:disable-next-line
const defaultPhoto: ImageRequireSource = require("assets/images/profile.png")

interface Props {
  source?: string
  style?: ImageStyle
  small?: boolean
  large?: boolean
  size?: number
}

class ProfileImage extends React.Component<Props> {
  state: {
    notFound?: true
  }
  constructor(props: Props) {
    super(props)
    this.state = {}
    this.notFound = this.notFound.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.source !== this.props.source) {
      this.setState(this.getStateFromProps(nextProps))
    }
  }

  getStateFromProps(props: Props) {
    return {
      source: {
        uri: props.source,
      },
    }
  }

  notFound() {
    this.setState({
      source: defaultPhoto,
    })
  }

  render() {
    return (
      <Thumbnail
        style={this.props.style}
        small={this.props.small}
        large={this.props.large}
        size={this.props.size}
        source={
          this.state.notFound
            ? defaultPhoto
            : {
                uri: this.props.source,
              }
        }
        onError={this.notFound}
      />
    )
  }
}

export default ProfileImage
