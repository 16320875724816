import React from "react"
import _ from "lodash"
import moment from "moment"
import { View } from "react-native"
import { Text } from "native-base"
import ImagesRow from "../Images/ImagesRow"
import I18n from "i18n"
import styles from "./styles"
import { SuitableTime } from "modules/Buyer/Questions/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"
import {
  OfferRequestQuestionOption,
  OfferRequestQuestion,
} from "database_types/offerRequestQuestions"

const getQuestionValues = (question: OfferRequestQuestion) => {
  switch (question.type) {
    case "checkbox": {
      const selectedOptions = _.filter(
        question.options,
        option => !!option.checked,
      )
      const orderedOptions = _.orderBy(selectedOptions, ["order", "asc"])
      return <OptionsRow options={orderedOptions} />
    }
    case "radio": {
      const option =
        question.selectedOption &&
        question.options.find(o => o.id === question.selectedOption)
      return option ? <OptionsRow options={[option]} /> : undefined
    }
    case "date":
      if (question.suitableTimes) {
        return <DateDetailsRow values={Object.values(question.suitableTimes)} />
      }
      if (question.preferredTime) {
        return <DateDetailsRow values={[question.preferredTime]} />
      }
      return undefined
    case "number_input":
      return question.value ? (
        <StandardDetailsRow values={[`${question.value}`]} />
      ) : null
    case "number":
      return question.value ? (
        <StandardDetailsRow
          values={[
            `${question.value} ${question.placeholder &&
              getLocaLizedName(question.placeholder)}`,
          ]}
        />
      ) : null
    case "textarea":
      return question.value ? (
        <StandardDetailsRow values={[question.value]} />
      ) : (
        undefined
      )
    case "image":
      return question.images ? (
        <ImageRow imageIds={Object.keys(question.images)} />
      ) : (
        undefined
      )
    case "list":
      return question.value ? (
        <StandardDetailsRow values={[`${question.value}h`]} />
      ) : null
    default:
      return undefined
  }
}

const StandardDetailsRow = ({ values }: { values: string[] }) => {
  return (
    <View>
      {_.map(values, (value, idx) => (
        <Text key={idx} style={styles.detailsContentText}>
          {value}
        </Text>
      ))}
    </View>
  )
}

const SubQuestionsRow = ({ question }: { question: OfferRequestQuestion }) => {
  const Values = getQuestionValues(question)
  if (!Values) {
    return null
  }

  return (
    <View style={styles.subQuestionRow}>
      <Text style={styles.detailsTitleText}>{`${question.name &&
        getLocaLizedName(question.name)}: `}</Text>
      <View style={styles.subQuestionValue}>{Values}</View>
    </View>
  )
}

const OptionsRow = ({ options }: { options: OfferRequestQuestionOption[] }) => {
  return (
    <View>
      {_.map(options, (option, idx) => (
        <View key={idx}>
          <Text style={styles.detailsContentText}>
            {option.name && getLocaLizedName(option.name)}
          </Text>
          {option.questions
            ? Object.values(option.questions).map(question => (
                <SubQuestionsRow key={question.id} question={question} />
              ))
            : null}
        </View>
      ))}
    </View>
  )
}

const getDateString = (
  date: number | { day: string; startTime: number; endTime: number },
) => {
  if (_.isString(date)) {
    return _.capitalize(moment(date, "x").format("dddd D.M. kk:mm"))
  }
  if (_.isObject(date)) {
    return `${moment(date.day).format("dd D.M.")} ${moment(
      date.startTime,
      "x",
    ).format("kk")}-${moment(date.endTime, "x").format("kk")}`
  }
  return ""
}

const DateDetailsRow = ({
  values,
}: {
  values: Array<number | SuitableTime>
}) => {
  return (
    <View>
      {values.map((value, idx) => (
        <Text key={idx} style={styles.detailsContentText}>
          {getDateString(value)}
        </Text>
      ))}
    </View>
  )
}

const ImageRow = ({ imageIds }: { imageIds: string[] }) => {
  return (
    <View style={styles.imagesContainer}>
      <ImagesRow imageIds={imageIds} />
    </View>
  )
}

const getTitle = (question: OfferRequestQuestion) => {
  if (question.type === "date") {
    if (question.suitableTimes) {
      return I18n.t("suitable_times")
    }
    if (question.preferredTime) {
      return I18n.t("date_and_time")
    }
  }
  return question.name && getLocaLizedName(question.name)
}

const Row = ({ question }: { question: OfferRequestQuestion }) => {
  const Values = getQuestionValues(question)
  if (!Values) {
    return null
  }

  return (
    <View style={styles.detailsRow}>
      <View style={styles.detailsTitle}>
        <Text style={styles.detailsTitleText}>{getTitle(question)}</Text>
      </View>
      <View style={styles.detailsContent}>{Values}</View>
    </View>
  )
}

export default ({ questions }: { questions: OfferRequestQuestion[] }) => (
  <View>
    {questions.map(question => (
      <Row key={question.id} question={question} />
    ))}
  </View>
)
