import React from "react"
import moment from "moment"
import _ from "lodash"
import Colors from "constants/Colors"
import { convertTimeToIndex, stepInHour } from "./helpers"
import Block from "./Block"
import { TempOffer } from "modules/Seller/CreateOffer/types"

export interface PreSelectedSlotsProps {
  selectedStartTime: string
  offer: TempOffer
  selectedDate: string
  categoryName: string
}

export default ({
  selectedStartTime,
  offer,
  selectedDate,
  categoryName,
}: PreSelectedSlotsProps) => {
  if (
    !offer.duration ||
    !offer.startTime ||
    selectedStartTime ||
    !moment(offer.startTime, "x").isSame(selectedDate, "day")
  ) {
    return null
  }
  return (
    <Block
      style={{
        backgroundColor: Colors.brandYellow,
        height: offer.duration * stepInHour - 2,
        top: offer.startTime
          ? convertTimeToIndex(moment(offer.startTime, "x").format("HH:mm")) *
              stepInHour +
            1
          : 0,
      }}
      name={categoryName}
      details={`${offer.pricePerHour}€ / h`}
    />
  )
}
