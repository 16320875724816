import React from "react"
import _ from "lodash"
import TouchableInput from "../TouchableInput"
import { TextAreaQuestion } from "modules/Buyer/Questions/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface Props {
  question: TextAreaQuestion
  isSubQuestion?: boolean
}

export default ({ question, isSubQuestion }: Props) => {
  let value = isSubQuestion
    ? question.name && getLocaLizedName(question.name)
    : question.placeholder && getLocaLizedName(question.placeholder)
  const questionValue = question.value
  if (questionValue) {
    value = questionValue
  }
  return <TouchableInput value={value} question={question} />
}
