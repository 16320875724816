import _ from "lodash"
import { createSelector } from "reselect"
import * as userSelectors from "modules/User/selectors"
import * as sellerOfferSelectors from "modules/Seller/Offers/selectors"
import { Store } from "utilities/Redux/store"
import { Offer } from "modules/Buyer/Offers/types"
import { DefaultValue } from "modules/Seller/CreateOffer/actions"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import { sortByCreatedAt } from "utilities/sortBy"
import { isDateQuestion, DateQuestion } from "modules/Buyer/Questions/types"
import {
  OfferRequestQuestion,
  OfferRequestCheckBoxQuestion,
} from "database_types/offerRequestQuestions"

export const getOfferRequestByIdForSeller = (state: Store, id: string) =>
  state.sellerOfferRequests.offerRequests[id]
    ? state.sellerOfferRequests.offerRequests[id]
    : undefined

const getOpenOffersByUser = (state: Store) => {
  const user = userSelectors.getUser(state)
  if (!user || !user.offers) {
    return []
  }

  const openOffersByUserForSeller: Offer[] = []
  Object.keys(user.offers).forEach(offerId => {
    const offer = sellerOfferSelectors.getSellerOfferById(state, offerId)
    if (offer && offer.status === "open") {
      openOffersByUserForSeller.push(offer)
    }
  })
  return openOffersByUserForSeller
}

const getOpenOfferRequestsForSeller = (state: Store) => {
  const asEntries = Object.entries(
    state.sellerOfferRequests.offerRequests,
  ).filter(entry => entry[1].status === "open")
  return Object.fromEntries(asEntries)
}

export const getSelectedOfferRequestForSeller = (state: Store) =>
  state.sellerOfferRequests.selected
    ? getOfferRequestByIdForSeller(state, state.sellerOfferRequests.selected)
    : undefined

export const getPreferredTimeForOfferRequest = (
  state: Store,
  offerRequest: OfferRequest,
) => {
  const { questions } = offerRequest
  let dateQuestion: DateQuestion | undefined
  questions.forEach(question => {
    if (isDateQuestion(question)) {
      dateQuestion = question
    }
  })
  if (!dateQuestion) {
    return
  } else if (dateQuestion.preferredTime) {
    return dateQuestion.preferredTime
  } else if (dateQuestion.suitableTimes) {
    const selected = Object.values(dateQuestion.suitableTimes).find(
      suitableTime => suitableTime.startTime,
    )
    if (selected) {
      return selected.startTime
    }
  }
  return
}

const isCheckbox = (
  variableToCheck: OfferRequestQuestion,
): variableToCheck is OfferRequestCheckBoxQuestion =>
  (variableToCheck as OfferRequestCheckBoxQuestion).type === "checkbox"

export const getDefaultValueForFixedOffer = createSelector(
  [getSelectedOfferRequestForSeller],
  offerRequest => {
    const data: OfferRequestQuestion | undefined =
      offerRequest &&
      offerRequest.questions.find(question =>
        question.id.includes("fixed_offer"),
      )

    if (data && isCheckbox(data)) {
      const defaultValue: DefaultValue =
        data.options && data.options[0].questions
          ? data.options[0].questions.reduce((acc, curr) => {
              if (curr.type === "list") {
                return {
                  ...acc,
                  duration: curr.value ? curr.value : undefined,
                  isFixedDuration: !!curr.value,
                }
              } else if (curr.type === "number_input") {
                return {
                  ...acc,
                  pricePerHour: curr.value ? curr.value : undefined,
                  isFixedPrice: !!curr.value,
                }
              } else {
                return acc
              }
            }, {})
          : {}

      return defaultValue
    } else {
      return null
    }
  },
)

export const getOpenOfferRequestIdsWithNoOwnOffers = createSelector(
  [getOpenOfferRequestsForSeller, getOpenOffersByUser],
  (openOfferRequests, openOffersByUser) => {
    const offerRequestIdsForOwnOffers = _.map(
      openOffersByUser,
      offer => offer.offerRequest,
    )
    const offerRequestsWithNoOwnOffers: OfferRequest[] = []

    _.values(openOfferRequests).forEach(openOfferRequest => {
      if (offerRequestIdsForOwnOffers.indexOf(openOfferRequest.id) === -1) {
        offerRequestsWithNoOwnOffers.push(openOfferRequest)
      }
    })

    const orderedOfferRequests = sortByCreatedAt(
      offerRequestsWithNoOwnOffers,
      "desc",
    )
    return orderedOfferRequests.map(offerRequest => offerRequest.id)
  },
)
