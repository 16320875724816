import {
  CHAT_OPENED_BY_USER,
  CHAT_CREATED,
  CHAT_UPDATED,
  IS_COMPOSING_CONTENT,
} from "./actionTypes"
import { Chat } from "./types"

export interface ChatOpenedByUser {
  type: typeof CHAT_OPENED_BY_USER
  chatId: string
}
export const chatOpenedByUser: (
  chatId: string,
) => ChatOpenedByUser = chatId => {
  return {
    type: CHAT_OPENED_BY_USER,
    chatId,
  }
}

interface ChatCreatedProps {
  chatId: string
  offerId: string
}
export interface ChatCreated extends ChatCreatedProps {
  type: typeof CHAT_CREATED
}
export const chatCreated: (props: ChatCreatedProps) => ChatCreated = props => {
  return {
    type: CHAT_CREATED,
    ...props,
  }
}

export interface ChatUpdated {
  type: typeof CHAT_UPDATED
  chat: Chat
}
export const chatUpdated: (chat: Chat) => ChatUpdated = chat => {
  return {
    type: CHAT_UPDATED,
    chat,
  }
}

interface IsComposingContentProps {
  chatId: string
  content: string
}
export interface IsComposingContent extends IsComposingContentProps {
  type: typeof IS_COMPOSING_CONTENT
}
export const isComposingContent: (
  props: IsComposingContentProps,
) => IsComposingContent = props => {
  return {
    type: IS_COMPOSING_CONTENT,
    ...props,
  }
}
