import { NotificationsState } from "./types"
import {
  AddPushToken,
  NotificationReveicedInApp,
  ClearInAppNotification,
  PushNotificationSelected,
  ClearPushNotification,
  NavigateToNotification,
} from "./actions"
import {
  ADD_PUSH_TOKEN,
  NOTIFICATION_REVEICED_IN_APP,
  CLEAR_IN_APP_NOTIFICATION,
  PUSH_NOTIFICATION_SELECTED,
  CLEAR_PUSH_NOTIFICATION,
  NAVIGATE_TO_NOTIFICATION,
} from "./actionTypes"

export const INITIAL_STATE: NotificationsState = {
  pushToken: undefined,
  selectedPushNotification: undefined,
  receivedInAppNotifications: [],
}

export default function notificationsReducer(
  state = INITIAL_STATE,
  action:
    | AddPushToken
    | NotificationReveicedInApp
    | ClearInAppNotification
    | PushNotificationSelected
    | ClearPushNotification
    | NavigateToNotification,
): NotificationsState {
  switch (action.type) {
    case ADD_PUSH_TOKEN:
      return {
        ...state,
        pushToken: action.pushToken,
      }
    case NOTIFICATION_REVEICED_IN_APP:
      return {
        ...state,
        receivedInAppNotifications: state.receivedInAppNotifications.concat([
          action.notification,
        ]),
      }
    case CLEAR_IN_APP_NOTIFICATION:
      return {
        ...state,
        receivedInAppNotifications: state.receivedInAppNotifications.splice(
          state.receivedInAppNotifications.length - 1,
          1,
        ),
      }
    case PUSH_NOTIFICATION_SELECTED:
      return {
        ...state,
        selectedPushNotification: action.notification,
      }
    case CLEAR_PUSH_NOTIFICATION:
      return {
        ...state,
        selectedPushNotification: undefined,
      }
    case NAVIGATE_TO_NOTIFICATION:
      return {
        ...state,
        notificationToNavigate: action.notification,
      }
    default:
      return state
  }
}
