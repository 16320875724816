import React from "react"
import { Separator, Text } from "native-base"
import { View } from "react-native"
import I18n from "ex-react-native-i18n"
import _ from "lodash"
import OptionQuestion from "./OptionQuestion"
import OpenTextQuestion from "./OpenTextQuestion"
import NumberQuestion from "./NumberQuestion"
import NumberInputQuestion from "./NumberInputQuestion"
import DateQuestion from "./DateQuestion"
import ImageQuestion from "./ImageQuestion"
import ModalInput from "./ModalInput"
import ListPicker from "./ListPicker"
import styles from "./styles"
import { Question } from "modules/Buyer/Questions/types"
import { Navigation } from "components/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

type QuestionContentProps = Required<
  Pick<Props, "question" | "isSubQuestion" | "navigation">
>

const QuestionContent = ({
  question,
  isSubQuestion,
  navigation,
}: QuestionContentProps) => {
  switch (question.type) {
    case "checkbox":
    case "radio":
      return (
        <OptionQuestion
          question={question}
          isSubQuestion={isSubQuestion}
          navigation={navigation}
        />
      )
    case "textarea":
      return (
        <OpenTextQuestion question={question} isSubQuestion={isSubQuestion} />
      )
    case "number":
      return <NumberQuestion question={question} />
    case "number_input":
      return <NumberInputQuestion question={question} />
    case "date":
      return (
        <DateQuestion
          question={question}
          navigation={navigation}
          isSubQuestion={isSubQuestion}
        />
      )
    case "image":
      return <ImageQuestion question={question} />
    case "list":
      return <ListPicker question={question} />
    default:
      return null
  }
}

type QuestionHeaderProps = Required<
  Pick<
    Props,
    "question" | "isSubQuestion" | "errorsForCategory" | "updateErrorVisibility"
  >
>
const QuestionHeader = ({
  question,
  isSubQuestion,
  errorsForCategory,
  updateErrorVisibility,
}: QuestionHeaderProps) => {
  if (!question.name) {
    return null
  }
  updateErrorVisibility(question, isSubQuestion)
  return (
    <Separator style={styles.separator}>
      <Text style={styles.label}>
        {getLocaLizedName(question.name).toUpperCase()}
      </Text>
      {!isSubQuestion &&
      question.showError &&
      _.includes(errorsForCategory, question.id) ? (
        <Text style={styles.warningText}>
          {I18n.t("mandatory_field").toUpperCase()}
        </Text>
      ) : null}
    </Separator>
  )
}

interface Props {
  question?: Question
  isSubQuestion: boolean
  errorsForCategory: string[]
  updateErrorVisibility: (question: Question, isSubQuestion?: boolean) => void
  navigation: Navigation
  inputModalQuestionId?: string | null
}

export default ({
  question,
  isSubQuestion,
  navigation,
  inputModalQuestionId,
  errorsForCategory,
  updateErrorVisibility,
}: Props) => {
  if (!question) {
    return null
  }
  let questionComponents
  switch (question.type) {
    case "checkbox":
    case "radio":
      questionComponents = [
        <QuestionHeader
          key={`${question.id}_title`}
          question={question}
          isSubQuestion={isSubQuestion}
          errorsForCategory={errorsForCategory}
          updateErrorVisibility={updateErrorVisibility}
        />,
        <View
          style={[
            styles.questionBody,
            isSubQuestion ? styles.questionBodyIsSubQuestion : null,
          ]}
          key={`${question.id}_question`}
        >
          <QuestionContent
            question={question}
            isSubQuestion={isSubQuestion}
            navigation={navigation}
          />
        </View>,
      ]
      break
    case "date":
      questionComponents = [
        <QuestionHeader
          key={`${question.id}_title`}
          question={question}
          isSubQuestion={isSubQuestion}
          errorsForCategory={errorsForCategory}
          updateErrorVisibility={updateErrorVisibility}
        />,
        <View
          style={[
            styles.questionBody,
            { borderBottomWidth: 0 },
            isSubQuestion ? styles.questionBodyIsSubQuestion : null,
          ]}
          key={`${question.id}_question`}
        >
          <QuestionContent
            question={question}
            isSubQuestion={isSubQuestion}
            navigation={navigation}
          />
        </View>,
      ]
      break
    case "number":
    case "number_input":
    case "image":
    case "textarea":
    case "list":
      questionComponents = [
        isSubQuestion ? null : (
          <QuestionHeader
            key={`${question.id}_title`}
            question={question}
            isSubQuestion={isSubQuestion}
            errorsForCategory={errorsForCategory}
            updateErrorVisibility={updateErrorVisibility}
          />
        ),
        <View
          style={[
            styles.questionBody,
            isSubQuestion ? styles.questionBodyIsSubQuestion : null,
          ]}
          key={`${question.id}_question`}
        >
          <QuestionContent
            question={question}
            isSubQuestion={isSubQuestion}
            navigation={navigation}
          />
        </View>,
      ]
      break
    default:
      questionComponents = null
      break
  }
  return (
    <View>
      {questionComponents}
      {inputModalQuestionId === question.id ? <ModalInput /> : null}
    </View>
  )
}
