import { connect } from "react-redux"
import { getUser } from "modules/User/selectors"
import I18n from "i18n"
import routeNames from "components/navigators/constants"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { Category } from "modules/Buyer/Categories/types"
import { getSelectedCategory } from "modules/Buyer/Categories/selectors"
import { CommonActions } from "@react-navigation/native"
import {
  categoryHasInvalidQuestions,
  getScrollEnabled,
  getScrollStatus,
} from "modules/Buyer/Questions/selectors"
import {
  shouldScrollToBottom,
  updateScrollPosition,
} from "modules/Buyer/Questions/actions"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const selectedCategory = getSelectedCategory(state)
  return {
    category: selectedCategory,
    scrollEnabled: getScrollEnabled(state),
    hasErrors: selectedCategory
      ? categoryHasInvalidQuestions(state, selectedCategory)
      : false,
    user: getUser(state),
    scrollToBottom: getScrollStatus(state),
  }
}

const mapDispatchToProps = {
  shouldScrollToBottom,
  updateScrollPosition,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  updateBottomScrollStatus: () => {
    dispatchProps.shouldScrollToBottom(false)
  },
  onButtonClicked: (category: Category) => {
    if (!stateProps.hasErrors) {
      ownProps.navigation.dispatch(
        CommonActions.navigate(routeNames.BUYER.CREATE_OFFER_REQUEST_ADDRESS, {
          name: `${getLocaLizedName(category.name)} ${I18n.t("address")}`,
        }),
      )
    }
  },
  updateScrollPosition: (scrollPosition: number) => {
    dispatchProps.updateScrollPosition(scrollPosition)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
