import {
  ADD_PUSH_TOKEN,
  LISTEN_TO_PUSH_NOTIFICATIONS,
  NOTIFICATION_REVEICED_IN_APP,
  PUSH_NOTIFICATION_SELECTED,
  CLEAR_IN_APP_NOTIFICATION,
  CLEAR_PUSH_NOTIFICATION,
  NAVIGATE_TO_NOTIFICATION,
} from "./actionTypes"
import { PushNotificationData } from "database_types/notification"

export interface AddPushToken {
  type: typeof ADD_PUSH_TOKEN
  pushToken: string
}
export const addPushToken: (pushToken: string) => AddPushToken = pushToken => {
  return {
    type: ADD_PUSH_TOKEN,
    pushToken,
  }
}

export interface ListenToPushNotifications {
  type: typeof LISTEN_TO_PUSH_NOTIFICATIONS
}
export const listenToPushNotifications: () => ListenToPushNotifications = () => {
  return {
    type: LISTEN_TO_PUSH_NOTIFICATIONS,
  }
}

export interface NotificationReveicedInApp {
  type: typeof NOTIFICATION_REVEICED_IN_APP
  notification: PushNotificationData
}
export const notificationReveicedInApp: (
  notification: PushNotificationData,
) => NotificationReveicedInApp = notification => {
  return {
    type: NOTIFICATION_REVEICED_IN_APP,
    notification,
  }
}

export interface PushNotificationSelected {
  type: typeof PUSH_NOTIFICATION_SELECTED
  notification: PushNotificationData
}
export const pushNotificationSelected: (
  notification: PushNotificationData,
) => PushNotificationSelected = notification => {
  return {
    type: PUSH_NOTIFICATION_SELECTED,
    notification,
  }
}

export interface ClearInAppNotification {
  type: typeof CLEAR_IN_APP_NOTIFICATION
}
export const clearInAppNotification: () => ClearInAppNotification = () => {
  return {
    type: CLEAR_IN_APP_NOTIFICATION,
  }
}

export interface ClearPushNotification {
  type: typeof CLEAR_PUSH_NOTIFICATION
}
export const clearPushNotification: () => ClearPushNotification = () => {
  return {
    type: CLEAR_PUSH_NOTIFICATION,
  }
}

export interface NavigateToNotification {
  type: typeof NAVIGATE_TO_NOTIFICATION
  notification: PushNotificationData
}
export const navigateToNotification: (
  notification: PushNotificationData,
) => NavigateToNotification = notification => {
  return {
    type: NAVIGATE_TO_NOTIFICATION,
    notification,
  }
}
