import { connect } from "react-redux"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Category } from "modules/Buyer/Categories/types"
import { Navigation } from "components/types"
import { getSortedQuestionsIdsForCategory } from "modules/Buyer/Questions/selectors"

interface Props {
  isSubQuestion?: boolean
  navigation: Navigation
}

interface SubQuestionListProps extends Props {
  questionIds: string[]
}

interface QuestionListProps extends Props {
  category: Category
}

type OwnProps = SubQuestionListProps | QuestionListProps

const mapStateToProps = (state: Store, ownProps: OwnProps) => {
  if ((ownProps as QuestionListProps).category) {
    return {
      questionIds: getSortedQuestionsIdsForCategory(
        state,
        (ownProps as QuestionListProps).category,
      ),
    }
  } else {
    return {
      questionIds: (ownProps as SubQuestionListProps).questionIds,
    }
  }
}

const mapDispatchToProps = null

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...ownProps,
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
