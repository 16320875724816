import { connect } from "react-redux"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { getOpenOfferRequestIdsWithNoOwnOffers } from "modules/Seller/OfferRequests/selectors"

const mapStateToProps = (state: Store) => ({
  offerRequestIds: getOpenOfferRequestIdsWithNoOwnOffers(state),
})

export default connect(mapStateToProps)(View)
