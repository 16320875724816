import { SellerCreateOfferState } from "./types"
import {
  CALENDAR_DATE_SELECTED,
  START_TIME_SELECTED,
  START_TIME_IS_OVERLAPPING,
  OFFER_VALUE_CHANGED,
  OFFER_CREATED,
  SET_DEFAULT_OFFER_VALUE,
} from "./actionTypes"
import {
  CalendarDateSelected,
  StartTimeSelected,
  StartTimeIsOverlapping,
  OfferValueChanged,
  OfferCreated,
  SetDefaultOfferValue,
} from "./actions"

export const INITIAL_STATE: SellerCreateOfferState = {
  selectedDate: undefined,
  selectedStartTime: undefined,
  tempOffers: {},
}

export default function sellerOfferRequestsReducer(
  state = INITIAL_STATE,
  action:
    | CalendarDateSelected
    | StartTimeSelected
    | StartTimeIsOverlapping
    | OfferValueChanged
    | OfferCreated
    | SetDefaultOfferValue,
): SellerCreateOfferState {
  switch (action.type) {
    case CALENDAR_DATE_SELECTED:
      return {
        ...state,
        selectedDate: action.date,
      }
    case START_TIME_SELECTED:
      return {
        ...state,
        selectedStartTime: action.selectedStartTime,
      }
    case START_TIME_IS_OVERLAPPING:
      return {
        ...state,
        isOverlapping: action.isOverlapping,
      }
    case OFFER_VALUE_CHANGED:
      return {
        ...state,
        tempOffers: {
          ...state.tempOffers,
          [action.offerRequestId]: {
            ...(state.tempOffers[action.offerRequestId]
              ? state.tempOffers[action.offerRequestId]
              : undefined),
            [action.key]: action.value,
          },
        },
      }
    case SET_DEFAULT_OFFER_VALUE:
      return {
        ...state,
        tempOffers: {
          ...state.tempOffers,
          [action.offerRequestId]: {
            ...(state.tempOffers[action.offerRequestId]
              ? state.tempOffers[action.offerRequestId]
              : {}),
            ...action.defaultValue,
          },
        },
      }
    case OFFER_CREATED:
      const updatedOffers = Object.fromEntries(
        Object.entries(state.tempOffers).filter(
          entry => entry[0] !== action.offer.offerRequest,
        ),
      )
      return {
        ...state,
        tempOffers: updatedOffers,
      }
    default:
      return state
  }
}
