import {
  BUYER_PROFILE_UPDATED,
  BUYER_PROFILE_CREATE,
  BUYER_PROFILE_ADD,
} from "./actionTypes"
import { BuyerProfile } from "./types"

const generateDisplayName = (firstName: string, lastName: string) => {
  return `${firstName} ${lastName.slice(0, 1)}.`
}

export interface BuyerProfileUpdated {
  type: typeof BUYER_PROFILE_UPDATED
  buyerProfile: BuyerProfile
}
export const buyerProfileUpdated: (
  buyerProfile: BuyerProfile,
) => BuyerProfileUpdated = buyerProfile => {
  return {
    type: BUYER_PROFILE_UPDATED,
    buyerProfile,
  }
}

export interface CreateBuyerProfile {
  type: typeof BUYER_PROFILE_CREATE
  buyerProfile: BuyerProfile
}
export const createBuyerProfile: (
  buyerProfile: BuyerProfile,
) => CreateBuyerProfile = buyerProfile => {
  return {
    type: BUYER_PROFILE_CREATE,
    buyerProfile: {
      ...buyerProfile,
      displayName: generateDisplayName(
        buyerProfile.firstName,
        buyerProfile.lastName,
      ),
    },
  }
}

export interface BuyerProfileAdd {
  type: typeof BUYER_PROFILE_ADD
  buyerProfile: BuyerProfile
}
export const buyerProfileAdd: (
  buyerProfile: BuyerProfile,
) => BuyerProfileAdd = buyerProfile => {
  return {
    type: BUYER_PROFILE_ADD,
    buyerProfile,
  }
}
