import { connect } from "react-redux"
import { CommonActions } from "@react-navigation/native"
import routeNames from "components/navigators/constants"
import View from "./view"
import { DateQuestion } from "modules/Buyer/Questions/types"
import {
  onSuitableTimeSelected,
  resetSuitableTimes,
  setAsSelectedDateQuestion,
} from "modules/Buyer/Questions/actions"
import { Navigation } from "components/types"
import { Moment } from "moment"

interface OwnProps {
  question: DateQuestion
  navigation: Navigation
  isSubQuestion?: boolean
}

const mapStateToProps = null

const mapDispatchToProps = {
  resetSuitableTimes,
  setAsSelectedDateQuestion,
  onSuitableTimeSelected,
}

const mergeProps = (
  stateProps: typeof mapStateToProps,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...dispatchProps,
  ...ownProps,
  resetSuitableTimes: () => {
    if (ownProps.question.suitableTimes) {
      dispatchProps.resetSuitableTimes(ownProps.question.id)
    }
  },
  selectPreferredTime: () => {
    dispatchProps.setAsSelectedDateQuestion(ownProps.question)
    ownProps.navigation.dispatch(
      CommonActions.navigate(routeNames.BUYER.QUESTION_PREFERRED_TIME_PICKER),
    )
  },
  onSuitableTimeSelected: (props: {
    dateKey: string
    day: string
    startTime: Moment
    endTime: Moment
  }) => {
    dispatchProps.onSuitableTimeSelected({
      questionId: ownProps.question.id,
      ...props,
    })
  },
  setAsSelectedDateQuestion: () => {
    dispatchProps.setAsSelectedDateQuestion(ownProps.question)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
