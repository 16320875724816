import React from "react"
import _ from "lodash"
import { FlatList } from "react-native"
import Message from "../Message"
import { ChatMessage } from "modules/ChatMessages/types"
import { sortByCreatedAt } from "utilities/sortBy"

interface Props {
  messages: ChatMessage[]
}

export default class MessageList extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return (
      _.difference(
        nextProps.messages.filter(message => message.createdAt),
        this.props.messages.filter(message => message.createdAt),
      ).length > 0
    )
  }

  render() {
    const { messages } = this.props
    const reversedList = sortByCreatedAt(messages, "desc")
    const maxArrayIndex = reversedList.length - 1
    return (
      <FlatList
        inverted
        data={reversedList}
        extraData={this.state}
        renderItem={({ item, index }) => {
          const messageAboveCreatedAt =
            index < maxArrayIndex && reversedList[index + 1].createdAt
          return (
            <Message
              previousDate={messageAboveCreatedAt || undefined}
              message={item}
            />
          )
        }}
        keyExtractor={item => item.id}
      />
    )
  }
}
