import React from "react"
import { Input, ListItem } from "native-base"
import _ from "lodash"
import styles from "./styles"
import { TempOffer } from "modules/Seller/CreateOffer/types"
import Colors from "constants/Colors"

const priceUnit = " €/h"

interface Props {
  onPriceChanged: (price: number) => void
  tempOffer?: TempOffer
  currencyPlaceholder: string
}
export class PriceInput extends React.Component<Props> {
  state: {
    isFocused?: boolean
  }
  constructor(props: Props) {
    super(props)
    this.state = {}
    this.onValueChanged = this.onValueChanged.bind(this)
  }

  getValue() {
    const price = this.props.tempOffer && this.props.tempOffer.pricePerHour
    if (price && this.state.isFocused) {
      return `${price}`
    }
    if (price) {
      return `${price}${priceUnit}`
    }
    return ""
  }

  onValueChanged(value: string) {
    const cleanedValue = value.replace(priceUnit, "").trim()
    this.props.onPriceChanged(Number(cleanedValue))
  }

  render() {
    const { currencyPlaceholder, tempOffer } = this.props

    const isDisabled: boolean | undefined | 0 =
      tempOffer && tempOffer.pricePerHour && tempOffer.isFixedPrice

    return (
      <ListItem style={styles.formItem}>
        <Input
          disabled={!!isDisabled}
          keyboardType="numeric"
          returnKeyType="done"
          placeholder={currencyPlaceholder}
          placeholderTextColor={Colors.noteColor}
          autoCorrect={false}
          autoCapitalize="none"
          onChangeText={this.onValueChanged}
          value={this.getValue()}
          style={[styles.input, styles.inputText]}
          onBlur={() => {
            this.setState({
              isFocused: false,
            })
          }}
          onFocus={() => {
            this.setState({
              isFocused: true,
            })
          }}
        />
      </ListItem>
    )
  }
}
