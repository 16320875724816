type Order = "desc" | "asc"
interface SortableByCreatedAt {
  createdAt: number
}

export function sortByCreatedAt<T extends SortableByCreatedAt>(
  objects: T[],
  order: Order,
): T[] {
  return [...objects].sort((a, b) => {
    if (a.createdAt < b.createdAt) {
      return order === "desc" ? 1 : -1
    } else if (a.createdAt > b.createdAt) {
      return order === "desc" ? -1 : 1
    }
    return 0
  })
}

interface SortableByOrder {
  order: number
}

export function sortByOrder<T extends SortableByOrder>(
  objects: T[],
  order: "desc" | "asc",
): T[] {
  return [...objects].sort((a, b) => {
    if (a.order < b.order) {
      return order === "desc" ? -1 : 1
    } else if (a.order > b.order) {
      return order === "desc" ? 1 : -1
    }
    return 0
  })
}

interface SortableByStartTime {
  startTime?: number
  actualStartTime?: number
  agreedStartTime?: number
}

export function sortByStartTime<T extends SortableByStartTime>(
  objects: T[],
  order: "desc" | "asc",
): T[] {
  return [...objects].sort((a, b) => {
    const startTimeA =
      a.startTime || a.actualStartTime || a.agreedStartTime || 0
    const startTimeB =
      b.startTime || b.actualStartTime || b.agreedStartTime || 0
    if (startTimeA < startTimeB) {
      return order === "desc" ? -1 : 1
    } else if (startTimeA > startTimeB) {
      return order === "desc" ? 1 : -1
    }
    return 0
  })
}
