import _ from "lodash"
import { Store } from "utilities/Redux/store"

export const getUser = (state: Store) => state.user.user
export const getUserInfoValidated = (state: Store) => state.user.validated
export const isUserAuthenticating = (state: Store) => state.user.authenticating
export const isUserLoggedIn = (state: Store) =>
  state.user.user && !!state.user.user.id
export const isUserBlocked = (state: Store) =>
  state.user.user && state.user.user.isBlocked
export const hasUserBuyerProfile = (state: Store) =>
  state.user.user && state.user.user.hasBuyerProfile
export const hasUserSellerProfile = (state: Store) =>
  state.user.user && state.user.user.hasSellerProfile
export const isUserApprovedSeller = (state: Store) =>
  state.user.user &&
  state.user.user.hasSellerProfile &&
  state.user.user.isApprovedSeller
export const isFetchingUser = (state: Store) => state.user.fetching
export const getAuthErrorMessage = (state: Store) =>
  state.user.authenticationFailedMessage
