import React from "react"
import Svg, { Path } from "react-native-svg"
import { TabBarIconProps } from "components/types"

export default (props: TabBarIconProps) => (
  <Svg height={24} width={24} viewBox="0 0 466.1 512">
    <Path
      fill={props.color ? props.color : undefined}
      d="M231.1 274h3.6c33.2-.6 60.1-12.3 80-34.6 43.7-49.2 36.4-112.4 35.6-120.5-2.8-60.5-31.4-89.4-55-102.9C277.7 5.9 257.2.4 234.2 0h-1.9c-12.6 0-37.3 2-61 15.6S118.4 58 115.5 118.9c-.8 8.1-8.1 71.2 35.6 120.5 19.8 22.3 46.7 34 80 34.6zm-85.3-152.2c0-.3.1-.7.1-.9 3.8-81.4 61.5-90.1 86.2-90.1h1.4c30.6.7 82.7 13.2 86.2 90.1 0 .3 0 .6.1.9.1.8 8.1 56.7-28 97.3-14.3 16.1-33.4 24.1-58.4 24.3h-1.1c-25-.2-44.1-8.2-58.3-24.3-36-40.4-28.2-96.7-28.2-97.3z"
    />
    <Path
      fill={props.color ? props.color : undefined}
      d="M466 413.5v-.3c0-.9-.1-1.8-.1-2.8-.7-22.5-2.2-75-51.4-91.8-.3-.1-.8-.2-1.1-.3-51.2-13-93.7-42.5-94.2-42.9-7-4.7-16.5-2.8-21.3 4.2-4.6 6.8-3 16 3.7 20.9 1.9 1.4 47.1 32.8 103.6 47.3 26.4 9.4 29.4 37.7 30.2 63.5 0 .9 0 1.9.1 2.8.1 10.2-.6 11.8-2.4 20.9-18.4 10.4-90.4 46.5-200.1 46.5-109.2 0-181.7-36.2-200.2-46.6-1.8-9.1-2.6-10.7-2.4-20.9 0-.9.1-1.8.1-2.8.8-25.9 3.8-54.1 30.2-63.5 56.5-14.5 101.7-46.1 103.6-47.3 6.9-4.9 8.6-14.4 3.7-21.3s-14.4-8.6-21.3-3.7c-.4.3-42.8 29.8-94.2 42.9-.5.1-.8.2-1.1.3C2.3 335.3.8 387.9.1 410.2c0 .9 0 1.9-.1 2.8v.3c-.1 5.9-.2 22 5.8 37.2 1.1 3 3.2 5.5 5.9 7.1 3.4 2.3 85 54.2 221.5 54.2s218.1-52.1 221.5-54.2c2.7-1.7 4.7-4.2 5.9-7.1 5.7-15 5.6-31.1 5.4-37z"
    />
  </Svg>
)
