import React from "react"
import { View, TouchableOpacity, Platform } from "react-native"
import { Separator, Text, Icon } from "native-base"
import moment from "moment"
import styles from "./styles"
import IosDateModal from "../Modals/IosDateModal"
import { Ionicons } from "@expo/vector-icons"
import I18n from "i18n"
import DateTimePicker, { Event } from "@react-native-community/datetimepicker"

interface Props {
  header: string
  placeholder: string
  value: Date
  onChange: (date: Date) => void
}

interface State {
  date: Date
  modalVisible: boolean
}

export default class DateRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      date: this.props.value,
      modalVisible: false,
    }
    this.showPicker = this.showPicker.bind(this)
    this.onChange = this.onChange.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  closeModal() {
    this.setState({ modalVisible: false })
  }

  showIosModal() {
    this.setState({ modalVisible: true })
  }

  onChange(date: Date) {
    this.setState({ date }, () => this.props.onChange(this.state.date))
  }

  showPicker() {
    this.showIosModal()
  }

  render() {
    return (
      <View>
        {Platform.OS === "ios" ? (
          <IosDateModal
            isVisible={this.state.modalVisible}
            closeModal={this.closeModal}
            confirm={this.onChange}
            title={this.props.header}
          />
        ) : !this.state.modalVisible ? null : (
          <DateTimePicker
            mode="date"
            value={this.state.date}
            locale={I18n.locale}
            onChange={(e: Event, date: Date) => {
              this.closeModal()
              this.onChange(date)
            }}
          />
        )}

        <Separator style={styles.separator}>
          <Text>{this.props.header.toUpperCase()}</Text>
        </Separator>
        <TouchableOpacity onPress={this.showPicker}>
          <View style={styles.flexContainer}>
            <View style={styles.iconContainer}>
              <Ionicons name="ios-calendar" size={20} style={styles.icon} />
            </View>
            <Text
              style={[
                styles.label,
                this.state.date ? styles.value : styles.placeholder,
              ]}
            >
              {this.state.date
                ? moment(this.state.date).format("DD.MM.YYYY")
                : this.props.placeholder}
            </Text>
            <View style={styles.button}>
              <Icon name="arrow-forward" style={styles.buttonIcon} />
            </View>
          </View>
        </TouchableOpacity>
      </View>
    )
  }
}
