import { StyleSheet, Dimensions, Platform } from "react-native"
import Colors from "constants/Colors"
import { buildStyle } from "utilities/crossPlatform"
const base = StyleSheet.create({
  container: {
    backgroundColor: Colors.containerBackground,
  },
  imageContainer: {
    flex: 1,
    width: Dimensions.get("window").width,
  },
  image: {
    resizeMode: "cover",
  },
  textWrapper: {
    alignItems: "center",
    backgroundColor: "transparent",
    flex: 1,
    justifyContent: "center",
    paddingHorizontal: 30,
  },
  text: {
    color: "#ffffff",
    fontSize: 30,
    fontWeight: "300",
    textAlign: "center",
  },
  buttonWrapper: {
    height: 130,
    padding: 15,
    position: "absolute",
    top:
      Dimensions.get("window").height -
      150 -
      (Platform.OS === "android" ? 24 : 0),
    width: Dimensions.get("window").width,
  },
  button: {
    backgroundColor: "transparent",
    borderColor: "#ffffff",
  },
  buttonText: {
    color: "#ffffff",
  },
  facebookButton: {
    marginBottom: 10,
  },
  label: {
    color: "#ffffff",
  },
})
const web = StyleSheet.create({
  buttonWrapper: {
    position: "relative",
    minWidth: 500,
    justifyContent: "center",
    alignSelf: "center",
    top: 0,
    paddingBottom: "15%",
  },
})
export default buildStyle({ base, web })
