import React from "react"
import { Platform } from "react-native"
import routeNames from "../constants"
import OfferRequests from "../Buyer/OfferRequests"
import SellerSwitch from "../SellerSwitch"
import Account from "../Account"
import Colors from "constants/Colors"
import I18n from "i18n"
import OpenOfferRequestsIcon from "./BottomIcons/OpenOfferRequestsIcon"
import AccountIcon from "./BottomIcons/AccountIcon"
import OrdersIcon from "./BottomIcons/OrdersIcon"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"

const { Navigator, Screen } = createBottomTabNavigator()

export default () => (
  <Navigator
    initialRouteName={routeNames.HOME_TABS.BUYER_TAB}
    tabBarOptions={{
      inactiveTintColor: Colors.darkerGrey,
      activeTintColor: Colors.brandYellow,
      showLabel: true,
      iconStyle: {
        height: 35,
        width: 45,
        top: 0,
      },
      labelStyle: {
        fontFamily: "open-sans-regular",
        fontSize: 11,
        lineHeight: 11,
        ...Platform.select({
          ios: {
            top: -3,
          },
          android: {
            top: -3,
          },
        }),
      },
      tabStyle: {
        opacity: 1,
      },
      style: {
        backgroundColor: Colors.tabBackground,
        height: 52,
      },
    }}
  >
    <Screen
      name={routeNames.HOME_TABS.BUYER_TAB}
      component={OfferRequests}
      options={{
        tabBarIcon: OrdersIcon,
        tabBarLabel: I18n.t("buy-tab-label"),
      }}
    />
    <Screen
      name={routeNames.HOME_TABS.SELLER_TAB}
      component={SellerSwitch}
      options={{
        tabBarIcon: OpenOfferRequestsIcon,
        tabBarLabel: I18n.t("sell-tab-label"),
      }}
    />
    <Screen
      name={routeNames.HOME_TABS.ACCOUNT_TAB}
      component={Account}
      options={{
        tabBarIcon: AccountIcon,
        tabBarLabel: I18n.t("account-tab-label"),
      }}
    />
  </Navigator>
)
