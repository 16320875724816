import React from "react"
import { View } from "react-native"
import OnGoingJob from "./OnGoingJob"
import CompletedJob from "./CompletedJob"
import styles from "./styles"
import { Navigation } from "components/types"

interface Props {
  onGoingJobs: string[]
  completedJobs: string[]
  navigation: Navigation
}

export default ({ onGoingJobs, completedJobs, navigation }: Props) => {
  return (
    <View style={styles.remindersContainer}>
      {onGoingJobs.map((item, idx) => (
        <OnGoingJob
          key={`current_${item}`}
          offerRequestId={item}
          navigation={navigation}
        />
      ))}
      {completedJobs.map((item, idx) => (
        <CompletedJob
          key={`current_${item}`}
          offerRequestId={item}
          navigation={navigation}
        />
      ))}
    </View>
  )
}
