import React from "react"
import _ from "lodash"
import { View } from "react-native"
import { Text } from "native-base"
import I18n from "i18n"
import {
  AddressTypes,
  ADDRESS_FIELDS,
} from "modules/Buyer/Categories/constants"
import styles from "./styles"
import { Address, AddressesAnswered } from "database_types/offerRequest"

const getAddressTitle = (addressType: AddressTypes) => {
  switch (addressType) {
    case "main":
      return I18n.t("address")
    case "from":
      return I18n.t("start_address")
    case "to":
      return I18n.t("destination_address")
    default:
      return ""
  }
}

const AddressFields = ({ address }: { address: Address }) => {
  return (
    <View style={styles.detailsContent}>
      <View style={styles.detailsContent}>
        {Object.values(ADDRESS_FIELDS).map(fieldName => (
          <Text key={fieldName} style={styles.detailsContentText}>
            {address[fieldName]}
          </Text>
        ))}
      </View>
    </View>
  )
}

const AddressView = ({
  addressType,
  address,
}: {
  addressType: AddressTypes
  address: Address
}) => {
  return (
    <View style={styles.detailsRow}>
      <View style={styles.detailsTitle}>
        <Text style={styles.detailsTitleText}>
          {getAddressTitle(addressType)}
        </Text>
      </View>
      <AddressFields address={address} />
    </View>
  )
}

export default ({ addresses }: { addresses: AddressesAnswered }) => (
  <View>
    {Object.keys(addresses).map(addressType => (
      <AddressView
        key={addressType}
        addressType={addressType as AddressTypes}
        address={addresses[addressType]}
      />
    ))}
  </View>
)
