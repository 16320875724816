import { StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  contentContainer: {
    flexDirection: "row",
  },
  textContainer: {
    flex: 1,
  },
  timeRemainingText: {
    fontFamily: "open-sans-bold",
    color: Colors.contentBackground,
    fontSize: 18,
  },
  buttonWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
})
