import React from "react"
import { createStackNavigator } from "@react-navigation/stack"
import Colors from "constants/Colors"
import routeNames from "../constants"
import SignInIndex from "components/views/SignIn"
import SignUp from "components/views/SignIn/SignUp"
import ConfirmUserInfo from "components/views/SignIn/ConfirmUserInfo"
import UserBlocked from "components/views/SignIn/UserBlocked"

const { Navigator, Screen } = createStackNavigator()

export default () => (
  <Navigator
    initialRouteName={routeNames.SIGN_IN.INDEX}
    headerMode={"none"}
    screenOptions={{
      gestureEnabled: false,
      headerLeft: undefined,
      header: undefined,
      cardStyle: {
        backgroundColor: Colors.containerBackground,
      },
    }}
  >
    <Screen name={routeNames.SIGN_IN.INDEX} component={SignInIndex} />
    <Screen name={routeNames.SIGN_IN.SIGNUP} component={SignUp} />
    <Screen
      name={routeNames.SIGN_IN.CONFIRM_USER_INFO}
      component={ConfirmUserInfo}
    />
    <Screen name={routeNames.SIGN_IN.BLOCKED} component={UserBlocked} />
  </Navigator>
)
