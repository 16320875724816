import firebase from "firebase"
import { getEventChannelForFirebaseRef } from "utilities/Redux/sagaHelpers"
import { all, call, takeEvery, fork, put, take } from "redux-saga/effects"
import * as actions from "./actions"
import { FEED_SUBSCRIBE } from "./actionTypes"
import { FIREBASE_PATH } from "./constants"
import { FeedDB } from "database_types/feed"

export function* subscribeWorker() {
  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
  const channel = yield call(getEventChannelForFirebaseRef, ref)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const { values: feed }: { values: FeedDB } = yield take(channel)
    yield put(actions.feedUpdated(feed))
  }
}

export function* subscribeSaga() {
  yield takeEvery(FEED_SUBSCRIBE, subscribeWorker)
}

export default function*() {
  yield all([fork(subscribeSaga)])
}
