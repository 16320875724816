import { createSelector } from "reselect"
import _ from "lodash"
import { Store } from "utilities/Redux/store"
import { OfferRequest } from "../OfferRequests/types"
import { Offer } from "./types"
import { OFFER_STATUS } from "constants/Status"

export const getOfferByIdForBuyer = (state: Store, id: string) =>
  state.offers.offers[id] ? state.offers.offers[id] : undefined

export const getOffersForBuyer = (state: Store) => state.offers.offers

export const getSelectedOfferForBuyer = (state: Store) =>
  state.offers.selectedOffer === undefined
    ? undefined
    : getOfferByIdForBuyer(state, state.offers.selectedOffer)

export const getOffersWithStatusForBuyer = createSelector(
  [getOffersForBuyer, (state: Store, offerStatus: OFFER_STATUS) => offerStatus],
  (offers, offerStatus) =>
    Object.fromEntries(
      Object.entries(offers).filter(offer => offer[1].status === offerStatus),
    ),
)

export const getOpenOffersForRequestForBuyer = (
  state: Store,
  offerRequest: OfferRequest,
) => {
  if (!offerRequest.offers) {
    return []
  }
  const offersForOfferRequest: Offer[] = []
  Object.keys(offerRequest.offers).forEach(offerId => {
    const offer = getOfferByIdForBuyer(state, offerId)
    if (offer && offer.status === "open") {
      offersForOfferRequest.push(offer)
    }
  })
  return offersForOfferRequest
}

export const getSelectedBookingForBuyer = (state: Store) =>
  state.offers.selectedBooking
    ? getOfferByIdForBuyer(state, state.offers.selectedBooking)
    : undefined

export const getAcceptedOfferForOfferRequest = createSelector(
  [
    (state: Store, offerRequest: OfferRequest) => offerRequest,
    getOffersForBuyer,
  ],
  (offerRequest, stateOffers) => {
    const { offers } = offerRequest
    return offers
      ? Object.keys(offers)
          .map(id => stateOffers[id])
          .filter(offer => offer.status === "accepted")[0]
      : undefined
  },
)

export const getCompletedOfferForOfferRequest = createSelector(
  [
    (state: Store, offerRequest: OfferRequest) => offerRequest,
    getOffersForBuyer,
  ],
  (offerRequest, stateOffers) => {
    const { offers } = offerRequest
    return offers
      ? Object.keys(offers)
          .map(id => stateOffers[id])
          .filter(offer => offer.status === "completed")[0]
      : undefined
  },
)

const filterUnseenOffersForBuyer = (offers: Offer[]) => {
  return offers.filter(
    ({ status, seenByBuyer }) =>
      (status === "open" || status === "accepted") && !seenByBuyer,
  )
}

export const getUnseenOffersTotalForBuyer = createSelector(
  getOffersForBuyer,
  offers => filterUnseenOffersForBuyer(Object.values(offers)).length,
)

export const getUnseenOffersCountForOfferRequestSelector = () =>
  createSelector(
    [
      (state: Store, offerRequest: OfferRequest) => offerRequest,
      getOffersForBuyer,
    ],
    (offerRequest, offers) => {
      const offerRequestOffers = offerRequest.offers
        ? Object.keys(offerRequest.offers).map(id => offers[id])
        : []
      return filterUnseenOffersForBuyer(offerRequestOffers).length
    },
  )
