import { connect } from "react-redux"
import _ from "lodash"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { SellerProfile } from "modules/SellerProfiles/types"
import { getTempSellerProfile } from "modules/SellerProfiles/selectors"
import { getFeed } from "modules/Buyer/Feed/selectors"
import {
  updateTempSellerProfileCategories,
  updateTempSellerProfile,
} from "modules/SellerProfiles/actions"

const mapStateToProps = (state: Store) => ({
  feed: getFeed(state),
  tempProfile: getTempSellerProfile(state),
})

const mapDispatchToProps = {
  updateTempSellerProfileCategories,
  updateTempSellerProfile,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  toggleCategorySelection: (categoryId: string) => {
    const categories: { [id: string]: true } | undefined =
      (stateProps.tempProfile && stateProps.tempProfile.categories) || {}

    if (categories !== undefined) {
      let updatedCategories: SellerProfile["categories"]
      if (categories[categoryId]) {
        updatedCategories = Object.fromEntries(
          Object.entries(categories).filter(
            category => category[0] !== categoryId,
          ),
        )
      } else {
        updatedCategories = {
          ...categories,
          [categoryId]: true,
        }
      }
      dispatchProps.updateTempSellerProfileCategories(updatedCategories)
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
