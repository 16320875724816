import _ from "lodash"
import { Store } from "utilities/Redux/store"

export const getPushToken = (state: Store) => state.notifications.pushToken
export const getReceivedInAppNotification = (state: Store) =>
  _.last(state.notifications.receivedInAppNotifications)
export const getSelectedPushNotification = (state: Store) =>
  state.notifications.selectedPushNotification
export const getNotificationToNavigate = (state: Store) =>
  state.notifications.notificationToNavigate
