import React from "react"
import moment from "moment"
import _ from "lodash"
import { Content, Container } from "native-base"
import { View } from "react-native"
import { CalendarList, DateObject } from "react-native-calendars"
import Colors from "constants/Colors"
import I18n from "i18n"
import EmptyList from "components/views/Shared/EmptyList"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import { TempOffer } from "modules/Seller/CreateOffer/types"
import { OfferRequestDateQuestion } from "database_types/offerRequestQuestions"

interface Props {
  dateQuestion?: OfferRequestDateQuestion
  openTimetable: (date: DateObject) => void
  offer?: TempOffer
  offerRequest?: OfferRequest
}

export default ({
  dateQuestion,
  openTimetable,
  offer,
  offerRequest,
}: Props) => {
  if (
    !offerRequest ||
    offerRequest.status !== "open" ||
    !offer ||
    !dateQuestion ||
    !dateQuestion.suitableTimes
  ) {
    return (
      <EmptyList
        fullPage
        emptyTextMessage={I18n.t("offer_request_not_available")}
      />
    )
  }
  const min = moment()
  const lastOfSelectedDays = Object.keys(dateQuestion.suitableTimes).pop()
  const max = moment(
    dateQuestion.suitableTimes[lastOfSelectedDays!].endTime,
    "x",
  )
  const offerStartTime = offer.startTime
    ? moment(offer.startTime, "x").format("YYYY-MM-DD")
    : null
  const suitableTimes = dateQuestion.suitableTimes
  const suitableDates = _.uniq(_.map(suitableTimes, "day"))
  const daysLength = max.diff(min, "days") + 2 // Add first and last day
  const markedDates = {}
  ;[...Array(daysLength)].forEach((obj, i) => {
    const day = moment()
      .add(i, "day")
      .format("YYYY-MM-DD")
    markedDates[day] = {
      selected: suitableDates.includes(day),
      disabled: !suitableDates.includes(day),
      marked: day === offerStartTime,
    }
  })

  return (
    <Container>
      <Content>
        <View>
          <CalendarList
            hideExtraDays
            firstDay={1}
            maxDate={max.format("YYYY-MM-DD")}
            minDate={min.format("YYYY-MM-DD")}
            onDayPress={openTimetable}
            markedDates={markedDates}
            pastScrollRange={0}
            futureScrollRange={12}
            theme={{
              selectedDayBackgroundColor: Colors.brandYellow,
              dotColor: Colors.brandGreen,
              monthTextColor: Colors.textColor,
              textDisabledColor: Colors.noteColor,
              dayTextColor: Colors.textColor,
              textSectionTitleColor: Colors.noteColor,
              todayTextColor: Colors.textColor,
              arrowColor: Colors.brandYellow,
            }}
          />
        </View>
      </Content>
    </Container>
  )
}
