import React from "react"
import { List, Separator, Text } from "native-base"
import { View, TextInput } from "react-native"
import { AddressTypes } from "modules/Buyer/Categories/constants"
import I18n from "i18n"
import Colors from "constants/Colors"
import styles from "./styles"
import { AddressUpdatedProps } from "modules/Buyer/Categories/actions"
import { Address } from "database_types/offerRequest"

interface Props {
  addressType: AddressTypes
  addressValues?: Address
  addressUpdated: (props: AddressUpdatedProps) => void
  categoryId?: string
}

export default ({
  addressValues,
  addressUpdated,
  addressType,
  categoryId,
}: Props) => {
  if (!categoryId || !addressValues) {
    return null
  }
  return (
    <List>
      <View style={{ flex: 1 }}>
        <View style={styles.item}>
          <Separator style={styles.separator}>
            <Text style={styles.label}>{I18n.t("city_placeholder")}</Text>
          </Separator>
          <TextInput
            style={styles.textarea}
            onChangeText={value =>
              addressUpdated({
                categoryId,
                addressType,
                address: {
                  ...addressValues,
                  city: value,
                },
              })
            }
            value={addressValues.city}
            placeholder={I18n.t("city_placeholder")}
            placeholderTextColor={Colors.noteColor}
            blurOnSubmit
            returnKeyType="done"
            underlineColorAndroid="transparent"
          />
        </View>
        <View style={styles.item}>
          <Separator style={styles.separator}>
            <Text style={styles.label}>{I18n.t("street_address")}</Text>
          </Separator>
          <TextInput
            style={styles.textarea}
            onChangeText={value =>
              addressUpdated({
                categoryId,
                addressType,
                address: {
                  ...addressValues,
                  streetAddress: value,
                },
              })
            }
            value={addressValues.streetAddress}
            placeholder={I18n.t("street_placeholder")}
            placeholderTextColor={Colors.noteColor}
            blurOnSubmit
            returnKeyType="done"
            underlineColorAndroid="transparent"
          />
        </View>
        <View style={styles.item}>
          <Separator style={styles.separator}>
            <Text style={styles.label}>{I18n.t("post_code")}</Text>
          </Separator>
          <TextInput
            style={styles.textarea}
            onChangeText={value =>
              addressUpdated({
                categoryId,
                addressType,
                address: {
                  ...addressValues,
                  postalCode: value,
                },
              })
            }
            value={addressValues.postalCode}
            placeholder={I18n.t("postal_placeholder")}
            placeholderTextColor={Colors.noteColor}
            keyboardType="numeric"
            blurOnSubmit
            returnKeyType="done"
            underlineColorAndroid="transparent"
          />
        </View>
      </View>
    </List>
  )
}
