import React from "react"
import { View } from "react-native"
import { Badge, Body, Icon, ListItem, Right, Text } from "native-base"
import ProfileImage from "components/views/Shared/ProfileImage"
import styles from "./styles"
import I18n from "i18n"
import { Offer } from "modules/Buyer/Offers/types"
import { SellerProfile } from "modules/SellerProfiles/types"

interface Props {
  offer?: Offer
  sellerProfile?: SellerProfile
  last: boolean
  onPress: () => void
}

export default ({ offer, sellerProfile, last, onPress }: Props) => {
  if (!sellerProfile || !offer) {
    return null
  }
  const { seenByBuyer } = offer
  const photoUrl = sellerProfile.photoURL
  return (
    <ListItem onPress={onPress} style={last ? styles.lastItem : null}>
      <ProfileImage style={styles.thumbnail} small source={photoUrl} />
      <Body style={styles.textWrap}>
        <Text
          style={[styles.title, !seenByBuyer ? styles.titleHighlighted : null]}
        >
          {sellerProfile.companyName}
        </Text>
        <View style={styles.info}>
          {sellerProfile.rating && (
            <View style={styles.infoWrap}>
              <Text style={styles.infoText}>
                <Icon name="star" active style={styles.infoIcon} />{" "}
                {sellerProfile.rating}
              </Text>
              <Text style={styles.infoSeparator}>•</Text>
            </View>
          )}
          <Text style={styles.infoText}>
            {offer.pricePerHour * offer.original.purchaseDuration} €
          </Text>
        </View>
      </Body>
      <Right>
        <View style={styles.rightWrap}>
          {!seenByBuyer ? (
            <Badge style={styles.notification}>
              <Text style={styles.notificationText}>{I18n.t("new")}</Text>
            </Badge>
          ) : null}
          <Icon name="arrow-forward" style={styles.icon} />
        </View>
      </Right>
    </ListItem>
  )
}
