import I18n from "ex-react-native-i18n"

I18n.fallbacks = true // let 'en-GB' fallback to 'en' if not found
I18n.translations = {
  en: require("./en.json"), // tslint:disable-line:no-unsafe-any
  fi: require("./fi.json"), // tslint:disable-line:no-unsafe-any
}

// This spares the need to translate english to english
// NOTE: ex-react-native-i18n package is not maintained, hence the typings are incorrent
//       and we need to use "as any" to avoid TS type errors.
;(I18n as any).missingBehaviour = "guess"
;(I18n as any).missingTranslationPrefix = ""

export default I18n
