import React from "react"
import _ from "lodash"
import { View } from "react-native"
import { Text } from "native-base"
import ProfileImage from "../ProfileImage"
import styles from "./styles"
import { BuyerProfile } from "modules/BuyerProfiles/types"

interface Props {
  buyerProfile: BuyerProfile
}

export default ({ buyerProfile }: Props) => {
  return (
    <View style={styles.profile}>
      <ProfileImage
        style={styles.profileImage}
        size={60}
        source={buyerProfile.photoURL}
      />
      <Text style={styles.profileName}>{buyerProfile.displayName}</Text>
    </View>
  )
}
