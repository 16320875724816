import _ from "lodash"
import { eventChannel } from "redux-saga"
import { Notifications } from "expo"
import { all, call, fork, put, take, takeEvery } from "redux-saga/effects"
import { notificationReveicedInApp, pushNotificationSelected } from "./actions"
import { LISTEN_TO_PUSH_NOTIFICATIONS } from "./actionTypes"
import { PushNotification } from "./types"
import { PushNotificationData } from "database_types/notification"

export const getEventChannelForPushNotifications = () => {
  const channel = eventChannel(emit => {
    // We need the channel to be returned before the callback can be called
    let listener: any
    setTimeout(() => {
      listener = Notifications.addListener((notification: any) =>
        emit({ notification }),
      )
    }, 0)
    // tslint:disable-next-line:no-unsafe-any
    return () => listener.remove()
  })
  return channel
}

export function* listenToPushNotificationsWorker() {
  const channel = yield call(getEventChannelForPushNotifications)
  while (true) {
    const { notification }: { notification: PushNotification } = yield take(
      channel,
    ) // tslint:disable-line:no-unsafe-any
    const origin: PushNotification["origin"] = yield notification.origin // tslint:disable-line:no-unsafe-any
    const data: PushNotificationData = yield notification.data // tslint:disable-line:no-unsafe-any
    if (origin === "selected") {
      yield put(pushNotificationSelected(data))
    }
    if (origin === "received") {
      yield put(notificationReveicedInApp(data))
    }
  }
}

export function* listenToPushNotificationsSaga() {
  yield takeEvery(LISTEN_TO_PUSH_NOTIFICATIONS, listenToPushNotificationsWorker)
}

export default function*() {
  yield all([fork(listenToPushNotificationsSaga)])
}
