import React from "react"
import {
  Modal as ReactModal,
  Text,
  View,
  SafeAreaView,
  TouchableOpacity,
} from "react-native"
import DateTimePicker, { Event } from "@react-native-community/datetimepicker"
import { Ionicons } from "@expo/vector-icons"
import I18n from "i18n"
import styles from "./styles"
import Colors from "constants/Colors"

interface Props {
  isVisible: boolean
  value?: Date
  title: string
  confirm: (date: Date) => void
  closeModal: () => void
}

interface State {
  date: Date
}

export default class IosDateModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      date: this.props.value || new Date(1980, 0, 1),
    }
    this.onChange = this.onChange.bind(this)
  }

  onChange(e: Event, date?: Date) {
    if (date) {
      this.setState({ date })
    }
  }

  close = () => {
    this.props.closeModal()
  }

  confirm = () => {
    this.close()
    this.props.confirm(this.state.date)
  }

  render() {
    return (
      <ReactModal
        animationType="slide"
        transparent={false}
        visible={this.props.isVisible}
      >
        <SafeAreaView>
          <View style={styles.buttonWrap}>
            <TouchableOpacity onPress={this.close}>
              <Ionicons name="ios-close" size={45} color={Colors.borderColor} />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={this.confirm}
              style={styles.confirmButton}
            >
              <Ionicons
                name="ios-checkmark"
                size={45}
                color={Colors.brandGreen}
              />
            </TouchableOpacity>
          </View>
          <View style={styles.container}>
            <Text style={styles.title}>{this.props.title || ""}</Text>
            <View style={styles.inputContainer}>
              <DateTimePicker
                mode="date"
                value={this.state.date}
                locale={I18n.locale}
                onChange={this.onChange}
              />
            </View>
          </View>
        </SafeAreaView>
      </ReactModal>
    )
  }
}
