import {
  StyleSheet,
  PixelRatio,
  Dimensions,
  Platform,
  ViewStyle,
  TextStyle,
} from "react-native"
import Colors from "constants/Colors"

const blockWidth = Dimensions.get("window").width - 65
const blockLeft = 55

interface Styles {
  timetable: ViewStyle
  timeBlock: ViewStyle
  timeBlockHourBorder: ViewStyle
  timeBlockHalfHourBorder: ViewStyle
  timeBlockNotAvailableHalfHourBorder: ViewStyle
  timeBlockText: TextStyle
  availableBlock: ViewStyle
  preferredBlock: ViewStyle
  preferredBlockFirst: ViewStyle
  preferredBlockLast: ViewStyle
  selectedBlock: ViewStyle
  existingBlock: ViewStyle
  blockName: ViewStyle
  blockDetails: ViewStyle
  placeHolderText: ViewStyle
  toast: ViewStyle
  toastText: TextStyle
}

export default StyleSheet.create<Styles>({
  timetable: {
    marginTop: 60,
    marginBottom: 80,
  },
  timeBlock: {
    backgroundColor: "transparent",
    left: Platform.OS === "ios" ? 45 : 0,
    position: "absolute",
    width:
      Platform.OS === "ios"
        ? Dimensions.get("window").width - 45
        : Dimensions.get("window").width,
  },
  timeBlockHourBorder: {
    borderColor: Colors.borderColor,
    borderTopWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  timeBlockHalfHourBorder: {
    borderColor: Colors.lighterGrey,
    borderTopWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  timeBlockNotAvailableHalfHourBorder: {
    borderColor: "#a1a1a1",
    borderTopWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  timeBlockText: {
    fontFamily: "open-sans-regular",
    fontSize: 11,
    color: Colors.noteColor,
    marginLeft: Platform.OS === "ios" ? -45 : 0,
    marginTop: Platform.OS === "ios" ? -9.5 : 0,
    textAlign: "right",
    width: 40,
  },
  availableBlock: {
    left: Platform.OS === "ios" ? 10 : blockLeft,
    position: "absolute",
    top: undefined,
    width: blockWidth,
  },
  preferredBlock: {
    backgroundColor: "#dcdcdc",
  },
  preferredBlockFirst: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    marginTop: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  preferredBlockLast: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    marginBottom: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  selectedBlock: {
    opacity: 0.8,
    borderRadius: 10,
    left: blockLeft,
    overflow: "hidden",
    padding: 15,
    position: "absolute",
    width: blockWidth,
  },
  existingBlock: {
    opacity: 0.6,
    borderRadius: 10,
    left: blockLeft,
    overflow: "hidden",
    padding: 15,
    position: "absolute",
    width: blockWidth,
  },
  blockName: {
    position: "absolute",
    left: 15,
    top: 15,
    fontFamily: "open-sans-bold",
    fontSize: 16,
  },
  blockDetails: {
    position: "absolute",
    left: 15,
    top: 45,
    fontFamily: "open-sans-regular",
    fontSize: 14,
  },
  placeHolderText: {
    fontFamily: "open-sans-regular",
    color: Colors.noteColor,
    alignSelf: "center",
    marginTop: 5,
  },
  toast: {
    bottom: 90,
  },
  toastText: {
    textAlign: "center",
  },
})
