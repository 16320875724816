import React from "react"
import { createStackNavigator } from "@react-navigation/stack"
import Colors from "constants/Colors"
import routeNames from "../constants"
import Account from "components/views/Account"
import I18n from "i18n"
import { headerStyles } from "../headerStyles"

const { Navigator, Screen } = createStackNavigator()

export default () => (
  <Navigator
    initialRouteName={routeNames.ACCOUNT.SETTINGS}
    screenOptions={{
      headerBackTitle: undefined,
      headerStyle: headerStyles.headerStyle,
      headerTitleStyle: headerStyles.headerTitleStyle,
      cardStyle: {
        backgroundColor: Colors.containerBackground,
      },
    }}
  >
    <Screen
      name={routeNames.ACCOUNT.SETTINGS}
      component={Account}
      options={{
        header: () => null,
        gestureEnabled: false,
        headerLeft: () => null,
        title: I18n.t("title_profile"),
      }}
    />
  </Navigator>
)
