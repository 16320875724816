import React, { useEffect } from "react"
import { Platform } from "react-native"
import { Picker, Icon } from "native-base"
import I18n from "i18n"
import _ from "lodash"
import { ListPicker } from "modules/Buyer/Questions/types"
import styles from "./styles"

interface Props {
  onDurationChanged: (duration: number) => void
  selectedValue: number | null
  question?: ListPicker
}

const durations: number[] = [...Array(30)].map((item, idx) => 0.5 * (idx + 1))

const DurationInput = ({ onDurationChanged, selectedValue }: Props) => {
  useEffect(() => {
    if (Platform.OS === "android" && !selectedValue) {
      onDurationChanged(durations[0])
    }
  }, [])

  return (
    <Picker
      note={false}
      iosIcon={<Icon name="arrow-down" />}
      style={styles.formItem}
      onValueChange={onDurationChanged}
      placeholder={I18n.t("select_duration")}
      placeholderStyle={[styles.inputPlaceholder, styles.pickerInput]}
      textStyle={[styles.inputText, styles.pickerInput]}
      selectedValue={selectedValue}
    >
      {durations.map(duration => (
        <Picker.Item
          label={`${duration} ${
            duration <= 1 ? I18n.t("hour") : I18n.t("hours")
          }`}
          value={duration}
          key={duration}
        />
      ))}
    </Picker>
  )
}

export default DurationInput
