import { Settings } from "./types"
import { SettingsLoaded, LoadSettings } from "./actions"
import { SETTINGS_LOADED } from "./actionTypes"

export const INITIAL_STATE: Settings = {
  langs: { en: [], fi: [] },
  workareas: { en: [], fi: [] },
}

export default function settingsReducer(
  state: Settings = INITIAL_STATE,
  action: SettingsLoaded | LoadSettings,
): Settings {
  switch (action.type) {
    case SETTINGS_LOADED: {
      return { ...action.payload }
    }
    default:
      return state
  }
}
