import { connect } from "react-redux"
import _ from "lodash"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import routeNames from "components/navigators/constants"
import { getTempSellerProfile } from "modules/SellerProfiles/selectors"
import { CommonActions } from "@react-navigation/native"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => ({
  tempSellerProfile: getTempSellerProfile(state),
})
const mapDispatchToProps = null
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...ownProps,
  buttonEnabled:
    stateProps.tempSellerProfile && stateProps.tempSellerProfile.categories
      ? Object.keys(stateProps.tempSellerProfile.categories).length > 0
      : false,
  confirm: () => {
    ownProps.navigation.dispatch(
      CommonActions.navigate(routeNames.SELLER_CREATE.CREATE_SELLER_PROFILE),
    )
  },
  goBack: () => {
    ownProps.navigation.goBack()
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
