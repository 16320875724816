import React from "react"
import { View } from "react-native"
import { Text } from "native-base"
import styles from "./styles"

export default (props: { emptyTextMessage: string; fullPage?: boolean }) => {
  return (
    <View style={[styles.emptyView, props.fullPage ? styles.fullPage : {}]}>
      <Text
        style={[
          styles.emptyTextMessage,
          props.fullPage ? styles.emptyTextMessageFullPage : {},
        ]}
      >
        {props.emptyTextMessage}
      </Text>
    </View>
  )
}
