import { StyleSheet } from "react-native"
import Colors from "constants/Colors"
import { buttonText, separatorText } from "constants/Fonts"

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    backgroundColor: Colors.containerBackground,
  },
  separator: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    marginTop: 15,
  },
  separatorText: {
    ...separatorText,
  },
  buttonWrap: {
    backgroundColor: "transparent",
    paddingTop: 30,
    paddingBottom: 15,
    paddingLeft: 15,
    paddingRight: 15,
  },
  errorButton: {
    backgroundColor: Colors.containerBackground,
    borderColor: Colors.borderColor,
  },
  errorButtonText: {
    ...buttonText,
    color: Colors.darkerGrey,
  },
  button: {
    backgroundColor: Colors.brandYellow,
  },
  buttonText,
  textStyle: {
    color: Colors.noteColor,
    fontSize: 14,
  },
  toast: {
    bottom: 100,
  },
  toastText: {
    textAlign: "center",
  },
})
