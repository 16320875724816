import React from "react"
import _ from "lodash"
import moment from "moment"
import { View } from "react-native"
import { Body, Icon, ListItem, Right, Text } from "native-base"
import ProfileImage from "components/views/Shared/ProfileImage"
import styles from "./styles"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import { Category } from "modules/Buyer/Categories/types"
import { Offer } from "modules/Buyer/Offers/types"
import { BuyerProfile } from "modules/BuyerProfiles/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface Props {
  offer?: Offer
  offerRequest?: OfferRequest
  category?: Category
  buyerProfile?: BuyerProfile
  onPress: (
    offer: Offer,
    offerRequest: OfferRequest,
    category: Category,
  ) => void
  last: boolean
}

export default ({
  offerRequest,
  category,
  onPress,
  last,
  buyerProfile,
  offer,
}: Props) => {
  if (!offer || !buyerProfile || !category || !offerRequest) {
    return null
  }
  const photoUrl = buyerProfile.photoURL
  const date = moment(offer.agreedStartTime)
  const preferredDate = date.isValid() ? date.format("D.M. [klo] HH:mm") : ""
  return (
    <ListItem
      onPress={() => onPress(offer, offerRequest, category)}
      style={last ? styles.lastItem : null}
    >
      <ProfileImage style={styles.thumbnail} small source={photoUrl} />
      <Body style={styles.textWrap}>
        <Text style={styles.title}>{getLocaLizedName(category.name)}</Text>
        <View style={styles.info}>
          <Text style={styles.infoText}>{buyerProfile.displayName}</Text>
          {preferredDate ? <Text style={styles.infoSeparator}>•</Text> : null}
          {preferredDate ? (
            <Text style={styles.infoText}>{preferredDate}</Text>
          ) : null}
        </View>
      </Body>
      <Right>
        <View style={styles.rightWrap}>
          <Icon name="arrow-forward" style={styles.icon} />
        </View>
      </Right>
    </ListItem>
  )
}
