import React from "react"
import { Body, View, ListItem, Text } from "native-base"
import { Ionicons } from "@expo/vector-icons"
import styles from "./styles"

interface Props {
  name: string
  isSelected: boolean
  toggleSelect: () => void
  first?: boolean
  last?: boolean
}

const Category = ({ name, isSelected, toggleSelect, first, last }: Props) => {
  return (
    <ListItem onPress={toggleSelect} style={styles.item}>
      <View style={styles.iconContainer}>
        <Ionicons
          name={isSelected ? "ios-checkbox" : "ios-square-outline"}
          size={20}
          style={isSelected ? styles.checked : styles.unchecked}
        />
      </View>
      <Body
        style={[
          styles.itemBody,
          first ? styles.itemBodyFirst : null,
          last ? styles.itemBodyLast : null,
        ]}
      >
        <Text
          style={[styles.itemText, isSelected ? styles.checkeditemText : null]}
        >
          {name}
        </Text>
      </Body>
    </ListItem>
  )
}

export default Category
