import { StyleSheet, Dimensions, Platform } from "react-native"
import Colors from "constants/Colors"

const windowWidth = Dimensions.get("window").width

export default StyleSheet.create({
  container: {
    backgroundColor: "white",
    borderTopWidth: 1,
    borderColor: Colors.borderColor,
    paddingHorizontal: 10,
    paddingVertical: 3,
    width: windowWidth,
  },
  flexContainer: {
    flex: 1,
    flexDirection: "row",
  },
  textInputContainer: {
    flex: 1,
  },
  textInput: {
    paddingTop: Platform.OS === "ios" ? 10 : 5,
    paddingBottom: Platform.OS === "ios" ? 10 : 5,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 14,
  },
  submitButton: {
    minHeight: 37,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
    paddingHorizontal: 10,
  },
  submitButtonLabel: {
    fontSize: 14,
  },
  active: {
    color: Colors.brandYellow,
  },
  inActive: {
    color: Colors.lighterGrey,
  },
})
