import { connect } from "react-redux"
import { getOfferStartDateString } from "utilities/time"
import { offerRequestSelected } from "modules/Seller/OfferRequests/actions"
import { offerSelected, startJob } from "modules/Seller/Offers/actions"
import routeNames from "components/navigators/constants"
import _ from "lodash"
import View from "./view"
import { Offer } from "modules/Buyer/Offers/types"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { getCategoryById } from "modules/Buyer/Categories/selectors"
import { getOfferRequestByIdForSeller } from "modules/Seller/OfferRequests/selectors"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface OwnProps {
  offer: Offer
  navigation: Navigation
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => {
  const { offer } = ownProps
  const offerRequest = getOfferRequestByIdForSeller(state, offer.offerRequest)
  return {
    offer,
    offerRequest,
    category: offerRequest && getCategoryById(state, offerRequest.category),
  }
}

const mapDispatchToProps = {
  offerRequestSelected,
  offerSelected,
  startJob,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onPress: () => {
    if (stateProps.offerRequest && stateProps.category) {
      dispatchProps.offerRequestSelected(stateProps.offerRequest.id)
      dispatchProps.offerSelected(stateProps.offer.id)
      ownProps.navigation.navigate(
        routeNames.SELLER.OPEN_OFFER_REQUEST_DETAILS,
        {
          name: `${getLocaLizedName(
            stateProps.category.name,
          )} ${getOfferStartDateString(stateProps.offer)}`,
        },
      )
    }
  },
  startJob: () => {
    dispatchProps.startJob(stateProps.offer)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
