import React from "react"
import { Container, Content } from "native-base"
import OfferRequestList from "../OfferRequests/OfferRequestList"
import Reminders from "../Reminders"
import OfferList from "./OfferList"
import styles from "./styles"
import { Navigation } from "components/types"

interface Props {
  navigation: Navigation
}
//todo add scroll handler to Offers
export default ({ navigation }: Props) => (
  <Container style={styles.container}>
    <Content>
      <Reminders navigation={navigation} />
      <OfferList navigation={navigation} />
      <OfferRequestList navigation={navigation} />
    </Content>
  </Container>
)
