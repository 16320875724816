import React from "react"
import {
  Modal as ReactModal,
  View,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from "react-native"
import { Input, Button, Text, Item } from "native-base"
import Colors from "constants/Colors"
import { isProductionEnvironment } from "api/environment"
import styles from "./styles"
import I18n from "i18n"


interface Props {
  onConfirm: () => void
  uid: string
  isVisible: boolean
}

interface State {
  isFetching: boolean
  resendSeconds: number
  code: string
  invalidCode: boolean
}

export default class VerificationCodeModal extends React.Component<
  Props,
  State
> {
  resendInterval?: number //NodeJS.Timeout

  constructor(props: Props) {
    super(props)
    this.state = {
      isFetching: false,
      resendSeconds: 0,
      code: "1234",
      invalidCode: false,
    }

    var verify = prompt("Please Enter Otp", "1234");
console.log(verify)
  if (verify != null) {
    this.props.onConfirm()
  }
    this.countdown = this.countdown.bind(this)
  }

  componentWillUnmount() {
    if (this.resendInterval) {
      clearInterval(this.resendInterval)
    }
  }

  countdown() {
    this.setState(state => {
      const secs = Math.max(state.resendSeconds - 1, 0)
      if (secs === 0 && this.resendInterval) {
        clearInterval(this.resendInterval)
      }

      return { resendSeconds: secs }
    })
  }

  resendCode() {
    this.setState({ resendSeconds: 30, isFetching: true })
    this.resendInterval = setInterval(this.countdown.bind(this), 1000)

    const postfix = isProductionEnvironment() ? "prod" : "dev"
    const url = `https://api.helpdor.com/sms/resendVerificationCode/${postfix}?uid=${this.props.uid}`

    fetch(url)
      .then(response => response.text())
      .then(text => {
        this.setState({ isFetching: false })
      })
  }

  verifyCode() {
    this.setState({ isFetching: true, invalidCode: false })
    const postfix = isProductionEnvironment() ? "prod" : "dev"
    const url = `https://api.helpdor.com/sms/checkVerificationCode/${postfix}?uid=${this.props.uid}&code=${this.state.code}`
    this.props.onConfirm() //TODO fix the sms
    fetch(url)
      .then(response => response.text())
      .then(text => {
        this.setState({ isFetching: false })

        if (text === "ok") {
          this.props.onConfirm()
        } else {
          this.setState({ invalidCode: true })
        }
      })
  }

  handleKeyPress = (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>,
  ) => {
    if (event.nativeEvent.key === "Enter") {
      this.verifyCode()
    }
  }

  render() {
    return (
      
      <ReactModal
        animationType="slide"
        transparent={true}
      >
        <View style={styles.container}>
          <View style={{ marginTop: 130 }}>
            <Text
              style={{
                marginBottom: 20,
                color: this.state.invalidCode ? "red" : "rgba(255,255,255,0)",
              }}
            >
              {I18n.t("Invalid code")}
            </Text>

            <Text style={{ marginBottom: 20, color: Colors.textColor }}>
              {I18n.t("Please input SMS confirmation code")}
            </Text>

            <Item>
              <Input
                keyboardType="numeric"
                autoCapitalize="none"
                placeholder="1234"
                returnKeyType="done"
                maxLength={4}
                underlineColorAndroid="transparent"
                style={styles.codeInput}
                onChangeText={text => this.setState({ code: text })}
              />
            </Item>
          </View>

          <Button
            style={styles.button}
            block
            rounded
            disabled={this.state.code.length !== 4 || this.state.isFetching}
            onPress={() => this.verifyCode()}
          >
            <Text style={{ color: Colors.textColor, fontSize: 18 }}>
              Verify
            </Text>
          </Button>

          <Button
            transparent
            style={{ alignSelf: "flex-end", marginTop: 15 }}
            disabled={this.state.resendSeconds > 0 || this.state.isFetching}
            onPress={() => this.resendCode()}
          >
            <Text>
              {I18n.t("Send code again")}
              {this.state.resendSeconds === 0
                ? ""
                : `in ${this.state.resendSeconds} sec.`}
            </Text>
          </Button>
        </View>
      </ReactModal>
      
    )
  }
}
