import { StyleSheet, ViewStyle, TextStyle } from "react-native"
import Colors from "constants/Colors"

interface Styles {
  indicatorContainer: ViewStyle
  indicatorText: TextStyle
}

export default StyleSheet.create<Styles>({
  indicatorContainer: {
    alignItems: "center",
    backgroundColor: Colors.brandYellow,
    borderColor: Colors.tabBackground,
    borderRadius: 20,
    borderWidth: 2,
    height: 18,
    justifyContent: "center",
    minWidth: 18,
    overflow: "hidden",
    paddingHorizontal: 2,
    position: "absolute",
    right: -9,
    top: -7,
  },
  indicatorText: {
    color: Colors.tabBackground,
    fontSize: 12,
    fontWeight: "700",
    letterSpacing: -1,
  },
})
