import { StyleSheet } from "react-native"
import Colors from "constants/Colors"
import { buttonText } from "constants/Fonts"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.containerBackground,
    flex: 1,
  },
  button: {
    borderColor: Colors.red,
    backgroundColor: Colors.containerBackground,
    position: "absolute",
    left: 15,
    right: 15,
    bottom: 15,
  },
  buttonText: {
    ...buttonText,
    color: Colors.red,
  },
})
