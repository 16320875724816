export type OFFER_STATUS =
  | "open"
  | "accepted"
  | "completed"
  | "cancelled"
  | "expired"
export type OFFER_REQUEST_STATUS = OFFER_STATUS

export const OFFER_STATUS = {
  OPEN: "open",
  ACCEPTED: "accepted",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
  EXPIRED: "expired",
}

export const OFFER_REQUEST_STATUS = OFFER_STATUS
