import { StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  content: {
    backgroundColor: Colors.containerBackground,
    flex: 1,
    paddingBottom: 75,
    paddingHorizontal: 0,
    paddingTop: 0,
  },
  contentWithoutButton: {
    paddingBottom: 0,
  },
  review: {
    backgroundColor: "transparent",
    paddingBottom: 22,
    paddingTop: 20,
  },
  thumbnail: {
    alignSelf: "flex-start",
    marginRight: 15,
  },
  textWrap: {
    marginLeft: -11,
  },
  reviewName: {
    color: Colors.textColor,
  },
  reviewInfo: {
    marginTop: 2,
  },
  reviewRating: {
    color: Colors.borderColor,
    fontSize: 12,
  },
  reviewRatingActive: {
    color: Colors.starYellow,
  },
  reviewText: {
    color: Colors.textColor,
    fontSize: 12,
    marginTop: 10,
  },
})
