import React from "react"
import { View, SafeAreaView, Modal, TouchableOpacity } from "react-native"
import { Text } from "native-base"
import I18n from "i18n"
import { Ionicons } from "@expo/vector-icons"
import Colors from "constants/Colors"
import styles from "./styles"
import DateTimePicker, { Event } from "@react-native-community/datetimepicker"

interface Props {
  value?: number
  title: string
  onValueChanged: (date: Date) => void
  onModalClosePressed: () => void
  minimumDate?: number
  maximumDate?: number
}

const iosDatePicker: (props: Props) => any = ({
  value,
  title,
  onValueChanged,
  onModalClosePressed,
  minimumDate,
  maximumDate,
}: Props) => {
  return (
    <Modal
      animationType="slide"
      transparent={false}
      onRequestClose={onModalClosePressed}
    >
      <SafeAreaView>
        <View style={styles.modalButtonWrap}>
          <TouchableOpacity onPress={onModalClosePressed}>
            <Ionicons name="ios-close" size={45} color={Colors.borderColor} />
          </TouchableOpacity>
          <Text>{title}</Text>
          <TouchableOpacity onPress={onModalClosePressed}>
            <Ionicons
              name="ios-checkmark"
              size={45}
              color={Colors.brandGreen}
            />
          </TouchableOpacity>
        </View>
        <View>
          <DateTimePicker
            mode="datetime"
            value={value ? new Date(value) : new Date()}
            minuteInterval={15}
            minimumDate={minimumDate ? new Date(minimumDate) : undefined}
            maximumDate={maximumDate ? new Date(maximumDate) : undefined}
            locale={I18n.locale}
            onChange={(e: Event, date?: Date) => {
              if (date) {
                onValueChanged(date)
              }
            }}
          />
        </View>
      </SafeAreaView>
    </Modal>
  )
}

export default iosDatePicker
