import { connect } from "react-redux"
import { getOfferStartDateString } from "utilities/time"
import routeNames from "components/navigators/constants"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Offer } from "modules/Buyer/Offers/types"
import { Navigation } from "components/types"
import { Category } from "modules/Buyer/Categories/types"
import { getSellerProfileForOffer } from "modules/SellerProfiles/selectors"
import { getCompletedOfferForOfferRequest } from "modules/Buyer/Offers/selectors"
import { bookingSelected } from "modules/Buyer/Offers/actions"
import { getCategoryById } from "modules/Buyer/Categories/selectors"
import { getOfferRequestByIdForBuyer } from "modules/Buyer/OfferRequests/selectors"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface OwnProps {
  id: string
  navigation: Navigation
  last: boolean
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => {
  const offerRequest = getOfferRequestByIdForBuyer(state, ownProps.id)
  const offer =
    offerRequest && getCompletedOfferForOfferRequest(state, offerRequest)
  return {
    offerRequest,
    offer,
    category: offerRequest
      ? getCategoryById(state, offerRequest.category)
      : undefined,
    sellerProfile: offer ? getSellerProfileForOffer(state, offer) : undefined,
  }
}

const mapDispatchToProps = {
  bookingSelected,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onPress: (offer: Offer, category: Category) => {
    dispatchProps.bookingSelected(offer.id)

    ownProps.navigation.navigate(routeNames.BUYER.BOOKING_DETAILS, {
      name: `${getLocaLizedName(category.name)} ${getOfferStartDateString(
        offer,
      )}`,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
