import { connect } from "react-redux"
import routeNames from "components/navigators/constants"
import { Props, CreateSellerProfile } from "./view.web"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { CommonActions } from "@react-navigation/native"
import {
  getSellerProfileById,
  getTempSellerProfile,
} from "modules/SellerProfiles/selectors"
import { getUser } from "modules/User/selectors"
import { SellerProfilePersonalInfo } from "modules/SellerProfiles/types"
import { updateTempSellerProfilePersonalInfo } from "modules/SellerProfiles/actions"
import { getLangs } from "modules/Settings/selectors"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const user = getUser(state)

  const sellerProfile = getSellerProfileById(state, (user && user.id) || "")
  const tempSellerProfile = getTempSellerProfile(state)
  const langOptions = getLangs(state)

  return {
    photoUrl:
      (sellerProfile && sellerProfile.photoURL) ||
      (user && user.photoURL) ||
      "",
    companyName: (tempSellerProfile && tempSellerProfile.companyName) || "",
    companyEmail:
      (tempSellerProfile && tempSellerProfile.companyEmail) ||
      (user && user.email) ||
      "",
    ssn: (tempSellerProfile && tempSellerProfile.ssn) || "",
    yearsOfExperience:
      (tempSellerProfile && tempSellerProfile.yearsOfExperience) || 0,
    langs: (tempSellerProfile && tempSellerProfile.langs) || [],
    birthDate:
      tempSellerProfile && tempSellerProfile.birthDate
        ? new Date(tempSellerProfile.birthDate)
        : new Date(1981, 1, 1),
    langOptions,
  }
}

const mapDispatchToProps = {
  updateTempSellerProfilePersonalInfo,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
): Props => ({
  ...stateProps,
  ...ownProps,
  next: (data: SellerProfilePersonalInfo) => {
    dispatchProps.updateTempSellerProfilePersonalInfo(data)
    ownProps.navigation.dispatch(
      CommonActions.navigate(routeNames.SELLER_CREATE.COMPANY_INFO),
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(CreateSellerProfile)
