// tslint:disable:no-unsafe-any
import { eventChannel } from "redux-saga"

export const getEventChannelForFirebaseRef = (
  ref: firebase.database.Reference,
) => {
  const channel: any = eventChannel(emit => {
    // Sometimes Firebase gets value from local database and calls the callback instantly on "ref.on()"
    // We need the channel to be returned before the callback called
    setTimeout(
      () =>
        ref.on("value", snapshot => {
          const values: { values: any } = { values: snapshot.val() }
          emit(values)
        }),
      0,
    )
    return () => ref.off(channel)
  })
  return channel
}
