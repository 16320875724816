import React from "react"
import { connect } from "react-redux"
import routeNames from "../constants"
import {
  HeaderBackButton,
  StackHeaderLeftButtonProps,
} from "@react-navigation/stack"
import { CommonActions, StackActions } from "@react-navigation/native"
import { updateCurrentTransaction } from "modules/Payment/Transaction/actions"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import {
  getCurrentTransaction,
  getTransactionById,
} from "modules/Payment/Transaction/selectors"
import { getSelectedOfferForBuyer } from "modules/Buyer/Offers/selectors"
import { bookingSelected } from "modules/Buyer/Offers/actions"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const currentTransaction = getCurrentTransaction(state)
  const transaction = currentTransaction
    ? getTransactionById(state, currentTransaction)
    : undefined
  return {
    offer: getSelectedOfferForBuyer(state),
    transaction,
  }
}
const mapDispatchToProps = {
  updateCurrentTransaction,
  bookingSelected,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onPress: () => {
    dispatchProps.updateCurrentTransaction(null)
    const { offer, transaction } = stateProps
    if (
      offer &&
      (offer.status === "accepted" ||
        (transaction && transaction.status === "paid"))
    ) {
      dispatchProps.bookingSelected(offer.id)
      //TODO add multiple redirect support
      ownProps.navigation.dispatch(StackActions.replace(routeNames.ROOT.TABS))
      ownProps.navigation.dispatch(
        CommonActions.navigate(routeNames.BUYER.BOOKING_DETAILS),
      )
    } else {
      ownProps.navigation.dispatch(StackActions.replace(routeNames.ROOT.TABS))
      ownProps.navigation.dispatch(
        CommonActions.navigate(routeNames.CHECKOUT_STRIPE.INDEX),
      )
    }
  },
})

const View = (props: StackHeaderLeftButtonProps) => {
  return <HeaderBackButton {...props} />
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
