
import React from "react"
import { View } from "react-native"
import I18n from "i18n"
import { Button, Container, Content, Text, Toast, Textarea } from "native-base"
import {
  ImagePickerRow,
  TextRow,
  PickerRow,
} from "components/views/Shared/Input"
import styles from "./styles"
import { SellerProfileCompanyInfo } from "modules/SellerProfiles/types"
import { Navigation } from "components/types"
import { ImagePickerResult } from "expo-image-picker"
import IBAN from "iban"
import DocumentPicker from 'react-native-document-picker';

export interface Props extends SellerProfileCompanyInfo {
  finish: (data: SellerProfileCompanyInfo) => void
  navigation: Navigation
  workAreaOptions: string[]
}

export class SellerCompanyInfo extends React.PureComponent<Props> {
  state: SellerProfileCompanyInfo

  constructor(props: Props) {
    super(props)
    const {
      companyVAT,
      certificate,
      insurance,
      workAreas,
      city,
      streetAddress,
      postalCode,
      iban,
    } = this.props
    this.state = {
      companyVAT,
      certificate,
      insurance,
      workAreas,
      city,
      streetAddress,
      postalCode,
      iban,
    }
    this.finishPressed = this.finishPressed.bind(this)
    this.hasEmptyValues = this.hasEmptyValues.bind(this)
  }

  hasEmptyValues() {
    return (
      !this.state.city ||
      !this.state.companyVAT ||
      !this.state.streetAddress ||
      !this.state.postalCode ||
      !this.state.iban
    )
  }

  validateIban() {
    return IBAN.isValid(this.state.iban)
  }

  showErrorMessage(text: string) {
    Toast.show({
      text: I18n.t(text),
      position: "bottom",
      duration: 1000,
      style: styles.toast,
      textStyle: styles.toastText,
    })
  }

  finishPressed() {
    if (!this.hasEmptyValues()) {
      if (this.validateIban()) {
        this.props.finish(this.state)
      } else {
        this.showErrorMessage("IBAN is invalid")
      }
    } else {
      this.showErrorMessage("fill_missing_details")
    }
  }

  render() {
    return (
      <Container style={styles.container}>
        <Content>
          <Text style={styles.textStyle}>City</Text>
          <Textarea
            placeholder={I18n.t("City")}
            value={this.state.city}
            style={styles.textarea}
            header={I18n.t("City")}
            onChangeText={(text: string) => {
              this.setState({ city: text })
            }}
          />
          <Text style={styles.textStyle}>Street Address</Text>

          <Textarea
            placeholder={I18n.t("Street Address")}
            value={this.state.streetAddress}
            style={styles.textarea}
            header={I18n.t("Company street address")}
            onChangeText={(text: string) => {
              this.setState({ streetAddress: text })
            }}
          />
          <Text style={styles.textStyle}>Postal Code</Text>

          <Textarea
            placeholder={I18n.t("Postal Code")}
            value={this.state.postalCode}
            style={styles.textarea}
            header={I18n.t("Company postal code")}
            onChangeText={(text: string) => {
              this.setState({ postalCode: text })
            }}
          />
          <Text style={styles.textStyle}>Company VAT</Text>

          <Textarea
            placeholder={I18n.t("Company VAT")}
            value={this.state.companyVAT}
            header={I18n.t("Company VAT")}
            style={styles.textarea}
            onChangeText={(text: string) => {
              this.setState({ companyVAT: text })
            }}
          />
          <Text style={styles.textStyle}>Bank Number (IBAN)</Text>

          <Textarea
            placeholder={I18n.t("Bank Number (IBAN)")}
            value={this.state.iban}
            style={styles.textarea}
            header={I18n.t("Company bank number (IBAN)")}
            onChangeText={(text: string) => {
              this.setState({ iban: text })
            }}
          />
<Text style={styles.textStyle}>Geographical work areas</Text>
          <Textarea
            placeholder={I18n.t("Geographical work areas")}
            value={this.state.workAreas || []}
            header={I18n.t("Places and regions you are willing to work at")}
            onChangeText={(selected: string[]) => {
              this.setState({ workAreas: selected })
            }}
          />

          <ImagePickerRow
            header={I18n.t("Certificate")}
            placeholder={I18n.t("Certificate of expertise, if any")}
            onChange={(result: ImagePickerResult) => {
              this.setState({ certificate: result })
            }}
          />
          <ImagePickerRow
            header={I18n.t("Insurance document")}
            placeholder={I18n.t("Insurance document, if present")}
            onChange={(result: ImagePickerResult) => {
              this.setState({ insurance: result })
            }}
          />
          <View style={styles.buttonWrapper}>
            <Button
              onPress={this.finishPressed}
              disabled={this.hasEmptyValues()}
              block
              rounded
              style={
                this.hasEmptyValues() ? styles.buttonDisabled : styles.button
              }
            >
              <Text
                style={
                  this.hasEmptyValues()
                    ? styles.buttonTextDisabled
                    : styles.buttonText
                }
              >
                {I18n.t("Finish")}
              </Text>
            </Button>
          </View>
        </Content>
      </Container>
    )
  }
}
