import React from "react"
import { View, TextInput, TouchableHighlight } from "react-native"
import { MaterialIcons } from "@expo/vector-icons"
import I18n from "i18n"
import styles from "./styles"

interface Props {
  sendMessage: (message: string) => void
}

interface State {
  height: number
  maxHeight: number
  message: string
}

export default class Editor extends React.Component<Props> {
  state: State
  constructor(props: Props) {
    super(props)
    this.state = {
      height: 0,
      maxHeight: 100,
      message: "",
    }
  }

  sendMessage = () => {
    const { message } = this.state
    if (message && message.trim() !== "") {
      this.setState({ message: "" }, () => {
        this.props.sendMessage(message)
      })
    }
  }

  render() {
    const { height: textHeight, maxHeight } = this.state
    const textInputHeight = Math.min(maxHeight, textHeight + 20)
    const textInputContainerHeight = textInputHeight
    const containerHeight = textInputHeight + 6
    return (
      <View style={[styles.container, { height: containerHeight }]}>
        <View style={styles.flexContainer}>
          <View
            style={[
              styles.textInputContainer,
              { height: textInputContainerHeight },
            ]}
          >
            <TextInput
              multiline
              onContentSizeChange={({
                nativeEvent: {
                  contentSize: { width, height },
                },
              }) => {
                this.setState({ height })
              }}
              onChangeText={value => this.setState({ message: value })}
              value={this.state.message}
              placeholder={I18n.t("send_a_message")}
              style={[styles.textInput, { height: textInputHeight }]}
              underlineColorAndroid="transparent"
            />
          </View>
          <TouchableHighlight
            underlayColor="transparent"
            style={styles.submitButton}
            onPress={this.sendMessage}
          >
            <MaterialIcons
              name="send"
              size={26}
              style={this.state.message ? styles.active : styles.inActive}
            />
          </TouchableHighlight>
        </View>
      </View>
    )
  }
}
