import React from "react"
import { createStackNavigator } from "@react-navigation/stack"
import routeNames from "../constants"
import SellerCreateNavigator from "../SellerCreate"
import SellerNavigator from "../Seller"
import WaitForApprovalView from "components/views/CreateSeller/WaitForApproval"
import Switcher from "./Switcher"

const { Navigator, Screen } = createStackNavigator()
export default () => (
  <Navigator initialRouteName={routeNames.SELLER_SWITCHER.SWITCH}>
    <Screen name={routeNames.SELLER_SWITCHER.SWITCH} component={Switcher} />
    <Screen
      name={routeNames.SELLER_SWITCHER.SELLER_CREATE}
      component={SellerCreateNavigator}
    />
    <Screen
      name={routeNames.SELLER_SWITCHER.SELLER_TABS}
      component={SellerNavigator}
    />
    <Screen
      name={routeNames.SELLER_SWITCHER.WAIT_FOR_APPROVAL}
      component={WaitForApprovalView}
    />
  </Navigator>
)
