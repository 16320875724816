import { Image } from "react-native"
import * as Font from "expo-font"
import { Asset } from "expo-asset"

const cacheImages = (images: any[]) => {
  return images.map(image => {
    if (typeof image === "string") {
      return Image.prefetch(image)
    }
    return Asset.fromModule(image).downloadAsync() // tslint:disable-line:no-unsafe-any
  })
}

const cacheFonts = (fonts: any[]) => {
  return fonts.map(font => Font.loadAsync(font)) // tslint:disable-line:no-unsafe-any
}

const cacheAssetsAsync = (props: { images: any[]; fonts: any[] }) => {
  return Promise.all([...cacheImages(props.images), ...cacheFonts(props.fonts)])
}

export default cacheAssetsAsync
