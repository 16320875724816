import { GET_STRIPE_SESSION, UPDATE_STRIPE_SESSION } from "./actionTypes"
import { GetStripeSessionProps } from "api/stripe"

export interface GetStripeSession extends GetStripeSessionProps {
  type: typeof GET_STRIPE_SESSION
}
export const getStripeSession: (
  props: GetStripeSessionProps,
) => GetStripeSession = props => {
  return {
    type: GET_STRIPE_SESSION,
    ...props,
  }
}

export interface UpdateStripeSession {
  type: typeof UPDATE_STRIPE_SESSION
  sessionId: string
}
export const updateStripeSession: (
  sessionId: string,
) => UpdateStripeSession = sessionId => {
  return {
    type: UPDATE_STRIPE_SESSION,
    sessionId,
  }
}
