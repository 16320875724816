import React from "react"
import _ from "lodash"
import { View, Text } from "react-native"
import { List, Separator } from "native-base"
import Item from "../Item"
import styles from "./styles"
import { Navigation } from "components/types"
import { FeedGroup } from "modules/Buyer/Feed/types"

interface Props {
  navigation: Navigation
  group: FeedGroup
}

export default ({ navigation, group }: Props) => {
  const items = Object.keys(group.items)
  return (
    <View style={styles.container}>
      <List>
        <Separator style={styles.separator}>
          <Text style={styles.titleText}>{group.name.toUpperCase()}</Text>
        </Separator>
        {_.map(items, (item, idx) => (
          <Item
            key={idx}
            navigation={navigation}
            item={group.items[item]}
            isLast={idx === items.length - 1}
          />
        ))}
      </List>
    </View>
  )
}
