import Colors from "constants/Colors"
import { separatorText } from "constants/Fonts"
import {
  StyleSheet,
  ViewStyle,
  ImageStyle,
  TextStyle,
  PixelRatio,
} from "react-native"

interface Styles {
  listItem: ViewStyle
  buttonsContainer: ViewStyle
  button: ViewStyle
  buttonLeft: ViewStyle
  buttonRight: ViewStyle
  buttonText: TextStyle
  iconContainer: ViewStyle
  icon: ImageStyle
  flexItem: ViewStyle
  value: TextStyle
  separator: ViewStyle
  separatorText: TextStyle
}

export default StyleSheet.create<Styles>({
  listItem: {
    flex: 1,
    flexDirection: "row",
    alignContent: "flex-start",
    backgroundColor: Colors.contentBackground,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
    marginLeft: 0,
    left: 0,
  },
  buttonsContainer: {
    flexDirection: "row",
    alignSelf: "flex-end",
    marginLeft: "auto",
  },
  button: {
    borderColor: Colors.borderColor,
    marginVertical: 0,
    height: 28,
    paddingHorizontal: 15,
    borderWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  buttonLeft: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  buttonRight: {
    borderLeftWidth: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  buttonText: {
    color: Colors.brandGreen,
    fontSize: 16,
    lineHeight: 16,
  },
  iconContainer: {
    marginLeft: 15,
  },
  icon: {
    color: Colors.grey,
  },
  flexItem: {
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  value: {
    color: Colors.textColor,
    marginLeft: 10,
  },
  separator: {
    backgroundColor: Colors.containerBackground,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
    marginTop: 15,
  },
  separatorText: {
    ...separatorText,
  },
})
