import { connect } from "react-redux"
import * as Permissions from "expo-permissions"
import * as ImagePicker from "expo-image-picker"
import ExpoPermission from "constants/ExpoPermission"
import View from "./view"
import {
  imageAdded,
  imageRemoved,
} from "modules/Buyer/Questions/actions"
import { ImageQuestion } from "modules/Buyer/Questions/types"

const checkCameraRollPermissions = async () => {
  const result = await Permissions.getAsync(Permissions.CAMERA_ROLL)
  if (result.status !== ExpoPermission.GRANTED) {
    const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL)
    if (status !== ExpoPermission.GRANTED) {
      return false
    }
  }
  return true
}
const checkCameraPermissions = async () => {
  const result = await Permissions.getAsync(Permissions.CAMERA)
  if (result.status !== ExpoPermission.GRANTED) {
    const { status } = await Permissions.askAsync(Permissions.CAMERA)
    if (status !== ExpoPermission.GRANTED) {
      return false
    }
  }
  return true
}

interface OwnProps {
  question: ImageQuestion
}

const mapStateToProps = null

const mapDispatchToProps = {
  imageAdded,
  removeImage: imageRemoved,
}

const mergeProps = (
  stateProps: typeof mapStateToProps,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...dispatchProps,
  ...ownProps,
  addImageFromCamera: async () => {
    const hasCameraRollPermission = await checkCameraRollPermissions()
    const hasCameraPermission = await checkCameraPermissions()

    if (hasCameraPermission && hasCameraRollPermission) {
      const result = await ImagePicker.launchCameraAsync({
        base64: true,
      })
      if (!result.cancelled) {
        dispatchProps.imageAdded({
          base64: (result as any).base64 as string, // expo typing doesn't include base64 even though it exists based on docs
          width: result.width,
          height: result.height,
          uri: result.uri,
          questionId: ownProps.question.id,
        })
      }
    }
  },
  addImageFromGallery: async () => {
    const hasCameraRollPermission = await checkCameraRollPermissions()
    if (hasCameraRollPermission) {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        base64: true,
      })
      if (!result.cancelled) {
        dispatchProps.imageAdded({
          base64: (result as any).base64 as string, // expo typing doesn't include base64 even though it exists based on docs
          width: result.width,
          height: result.height,
          uri: result.uri,
          questionId: ownProps.question.id,
        })
      }
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
