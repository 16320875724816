import { StyleSheet } from "react-native"
import Colors from "constants/Colors"
import { titleText } from "constants/Fonts"

export default StyleSheet.create({
  listItem: {
    padding: 0,
  },
  listItemLast: {
    padding: 0,
    borderBottomWidth: 0,
  },
  body: {
    paddingLeft: 15,
  },
  title: {
    ...titleText,
    lineHeight: 20,
  },
  meta: {
    fontSize: 12,
    fontFamily: "open-sans-regular",
    color: Colors.noteColor,
  },
  icon: {
    color: Colors.brandYellow,
  },
})
