import React from "react"
import _ from "lodash"
import { View } from "react-native"
import { List, Separator, Text, Content } from "native-base"
import OrderDetails from "components/views/Shared/OrderDetails"
import I18n from "i18n"
import OffersListItem from "../OffersListItem"
import styles from "./styles"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import { Navigation } from "components/types"
import { Offer } from "modules/Buyer/Offers/types"
import { sortByCreatedAt } from "utilities/sortBy"

const NoOffers = ({ offerRequest }: { offerRequest?: OfferRequest }) => (
  <View style={styles.listWrap}>
    <View style={styles.subHeaderWrapper}>
      <Text style={styles.fetchingOfferText}>{I18n.t("fetching_offers")}</Text>
    </View>
    {offerRequest ? <OrderDetails offerRequest={offerRequest} /> : null}
  </View>
)

const OffersList = ({
  navigation,
  offers,
}: {
  navigation: Navigation
  offers: Offer[]
}) => (
  <View style={styles.listWrap}>
    <View style={styles.headerBorder} />
    <Content>
      <List style={styles.list}>
        <Separator style={styles.separator}>
          <Text style={styles.separatorText}>
            {`${offers.length} `}
            {offers.length === 1
              ? I18n.t("one_offer_received").toUpperCase()
              : I18n.t("multiple_offers_received").toUpperCase()}
          </Text>
        </Separator>
        {offers.map(({ id }, idx) => (
          <OffersListItem
            key={`${id}_service_provider_list_item`}
            offerId={id}
            last={offers.length === idx + 1}
            navigation={navigation}
          />
        ))}
      </List>
    </Content>
  </View>
)

interface Props {
  offers: Offer[]
  navigation: Navigation
  offerRequest?: OfferRequest
}

export default ({ offers, navigation, offerRequest }: Props) => {
  const orderedOffers = sortByCreatedAt(offers, "desc")
  return orderedOffers.length ? (
    <OffersList navigation={navigation} offers={orderedOffers} />
  ) : (
    <NoOffers offerRequest={offerRequest} />
  )
}
