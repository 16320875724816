import React from "react"
import { View, TouchableOpacity } from "react-native"
import { Separator, Text } from "native-base"
import { Ionicons } from "@expo/vector-icons"
import Colors from "constants/Colors"
import I18n from "i18n"
import styles from "./styles"

import PickerModal from "../Modals/PickerModal"


interface Props {
  options: string[]
  value: string[]
  header: string
  title: string
  placeholder: string
  onChange: (value: string[]) => void
}

interface State {
  options: string[]
  value: string[]

}

export default class PickerRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      options: props.options,
      value: props.value || [],
     
    }

    this.printValue = this.printValue.bind(this)
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.options !== this.props.options) {
      this.setState({ options: this.props.options })
    }
  }

  onChange(value: string[]) {
    this.setState({ value }, () => {
      this.props.onChange(value)
    })
  }

  printValue() {
    return this.state.value.length > 0
      ? this.state.value.map(value => I18n.t(value)).join(", ")
      : this.props.placeholder
  }

  render() {
    return (
      <View>
        <PickerModal

        
          items={this.state.options}
          selected={this.state.value}
          

          confirm={(value: string[]) => this.onChange(value)}
          title={this.props.title}
        />
        <Separator style={styles.separator}>
          <Text>{this.props.header.toUpperCase()}</Text>
        </Separator>

      </View>
    )
  }
}
