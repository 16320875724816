import Colors from "./Colors"

export const titleText = {
  fontFamily: "open-sans-regular",
  color: Colors.textColor,
  fontSize: 18,
}

export const titleTextBold = {
  fontFamily: "open-sans-bold",
  color: Colors.textColor,
  fontSize: 18,
}

export const bodyText = {
  fontFamily: "open-sans-regular",
  color: Colors.textColor,
  fontSize: 16,
}

export const bodyTextBold = {
  fontFamily: "open-sans-bold",
  color: Colors.textColor,
  fontSize: 16,
}

export const separatorText = {
  fontFamily: "open-sans-regular",
  color: Colors.noteColor,
  fontSize: 13,
}

export const listItemSeparatorText = {
  fontFamily: "open-sans-regular",
  color: Colors.darkerGrey,
  fontSize: 12,
}

export const listItemText = {
  fontFamily: "open-sans-regular",
  color: Colors.noteColor,
}

export const selectedText = {
  color: Colors.textColor,
}

export const buttonText = {
  fontFamily: "open-sans-regular",
  fontSize: 16,
}

export const spinnerText = {
  fontFamily: "open-sans-regular",
  fontSize: 11,
  color: Colors.noteColor,
}
