import _ from "lodash"
import React from "react"
import {
  Animated,
  TouchableHighlight,
  View,
  Text,
  SafeAreaView,
} from "react-native"
// tslint:disable-next-line:no-implicit-dependencies
import Swipeable from "react-native-gesture-handler/Swipeable"
import GestureRecognizer from "react-native-swipe-gestures"
import styles from "./styles"
import { PushNotificationData } from "database_types/notification"

const SHOW_ANIMATION_DURATION = 250
const HIDE_ANIMATION_DURATION = 150

interface Props {
  onDismiss: () => void
  onPress: (inAppNotification: PushNotificationData) => void
  notification: PushNotificationData
}

class InAppNotification extends React.Component<Props> {
  closeTimer?: NodeJS.Timeout
  swipeableRow?: Swipeable
  state: {
    top: Animated.Value
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      top: new Animated.Value(-150),
    }
    this.dismiss = this.dismiss.bind(this)
  }

  componentDidMount() {
    this.open()
    this.delayedClose()
  }

  componentDidUpdate() {
    this.open()
    this.delayedClose()
  }

  open() {
    if (this.closeTimer) {
      clearTimeout(this.closeTimer)
    }
    Animated.timing(this.state.top, {
      toValue: 0,
      duration: SHOW_ANIMATION_DURATION,
      useNativeDriver: true,
    }).start()
  }

  delayedClose() {
    this.closeTimer = setTimeout(() => {
      Animated.timing(this.state.top, {
        toValue: -150,
        duration: HIDE_ANIMATION_DURATION,
        useNativeDriver: true,
      }).start(this.dismiss)
    }, 28500)
  }

  dismiss() {
    this.props.onDismiss()
    if (this.swipeableRow) {
      this.swipeableRow.close()
    }
  }

  updateRef = (ref: Swipeable) => {
    this.swipeableRow = ref
  }

  render() {
    const { notification, onPress } = this.props
    const sideAction = () => <Text style={{ flex: 1, opacity: 0 }}>x</Text>
    return (
      <View style={{ zIndex: 1000000000, position: "absolute", top: 0 }}>
        <SafeAreaView>
          <Animated.View style={{ top: this.state.top }}>
            {" "}
            {/* tslint:disable-line:no-unsafe-any */}
            <GestureRecognizer onSwipeUp={this.dismiss}>
              <Swipeable
                ref={this.updateRef}
                onSwipeableOpen={this.dismiss}
                renderLeftActions={sideAction}
                renderRightActions={sideAction}
              >
                <TouchableHighlight
                  activeOpacity={1}
                  onPress={() => onPress(notification)}
                >
                  <View style={styles.container}>
                    <View style={styles.titleRow}>
                      <Text style={styles.title}>
                        {notification.message.title}
                      </Text>
                    </View>
                    <View style={styles.bodyRow}>
                      <Text style={styles.body}>
                        {notification.message.body}
                      </Text>
                    </View>
                  </View>
                </TouchableHighlight>
              </Swipeable>
            </GestureRecognizer>
          </Animated.View>{" "}
          {/* tslint:disable-line:no-unsafe-any */}
        </SafeAreaView>
      </View>
    )
  }
}

export default InAppNotification
