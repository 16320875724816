import { connect } from "react-redux"
import routeNames from "components/navigators/constants"
import { TYPES as notificationTypes } from "modules/Notifications/constants"
import { getOfferStartDateString } from "utilities/time"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { getNotificationToNavigate } from "modules/Notifications/selectors"
import { getSellerOfferById } from "modules/Seller/Offers/selectors"
import { getCategoryById } from "modules/Buyer/Categories/selectors"
import { getOfferByIdForBuyer } from "modules/Buyer/Offers/selectors"
import { offerRequestSelectedByBuyer } from "modules/Buyer/OfferRequests/actions"
import { getOfferRequestByIdForBuyer } from "modules/Buyer/OfferRequests/selectors"
import {
  bookingSelected,
  offerSelected as buyerOfferSelected,
} from "modules/Buyer/Offers/actions"
import { offerRequestSelected } from "modules/Seller/OfferRequests/actions"
import { offerSelected as sellerOfferSelected } from "modules/Seller/Offers/actions"
import { getOfferRequestByIdForSeller } from "modules/Seller/OfferRequests/selectors"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"
import { CommonActions } from "@react-navigation/native"
import { Navigation } from "components/types"

interface OwnProps {
  navigate: Navigation
}

const mapStateToProps = (state: Store) => {
  const notification = getNotificationToNavigate(state)

  if (!notification) {
    return {}
  }

  let offerRequest
  let offer
  let category
  switch (notification.meta.type) {
    case "newOffer":
      return {
        type: "newOffer",
        notification,
        buyerOfferRequestId: getOfferRequestByIdForBuyer(
          state,
          notification.meta.offerRequestId,
        )
          ? notification.meta.offerRequestId
          : undefined,
      }
    case "chatMessageForBuyer":
      offerRequest = getOfferRequestByIdForBuyer(
        state,
        notification.meta.offerRequestId,
      )
      offer = getOfferByIdForBuyer(state, notification.meta.offerId)
      category = offerRequest
        ? getCategoryById(state, offerRequest.category)
        : undefined
      return {
        type: "chatMessageForBuyer",
        notification,
        buyerOfferRequestId: offerRequest ? offerRequest.id : undefined,
        buyerOfferId: offer ? offer.id : undefined,
        title:
          offer && category
            ? `${getLocaLizedName(category.name)} ${getOfferStartDateString(
                offer,
              )}`
            : "",
      }
    case "chatMessageForSeller":
      offerRequest = getOfferRequestByIdForSeller(
        state,
        notification.meta.offerRequestId,
      )
      offer = getSellerOfferById(state, notification.meta.offerId)
      category = offerRequest
        ? getCategoryById(state, offerRequest.category)
        : undefined
      return {
        type: "chatMessageForBuyer",
        notification,
        sellerOfferRequestId: offerRequest ? offerRequest.id : undefined,
        sellerOfferId: offer ? offer.id : undefined,
        title:
          offer && category
            ? `${getLocaLizedName(category.name)} ${getOfferStartDateString(
                offer,
              )}`
            : "",
      }
    default:
      return {}
  }
}

const mapDispatchToProps = {
  buyerOfferRequestSelected: offerRequestSelectedByBuyer,
  buyerOfferSelected,
  bookingSelected,
  sellerOfferRequestSelected: offerRequestSelected,
  sellerOfferSelected,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  navigateToNotification: () => {
    const {
      type,
      buyerOfferId,
      buyerOfferRequestId,
      sellerOfferId,
      sellerOfferRequestId,
      title,
    } = stateProps
    const { navigate } = ownProps
    if (type === notificationTypes.NEW_OFFER_REQUEST) {
      navigate.dispatch(CommonActions.navigate(routeNames.HOME_TABS.SELLER_TAB))
    }
    if (type === notificationTypes.NEW_OFFER) {
      if (buyerOfferRequestId) {
        dispatchProps.buyerOfferRequestSelected(buyerOfferRequestId)
        //todo
        navigate.dispatch(
          CommonActions.navigate(routeNames.HOME_TABS.BUYER_TAB),
        )
        navigate.dispatch(
          CommonActions.navigate(routeNames.BUYER.REQUEST_OFFERS),
        )
      }

      navigate.dispatch(CommonActions.navigate(routeNames.HOME_TABS.BUYER_TAB))
    }
    if (type === notificationTypes.OFFER_ACCEPTED) {
      navigate.dispatch(CommonActions.navigate(routeNames.HOME_TABS.SELLER_TAB))
      navigate.dispatch(CommonActions.navigate(routeNames.SELLER.TAB_BOOKINGS))
    }
    if (type === notificationTypes.CHAT_MESSAGE_FOR_BUYER) {
      if (buyerOfferRequestId && buyerOfferId) {
        dispatchProps.buyerOfferRequestSelected(buyerOfferRequestId)
        dispatchProps.bookingSelected(buyerOfferId)
        navigate.dispatch(
          CommonActions.navigate(routeNames.HOME_TABS.BUYER_TAB),
        )
        navigate.dispatch(
          CommonActions.navigate(routeNames.BUYER.BOOKING_DETAILS, {
            name: title,
          }),
        )
      }
      navigate.dispatch(CommonActions.navigate(routeNames.HOME_TABS.BUYER_TAB))
    }
    if (type === notificationTypes.CHAT_MESSAGE_FOR_SELLER) {
      if (sellerOfferRequestId && sellerOfferId) {
        dispatchProps.sellerOfferRequestSelected(sellerOfferRequestId)
        dispatchProps.sellerOfferSelected(sellerOfferId)
        navigate.dispatch(
          CommonActions.navigate(routeNames.HOME_TABS.SELLER_TAB),
        )
        navigate.dispatch(
          CommonActions.navigate(routeNames.SELLER.OPEN_OFFER_REQUEST_DETAILS, {
            name: title,
          }),
        )
      }
      navigate.dispatch(CommonActions.navigate(routeNames.HOME_TABS.SELLER_TAB))
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
