import _ from "lodash"
import React from "react"
import { View, Text } from "react-native"
import { List, Separator } from "native-base"
import HistoryListItem from "../HistoryListItem"
import EmptyList from "components/views/Shared/EmptyList"
import I18n from "i18n"
import styles from "./styles"
import { Navigation } from "components/types"

interface Props {
  historyOfferRequestIds: string[]
  navigation: Navigation
}

export default class HistoryList extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !_.isEqual(
      nextProps.historyOfferRequestIds,
      this.props.historyOfferRequestIds,
    )
  }

  render() {
    const { historyOfferRequestIds = [], navigation } = this.props
    return (
      <List style={styles.list}>
        <Separator style={styles.separator}>
          <Text style={styles.separatorText}>
            {I18n.t("buyer_orders_history").toUpperCase()}
          </Text>
        </Separator>
        <View>
          {historyOfferRequestIds.length ? (
            historyOfferRequestIds.map((item, idx) => (
              <HistoryListItem
                last={historyOfferRequestIds.length === idx + 1}
                key={`bookings_list_item_${item}`}
                id={item}
                navigation={navigation}
              />
            ))
          ) : (
            <EmptyList
              emptyTextMessage={I18n.t("empty_completed_offer_request_list")}
            />
          )}
        </View>
      </List>
    )
  }
}
