import {
  CATEGORY_SELECTED,
  CATEGORIES_SUBSCRIBE,
  CATEGORIES_UPDATED,
  CATEGORIES_ADDRESS_UPDATED,
} from "./actionTypes"
import { AddressTypes } from "./constants"
import { CategoriesDB } from "database_types/category"
import { Address } from "database_types/offerRequest"

export interface CategorySelected {
  type: typeof CATEGORY_SELECTED
  categoryId: string
}
export const categorySelected: (
  categoryId: string,
) => CategorySelected = categoryId => {
  return {
    type: CATEGORY_SELECTED,
    categoryId,
  }
}

export interface SubscribeToCategories {
  type: typeof CATEGORIES_SUBSCRIBE
}
export const subscribeToCategories: () => SubscribeToCategories = () => {
  return {
    type: CATEGORIES_SUBSCRIBE,
  }
}

export interface CategoriesUpdated {
  type: typeof CATEGORIES_UPDATED
  categories: CategoriesDB
}
export const categoriesUpdated: (
  categories: CategoriesDB,
) => CategoriesUpdated = categories => {
  return {
    type: CATEGORIES_UPDATED,
    categories,
  }
}

export interface AddressUpdatedProps {
  categoryId: string
  addressType: AddressTypes
  address: Address
}
export interface AddressUpdated extends AddressUpdatedProps {
  type: typeof CATEGORIES_ADDRESS_UPDATED
}
export const addressUpdated: (
  props: AddressUpdatedProps,
) => AddressUpdated = props => {
  return {
    type: CATEGORIES_ADDRESS_UPDATED,
    ...props,
  }
}
