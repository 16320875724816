import { StyleSheet } from "react-native"
import Colors from "constants/Colors"
import { titleText, buttonText } from "constants/Fonts"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.containerBackground,
  },
  buttonWrap: {
    position: "absolute",
    bottom: 15,
    left: 15,
    right: 15,
  },
  modalText: {
    ...titleText,
    margin: 30,
    marginTop: 120,
    textAlign: "center",
  },
  errorButton: {
    borderColor: Colors.darkerGrey,
  },
  errorButtonText: {
    color: Colors.darkerGrey,
  },
  buttonWrapper: {
    bottom: 15,
    left: 15,
    right: 15,
    position: "absolute",
  },
  modalButton: {
    backgroundColor: Colors.brandYellow,
    marginHorizontal: 15,
    marginBottom: 15,
  },
  button: {
    backgroundColor: Colors.brandYellow,
  },
  buttonText,
  toast: {
    bottom: 100,
  },
  toastText: {
    textAlign: "center",
  },
  formTitle: {
    fontSize: 16,
    fontFamily: "open-sans-bold",
    color: Colors.textColor,
    flex: 1,
    marginTop: 30,
    paddingHorizontal: 15,
  },
})
