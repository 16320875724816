import React from "react"
import moment from "moment"
import _ from "lodash"
import { Text } from "native-base"
import { View, TouchableOpacity } from "react-native"
import I18n from "i18n"
import styles from "./styles"
import { stepInHour, HOURS, convertTimeToIndex } from "./helpers"
import { SuitableTime } from "modules/Buyer/Questions/types"

const NOT_SUITABLE_SLOT = 0
const SUITABLE_SLOT_FIRST = 1
const SUITABLE_SLOT_MIDDLE = 2
const SUITABLE_SLOT_LAST = 3
const SUITABLE_SLOT_FIRST_AND_LAST = 4
const getSuitableSlot = ({
  idx,
  suitableTimesForTheDay,
}: {
  idx: number
  suitableTimesForTheDay: SuitableTime[]
}) => {
  let slotType = NOT_SUITABLE_SLOT
  suitableTimesForTheDay.some(time => {
    const startTimeIdx = convertTimeToIndex(
      moment(time.startTime, "x").format("HH:MM"),
    )
    const endTimeIdx = convertTimeToIndex(
      moment(time.endTime, "x").format("HH:MM"),
    )
    if (idx === startTimeIdx && idx === endTimeIdx) {
      slotType = SUITABLE_SLOT_FIRST_AND_LAST
      return true
    }
    if (idx === startTimeIdx) {
      slotType = SUITABLE_SLOT_FIRST
      return true
    }
    if (idx === endTimeIdx) {
      slotType = SUITABLE_SLOT_LAST
      return true
    }
    if (idx > startTimeIdx && idx < endTimeIdx) {
      slotType = SUITABLE_SLOT_MIDDLE
      return true
    }
    return false
  })
  return slotType
}

const Slot = ({
  startHour,
  minutes,
  top,
  suitable,
  selectTimeSlot,
}: {
  startHour: number
  minutes: string
  top: number
  suitable: number
  selectTimeSlot: (timeSlot: string) => void
}) => {
  let placeHolder = ""
  let height = stepInHour / 2
  let style = [styles.availableBlock]
  if (suitable === SUITABLE_SLOT_FIRST_AND_LAST) {
    placeHolder = I18n.t("preferred_starting_time")
    style = style.concat([
      styles.preferredBlock,
      styles.preferredBlockFirst,
      styles.preferredBlockLast,
    ])
    height = stepInHour / 2 - 1
  }
  if (suitable === SUITABLE_SLOT_FIRST) {
    placeHolder = I18n.t("preferred_slot")
    style = style.concat([styles.preferredBlock, styles.preferredBlockFirst])
    height = stepInHour / 2
  }
  if (suitable === SUITABLE_SLOT_MIDDLE) {
    style = style.concat([styles.preferredBlock])
  }
  if (suitable === SUITABLE_SLOT_LAST) {
    style = style.concat([styles.preferredBlock, styles.preferredBlockLast])
    height = stepInHour / 2
  }

  return (
    <View
      style={[
        styles.timeBlock,
        minutes !== "30"
          ? styles.timeBlockHourBorder
          : styles.timeBlockHalfHourBorder,
        { top, height },
      ]}
    >
      <TouchableOpacity
        onPress={() =>
          selectTimeSlot(`${startHour < 10 ? "0" : ""}${startHour}:${minutes}`)
        }
      >
        <Text style={[styles.timeBlockText, { height }]}>
          {startHour}:{minutes}
        </Text>
        <View style={[style, { height }]}>
          <Text style={styles.placeHolderText}>{placeHolder}</Text>
        </View>
      </TouchableOpacity>
    </View>
  )
}

export default ({
  suitableTimesForTheDay,
  selectTimeSlot,
}: {
  suitableTimesForTheDay: SuitableTime[]
  selectTimeSlot: (timeSlot: string) => void
}) => (
  <>
    {_.map([...Array(HOURS * 2)], (hour, idx) => {
      const top = stepInHour * idx * 0.5
      let startHour
      let minutes

      if (idx % 2 === 0) {
        // is even hour
        startHour = idx / 2
        minutes = "00"
      } else {
        // is half hour
        startHour = (idx - 1) / 2
        minutes = "30"
      }
      const suitable = getSuitableSlot({ idx, suitableTimesForTheDay })
      return (
        <Slot
          key={idx}
          top={top}
          startHour={startHour}
          minutes={minutes}
          suitable={suitable}
          selectTimeSlot={selectTimeSlot}
        />
      )
    })}
  </>
)
