import { StyleSheet, PixelRatio } from "react-native"
import Colors from "constants/Colors"
import FontSizes from "constants/FontSizes"

export default StyleSheet.create({
  item: {
    backgroundColor: Colors.containerBackground,
  },
  separator: {
    backgroundColor: "transparent",
    borderWidth: 0,
    height: "auto",
    marginBottom: 10,
    marginTop: 30,
  },
  label: {
    color: Colors.darkerGrey,
    fontSize: 12,
  },
  textarea: {
    backgroundColor: Colors.contentBackground,
    height: 45,
    fontSize: FontSizes.questionPlaceholderSize,
    lineHeight: FontSizes.questionPlaceholderSize,
    paddingLeft: 15,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderTopWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
  },
})
