import React from "react"
import { Container, Content } from "native-base"
import HistoryList from "./HistoryList"
import styles from "./styles"
import { Navigation } from "components/types"

interface Props {
  navigation: Navigation
}

export default ({ navigation }: Props) => (
  <Container style={styles.container}>
    <Content>
      <HistoryList navigation={navigation} />
    </Content>
  </Container>
)
