import { StyleSheet, Dimensions, PixelRatio } from "react-native"
import Colors from "constants/Colors"
import FontSizes from "constants/FontSizes"

const { width } = Dimensions.get("window")
export default StyleSheet.create({
  buttonWrap: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 15,
    height: 45,
    width,
  },
  container: {
    paddingHorizontal: 15,
    width,
  },
  questionTitle: {
    fontFamily: "open-sans-bold",
    color: Colors.noteColor,
    fontSize: 12,
    paddingBottom: 10,
  },
  inputContainer: {
    borderTopWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderTopColor: Colors.borderColor,
  },
  input: {
    fontFamily: "open-sans-regular",
    color: Colors.textColor,
    fontSize: FontSizes.questionPlaceholderSize,
    lineHeight: FontSizes.questionPlaceholderSize,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 0,
    borderBottomWidth: 0,
  },
  confirmButton: {
    paddingLeft: 15,
  },
})
