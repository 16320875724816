import { QuestionDB } from "database_types/categoryQuestions"
import { OfferRequestQuestion } from "database_types/offerRequestQuestions"

export interface Label {
  en: string
  fi: string
}

export interface BaseQuestion {
  id: string
  name?: Label
  optional?: boolean
  order: number
  type: string
  showError?: boolean
  position?: number
}

export interface RadioQuestion extends BaseQuestion {
  type: "radio"
  options: QuestionOptions
  selectedOption?: string
}

export interface CheckBoxQuestion extends BaseQuestion {
  type: "checkbox"
  options: QuestionOptions
}

export interface QuestionOptions {
  [id: string]: QuestionOption
}

export interface QuestionOption {
  id: string
  name?: Label
  order?: number
  questions?: string[]
  checked?: boolean
}

export interface TextAreaQuestion extends BaseQuestion {
  type: "textarea"
  placeholder?: Label
  value?: string
  defaultValue?: number
}

export interface ListPicker extends BaseQuestion {
  type: "list"
  placeholder?: Label
  value?: number
}

export interface NumberQuestion extends BaseQuestion {
  type: "number"
  placeholder?: Label
  value?: number
}

export interface NumberInputQuestion extends BaseQuestion {
  type: "number_input"
  placeholder?: Label
  value?: string
  defaultValue?: number
}

export interface ImageQuestion extends BaseQuestion {
  type: "image"
  images?: {
    [id: string]: true
  } | null
  imageCount?: number
}

export interface SuitableTime {
  day: string
  startTime: number
  endTime: number
}

export interface SuitableTimes {
  [key: string]: SuitableTime
}

export interface DateQuestion extends BaseQuestion {
  type: "date"
  preferredTime?: number
  suitableTimesCount?: number
  suitableTimes?: SuitableTimes
}

export type Question =
  | RadioQuestion
  | CheckBoxQuestion
  | TextAreaQuestion
  | ListPicker
  | NumberQuestion
  | DateQuestion
  | ImageQuestion
  | NumberInputQuestion

export interface Questions {
  [id: string]: Question
}

export interface QuestionsState {
  questions: Questions
  successModalVisible: boolean
  scrollToBottom: boolean
  inputModalQuestionId?: string
  scrollEnabled: boolean
  scrollPosition: number
  selectedDateQuestionId?: string
}

export function isDateQuestion(
  question: Question | QuestionDB | OfferRequestQuestion,
): question is DateQuestion {
  return (question as DateQuestion).type === "date"
}
