import React from "react"
import { Container, Content } from "native-base"
import BookingsList from "../Bookings/BookingsList"
import Reminders from "../Reminders"
import OfferRequestList from "./OfferRequestList"
import styles from "./styles"
import { Navigation } from "components/types"

export default ({ navigation }: { navigation: Navigation }) => (
  <Container style={styles.container}>
    <Content>
      <Reminders navigation={navigation} />
      <BookingsList navigation={navigation} />
      <OfferRequestList navigation={navigation} />
    </Content>
  </Container>
)
