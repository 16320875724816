import React from "react"
import Label from "../Label"
import reduxStore, { Store } from "utilities/Redux/store"
import { connect, Provider } from "react-redux"
import I18n from "i18n"
import {
  getUnseenAcceptedOffersTotalForSeller,
  getSellerOffersWithStatus,
} from "modules/Seller/Offers/selectors"
import { TabBarLabelProps } from "components/types"
import { getUnseenMessagesCount } from "modules/ChatMessages/selectors"

const mapStateToProps = (state: Store) => ({
  unseenOffersCount: getUnseenAcceptedOffersTotalForSeller(state),
  unseenMessagesCount: getUnseenMessagesCount(
    state,
    Object.values(getSellerOffersWithStatus(state, "accepted")),
  ),
})
const mapDispatchToProps = null
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: TabBarLabelProps,
) => ({
  ...stateProps,
  ...ownProps,
  unseenCount: stateProps.unseenMessagesCount + stateProps.unseenOffersCount,
})

const ConnectedLabel = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Label)

export default (props: TabBarLabelProps) => (
  <Provider store={reduxStore}>
    <ConnectedLabel
      label={I18n.t("title_seller_orders_bookings_tab")}
      {...props}
    />
  </Provider>
)
