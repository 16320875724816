import React from "react"
import { View } from "react-native"
import { Separator, Text, ListItem, Button } from "native-base"
import { Ionicons } from "@expo/vector-icons"
import styles from "./styles"

interface Props {
  header: string
  value: number
  min: number
  max: number
  onChange: (value: number) => void
}

interface State {
  value: number
}

export default class NumberRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      value: this.props.value || 0,
    }
    this.increaseValue = this.increaseValue.bind(this)
    this.decreaseValue = this.decreaseValue.bind(this)
  }

  increaseValue() {
    if (this.props.max === undefined || this.state.value < this.props.max) {
      this.setState(
        prevState => ({ value: prevState.value + 1 }),
        () => {
          this.props.onChange(this.state.value)
        },
      )
    }
  }

  decreaseValue() {
    if (this.props.min === undefined || this.state.value > this.props.min) {
      this.setState(
        prevState => ({ value: prevState.value - 1 }),
        () => {
          this.props.onChange(this.state.value)
        },
      )
    }
  }

  render() {
    return (
      <View>
        <Separator style={styles.separator}>
          <Text>{this.props.header.toUpperCase()}</Text>
        </Separator>
        <ListItem style={styles.listItem}>
          <View style={[styles.flexItem, styles.iconContainer]}>
            <Ionicons name="ios-stats" size={20} style={styles.icon} />
          </View>
          <Text style={[styles.flexItem, styles.value]}>
            {this.state.value}
          </Text>

          <View style={styles.buttonsContainer}>
            <Button
              light
              style={[styles.button, styles.buttonLeft]}
              onPress={this.decreaseValue}
            >
              <Text style={styles.buttonText}>-</Text>
            </Button>
            <Button
              light
              style={[styles.button, styles.buttonRight]}
              onPress={this.increaseValue}
            >
              <Text style={styles.buttonText}>+</Text>
            </Button>
          </View>
        </ListItem>
      </View>
    )
  }
}
