const cleaningHome = require('assets/images/feed/homecleaning.png')
const cleaningRenovation = require('assets/images/feed/renovationcleaning.png')
const cleaningMoving = require('assets/images/feed/movingcleaning.png')
const cleaningWindows = require('assets/images/feed/windowcleaning.png')
const movingService = require('assets/images/feed/movingservice.png')
const delivery = require('assets/images/feed/deliveryservice.png')
const movingLifting = require('assets/images/feed/carryinghelp.png')
const handymanFurnitures = require('assets/images/feed/handyman.png')
const renovation = require('assets/images/feed/renovation.png')
const plumber = require('assets/images/feed/plumber.png')
const electrician = require('assets/images/feed/electrician.png')
const walls = require('assets/images/feed/painting.png')
const yardWork = require('assets/images/feed/yardwork.png')
const cleaningSnow = require('assets/images/feed/snowremoval.png')
const learningLanguages = require('assets/images/feed/languages.png')
const learningMusic = require('assets/images/feed/music.png')
const personalTrainer = require('assets/images/feed/personaltrainer.png')
const personalTrainingProgram = require('assets/images/feed/trainingprogram.png')
const personalNutritionProgram = require('assets/images/feed/nutritionplan.png')
const photoPortrait = require('assets/images/feed/portrait.png')
const photoWedding = require('assets/images/feed/wedding.png')
const photoEvents = require('assets/images/feed/event.png')
const photoParty = require('assets/images/feed/party.png')
const photoProduct = require('assets/images/feed/product.png')
const photoApartment = require('assets/images/feed/apartment.png')

export default (name: string) => {
  switch (name) {
    case "cleaning_home":
      return cleaningHome
    case "cleaning_renovation":
      return cleaningRenovation
    case "cleaning_moving":
      return cleaningMoving
    case "cleaning_windows":
      return cleaningWindows
    case "moving_service":
      return movingService
    case "delivery":
      return delivery
    case "moving_lifting":
      return movingLifting
    case "handyman_furnitures":
      return handymanFurnitures
    case "renovation":
      return renovation
    case "plumber":
      return plumber
    case "electrician":
      return electrician
    case "walls":
      return walls
    case "yard_work":
      return yardWork
    case "cleaning_snow":
      return cleaningSnow
    case "learning_languages":
      return learningLanguages
    case "learning_music":
      return learningMusic
    case "personal_trainer":
      return personalTrainer
    case "personal_training_program":
      return personalTrainingProgram
    case "personal_nutrition_program":
      return personalNutritionProgram
    case "photo_portrait":
      return photoPortrait
    case "photo_wedding":
      return photoWedding
    case "photo_events":
      return photoEvents
    case "photo_party":
      return photoParty
    case "photo_product":
      return photoProduct
    case "photo_apartment":
      return photoApartment
    default:
      console.log('Could not find image for category: ${name}')
      return ""
  }
}
