import React from "react"
import { createStackNavigator } from "@react-navigation/stack"
import { Button, View } from "react-native"
import Colors from "constants/Colors"
import routeNames from "components/navigators/constants"
import Logo from "components/navigators/Logo"
import OfferDetails from "components/views/Buyer/OfferDetails"
import BookingDetails from "components/views/Buyer/BookingDetails"
import QuestionsStep from "components/views/Buyer/QuestionsStep"
import AddressStep from "components/views/Buyer/AddressStep"
import PreferredTimePicker from "components/views/Buyer/Questions/Question/DateQuestion/PreferredTimePicker"
import Offers from "components/views/Buyer/Offers"
import ReviewForm from "components/views/Shared/ReviewForm"
import I18n from "i18n"
import { headerStyles } from "components/navigators/headerStyles"
import TopTabs from "./TopTabs"

const { Navigator, Screen } = createStackNavigator()

export default () => (
  <Navigator
    initialRouteName={routeNames.BUYER.TABS}
    screenOptions={{
      headerBackTitle: undefined,
      headerTintColor: Colors.brandYellow,
      headerStyle: headerStyles.headerStyleNoBorder,
      headerTitleStyle: headerStyles.headerTitleStyle,
      cardStyle: {
        backgroundColor: Colors.containerBackground,
      },
    }}
  >
    <Screen
      name={routeNames.BUYER.TABS}
      component={TopTabs}
      options={{
        gestureEnabled: false,
        headerLeft: undefined,
        headerStyle: headerStyles.headerStyleNoBorder,
        headerTitle: Logo,
      }}
    />
    <Screen
      name={routeNames.BUYER.REQUEST_OFFERS}
      component={Offers}
      options={({ route }) => ({
        title:
          route.params && route.params["name"]
            ? route.params["name"]
            : I18n.t("title_buyer_orders_request_offers"),
        headerRight: () => <View />, // needed to center the title on android
      })}
    />
    <Screen
      name={routeNames.BUYER.REQUEST_OFFER}
      component={OfferDetails}
      options={({ route }) => ({
        gestureEnabled: route.params ? route.params["enableGestures"] : true,
        title: I18n.t("title_buyer_orders_request_offer_details"),
        headerRight: () => <View />, // needed to center the title on android
      })}
    />
    <Screen
      name={routeNames.BUYER.BOOKING_DETAILS}
      component={BookingDetails}
      options={({ route }) => ({
        title:
          route.params && route.params["name"]
            ? route.params["name"]
            : I18n.t("title_buyer_orders_booking_details"),
        headerRight: () => <View />, // needed to center the title on android
      })}
    />

    <Screen
      name={routeNames.BUYER.CREATE_OFFER_REQUEST_QUESTIONS}
      component={QuestionsStep}
      options={({ route }) => ({
        gestureEnabled: route.params ? route.params["enableGestures"] : true,
        title:
          route.params && route.params["name"]
            ? route.params["name"]
            : undefined,
        headerStyle: headerStyles.headerStyle,
        headerRight: () => <View />, // needed to center the title on android
      })}
    />
    <Screen
      name={routeNames.BUYER.CREATE_OFFER_REQUEST_ADDRESS}
      component={AddressStep}
      options={({ route }) => ({
        gestureEnabled: route.params ? route.params["enableGestures"] : true,
        title: route.params && route.params["name"] ? route.params["name"] : "",
        headerStyle: headerStyles.headerStyle,
        headerRight: () => <View />, // needed to center the title on android
      })}
    />
    <Screen
      name={routeNames.BUYER.QUESTION_PREFERRED_TIME_PICKER}
      component={PreferredTimePicker}
      options={({ navigation }) => ({
        gestureEnabled: false,
        title: I18n.t("title_buyer_categories_question_preferred_time_picker"),
        headerStyle: headerStyles.headerStyle,
        headerRight: () => (
          <Button title={I18n.t("ready")} onPress={() => navigation.goBack()} />
        ),
      })}
    />
    <Screen
      name={routeNames.BUYER.NEW_OFFER_REQUEST}
      component={Offers}
      options={({ route }) => ({
        gestureEnabled: route.params ? route.params["enableGestures"] : true,
        title: route.params && route.params["name"] ? route.params["name"] : "",
        headerStyle: headerStyles.headerStyle,
        headerRight: () => <View />, // needed to center the title on android
      })}
    />
    <Screen
      name={routeNames.BUYER.GIVE_REVIEW}
      component={ReviewForm}
      options={{
        headerTitleStyle: headerStyles.headerTitleStyle,
        gestureEnabled: false,
        title: I18n.t("title_give_review"),
        headerRight: () => <View />, // needed to center the title on android
      }}
    />
  </Navigator>
)
