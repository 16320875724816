import React from "react"
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs"
import Colors from "constants/Colors"
import routeNames from "../constants"
import { headerStyles } from "../headerStyles"
import Offers from "components/views/Seller/Offers"
import Bookings from "components/views/Seller/Bookings"
import History from "components/views/Seller/History"
import OffersLabel from "./OffersLabel"
import BookingsLabel from "./BookingsLabel"
import HistoryLabel from "./HistoryLabel"

const { Screen, Navigator } = createMaterialTopTabNavigator()
export default () => (
  <Navigator
    initialRouteName={routeNames.SELLER.TAB_REQUESTS}
    swipeEnabled
    tabBarOptions={{
      inactiveTintColor: Colors.noteColor,
      activeTintColor: Colors.brandYellow,
      indicatorStyle: headerStyles.tabIndicatorStyle,
      style: {
        ...headerStyles.headerStyle,
        height: "auto",
      },
      tabStyle: headerStyles.tabStyle,
    }}
  >
    <Screen
      name={routeNames.SELLER.TAB_REQUESTS}
      component={Offers}
      options={{
        tabBarLabel: OffersLabel,
      }}
    />
    <Screen
      name={routeNames.SELLER.TAB_BOOKINGS}
      component={Bookings}
      options={{
        tabBarLabel: BookingsLabel,
      }}
    />
    <Screen
      name={routeNames.SELLER.TAB_HISTORY}
      component={History}
      options={{
        tabBarLabel: HistoryLabel,
      }}
    />
  </Navigator>
)
