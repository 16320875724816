import { connect } from "react-redux"
import routeNames from "components/navigators/constants"
import { CommonActions, StackActions } from "@react-navigation/native"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { getSessionId } from "modules/Payment/Stripe/selectors"
import {
  getTransactionById,
  getCurrentTransaction,
} from "modules/Payment/Transaction/selectors"
import { bookingSelected } from "modules/Buyer/Offers/actions"
import { updateCurrentTransaction } from "modules/Payment/Transaction/actions"
import { getSelectedOfferForBuyer } from "modules/Buyer/Offers/selectors"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const transactionId = getCurrentTransaction(state)
  const transaction = transactionId
    ? getTransactionById(state, transactionId)
    : undefined
  return {
    sessionId: getSessionId(state),
    transactionStatus: transaction && transaction.status,
    offer: getSelectedOfferForBuyer(state),
  }
}

const mapDispatchToProps = {
  updateCurrentTransaction,
  bookingSelected,
}
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  paymentFinished: (value: "success" | "cancel") => {
    dispatchProps.updateCurrentTransaction(null)
    if (stateProps.offer) {
      dispatchProps.bookingSelected(stateProps.offer.id)
    }
    //TODO:
    ownProps.navigation.dispatch(StackActions.replace(routeNames.ROOT.TABS))
    ownProps.navigation.dispatch(
      CommonActions.navigate(routeNames.HOME_TABS.BUYER_TAB),
    )
    if (value.indexOf("success") !== -1) {
      ownProps.navigation.dispatch(
        CommonActions.navigate(routeNames.BUYER.BOOKING_DETAILS),
      )
    } else {
      ownProps.navigation.dispatch(
        CommonActions.navigate(routeNames.BUYER.REQUEST_OFFER),
      )
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
