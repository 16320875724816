import { createSelector } from "reselect"
import { Store } from "utilities/Redux/store"
import { OfferRequest } from "./types"
import { sortByCreatedAt } from "utilities/sortBy"

export const getOfferRequestByIdForBuyer = (state: Store, id: string) =>
  state.offerRequests.offerRequests[id]
    ? state.offerRequests.offerRequests[id]
    : undefined

export const getSelectedOfferRequestForBuyer = (state: Store) =>
  state.offerRequests.selected
    ? getOfferRequestByIdForBuyer(state, state.offerRequests.selected)
    : undefined

export const getOfferRequestsForBuyer = (state: Store) =>
  state.offerRequests.offerRequests

export const getOpenOfferRequestsForBuyer = createSelector(
  getOfferRequestsForBuyer,
  offerRequests =>
    Object.values(offerRequests).filter(
      offerRequest => offerRequest.status === "open",
    ),
)

export const getAcceptedOfferRequestsForBuyer = createSelector(
  getOfferRequestsForBuyer,
  offerRequests =>
    Object.values(offerRequests).filter(
      offerRequest => offerRequest.status === "accepted",
    ),
)

export const getCompletedOfferRequestsForBuyer = createSelector(
  getOfferRequestsForBuyer,
  offerRequests =>
    Object.values(offerRequests).filter(
      offerRequest => offerRequest.status === "completed",
    ),
)

const getOfferRequestsSorted = (offerRequests: OfferRequest[]) => {
  return sortByCreatedAt(offerRequests, "desc")
}

export const getOpenOfferRequestIdsForBuyer = createSelector(
  getOpenOfferRequestsForBuyer,
  offerRequests => {
    const orderedofferRequests = getOfferRequestsSorted(offerRequests)
    return orderedofferRequests.map(offerRequest => offerRequest.id)
  },
)

export const getAcceptedOfferRequestIdsForBuyer = createSelector(
  getAcceptedOfferRequestsForBuyer,
  offerRequests => {
    const orderedofferRequests = sortByCreatedAt(offerRequests, "desc")
    return orderedofferRequests.map(offerRequest => offerRequest.id)
  },
)

export const getStartedOfferRequestIdsForBuyer = createSelector(
  getAcceptedOfferRequestsForBuyer,
  offerRequests => {
    const orderedofferRequests = getOfferRequestsSorted(offerRequests)
    return orderedofferRequests.map(offerRequest => offerRequest.id)
  },
)

export const getCompletedOfferRequestIdsForBuyer = createSelector(
  getCompletedOfferRequestsForBuyer,
  offerRequests => {
    const orderedofferRequests = getOfferRequestsSorted(offerRequests)
    return orderedofferRequests.map(offerRequest => offerRequest.id)
  },
)

export const getUnreviewedCompletedOfferRequestIdsForBuyer = createSelector(
  getCompletedOfferRequestsForBuyer,
  offerRequests => {
    const filteredOfferRequests = offerRequests.filter(
      offerRequest => !offerRequest.reviewed,
    )
    const orderedofferRequests = getOfferRequestsSorted(filteredOfferRequests)
    return orderedofferRequests.map(offerRequest => offerRequest.id)
  },
)

export const getReminderCountForBuyer = createSelector(
  [
    getStartedOfferRequestIdsForBuyer,
    getUnreviewedCompletedOfferRequestIdsForBuyer,
  ],
  (startedJobs, unreviewedJobs) => {
    return Number(startedJobs.length) + Number(unreviewedJobs.length)
  },
)
