import { Store } from "utilities/Redux/store"
import I18n from "i18n"

export const getLangs = (state: Store) => {
  const langs = state.settings.langs
  return langs[I18n.currentLocale()] || langs["en"]
}

export const getWorkAreas = (state: Store) => {
  const workAreas = state.settings.workareas
  return workAreas[I18n.currentLocale()] || workAreas["en"]
}
