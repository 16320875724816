import _ from "lodash"
import moment from "moment"

import {
  SellerProfileUpdated,
  CreateSellerProfile,
  ReviewUpdated,
  UpdateTempSellerProfile,
  UpdateTempSellerProfileCategories,
  SellerProfileSelected,
  UpdateTempSellerProfilePersonalInfo,
} from "./actions"
import {
  SELLER_PROFILE_UPDATED,
  SELLER_PROFILE_CREATE,
  REVIEW_UPDATED,
  TEMP_SELLER_PROFILE_UPDATE,
  TEMP_SELLER_PROFILE_UPDATE_CATEGORIES,
  TEMP_SELLER_PROFILE_UPDATE_PERSONAL_INFO,
  SELLER_PROFILE_SELECTED,
} from "./actionTypes"
import { SellerProfileState } from "./types"

export const INITIAL_STATE: SellerProfileState = {
  profiles: {},
  tempProfile: undefined,
  reviews: {},
}

export default function sellerProfilesReducer(
  state = INITIAL_STATE,
  action:
    | SellerProfileUpdated
    | CreateSellerProfile
    | ReviewUpdated
    | UpdateTempSellerProfile
    | UpdateTempSellerProfileCategories
    | UpdateTempSellerProfilePersonalInfo
    | SellerProfileSelected,
): SellerProfileState {
  switch (action.type) {
    case SELLER_PROFILE_UPDATED:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          [action.sellerProfile.id]: action.sellerProfile,
        },
      }
    case SELLER_PROFILE_CREATE:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          [action.sellerProfile.id]: action.sellerProfile,
        },
      }
    case REVIEW_UPDATED:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          [action.sellerProfileId]: {
            ...state.reviews[action.sellerProfileId],
            [action.review.id]: action.review,
          },
        },
      }
    case TEMP_SELLER_PROFILE_UPDATE:
      return {
        ...state,
        tempProfile: {
          ...state.tempProfile,
          ...action.tempSellerProfile,
        },
      }
    case TEMP_SELLER_PROFILE_UPDATE_CATEGORIES:
      if (state.tempProfile) {
        return {
          ...state,
          tempProfile: {
            ...state.tempProfile,
            categories: action.categories,
          },
        }
      } else {
        // Create a blank seller profile to fill later
        return {
          ...state,
          tempProfile: {
            photoURL: "",
            companyName: "",
            companyEmail: "",
            id: "",
            categories: action.categories,
            companyVAT: "",
            langs: [],
            birthDate: "",
            city: "",
            postalCode: "",
            iban: "",
            streetAddress: "",
          },
        }
      }
    case TEMP_SELLER_PROFILE_UPDATE_PERSONAL_INFO:
      if (state.tempProfile) {
        return {
          ...state,
          tempProfile: {
            ...state.tempProfile,
            ...action.data,
            birthDate: moment(action.data.birthDate)
              .format("DD.MM.YYYY")
              .toString(),
          },
        }
      }
      return state
    case SELLER_PROFILE_SELECTED:
      return {
        ...state,
        selected: action.sellerProfileId,
      }
    default:
      return state
  }
}
