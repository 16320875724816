import _ from "lodash"
import React from "react"
import Label from "components/navigators/Label"
import { connect, Provider } from "react-redux"
import reduxStore, { Store } from "utilities/Redux/store"
import I18n from "i18n"
import {
  getOffersWithStatusForBuyer,
  getUnseenOffersTotalForBuyer,
} from "modules/Buyer/Offers/selectors"
import { TabBarLabelProps } from "components/types"
import { getUnseenMessagesCount } from "modules/ChatMessages/selectors"

const mapStateToProps = (state: Store) => ({
  unseenOffersCount: getUnseenOffersTotalForBuyer(state),
  unseenMessagesCount: getUnseenMessagesCount(
    state,
    Object.values(getOffersWithStatusForBuyer(state, "accepted")),
  ),
})

const mapDispatchToProps = null
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: null,
  ownProps: TabBarLabelProps,
) => ({
  ...stateProps,
  ...ownProps,
  unseenCount: stateProps.unseenMessagesCount + stateProps.unseenOffersCount,
})

const ConnectedLabel = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Label)

export default (props: TabBarLabelProps) => {
  return (
    <Provider store={reduxStore}>
      <ConnectedLabel
        label={I18n.t("title_buyer_orders_offer_requests_tab")}
        {...props}
      />
    </Provider>
  )
}
