import { StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  item: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    minHeight: 47,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderBottomWidth: 0,
  },
  iconContainer: {
    justifyContent: "center",
    minHeight: 47,
    marginRight: 15,
  },
  icon: {
    color: Colors.darkerGrey,
    fontSize: 18,
  },
  label: {
    flex: 1,
    marginLeft: 13,
    textAlign: "left",
    color: Colors.darkerGrey,
  },
  carousel: {
    paddingBottom: 15,
  },
  carouselImage: {
    marginLeft: 15,
  },
})
