import _ from "lodash"
import { validateEmail, validateName } from "utilities/validator"
import {
  AUTH_STATUS_CHANGED,
  USER_UPDATE,
  USER_UPDATED,
  LOG_OUT,
  AUTHENTICATING,
  AUTHENTICATE_WITH_CREDENTIAL,
  AUTH_FAILED,
  USER_UPDATE_PRESENCE,
  USER_UPDATE_ACTIVE_ROUTE,
} from "./actionTypes"
import { User, UserInfoValidated } from "./types"

const userWithValidateUserInfo: (user: User) => UserInfoValidated = user => {
  const validated: UserInfoValidated = {}
  if (user) {
    if (user.email) {
      validated.emailIsValid = validateEmail(user.email)
    }
    if (!_.isUndefined(user.firstName)) {
      validated.firstNameIsValid = validateName(user.firstName)
    }
    if (!_.isUndefined(user.lastName)) {
      validated.lastNameIsValid = validateName(user.lastName)
    }
  }
  return validated
}

export interface AuthStatusChanged {
  type: typeof AUTH_STATUS_CHANGED
  user: null | User
}
export const authStatusChanged: (
  user: User | null,
) => AuthStatusChanged = user => {
  return {
    type: AUTH_STATUS_CHANGED,
    user,
  }
}

export interface UpdateUser {
  type: typeof USER_UPDATE
  user: User
  validated: UserInfoValidated
}
export const updateUser: (user: User) => UpdateUser = user => {
  return {
    type: USER_UPDATE,
    user,
    validated: userWithValidateUserInfo(user),
  }
}

export interface UserUpdated {
  type: typeof USER_UPDATED
  user: User
  validated: UserInfoValidated
}
export const userUpdated: (user: User) => UserUpdated = user => {
  return {
    type: USER_UPDATED,
    user,
    validated: userWithValidateUserInfo(user),
  }
}

export interface LogOutProps {
  userId: string
  expoPushToken?: string
}
export interface LogOut extends LogOutProps {
  type: typeof LOG_OUT
}
export const logOut: (props: LogOutProps) => LogOut = props => {
  return {
    type: LOG_OUT,
    ...props,
  }
}

export interface Authenticating {
  type: typeof AUTHENTICATING
}
export const authenticating: () => Authenticating = () => {
  return {
    type: AUTHENTICATING,
  }
}

export interface AuthenticateWithCredential {
  type: typeof AUTHENTICATE_WITH_CREDENTIAL
  credential: firebase.auth.OAuthCredential
}
export const authenticateWithCredential: (
  credential: firebase.auth.OAuthCredential,
) => AuthenticateWithCredential = credential => {
  return {
    type: AUTHENTICATE_WITH_CREDENTIAL,
    credential,
  }
}

export interface AuthenticationFailed {
  type: typeof AUTH_FAILED
  error: Error
}
export const authenticationFailed: (
  error: Error,
) => AuthenticationFailed = error => {
  return {
    type: AUTH_FAILED,
    error,
  }
}

export interface UserUpdatePresence {
  type: typeof USER_UPDATE_PRESENCE
  isActive: boolean
}
export const userUpdatePresence: (
  isActive: boolean,
) => UserUpdatePresence = isActive => {
  return {
    type: USER_UPDATE_PRESENCE,
    isActive,
  }
}

export interface UserUpdateActiveRoute {
  type: typeof USER_UPDATE_ACTIVE_ROUTE
  activeRoute?: {
    [key: string]: string
  }
}
export const userUpdateActiveRoute: (activeRoute?: {
  [key: string]: string
}) => UserUpdateActiveRoute = activeRoute => {
  return {
    type: USER_UPDATE_ACTIVE_ROUTE,
    activeRoute,
  }
}
