import React from "react"
import { View } from "react-native"
import OptionView from "./OptionView"
import {
  RadioQuestion,
  CheckBoxQuestion,
  QuestionOption,
} from "modules/Buyer/Questions/types"
import { Navigation } from "components/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

type Question = RadioQuestion | CheckBoxQuestion

const isChecked = (question: Question, option: QuestionOption) =>
  question.type === "checkbox"
    ? !!option.checked
    : option.id === question.selectedOption

interface Props {
  question: Question
  orderedOptions: QuestionOption[]
  onChecked: (option: QuestionOption, optionId: string) => void
  isSubQuestion?: boolean
  navigation: Navigation
}

export default ({
  question,
  orderedOptions,
  onChecked,
  isSubQuestion,
  navigation,
}: Props) => (
  <View>
    {orderedOptions.map((option, idx) => (
      <OptionView
        key={option.id}
        last={orderedOptions.length === idx + 1}
        name={option.name && getLocaLizedName(option.name)}
        type={question.type}
        checked={isChecked(question, option)}
        onChecked={() => onChecked(option, option.id)}
        subQuestions={option.questions}
        isSubQuestion={isSubQuestion}
        navigation={navigation}
      />
    ))}
  </View>
)
