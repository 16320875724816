import _ from "lodash"
import {
  OfferRequestSelected,
  OfferRequestCreated,
  OfferRequestUpdated,
} from "./actions"
import {
  OFFER_REQUEST_SELECTED,
  OFFER_REQUEST_CREATED,
  OFFER_REQUEST_UPDATED,
} from "./actionTypes"
import { USER_UPDATED } from "modules/User/actionTypes"
import { UserUpdated } from "modules/User/actions"
import { BuyerOfferRequestsState } from "./types"

export const INITIAL_STATE: BuyerOfferRequestsState = {
  offerRequests: {},
  selected: undefined,
}

export default function buyerOfferRequestsReducer(
  state = INITIAL_STATE,
  action:
    | OfferRequestSelected
    | OfferRequestCreated
    | OfferRequestUpdated
    | UserUpdated,
): BuyerOfferRequestsState {
  switch (action.type) {
    case OFFER_REQUEST_SELECTED:
      return {
        ...state,
        selected: action.offerRequestId,
      }
    case OFFER_REQUEST_CREATED:
      return {
        ...state,
        selected: action.offerRequest.id,
      }
    case OFFER_REQUEST_UPDATED:
      return {
        ...state,
        offerRequests: {
          ...state.offerRequests,
          [action.offerRequest.id]: action.offerRequest,
        },
      }
    case USER_UPDATED:
      if (action.user.offerRequests) {
        const newOfferRequests = Object.keys(action.user.offerRequests)
          .filter(id => !state.offerRequests[id])
          .reduce((offerRequests, id) => {
            offerRequests[id] = {
              id,
            }
            return offerRequests
          }, {})
        return {
          ...state,
          offerRequests: {
            ...state.offerRequests,
            ...newOfferRequests,
          },
        }
      }
    default:
      return state
  }
}
