import { connect } from "react-redux"
import { markPastJobCompleted } from "modules/Seller/Offers/actions"
import _ from "lodash"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { getSelectedSellerOffer } from "modules/Seller/Offers/selectors"
import { getBuyerProfileForId } from "modules/BuyerProfiles/selectors"
import { getCategoryById } from "modules/Buyer/Categories/selectors"
import { getOfferRequestByIdForSeller } from "modules/Seller/OfferRequests/selectors"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const offer = getSelectedSellerOffer(state)
  if (!offer) {
    return {}
  }
  const offerRequest = getOfferRequestByIdForSeller(state, offer.offerRequest)
  const category = offerRequest && getCategoryById(state, offerRequest.category)
  const buyerProfile = getBuyerProfileForId(state, offer.buyerProfile)
  return {
    displayName: buyerProfile ? buyerProfile.displayName : "",
    categoryName: category && getLocaLizedName(category.name),
    offer,
    offerRequest,
  }
}

const mapDispatchToProps = {
  markPastJobCompleted,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onConfirm: ({
    actualStartTime,
    actualCompletedTime,
  }: {
    actualStartTime: number
    actualCompletedTime: number
  }) => {
    dispatchProps.markPastJobCompleted({
      offerId: stateProps.offer!.id,
      actualStartTime,
      actualCompletedTime,
    })
    ownProps.navigation.goBack()
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
