import React from "react"
import { List, Text, Separator } from "native-base"
import EmptyList from "components/views/Shared/EmptyList"
import I18n from "i18n"
import _ from "lodash"
import styles from "./styles"
import OfferRequestListItem from "../OfferRequestListItem"
import { Navigation } from "components/types"

interface Props {
  offerRequestIds: string[]
  navigation: Navigation
}

export default class OfferRequestList extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !_.isEqual(nextProps.offerRequestIds, this.props.offerRequestIds)
  }

  render() {
    const { offerRequestIds, navigation } = this.props
    return (
      <List style={styles.list}>
        <Separator style={styles.separator}>
          <Text style={styles.separatorText}>
            {I18n.t("title_seller_open_offer_requests_list").toUpperCase()}
          </Text>
        </Separator>
        {offerRequestIds.length ? (
          offerRequestIds.map((item, idx) => (
            <OfferRequestListItem
              last={offerRequestIds.length === idx + 1}
              key={`offerRequest_list_item_${item}`}
              id={item}
              navigation={navigation}
            />
          ))
        ) : (
          <EmptyList
            emptyTextMessage={I18n.t("empty_open_offer_request_list")}
          />
        )}
      </List>
    )
  }
}
