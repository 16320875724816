import { getEventChannelForFirebaseRef } from "utilities/Redux/sagaHelpers"
import firebase from "firebase"
import {
  all,
  call,
  take,
  takeEvery,
  fork,
  put,
  select,
} from "redux-saga/effects"
import { CHAT_UPDATED } from "../Chats/actionTypes"
import { getUser } from "../User/selectors"
import { FIREBASE_PATH } from "./constants"
import { chatMessageUpdated, chatMessageSent, SendChatMessage } from "./actions"
import { SEND_CHAT_MESSAGE } from "./actionTypes"
import { ChatUpdated } from "../Chats/actions"
import { User } from "../User/types"
import { ChatMessageDB } from "database_types/chatMessage"

const subscribed = {}
export function* subscribeToChatMessageWorker(messageId: string) {
  if (!messageId || subscribed[messageId]) {
    return
  }
  yield (subscribed[messageId] = true)

  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(messageId)
  const channel = yield call(getEventChannelForFirebaseRef, ref)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const { values: message }: { values: ChatMessageDB } = yield take(channel)
    if (message) {
      yield put(
        chatMessageUpdated({
          ...message,
          id: messageId,
        }),
      )
    }
  }
}

export function* subscribeChatMessagesWorker(action: ChatUpdated) {
  if (!action.chat.messages) {
    return
  }

  yield all(
    Object.keys(action.chat.messages).map(id =>
      call(subscribeToChatMessageWorker, id),
    ),
  )
}

export function* subscribeToChatMessagesSaga() {
  yield takeEvery(CHAT_UPDATED, subscribeChatMessagesWorker)
}

export function* sendChatMessageWorker(action: SendChatMessage) {
  // tslint:disable-next-line:no-unsafe-any
  const sender: User | undefined = yield select(getUser)
  if (!sender) {
    return
  }

  // tslint:disable-next-line:no-unsafe-any
  const messageRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .push()

  yield messageRef.set({
    createdAt: firebase.database.ServerValue.TIMESTAMP,
    chat: action.chatId,
    content: action.content,
    sender: sender.id,
  })

  // tslint:disable-next-line:no-unsafe-any
  const messageSnapshot: firebase.database.DataSnapshot = yield messageRef.once(
    "value",
  )

  yield put(
    chatMessageSent({
      id: messageRef.key,
      ...messageSnapshot.val(),
    }),
  )
}

export function* sendChatMessageSaga() {
  yield takeEvery(SEND_CHAT_MESSAGE, sendChatMessageWorker)
}

export default function*() {
  yield all([fork(subscribeToChatMessagesSaga), fork(sendChatMessageSaga)])
}
