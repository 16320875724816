import React from "react"
import { createStackNavigator } from "@react-navigation/stack"
import { View } from "react-native"
import Colors from "constants/Colors"
import routeNames from "../constants"
import Logo from "../Logo"
import Offers from "components/views/Seller/Offers"
import CreateOffer from "components/views/Seller/CreateOffer"
import OfferDetails from "components/views/Seller/OfferDetails"
import CreateOfferCalendar from "components/views/Seller/CreateOffer/Calendar"
import CreateOfferTimetable from "components/views/Seller/CreateOffer/Timetable"
import ExtendOffer from "components/views/Seller/Reminders/ExtendOffer"
import MarkPastJobCompleted from "components/views/Seller/Reminders/MarkPastJobCompleted"
import I18n from "i18n"
import { headerStyles } from "../headerStyles"
import TopTabs from "./TopTabs"

const { Navigator, Screen } = createStackNavigator()
export default () => (
  <Navigator
    initialRouteName={routeNames.SELLER.TABS}
    screenOptions={{
      headerBackTitle: undefined,
      headerTintColor: Colors.brandYellow,
      headerStyle: headerStyles.headerStyleNoBorder,
      headerTitleStyle: headerStyles.headerTitleStyle,
      headerRight: () => <View />, // needed to center the title on android
      cardStyle: {
        backgroundColor: Colors.containerBackground,
      },
    }}
  >
    <Screen
      name={routeNames.SELLER.TABS}
      component={TopTabs}
      options={{
        gestureEnabled: false,
        headerLeft: undefined,
        headerStyle: headerStyles.headerStyleNoBorder,
        headerTitle: () => <Logo />,
        headerRight: () => undefined,
      }}
    />
    <Screen
      name={routeNames.SELLER.OPEN_OFFER_REQUESTS}
      component={Offers}
      options={{
        title: I18n.t("title_seller_open_offer_request_details"),
        headerRight: () => undefined,
      }}
    />
    <Screen
      name={routeNames.SELLER.CREATE_OFFER}
      component={CreateOffer}
      options={{
        title: I18n.t("title_seller_create_offer"),
        headerStyle: headerStyles.headerStyle,
      }}
    />
    <Screen
      name={routeNames.SELLER.OPEN_OFFER_REQUEST_DETAILS}
      component={OfferDetails}
      options={({ route }) => ({
        title: route.params
          ? route.params["name"]
          : I18n.t("title_seller_open_offer_request_details"),
        headerStyle: headerStyles.headerStyle,
      })}
    />
    <Screen
      name={routeNames.SELLER.CREATE_OFFER_CALENDAR}
      component={CreateOfferCalendar}
      options={{
        title: I18n.t("title_seller_create_offer_calendar"),
        headerStyle: headerStyles.headerStyle,
      }}
    />
    <Screen
      name={routeNames.SELLER.CREATE_OFFER_TIMETABLE}
      component={CreateOfferTimetable}
      options={({ route }) => ({
        title: route.params ? route.params["name"] : "",
        headerStyle: headerStyles.headerStyle,
      })}
    />
    <Screen
      name={routeNames.SELLER.EXTEND_OFFER}
      component={ExtendOffer}
      options={{
        title: I18n.t("request_to_extend"),
        headerStyle: headerStyles.headerStyle,
      }}
    />
    <Screen
      name={routeNames.SELLER.MARK_JOB_COMPLETED}
      component={MarkPastJobCompleted}
      options={{
        title: I18n.t("mark_as_done"),
        headerStyle: headerStyles.headerStyle,
      }}
    />
  </Navigator>
)
