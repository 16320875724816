import { connect } from "react-redux"
import routeNames from "components/navigators/constants"
import View from "./view"
import * as actions from "modules/Buyer/Offers/actions"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { getSellerProfileById } from "modules/SellerProfiles/selectors"
import { getOfferByIdForBuyer } from "modules/Buyer/Offers/selectors"

interface OwnProps {
  offerId: string
  navigation: Navigation
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => {
  const offer = getOfferByIdForBuyer(state, ownProps.offerId)
  return {
    offer,
    sellerProfile: offer
      ? getSellerProfileById(state, offer.sellerProfile)
      : undefined,
  }
}

const mapDispatchToProps = {
  offerSelected: actions.offerSelected,
}

let lock: boolean
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onPress: () => {
    if (lock) {
      return
    }
    lock = true
    dispatchProps.offerSelected(ownProps.offerId)
    ownProps.navigation.navigate(routeNames.BUYER.REQUEST_OFFER)
    setTimeout(() => {
      lock = false
    }, 500)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
