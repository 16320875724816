import React, { forwardRef } from "react"
import "react-native-gesture-handler"
import Colors from "constants/Colors"
import { headerStyles } from "./headerStyles"
import constants from "./constants"
import BottomTabs from "./BottomTabs"
import SignIn from "./SignIn"
import CheckoutStripe from "./CheckoutStripe"
import { NavigationContainer } from "@react-navigation/native"
import { createStackNavigator } from "@react-navigation/stack"
import { NavigationContainerRef } from "@react-navigation/core"
import { InitialState, NavigationState } from "@react-navigation/routers"

const { Navigator, Screen } = createStackNavigator()

export default forwardRef<
  NavigationContainerRef | null,
  {
    initialState?: InitialState
    onStateChange?: (state: NavigationState | undefined) => void
    independent?: boolean
  }
>((props, ref) => (
  <NavigationContainer {...props} ref={ref}>
    <Navigator
      initialRouteName={constants.ROOT.SIGN_IN}
      screenOptions={{
        cardStyle: {
          backgroundColor: Colors.containerBackground,
        },
      }}
    >
      <Screen
        name={constants.ROOT.TABS}
        component={BottomTabs}
        options={{
          header: () => null,
          gestureEnabled: false,
        }}
      />
      <Screen
        name={constants.ROOT.SIGN_IN}
        component={SignIn}
        options={{
          header: () => null,
        }}
      />
      <Screen
        name={constants.ROOT.CHECKOUT_STRIPE}
        component={CheckoutStripe}
        options={{
          headerStyle: headerStyles.headerStyle,
          headerTitleStyle: headerStyles.headerTitleStyle,
        }}
      />
    </Navigator>
  </NavigationContainer>
))
