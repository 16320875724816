import React from "react"
import {
  Modal as ReactModal,
  View,
  Text,
  TouchableOpacity,
  SafeAreaView,
} from "react-native"
import { List, ListItem, Body } from "native-base"
import { Ionicons } from "@expo/vector-icons"
import Colors from "constants/Colors"
import I18n from "i18n"
import styles from "./styles"

interface Props {
  items: string[]
  selected: string[]
  title: string
  confirm: (selected: string[]) => void
}

interface State {
  height: number
  maxHeight: number
  items: string[]
  selected: string[]
}

export default class PickerModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      height: 0,
      maxHeight: 100,
      items: props.items,
      selected: props.selected || [],
    }

    this.toggleValue = this.toggleValue.bind(this)
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.items !== this.props.items) {
      this.setState({ items: this.props.items })
    }
  }



  confirm = () => {
    this.props.confirm(this.state.selected)
  }

  toggleValue(value: string) {
    const { selected } = this.state
    const index = selected.indexOf(value)
    if (index === -1) {
      selected.push(value)
    } else {
      selected.splice(index, 1)
    }

    this.setState({ selected })
  }

  render() {
    const { height: textHeight, maxHeight } = this.state
    const textInputHeight = Math.min(maxHeight, textHeight + 20)
    const containerHeight = textInputHeight + 50
    return (
      <ReactModal
        animationType="slide"
        transparent={false}
       
      >
        <SafeAreaView>
          <View style={styles.buttonWrap}>
            <TouchableOpacity
              onPress={this.confirm}
              style={styles.confirmButton}
            >
              <Ionicons
                name="ios-checkmark"
                size={45}
                color={Colors.brandGreen}
              />
            </TouchableOpacity>
          </View>
          <View style={[styles.container, { height: containerHeight }]}>
            <Text style={styles.title}>{this.props.title || ""}</Text>
            <View style={styles.inputContainer}>
              <List style={styles.list}>
                {this.state.items.map((value, index) => {
                  const isSelected = this.state.selected.indexOf(value) >= 0
                  return (
                    <ListItem
                      key={value}
                      style={styles.item}
                      onPress={() => this.toggleValue(value)}
                    >
                      <View style={styles.iconContainer}>
                        <Ionicons
                          name={
                            isSelected ? "ios-checkbox" : "ios-square-outline"
                          }
                          size={20}
                          style={isSelected ? styles.checked : styles.unchecked}
                        />
                      </View>
                      <Body
                        style={[
                          styles.itemBody,
                          isSelected ? styles.checked : styles.unchecked,
                          index === 0 ? styles.itemBodyFirst : null,
                          index === this.state.items.length - 1
                            ? styles.itemBodyLast
                            : null,
                        ]}
                      >
                        <Text
                          style={[
                            styles.itemText,
                            isSelected ? styles.checkeditemText : null,
                          ]}
                        >
                          {I18n.t(value)}
                        </Text>
                      </Body>
                    </ListItem>
                  )
                })}
              </List>
            </View>
          </View>
        </SafeAreaView>
      </ReactModal>
    )
  }
}
