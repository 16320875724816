import {
  StyleSheet,
  ViewStyle,
  ImageStyle,
  TextStyle,
  PixelRatio,
} from "react-native"
import Colors from "constants/Colors"
import { separatorText } from "constants/Fonts"

interface Styles {
  container: ViewStyle
  flexItem: ViewStyle
  iconContainer: ViewStyle
  icon: ImageStyle
  right: ViewStyle
  placeholder: TextStyle
  regular: TextStyle
  fileName: TextStyle
  separator: ViewStyle
  separatorText: TextStyle
}

export default StyleSheet.create<Styles>({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: 47,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: Colors.contentBackground,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
    marginLeft: 0,
  },
  flexItem: {
    flexDirection: "row",
  },
  iconContainer: {
    justifyContent: "center",
    minHeight: 47,
    marginRight: 15,
    marginLeft: 15,
  },
  icon: {
    color: Colors.darkerGrey,
    fontSize: 18,
  },
  right: {
    marginLeft: "auto",
  },
  placeholder: {
    color: Colors.grey,
  },
  regular: {
    color: Colors.textColor,
  },
  fileName: {
    marginRight: 100,
  },
  separator: {
    backgroundColor: Colors.containerBackground,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
    marginTop: 15,
  },
  separatorText: {
    ...separatorText,
  },
})
