import { connect } from "react-redux"
import View from "./view.web"
import { Store } from "utilities/Redux/store"
import { AddressTypes } from "modules/Buyer/Categories/constants"
import {
  getSelectedCategory,
  getAddressValuesForAddressType,
} from "modules/Buyer/Categories/selectors"
import { addressUpdated } from "modules/Buyer/Categories/actions"

interface OwnProps {
  addressType: AddressTypes
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => {
  const category = getSelectedCategory(state)
  return {
    categoryId: category ? category.id : undefined,
    addressValues:
      category &&
      getAddressValuesForAddressType(state, {
        category,
        addressType: ownProps.addressType,
      }),
  }
}

const mapDispatchToProps = {
  addressUpdated,
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
