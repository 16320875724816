import React from "react"
import _ from "lodash"
import { List } from "native-base"
import { View } from "react-native"
import Question from "../Question"
import styles from "./styles"
import { Navigation } from "components/types"

interface Props {
  questionIds: string[]
  isSubQuestion?: boolean
  navigation: Navigation
}

const QuestionList = ({ questionIds, isSubQuestion, navigation }: Props) => (
  <List style={isSubQuestion ? styles.questionListWithSubQuestions : {}}>
    {questionIds.map(questionId => (
      <View key={questionId}>
        <Question
          questionId={questionId}
          isSubQuestion={isSubQuestion}
          navigation={navigation}
        />
      </View>
    ))}
  </List>
)

const areEqual = (prevProps: Props, nextProps: Props): boolean => {
  return !(
    _.difference(prevProps.questionIds, nextProps.questionIds).length > 0
  )
}

export default React.memo(QuestionList, areEqual)
