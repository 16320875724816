import * as firebase from "firebase"
// import Sentry from "sentry-expo"
import _ from "lodash"
import {
  all,
  call,
  takeEvery,
  fork,
  put,
  select,
  take,
} from "redux-saga/effects"
import { getEventChannelForFirebaseRef } from "utilities/Redux/sagaHelpers"
import { FIREBASE_PATH as FIREBASE_PATH_OFFER_REQUESTS } from "../Buyer/OfferRequests/constants"
import { OFFER_REQUEST_CREATED } from "../Buyer/OfferRequests/actionTypes"
import { FIREBASE_PATH as FIREBASE_PATH_OFFERS } from "../Seller/Offers/constants"
import { OFFER_CREATED } from "../Seller/CreateOffer/actionTypes"
import { FIREBASE_PATH as FIREBASE_PATH_USERS } from "./constants"
import {
  AUTH_STATUS_CHANGED,
  AUTH_FAILED,
  LOG_OUT,
  AUTHENTICATE_WITH_CREDENTIAL,
  USER_UPDATE,
  USER_UPDATE_PRESENCE,
  USER_UPDATE_ACTIVE_ROUTE,
} from "./actionTypes"
import {
  userUpdated,
  authenticationFailed,
  AuthenticationFailed,
  UpdateUser,
  AuthenticateWithCredential,
  LogOut,
  UserUpdateActiveRoute,
  UserUpdatePresence,
  AuthStatusChanged,
} from "./actions"
import { getUser } from "./selectors"
import { OfferRequestCreated } from "../Buyer/OfferRequests/actions"
import { OfferCreated } from "../Seller/CreateOffer/actions"
import { User } from "./types"
import { UserDB } from "database_types/user"

export function* createUserWorker(user: User) {
  // tslint:disable-next-line:no-unsafe-any
  const userRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH_USERS)
    .child(user.id)
  // tslint:disable-next-line:no-unsafe-any
  const snapshot: firebase.database.DataSnapshot = yield userRef.once("value")
  const userValues: UserDB = _.omit(user, ["pushToken", "id"])

  // if user does not exist -> CREATE
  if (snapshot.val() === null) {
    yield snapshot.ref.set({
      createdAt: firebase.database.ServerValue.TIMESTAMP,
      ...userValues,
    })
  } else {
    // onlyNewValuesToUpdate:
    // - e.g. if Google Auth now provides a phoneNumber that didn't previously exist in the user object
    // - avoids overriding for example user.displayName if it has been modified since last login
    const onlyChangedUserValues = _.omit(
      userValues,
      Object.keys(snapshot.val() as Partial<User>),
    )
    yield snapshot.ref.update({
      // 'updatedAt' field fixes an issue where login(UserA)->logout->login(UserA) wouldn't reload the user
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
      language: userValues.deviceLanguage,
      deviceLanguage: userValues.deviceLanguage,
      ...onlyChangedUserValues,
    })
  }
  if (user.pushToken) {
    yield all(
      Object.keys(user.pushToken).map(function*(pushToken) {
        yield snapshot.ref
          .child("pushToken")
          .child(pushToken)
          .set(true)
      }),
    )
  }
}

const subscribed = {}
export function* subscribeToUserWorker(userId: string | undefined) {
  if (!userId) {
    return Object.keys(subscribed).map(id => {
      firebase
        .database()
        .ref(FIREBASE_PATH_USERS)
        .child(id)
        .off()
      subscribed[id] = false
    })
  }
  if (subscribed[userId]) {
    return
  }
  yield (subscribed[userId] = true)

  // tslint:disable-next-line:no-unsafe-any
  const userRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH_USERS)
    .child(userId)

  const channel = yield call(getEventChannelForFirebaseRef, userRef)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const { values: user }: { values: UserDB } = yield take(channel)
    if (user) {
      yield userRef
        .child("isActive")
        .onDisconnect()
        .set(false)
      yield userRef
        .child("activeRoute")
        .onDisconnect()
        .remove()
      yield put(
        userUpdated({
          id: userId,
          ...user,
        }),
      )
    }
  }
}

export function* authStatusChangedSaga() {
  yield takeEvery(AUTH_STATUS_CHANGED, function*(action: AuthStatusChanged) {
    if (action.user) {
      yield createUserWorker(action.user)
      yield subscribeToUserWorker(action.user.id)
    }
    {
      yield subscribeToUserWorker(undefined)
    }
  })
}

export function* updateUserPresenceWorker(action: UserUpdatePresence | LogOut) {
  // tslint:disable-next-line:no-unsafe-any
  const user: User | undefined = yield select(getUser)
  if (!user) {
    return
  }

  // tslint:disable-next-line:no-unsafe-any
  const userRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH_USERS)
    .child(user.id)
  if (action.type === "user/LOG_OUT" || !action.isActive) {
    yield userRef.child("isActive").set(false)
    yield userRef.child("activeRoute").remove()
  } else {
    yield userRef.child("isActive").set(true)
  }
}

export function* updatePresenceSaga() {
  yield takeEvery(USER_UPDATE_PRESENCE, updateUserPresenceWorker)
}

export function* updateActiveRouteWorker(action: UserUpdateActiveRoute) {
  // tslint:disable-next-line:no-unsafe-any
  const user: User | undefined = yield select(getUser)

  if (user) {
    // tslint:disable-next-line:no-unsafe-any
    const userRef: firebase.database.Reference = yield firebase
      .database()
      .ref(FIREBASE_PATH_USERS)
      .child(user.id)
    if (action.activeRoute) {
      yield userRef.child("activeRoute").set(action.activeRoute)
    } else {
      yield userRef.child("activeRoute").remove()
    }
  }
}

export function* updateActiveRouteSaga() {
  yield takeEvery(USER_UPDATE_ACTIVE_ROUTE, updateActiveRouteWorker)
}

export function* unSubscribeUser(action: LogOut) {
  yield (subscribed[action.userId] = false)
  firebase
    .database()
    .ref(FIREBASE_PATH_USERS)
    .child(action.userId)
    .off()
}

export function* deletePushTokenWorker(action: LogOut) {
  // tslint:disable-next-line:no-unsafe-any
  const userRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH_USERS)
    .child(action.userId)
  if (action.expoPushToken) {
    yield userRef
      .child("pushToken")
      .child(action.expoPushToken)
      .remove()
  }
}

export function* logOutSaga() {
  yield takeEvery(LOG_OUT, function*(action: LogOut) {
    yield unSubscribeUser(action)
    yield deletePushTokenWorker(action)
    yield updateUserPresenceWorker(action)
    yield firebase
      .auth()
      .signOut()
      .catch(console.log)
  })
}

export function* addOfferRequestToUserWorker(action: OfferRequestCreated) {
  // tslint:disable-next-line:no-unsafe-any
  const user: User | undefined = yield select(getUser)
  if (user) {
    yield firebase
      .database()
      .ref(FIREBASE_PATH_USERS)
      .child(user.id)
      .child(FIREBASE_PATH_OFFER_REQUESTS)
      .child(action.offerRequest.id)
      .set(true)
  }
}

export function* offerRequestCreatedSaga() {
  yield takeEvery(OFFER_REQUEST_CREATED, addOfferRequestToUserWorker)
}

export function* addOffersToUserWorker(action: OfferCreated) {
  // tslint:disable-next-line:no-unsafe-any
  const user: User | undefined = yield select(getUser)

  if (user) {
    // tslint:disable-next-line:no-unsafe-any
    const usersRef: firebase.database.Reference = yield firebase
      .database()
      .ref(FIREBASE_PATH_USERS)
    const userRef = yield usersRef.child(user.id)
    // tslint:disable-next-line:no-unsafe-any
    const offersRef: firebase.database.Reference = yield userRef.child(
      FIREBASE_PATH_OFFERS,
    )
    yield offersRef
      .child(action.offer.id)
      .set({ offerRequestId: action.offer.offerRequest })
  }
}

export function* offersCreatedSaga() {
  yield takeEvery(OFFER_CREATED, addOffersToUserWorker)
}

export function* authenticateWithCredentialWorker(
  credential: firebase.auth.OAuthCredential,
) {
  try {
    yield firebase.auth().signInWithCredential(credential)
  } catch (error) {
    // tslint:disable-next-line:no-unsafe-any
    yield put(authenticationFailed(error))
  }
}

export function* authenticateWithCredentialSaga() {
  yield takeEvery(AUTHENTICATE_WITH_CREDENTIAL, function*(
    action: AuthenticateWithCredential,
  ) {
    yield authenticateWithCredentialWorker(action.credential)
  })
}

export function* updateUserWorker(action: UpdateUser) {
  if (
    (action.user.email && !action.validated.emailIsValid) ||
    (action.user.firstName && !action.validated.firstNameIsValid) ||
    (action.user.lastName && !action.validated.lastNameIsValid)
  ) {
    return
  }

  yield firebase
    .database()
    .ref(FIREBASE_PATH_USERS)
    .child(action.user.id)
    .update(action.user)
}

export function* updateUserSaga() {
  yield takeEvery(USER_UPDATE, updateUserWorker)
}

export function* authFailedSaga() {
  yield takeEvery(AUTH_FAILED, function*(action: AuthenticationFailed) {
    //TODO
    // Sentry.captureMessage("authenticationFailed", {
    //   level: "error",
    //   extra: {
    //     error: action.error,
    //   },
    // })
  })
}

export default function*() {
  yield all([
    fork(authStatusChangedSaga),
    fork(authFailedSaga),
    fork(offerRequestCreatedSaga),
    fork(offersCreatedSaga),
    fork(logOutSaga),
    fork(authenticateWithCredentialSaga),
    fork(updateUserSaga),
    fork(updatePresenceSaga),
    fork(updateActiveRouteSaga),
  ])
}
