import { StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  profile: {
    alignItems: "center",
    backgroundColor: Colors.contentBackground,
  },
  profileImage: {
    marginTop: 0,
  },
  profileName: {
    color: Colors.textColor,
    fontFamily: "open-sans-bold",
    fontSize: 14,
    marginTop: 7,
  },
  profileInfo: {
    flex: 1,
    flexDirection: "row",
  },
  profileInfoWrap: {
    flexDirection: "row",
  },
  profileInfoText: {
    color: Colors.noteColor,
    fontSize: 13,
  },
  profileInfoSeparator: {
    color: Colors.noteColor,
    fontSize: 8,
    marginTop: 4,
    lineHeight: 8,
    paddingHorizontal: 9,
  },
  profileInfoIcon: {
    color: Colors.starYellow,
    fontSize: 13,
  },
})
