import {
  SELLER_PROFILE_UPDATED,
  SELLER_PROFILE_CREATE,
  REVIEW_UPDATED,
  TEMP_SELLER_PROFILE_UPDATE,
  TEMP_SELLER_PROFILE_UPDATE_CATEGORIES,
  TEMP_SELLER_PROFILE_UPDATE_PERSONAL_INFO,
  SELLER_PROFILE_SELECTED,
  ADD_REVIEW,
  FILE_UPLOAD,
} from "./actionTypes"
import { SellerProfile, Review } from "./types"
import { ReviewDB } from "database_types/sellerProfile"
import { SellerProfilePersonalInfo } from "./types"

export interface SellerProfileUpdated {
  type: typeof SELLER_PROFILE_UPDATED
  sellerProfile: SellerProfile
}
export const sellerProfileUpdated: (
  sellerProfile: SellerProfile,
) => SellerProfileUpdated = sellerProfile => {
  return {
    type: SELLER_PROFILE_UPDATED,
    sellerProfile,
  }
}

export interface CreateSellerProfile {
  type: typeof SELLER_PROFILE_CREATE
  sellerProfile: SellerProfile
}
export const createSellerProfile: (
  sellerProfile: SellerProfile,
) => CreateSellerProfile = sellerProfile => {
  return {
    type: SELLER_PROFILE_CREATE,
    sellerProfile,
  }
}

export interface ReviewUpdated {
  type: typeof REVIEW_UPDATED
  sellerProfileId: string
  review: Review & { id: string }
}
export const reviewUpdated: (props: {
  sellerProfileId: string
  review: Review & { id: string }
}) => ReviewUpdated = props => {
  return {
    type: REVIEW_UPDATED,
    ...props,
  }
}

export interface UpdateTempSellerProfile {
  type: typeof TEMP_SELLER_PROFILE_UPDATE
  tempSellerProfile: SellerProfile
}
export const updateTempSellerProfile: (
  tempSellerProfile: SellerProfile,
) => UpdateTempSellerProfile = tempSellerProfile => {
  return {
    type: TEMP_SELLER_PROFILE_UPDATE,
    tempSellerProfile,
  }
}

export interface UpdateTempSellerProfileCategories {
  type: typeof TEMP_SELLER_PROFILE_UPDATE_CATEGORIES
  categories: SellerProfile["categories"]
}
export const updateTempSellerProfileCategories: (
  categories: SellerProfile["categories"],
) => UpdateTempSellerProfileCategories = categories => {
  return {
    type: TEMP_SELLER_PROFILE_UPDATE_CATEGORIES,
    categories,
  }
}

export interface UpdateTempSellerProfilePersonalInfo {
  type: typeof TEMP_SELLER_PROFILE_UPDATE_PERSONAL_INFO
  data: SellerProfilePersonalInfo
}
export const updateTempSellerProfilePersonalInfo: (
  data: SellerProfilePersonalInfo,
) => UpdateTempSellerProfilePersonalInfo = data => {
  return {
    type: TEMP_SELLER_PROFILE_UPDATE_PERSONAL_INFO,
    data,
  }
}

export interface SellerProfileSelected {
  type: typeof SELLER_PROFILE_SELECTED
  sellerProfileId: string
}
export const sellerProfileSelected: (
  sellerProfileId: string,
) => SellerProfileSelected = sellerProfileId => {
  return {
    type: SELLER_PROFILE_SELECTED,
    sellerProfileId,
  }
}

export interface AddReview {
  type: typeof ADD_REVIEW
  review: ReviewDB
}
export const addReview: (review: ReviewDB) => AddReview = review => {
  return {
    type: ADD_REVIEW,
    review,
  }
}

export interface FileUpload {
  type: typeof FILE_UPLOAD
  userId: string
  file: {
    id: string
    name: string
    uri: string
    width: number
    height: number
  }
}
export const uploadFile: (
  id: string,
  userId: string,
  name: string,
  uri: string,
  height: number,
  width: number,
) => FileUpload = (id, userId, name, uri, height, width) => {
  return {
    type: FILE_UPLOAD,
    userId,
    file: {
      id,
      name,
      uri,
      width,
      height,
    },
  }
}
