import { StyleSheet } from "react-native"
import FontSizes from "constants/FontSizes"
import Colors from "constants/Colors"

export default StyleSheet.create({
  listItem: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: 50,
    borderBottomWidth: 0,
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 0,
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  iconContainer: {
    justifyContent: "center",
    marginLeft: 15,
    minHeight: 47,
  },
  icon: {
    color: Colors.grey,
  },
  textarea: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    fontSize: FontSizes.questionPlaceholderSize,
    lineHeight: FontSizes.questionPlaceholderSize,
    paddingLeft: 15,
    paddingBottom: 0,
    borderBottomWidth: 0,
  },
})
