import React from "react"
import { View } from "react-native"
import { Icon, Text } from "native-base"
import ProfileImage from "../ProfileImage"
import styles from "./styles"
import { SellerProfile } from "modules/SellerProfiles/types"

interface Props {
  sellerProfile: SellerProfile
  price: number
}

export default ({ sellerProfile, price }: Props) => {
  const displayName = sellerProfile.companyName
  return (
    <View style={styles.profile}>
      <ProfileImage
        style={styles.profileImage}
        size={60}
        source={sellerProfile.photoURL}
      />
      <Text style={styles.profileName}>{displayName}</Text>
      <View style={styles.profileInfo}>
        {sellerProfile.rating && (
          <View style={styles.profileInfoWrap}>
            <Text style={styles.profileInfoText}>
              <Icon name="star" active style={styles.profileInfoIcon} />{" "}
              {sellerProfile.rating}
            </Text>
            <Text style={styles.profileInfoSeparator}>•</Text>
          </View>
        )}
        <Text style={styles.profileInfoText}>{`${price} €`}</Text>
      </View>
    </View>
  )
}
