export default {
  ROOT: {
    TABS: "rootTabs",
    SIGN_IN: "signIn",
    CHECKOUT_STRIPE: "checkoutStripe",
  },
  SIGN_IN: {
    INDEX: "signInIndex",
    SIGNUP: "signUp",
    CONFIRM_USER_INFO: "confirmUserInfo",
    BLOCKED: "userIsBlocked",
  },
  HOME_TABS: {
    ACCOUNT_TAB: "accountTab",
    BUYER_TAB: "buyerTab",
    SELLER_TAB: "sellerTab",
    REGISTER_AS_SELLER_TAB: "registerAsSellerTab",
  },
  ACCOUNT: {
    SETTINGS: "accountSettings",
  },
  BUYER: {
    TABS: "tabs",
    DISCOVER_TAB: "buyerDiscoverTab",
    TAB_REQUESTS: "buyerRequestsTab",
    TAB_HISTORY: "buyerHistoryTab",
    REQUEST_OFFERS: "buyerOffersForRequest",
    REQUEST_OFFER: "buyerRequestOfferDetails",
    BOOKING_DETAILS: "buyerBookingDetails",
    FEED: "buyerFeed",
    GIVE_REVIEW: "buyerGiveReview",
    CREATE_OFFER_REQUEST_QUESTIONS: "buyerOfferRequestQuestions",
    CREATE_OFFER_REQUEST_ADDRESS: "buyerOfferRequestAddress",
    QUESTION_PREFERRED_TIME_PICKER: "buyerQuestionsPreferredTimePicker",
    NEW_OFFER_REQUEST: "buyerNewOfferRequest",
  },
  SELLER_SWITCHER: {
    SWITCH: "sellerSwitch",
    SELLER_CREATE: "sellerCreate",
    SELLER_TABS: "sellerTabs",
    WAIT_FOR_APPROVAL: "waitForApproval",
  },
  SELLER_CREATE: {
    INDEX: "registerAsSeller",
    CREATE_SELLER_PROFILE: "createSellerProfile",
    FIELD_OF_EXPERTISE: "fieldOfExpertise",
    COMPANY_INFO: "companyInfo",
  },
  SELLER: {
    TABS: "tabs",
    TAB_REQUESTS: "sellerRequestsTab",
    TAB_BOOKINGS: "sellerBookingsTab",
    TAB_HISTORY: "sellerHistoryTab",
    OPEN_OFFER_REQUESTS: "sellerOpenOfferRequests",
    CREATE_OFFER: "sellerCreateOffer",
    OPEN_OFFER_REQUEST_DETAILS: "sellerOpenOfferRequestDetails",
    CREATE_OFFER_CALENDAR: "sellerCreateOfferCalendar",
    CREATE_OFFER_TIMETABLE: "sellerCreateOfferTimeTable",
    EXTEND_OFFER: "sellerExtendOffer",
    MARK_JOB_COMPLETED: "markPastJobCompleted",
  },
  CHECKOUT_STRIPE: {
    INDEX: "checkoutStripeIndex",
    PAYMENT: "checkoutStripePayment",
  },
}
