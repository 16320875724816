import { StyleSheet, Dimensions, PixelRatio } from "react-native"
import Colors from "constants/Colors"
import FontSizes from "constants/FontSizes"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.containerBackground,
  },
  imageContainer: {
    flex: 1,
    width: Dimensions.get("window").width,
  },
  image: {
    resizeMode: "cover",
  },
  thumbnail: {
    margin: 30,
    alignSelf: "center",
  },
  content: {
    padding: 15,
  },
  input: {
    color: Colors.lightestGrey,
    textAlign: "center",
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.contentBackground,
    marginTop: 15,
    height: 45,
    fontSize: FontSizes.questionPlaceholderSize,
    lineHeight: FontSizes.questionPlaceholderSize,
  },
  buttonWrapper: {
    bottom: 15,
    left: 15,
    right: 15,
    position: "absolute",
  },
  button: {
    backgroundColor: "transparent",
    borderWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.contentBackground,
  },
})
