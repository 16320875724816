import React, { useState, useEffect } from "react"
import { View, Text, TouchableOpacity } from "react-native"
import { Button, Icon } from "native-base"
import { SimpleLineIcons } from "@expo/vector-icons"
import I18n from "i18n"
import moment, { Moment } from "moment"
import {
  getTimeRemainingString,
  getTimeRemainingInMinutes,
  getOfferDuration,
} from "utilities/time"
import styles from "./styles"
import ReminderStyles from "../styles"
import { Offer } from "modules/Buyer/Offers/types"
import { Category } from "modules/Buyer/Categories/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface Props {
  offer: Offer
  category?: Category
  stopJob: () => void
  onPress: () => void
  extendJob: () => void
  pauseJob: (isPaused: boolean) => void
}

const CurrentJob = (props: Props) => {
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null)
  const [timePause, setTimePause] = useState<string>("")
  const [timeIntervalId, setTimeIntervalId] = useState<number>(0)
  const [pauseIntervalId, setPauseIntervalId] = useState<number>(0)

  const { category, offer } = props
  const categoryName: string =
    category && category.name ? getLocaLizedName(category.name) : ""
  const timeRemainingString: string = timeRemaining
    ? getTimeRemainingString(timeRemaining)
    : ""
  const timeToExtend: number =
    offer && offer.timeToExtend ? offer.timeToExtend : 0
  const offerIsPaused: boolean =
    offer && offer.isPaused ? offer.isPaused : false

  const updateTimeRemaining = (endTime: Moment): number => {
    return window.setInterval(() => {
      const diff: number = moment(endTime, "x").diff(moment(), "minutes")
      if (diff > 0) {
        setTimeRemaining(getTimeRemainingInMinutes(endTime))
      } else {
        clearInterval(timeIntervalId)
      }
    }, 60000)
  }

  const getEndTimeJob = (): Moment => {
    const { actualStartTime, pauseDuration } = offer
    const startedTime: Moment = moment(actualStartTime)
    const endTime: Moment = startedTime
      .clone()
      .add(getOfferDuration(offer), "hour")
      .add(pauseDuration || 0, "ms")

    return endTime
  }

  const setTimeLeftToCompleteJob = (): void => {
    const endTime: Moment = getEndTimeJob()
    const interValId: number = updateTimeRemaining(endTime)
    setTimeIntervalId(interValId)
    setTimeRemaining(getTimeRemainingInMinutes(endTime))
  }

  const getCurrentPauseDuration = (): number => {
    const { workTime } = offer
    const diff: number = workTime
      ? Number(moment().diff(moment(workTime[workTime.length - 1].endTime)))
      : Number(moment())
    return diff
  }

  const updateTimePauseJob = (): void => {
    if (!props.offer.isPaused) {
      return
    }

    const pauseInterval: number = window.setInterval(() => {
      const pauseDuration: number = getCurrentPauseDuration()
      setTimePause(
        getTimeRemainingString(
          Number(
            moment
              .duration(pauseDuration)
              .asMinutes()
              .toFixed(),
          ),
        ),
      )
    }, 60000)

    setPauseIntervalId(pauseInterval)
  }

  const setInitialTimeLeftToCompleteJob = () => {
    const endTime: Moment = getEndTimeJob()
    const pauseDuration: number = getCurrentPauseDuration()
    const time: Moment = moment(endTime).add(pauseDuration)
    setTimeRemaining(getTimeRemainingInMinutes(time))
  }

  const setInitialPauseTime = () => {
    const pauseDuration = getCurrentPauseDuration()
    setTimePause(
      getTimeRemainingString(
        Number(
          moment
            .duration(pauseDuration)
            .asMinutes()
            .toFixed(),
        ),
      ),
    )
  }

  useEffect(() => {
    if (offer && offer.isPaused) {
      updateTimePauseJob()
      setInitialPauseTime()
      setInitialTimeLeftToCompleteJob()
    } else {
      setTimeLeftToCompleteJob()
    }

    return () => {
      clearInterval(pauseIntervalId)
      clearInterval(timeIntervalId)
    }
  }, [props.offer])

  const stopJob = (): void => {
    clearInterval(timeIntervalId)
    props.stopJob()
  }

  const togglePauseJob = (): void => {
    props.pauseJob(!props.offer.isPaused)
  }

  return (
    <View style={ReminderStyles.reminderContainer}>
      <View style={styles.contentContainer}>
        <View style={styles.textContainer}>
          <Text style={ReminderStyles.title}>
            {I18n.t("service")}:{" "}
            <Text style={ReminderStyles.highlighted}>{`${categoryName}`}</Text>
          </Text>
          <Text style={ReminderStyles.title}>
            {I18n.t("time_left")}:{" "}
            <Text style={ReminderStyles.highlighted}>
              {timeRemainingString}
            </Text>
          </Text>
          <Text style={ReminderStyles.title}>
            {I18n.t("status")}:{" "}
            {offerIsPaused ? (
              <Text style={ReminderStyles.highlighted}>{`${I18n.t(
                "paused_job",
              )} (${timePause})`}</Text>
            ) : (
              <Text style={ReminderStyles.highlighted}>
                {I18n.t("on_going_job")}
              </Text>
            )}
          </Text>
        </View>
        <TouchableOpacity
          onPress={props.onPress}
          style={ReminderStyles.iconContainer}
        >
          <SimpleLineIcons
            name="arrow-right-circle"
            size={30}
            style={ReminderStyles.icon}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.buttonWrap}>
        <Button
          success
          rounded
          block
          iconLeft
          onPress={stopJob}
          style={[ReminderStyles.button]}
        >
          <Icon style={ReminderStyles.buttonIcon} name="done-all" />
          <Text style={ReminderStyles.buttonText}>{I18n.t("stop_job")}</Text>
        </Button>
      </View>
      <View style={styles.buttonWrap}>
        {offerIsPaused ? (
          <Button
            rounded
            block
            iconLeft
            dark
            onPress={togglePauseJob}
            style={ReminderStyles.button}
          >
            <Icon style={ReminderStyles.buttonIcon} name="play" />
            <Text style={ReminderStyles.buttonText}>
              {I18n.t("continue_job")}
            </Text>
          </Button>
        ) : (
          <Button
            rounded
            block
            iconLeft
            dark
            onPress={togglePauseJob}
            style={ReminderStyles.button}
          >
            <Icon style={ReminderStyles.buttonIcon} name="pause" />
            <Text style={ReminderStyles.buttonText}>{I18n.t("pause_job")}</Text>
          </Button>
        )}
      </View>
      <View style={styles.buttonWrap}>
        {timeToExtend && timeToExtend > 0 ? (
          <Button
            rounded
            block
            disabled
            transparent
            light
            iconLeft
            style={ReminderStyles.button}
          >
            <Icon
              style={[ReminderStyles.buttonIcon, { color: "white" }]}
              name="timer"
            />
            <Text style={ReminderStyles.buttonText}>{`${I18n.t(
              "requested",
            )} ${timeToExtend}h`}</Text>
          </Button>
        ) : (
          <Button
            success
            bordered
            rounded
            block
            iconLeft
            onPress={props.extendJob}
            style={[ReminderStyles.button, { borderColor: "white" }]}
          >
            <Icon
              style={[ReminderStyles.buttonIcon, { color: "white" }]}
              name="timer"
            />
            <Text style={ReminderStyles.buttonText}>
              {I18n.t("request_to_extend")}
            </Text>
          </Button>
        )}
      </View>
    </View>
  )
}

export default CurrentJob
