import _ from "lodash"
import { connect } from "react-redux"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { getChatById } from "modules/Chats/selectors"
import { sendChatMessage } from "modules/ChatMessages/actions"

interface OwnProps {
  chatId: string
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => ({
  chat: getChatById(state, ownProps.chatId),
})

const mapDispatchToProps = {
  sendMessage: sendChatMessage,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  sendMessage: (message: string) => {
    if (stateProps.chat) {
      dispatchProps.sendMessage({
        chatId: stateProps.chat.id,
        content: message,
      })
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
