import _ from "lodash"
import I18n from "i18n"
import {
  Categories,
  MainAddress,
  DeliveryAddress,
} from "modules/Buyer/Categories/types"
import { Feed } from "modules/Buyer/Feed/types"
import {
  Questions,
  Question,
  QuestionOptions,
  QuestionOption,
  Label,
} from "modules/Buyer/Questions/types"
import { FeedDB } from "database_types/feed"
import {
  MainAddressDB,
  CategoryDB,
  CategoriesDB,
} from "database_types/category"
import { QuestionsDB } from "database_types/categoryQuestions"

export const getLocaLizedName: (nameObject: Label) => string = nameObject => {
  if (I18n.currentLocale().startsWith("fi-")) {
    return nameObject.fi
  }
  return nameObject.en
}

export const normalizedFeeds: (feed: FeedDB) => Feed = feed => {
  return _.mapValues(feed, feedGroup => {
    const newItems = _.mapValues(feedGroup.items, item => {
      return {
        ...item,
        name: getLocaLizedName(item.name),
      }
    })
    return {
      ...feedGroup,
      items: newItems,
      name: getLocaLizedName(feedGroup.name),
    }
  })
}
/*
  Takes 'categories' object and replaces 'questions' with array of ids.
  Ids are formed like this: ['categoryKey/questionKey1', 'categoryKey/questionKey2/questionKey21']
*/
const getAddresses = (category: CategoryDB) => {
  return (category.addresses as MainAddressDB).main
    ? ({
        type: "basic",
        main: true,
      } as MainAddress)
    : ({
        type: "delivery",
        from: true,
        to: true,
      } as DeliveryAddress)
}

export const normalizeCategories: (
  categories: CategoriesDB,
) => Categories = categories => {
  return _.mapValues(categories, (category, categoryKey) => {
    return {
      id: categoryKey,
      addresses: getAddresses(category),
      name: category.name,
      questions: category.questions
        ? Object.keys(category.questions).map(
            questionKey => `${categoryKey}/${questionKey}`,
          )
        : [],
    }
  })
}

/*
Takes 'categories' object and returns a new 'questions' object where categoryId and questionId are used as a key.
From:
{
    categoryKey: {
        questions: {
            questionKey1: {
                ...,
                questions: {
                    questionKey12: { ... }
                }
            },
            questionKey2: {
                options: {
                    optionKey21: {
                        questionKey211: { ... }
                    }
                }
            }
        }
    }
}
To:
{
    'categoryKey/questionKey1': { ... },
    'categoryKey/questionKey1/questionKey12': { ... },
    'categoryKey/questionKey2': { ... }
    'categoryKey/questionKey2/optionKey21/questionKey211': { ... }
}
*/
const normalizeQuestions: (
  questions: QuestionsDB,
  keyPrefix: string,
) => Questions = (questions, keyPrefix) => {
  // "questionsKeyVal" will hold { questionId: questionObject } of all the questions (flat, not a tree, see example above)
  const questionsKeyVal: Questions = {}
  _.forEach(questions, (question, questionKey) => {
    const newKey = `${keyPrefix}/${questionKey}`
    let normalizedQuestion: Question

    if (question.type === "radio" || question.type === "checkbox") {
      const options: QuestionOptions = {}
      // normalize options sub-questions
      _.forEach(question.options, (option, optionKey) => {
        const newOptionKey = `${newKey}/${optionKey}`
        const flattenOption: QuestionOption = {
          id: newOptionKey,
          name: option.name,
          order: option.order,
        }
        const optionQuestions = question.options[optionKey].questions
        if (optionQuestions) {
          // add subQuestions to 'questionsKeyVal'
          const optionSubQuestionsKeyVal = normalizeQuestions(
            optionQuestions,
            newOptionKey,
          )
          Object.assign(questionsKeyVal, optionSubQuestionsKeyVal)

          // flatten "question.options.option.questions" to an array of ids
          flattenOption.questions = Object.keys(optionQuestions).map(
            optionQuestionKey => `${newOptionKey}/${optionQuestionKey}`,
          )
        }
        options[optionKey] = flattenOption
      })
      normalizedQuestion = {
        ...question,
        id: newKey,
        name: question.name,
        options,
      }

      questionsKeyVal[newKey] = normalizedQuestion
    } else if (
      question.type === "textarea" ||
      question.type === "number_input" ||
      question.type === "number" ||
      question.type === "list"
    ) {
      normalizedQuestion = {
        ...question,
        id: newKey,
        name: question.name,
        placeholder: question.placeholder,
      }
      questionsKeyVal[newKey] = normalizedQuestion
    } else if (question.type === "image") {
      normalizedQuestion = {
        ...question,
        id: newKey,
        name: question.name,
      }
      questionsKeyVal[newKey] = normalizedQuestion
    } else if (question.type === "date") {
      normalizedQuestion = {
        ...question,
        id: newKey,
        name: question.name,
      }
      questionsKeyVal[newKey] = normalizedQuestion
    }
  })

  return questionsKeyVal
}

export const normalizeQuestionsFromCategories: (
  categories: CategoriesDB,
) => Questions = (categories: CategoriesDB) => {
  const questionsKeyVal: Questions = {}
  _.forEach(categories, (category, categoryKey) => {
    Object.assign(
      questionsKeyVal,
      normalizeQuestions(category.questions, categoryKey),
    )
  })
  return questionsKeyVal
}
