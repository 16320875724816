import React from "react"
import { View, TouchableOpacity } from "react-native"
import { Separator, Text } from "native-base"
import { Ionicons } from "@expo/vector-icons"
import Colors from "constants/Colors"
import styles from "./styles"
import TextModal from "../Modals/TextModal/index.web"

interface Props {
  header: string
  value: string
  placeholder: string
  onChange: (value: string) => void
}

interface State {
  value: string
}

export default class TextRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      value: props.value,
    
    }
  }

  
  onChange(text: string) {
    this.setState({ value: text })
    this.props.onChange(text)
  }

  render() {
    return (
      <View>
        <TextModal
          value={this.state.value}
          placeholder={this.props.placeholder}
          confirm={(text: string) => this.onChange(text)}
          title={this.props.header}
        />
        <Separator style={styles.separator}>
          <Text>{this.props.header.toUpperCase()}</Text>
        </Separator>
        <TouchableOpacity
       
          style={styles.listItem}
        >
          <View style={styles.iconContainer}>
            <Ionicons name="md-create" style={styles.icon} size={20} />
          </View>
          <Text
            style={[
              styles.textarea,
              this.props.value ? null : { color: Colors.grey },
            ]}
          >
            {this.state.value || this.props.placeholder}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }
}
