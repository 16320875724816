import cacheAssetsAsync from "./cacheAssetsAsync"

const loadAssetsAsync = async () => {
  try {
    await cacheAssetsAsync({
      fonts: [],
      images: [
        require(`assets/images/logo-icon.png`),
        require(`assets/images/logo-icon-notification.png`),
        require(`assets/images/logo-splash.png`),
        require(`assets/images/wants_to_create_seller_profile.png`),
        require(`assets/images/profile.png`),
        require(`assets/images/feed/homecleaning.png`),
        require(`assets/images/feed/renovationcleaning.png`),
        require(`assets/images/feed/movingcleaning.png`),
        require(`assets/images/feed/windowcleaning.png`),
        require(`assets/images/feed/movingservice.png`),
        require(`assets/images/feed/deliveryservice.png`),
        require(`assets/images/feed/carryinghelp.png`),
        require(`assets/images/feed/handyman.png`),
        require(`assets/images/feed/renovation.png`),
        require(`assets/images/feed/plumber.png`),
        require(`assets/images/feed/electrician.png`),
        require(`assets/images/feed/painting.png`),
        require(`assets/images/feed/yardwork.png`),
        require(`assets/images/feed/snowremoval.png`),
        require(`assets/images/feed/languages.png`),
        require(`assets/images/feed/music.png`),
        require(`assets/images/feed/personaltrainer.png`),
        require(`assets/images/feed/trainingprogram.png`),
        require(`assets/images/feed/nutritionplan.png`),
        require(`assets/images/feed/portrait.png`),
        require(`assets/images/feed/wedding.png`),
        require(`assets/images/feed/event.png`),
        require(`assets/images/feed/party.png`),
        require(`assets/images/feed/product.png`),
        require(`assets/images/feed/apartment.png`),
      ],
    })
    return true
  } catch (e) {
    console.warn(
      "There was an error caching assets (see: main.js), perhaps due to a " +
        "network timeout, so we skipped caching. Reload the app to try again.",
    )
    console.log("Error:", e)
    return false
  }
}

export default loadAssetsAsync
