import _ from "lodash"
import { connect } from "react-redux"
import { getUser } from "modules/User/selectors"
import { offerRequestCreate } from "modules/Buyer/OfferRequests/actions"
import routeNames from "components/navigators/constants"
import View from "./view.web"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { StackActions, CommonActions } from "@react-navigation/native"
import {
  getSuccessModalVisible,
  getQuestionsForOfferRequest,
} from "modules/Buyer/Questions/selectors"
import { setSuccessModalVisibility } from "modules/Buyer/Questions/actions"
import {
  getAddressValuesForCategory,
  getSelectedCategory,
  hasInvalidAddressForCategory,
} from "modules/Buyer/Categories/selectors"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const category = getSelectedCategory(state)
  return {
    category,
    successModalIsVisible: getSuccessModalVisible(state),
    addresses: category
      ? getAddressValuesForCategory(state, category)
      : undefined,
    questions: category
      ? getQuestionsForOfferRequest(state, category)
      : undefined,
    hasErrors: category
      ? hasInvalidAddressForCategory(state, category.addresses)
      : false,
    user: getUser(state),
  }
}

const mapDispatchToProps = {
  offerRequestCreate,
  setSuccessModalVisibility,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  hasErrors: stateProps.hasErrors,
  category: stateProps.category,
  successModalIsVisible: stateProps.successModalIsVisible,
  onButtonClicked: () => {
    if (
      !stateProps.hasErrors &&
      stateProps.category &&
      stateProps.addresses &&
      stateProps.questions &&
      stateProps.user
    ) {
      dispatchProps.offerRequestCreate({
        buyerProfileId: stateProps.user.id,
        categoryId: stateProps.category.id,
        addresses: stateProps.addresses,
        questions: stateProps.questions,
      })
      ownProps.navigation.dispatch(
        CommonActions.setParams({ enableGestures: false }),
      )
      dispatchProps.setSuccessModalVisibility(true)
    }
  },
  onCloseModal: () => {
    ownProps.navigation.dispatch(
      CommonActions.setParams({ enableGestures: true }),
    )
    ownProps.navigation.dispatch(StackActions.replace(routeNames.ROOT.TABS))
    ownProps.navigation.dispatch(
      CommonActions.navigate(routeNames.HOME_TABS.BUYER_TAB),
    )
    ownProps.navigation.dispatch(CommonActions.navigate(routeNames.BUYER.TABS))
    ownProps.navigation.dispatch(
      CommonActions.navigate(routeNames.BUYER.TAB_REQUESTS),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
