import { PixelRatio, StyleSheet } from "react-native"
import Colors from "constants/Colors"
import { separatorText } from "constants/Fonts"

export default StyleSheet.create({
  list: {
    backgroundColor: Colors.contentBackground,
    borderColor: Colors.borderColor,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  separator: {
    backgroundColor: Colors.containerBackground,
    borderColor: Colors.borderColor,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    height: "auto",
  },
  separatorText: {
    ...separatorText,
    paddingBottom: 10,
    paddingTop: 30,
  },
  emptyView: {
    flex: 1,
    justifyContent: "center",
    height: 40,
    alignItems: "center",
    backgroundColor: "white",
  },
  emptyTextMessage: {
    color: Colors.noteColor,
    fontSize: 20,
  },
})
