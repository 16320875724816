import { all, call, takeEvery, fork, put, take } from "redux-saga/effects"
import { getEventChannelForFirebaseRef } from "utilities/Redux/sagaHelpers"
import firebase from "firebase"
import {
  sellerProfileUpdated,
  reviewUpdated,
  AddReview,
  CreateSellerProfile,
  SellerProfileUpdated,
} from "./actions"
import { FIREBASE_PATH, FIREBASE_REVIEWS_PATH } from "./constants"
import {
  ADD_REVIEW,
  SELLER_PROFILE_CREATE,
  SELLER_PROFILE_UPDATED,
} from "./actionTypes"
import { OFFER_UPDATED } from "../Buyer/Offers/actionTypes"
import { OfferUpdated } from "../Buyer/Offers/actions"
import { SellerProfileDB, ReviewDB } from "database_types/sellerProfile"

const subscribed = {}
export function* subscribeSellerProfileWorker(action: OfferUpdated) {
  if (subscribed[action.offer.sellerProfile]) {
    return
  }
  yield (subscribed[action.offer.sellerProfile] = true)

  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offer.sellerProfile)
  const channel = yield call(getEventChannelForFirebaseRef, ref)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const { values: sellerProfile }: { values: SellerProfileDB } = yield take(
      channel,
    )
    if (sellerProfile) {
      yield put(
        sellerProfileUpdated({
          ...sellerProfile,
          id: action.offer.sellerProfile,
        }),
      )
    }
  }
}

export function* subscribeToSellerProfileSaga() {
  yield takeEvery(OFFER_UPDATED, subscribeSellerProfileWorker)
}

const reviewsSubscribed = {}
export function* subscribeToReviewWorker(
  reviewId: string,
  sellerProfileId: string,
) {
  if (reviewsSubscribed[reviewId]) {
    return
  }
  yield (reviewsSubscribed[reviewId] = true)

  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_REVIEWS_PATH)
    .child(reviewId)
  const channel = yield call(getEventChannelForFirebaseRef, ref)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const { values: review }: { values: ReviewDB | undefined } = yield take(
      channel,
    )
    if (review) {
      yield put(
        reviewUpdated({
          sellerProfileId,
          review: {
            id: reviewId,
            ...review,
          },
        }),
      )
    }
  }
}

export function* subscribeToAllReviewsWorker(action: SellerProfileUpdated) {
  if (!action.sellerProfile.reviews) {
    return
  }
  yield all(
    Object.keys(action.sellerProfile.reviews).map(reviewId =>
      call(subscribeToReviewWorker, reviewId, action.sellerProfile.id),
    ),
  )
}

export function* subscribeToReviewsSaga() {
  yield takeEvery(SELLER_PROFILE_UPDATED, subscribeToAllReviewsWorker)
}

export function* createSellerProfileWorker(action: CreateSellerProfile) {
  // tslint:disable-next-line:no-unsafe-any
  const sellerRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.sellerProfile.id)
  // tslint:disable-next-line:no-unsafe-any
  const sellerSnaphot: firebase.database.DataSnapshot = yield sellerRef.once(
    "value",
  )

  if (sellerSnaphot.val() === null) {
    yield sellerRef.set({
      createdAt: firebase.database.ServerValue.TIMESTAMP,
      ...action.sellerProfile,
    })
  } else {
    yield sellerRef.update({
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
      ...action.sellerProfile,
    })
  }
}

export function* createSellerProfileSaga() {
  yield takeEvery(SELLER_PROFILE_CREATE, createSellerProfileWorker)
}

export function* addReviewWorker(action: AddReview) {
  // tslint:disable-next-line:no-unsafe-any
  const reviewRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_REVIEWS_PATH)
    .push()

  const review = {
    sellerProfile: action.review.sellerProfileId, // TODO rename to 'sellerProfileId' in database as well
    text: action.review.text,
    rating: action.review.rating,
    name: action.review.name,
    offerRequestId: action.review.offerRequestId,
    offerId: action.review.offerId,
  }
  yield reviewRef.set({
    ...review,
    createdAt: firebase.database.ServerValue.TIMESTAMP,
  })
}

export function* addReviewSaga() {
  yield takeEvery(ADD_REVIEW, addReviewWorker)
}

export default function*() {
  yield all([
    fork(createSellerProfileSaga),
    fork(subscribeToSellerProfileSaga),
    fork(subscribeToReviewsSaga),
    fork(addReviewSaga),
  ])
}
