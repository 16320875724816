import {
  PixelRatio,
  StyleSheet,
  ViewStyle,
  TextStyle,
  ImageStyle,
} from "react-native"
import Colors from "constants/Colors"
import { listItemSeparatorText, listItemText } from "constants/Fonts"

interface Styles {
  container: ViewStyle
  profile: ViewStyle
  profileImage: ImageStyle
  profileName: TextStyle
  list: ViewStyle
  listItem: ViewStyle
  separator: ViewStyle
  label: TextStyle
  contactInfoText: TextStyle
}

export default StyleSheet.create<Styles>({
  container: {
    backgroundColor: Colors.containerBackground,
  },
  profile: {
    alignItems: "center",
    justifyContent: "center",
    padding: 15,
  },
  profileImage: {
    marginTop: 0,
  },
  profileName: {
    color: Colors.textColor,
    fontFamily: "open-sans-bold",
    fontSize: 14,
    marginTop: 7,
  },
  list: {
    backgroundColor: Colors.contentBackground,
    padding: 0,
    margin: 0,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderTopWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
  },
  listItem: {
    padding: 0,
    margin: 0,
    borderBottomWidth: 0,
  },
  separator: {
    backgroundColor: "transparent",
    borderWidth: 0,
    height: "auto",
    paddingBottom: 10,
    paddingTop: 30,
    justifyContent: "space-between",
  },
  label: {
    ...listItemSeparatorText,
  },
  contactInfoText: {
    ...listItemText,
  },
})
