import { Store } from "utilities/Redux/store"

export const getTempOffer = (state: Store, offerRequestId: string) =>
  state.sellerCreateOffer.tempOffers[offerRequestId]
    ? state.sellerCreateOffer.tempOffers[offerRequestId]
    : undefined

export const getSelectedDate = (state: Store) =>
  state.sellerCreateOffer.selectedDate
export const getSelectedStartTime = (state: Store) =>
  state.sellerCreateOffer.selectedStartTime
export const getIsOverlapping = (state: Store) =>
  state.sellerCreateOffer.isOverlapping
