import { connect } from "react-redux"
import _ from "lodash"
import View from "./view"
import {
  RadioQuestion,
  CheckBoxQuestion,
  QuestionOption,
} from "modules/Buyer/Questions/types"
import {
  checkboxOptionChecked,
  radioOptionChecked,
} from "modules/Buyer/Questions/actions"
import { Navigation } from "components/types"

interface OwnProps {
  question: RadioQuestion | CheckBoxQuestion
  isSubQuestion?: boolean
  navigation: Navigation
}

const mapStateToProps = null

const mapDispatchToProps = {
  checkboxOptionChecked,
  radioOptionChecked,
}

const mergeProps = (
  stateProps: typeof mapStateToProps,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...dispatchProps,
  ...ownProps,
  orderedOptions: _(ownProps.question.options)
    .map((item, key) => _.assign(item, { id: key }))
    .orderBy(["order", "asc"])
    .value(),
  onChecked: (option: QuestionOption, optionId: string) => {
    if (ownProps.question.type === "checkbox") {
      dispatchProps.checkboxOptionChecked({
        questionId: ownProps.question.id,
        checked: !option.checked,
        optionId,
      })
    } else {
      dispatchProps.radioOptionChecked({
        questionId: ownProps.question.id,
        optionId,
      })
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
