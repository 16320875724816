import _ from "lodash"
import { connect } from "react-redux"
import routeNames from "components/navigators/constants"
import { offerRequestSelected } from "modules/Seller/OfferRequests/actions"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { getBuyerProfileForId } from "modules/BuyerProfiles/selectors"
import { getCategoryById } from "modules/Buyer/Categories/selectors"
import {
  getOfferRequestByIdForSeller,
  getPreferredTimeForOfferRequest,
} from "modules/Seller/OfferRequests/selectors"

interface OwnProps {
  id: string
  navigation: Navigation
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => {
  const offerRequest = getOfferRequestByIdForSeller(state, ownProps.id)
  const buyerProfile =
    offerRequest && getBuyerProfileForId(state, offerRequest.buyerProfile)
  const category = offerRequest && getCategoryById(state, offerRequest.category)
  const offerRequestPreferredTime =
    offerRequest && getPreferredTimeForOfferRequest(state, offerRequest)

  return {
    offerRequest,
    buyerProfile,
    category,
    offerRequestPreferredTime,
  }
}

const mapDispatchToProps = {
  offerRequestSelected,
}

let lock: boolean
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onPress: () => {
    if (lock) {
      return
    }
    lock = true
    if (stateProps.offerRequest) {
      dispatchProps.offerRequestSelected(stateProps.offerRequest.id)
      ownProps.navigation.navigate(routeNames.SELLER.CREATE_OFFER)
    }
    setTimeout(() => {
      lock = false
    }, 500)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
