import { StyleSheet } from "react-native"
import Colors from "constants/Colors"
import { buttonText } from "constants/Fonts"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.containerBackground,
  },
  buttonWrap: {
    padding: 15,
    paddingTop: 45,
  },
  modalBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    flex: 1,
    padding: 15,
  },
  modalContent: {
    alignItems: "center",
    backgroundColor: Colors.contentBackground,
    borderRadius: 5,
    flex: 1,
    justifyContent: "center",
    padding: 15,
  },
  modelText: {
    marginBottom: 15,
    textAlign: "center",
  },
  errorButton: {
    borderColor: Colors.darkerGrey,
  },
  errorButtonText: {
    color: Colors.darkerGrey,
  },
  button: {
    backgroundColor: Colors.brandYellow,
  },
  buttonText,
  toast: {
    bottom: 100,
  },
  toastText: {
    textAlign: "center",
  },
})
