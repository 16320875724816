import React from "react"
import { Container, Content } from "native-base"
import styles from "./styles"
import HistoryList from "./HistoryList"
import { Navigation } from "components/types"

interface Props {
  navigation: Navigation
}

export default ({ navigation }: Props) => {
  return (
    <Container style={styles.container}>
      <Content>
        <HistoryList navigation={navigation} />
      </Content>
    </Container>
  )
}
