import React from "react"
import _ from "lodash"
import { View } from "react-native"
import { Body, ListItem, Text } from "native-base"
import styles from "./styles"
import { Offer } from "modules/Buyer/Offers/types"
import { Category } from "modules/Buyer/Categories/types"
import { SellerProfile } from "modules/SellerProfiles/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"
import { formatDateAndTime } from "utilities/time"

interface Props {
  offer?: Offer
  category?: Category
  sellerProfile?: SellerProfile
  last: boolean
  onPress: (offer: Offer, category: Category) => void
}

export default (props: Props) => {
  const { offer, category, sellerProfile, last } = props
  if (!offer || !category || !sellerProfile || !offer.actualCompletedTime) {
    return null
  }

  const completedDate = formatDateAndTime(
    offer.actualCompletedTime,
    "dateAndTimeFormat",
  )
  return (
    <ListItem
      onPress={() => props.onPress(offer, category)}
      style={last ? styles.lastItem : null}
    >
      <Body style={styles.textWrap}>
        <Text style={styles.title}>{sellerProfile.companyName}</Text>
        <View style={styles.info}>
          <Text style={styles.infoText}>{getLocaLizedName(category.name)}</Text>
          <Text style={styles.infoSeparator}>•</Text>
          <Text style={styles.infoText}>{completedDate}</Text>
        </View>
      </Body>
    </ListItem>
  )
}
