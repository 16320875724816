import React from "react"
import reduxStore, { Store } from "utilities/Redux/store"
import { connect, Provider } from "react-redux"
import Icon from "./Icon"
import {
  getOffersWithStatusForBuyer,
  getUnseenOffersTotalForBuyer,
} from "modules/Buyer/Offers/selectors"
import { getReminderCountForBuyer } from "modules/Buyer/OfferRequests/selectors"
import { TabBarIconProps } from "components/types"
import { getUnseenMessagesCount } from "modules/ChatMessages/selectors"

const mapStateToProps = (state: Store) => {
  const acceptedOffers = getOffersWithStatusForBuyer(state, "accepted")
  return {
    unseenOffersCount: getUnseenOffersTotalForBuyer(state),
    unseenMessagesCount: getUnseenMessagesCount(
      state,
      Object.values(acceptedOffers),
    ),
    reminderCount: getReminderCountForBuyer(state),
  }
}
const mapDispatchToProps = null
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: null,
  ownProps: TabBarIconProps,
) => ({
  ...stateProps,
  ...ownProps,
  unseenCount:
    stateProps.unseenOffersCount +
    stateProps.unseenMessagesCount +
    stateProps.reminderCount,
})

const ConnectedIcon = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Icon)

export default (props: TabBarIconProps) => (
  <Provider store={reduxStore}>
    <ConnectedIcon {...props} />
  </Provider>
)
