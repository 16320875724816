import React from "react"
import moment from "moment"
import { View, Text } from "react-native"
import styles from "./styles"
import { ChatMessage } from "modules/ChatMessages/types"
import { User } from "modules/User/types"
import { getMoment, formatDateAndTime } from "utilities/time"

const getTimeSent = (props: { previousDate?: number; createdAt: number }) => {
  if (
    props.previousDate &&
    (props.createdAt - props.previousDate) / 1000 / 60 < 1
  ) {
    // less that a minute since previous message
    return
  }

  const createdAtMoment = getMoment(props.createdAt)
  const todayMoment = moment()

  // TODAY
  if (
    todayMoment.format("YYYY-MM-DD") === createdAtMoment.format("YYYY-MM-DD")
  ) {
    return formatDateAndTime(createdAtMoment, "timeFormatToday")
  }

  // YESTERDAY
  if (
    todayMoment.subtract(1, "days").format("YYYY-MM-DD") ===
    createdAtMoment.format("YYYY-MM-DD")
  ) {
    return formatDateAndTime(createdAtMoment, "timeFormatYesterday")
  }

  // WITHIN A WEEK
  const differenceFromToday = Number(moment().format("x")) - props.createdAt
  if (differenceFromToday / 1000 / 60 / 60 / 24 / 7 < 1) {
    return formatDateAndTime(createdAtMoment, "timeFormatWeek")
  }

  // OLDER
  return formatDateAndTime(createdAtMoment, "dateAndTimeFormat")
}

interface Props {
  message: ChatMessage
  previousDate?: number
  loggedUser?: User
}

export default (props: Props) => {
  if (!props.loggedUser) {
    return null
  }
  const timeSent = getTimeSent({
    previousDate: props.previousDate,
    createdAt: props.message.createdAt,
  })
  const isMe = props.message.sender === props.loggedUser.id
  return (
    <View key={props.message.id} style={styles.container}>
      {timeSent && (
        <Text key={`${props.message.id}-date`} style={styles.date}>
          {timeSent}
        </Text>
      )}
      <Text
        key={`${props.message.id}-content`}
        style={[
          styles.messageContent,
          isMe ? styles.meMessageContent : styles.otherMessageContent,
        ]}
      >
        {props.message.content}
      </Text>
    </View>
  )
}
