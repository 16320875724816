import { StyleSheet } from "react-native"
import Colors from "constants/Colors"
import { buttonText, titleText, titleTextBold } from "constants/Fonts"

export default StyleSheet.create({
  remindersContainer: {
    marginBottom: 15,
  },
  reminderContainer: {
    backgroundColor: Colors.brandYellow,
    padding: 15,
    marginTop: 15,
    marginHorizontal: 15,
    borderRadius: 10,
  },
  content: {
    overflow: "hidden",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  iconContainer: {
    justifyContent: "center",
    width: 30,
  },
  icon: {
    color: Colors.contentBackground,
  },
  title: {
    ...titleText,
    color: Colors.contentBackground,
  },
  text: {
    ...titleText,
    color: Colors.contentBackground,
  },
  highlighted: {
    ...titleTextBold,
    color: Colors.contentBackground,
  },
  button: {
    borderColor: Colors.containerBackground,
    flex: 1,
    marginTop: 10,
  },
  buttonText: {
    color: Colors.containerBackground,
    ...buttonText,
  },
  buttonIcon: {
    marginRight: 15,
  },
  extensionAction: {
    borderTopWidth: 1,
    borderColor: Colors.contentBackground,
    marginTop: 15,
    paddingTop: 15,
  },
})
