import React from "react"
import moment from "moment"
import _ from "lodash"
import { Text } from "native-base"
import { View } from "react-native"
import Colors from "constants/Colors"
import styles from "../styles"
import { stepInHour, convertTimeToIndex } from "../helpers"
import { Offer } from "modules/Buyer/Offers/types"

interface Props {
  openAndAcceptedOffersForSelectedDate: Array<{
    offer: Offer
    name: string
    buyer?: string
  }>
}

export default ({ openAndAcceptedOffersForSelectedDate }: Props) => {
  return (
    <>
      {_.map(openAndAcceptedOffersForSelectedDate, ({ offer, name, buyer }) => {
        const duration =
          offer.original.actualDuration || offer.original.purchaseDuration
        if (!duration) {
          return null
        }
        const slotStyle = {
          backgroundColor: Colors.brandYellow,
          height: duration * stepInHour - 2,
          top:
            (convertTimeToIndex(
              moment(
                offer.actualStartTime || offer.agreedStartTime,
                "x",
              ).format("HH:mm"),
            ) *
              stepInHour) /
              2 +
            1,
        }
        return (
          <View key={offer.id} style={[styles.existingBlock, slotStyle]}>
            <Text>{name}</Text>
            <Text>{buyer}</Text>
          </View>
        )
      })}
    </>
  )
}
