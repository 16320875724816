import React from "react"
import I18n from "i18n"
import { Text } from "react-native"
import { ListItem, Thumbnail, Body } from "native-base"
import feedImages from "../feedImages"
import styles from "./styles"
import { FeedItem } from "modules/Buyer/Feed/types"

interface Props {
  item: FeedItem
  onPress: () => void
  isLast: boolean
}

export default ({ item: { imageName, name }, onPress, isLast }: Props) => {
  return (
    <ListItem
      onPress={onPress}
      style={[styles.listItem, isLast ? styles.listItemLast : {}]}
    >
      <Thumbnail square large source={feedImages(imageName)} />
      <Body style={styles.body}>
        <Text style={styles.title}>{name}</Text>
        <Text style={styles.meta}>{I18n.t("make_an_offer_request")}</Text>
      </Body>
    </ListItem>
  )
}
