import moment from "moment"
import { all, call, takeEvery, fork, put, take } from "redux-saga/effects"
import { getEventChannelForFirebaseRef } from "utilities/Redux/sagaHelpers"
import { OFFER_STATUS } from "constants/Status"
import firebase from "firebase"
import { FIREBASE_PATH } from "./constants"
import {
  offerUpdated,
  offerStatusUpdated,
  MarkOfferAsSeen,
  MarkPastJobCompleted,
  StopJob,
  PauseJob,
  RequestToExtendOfferDuration,
  StartJob,
  OfferStatusUpdate,
} from "./actions"
import {
  MARK_PAST_JOB_COMPLETED,
  STOP_JOB,
  REQUEST_OFFER_DURATION_EXTENSION,
  START_JOB,
  MARK_OFFER_AS_SEEN,
  OFFER_STATUS_UPDATE,
  PAUSE_JOB,
} from "./actionTypes"
import { USER_UPDATED } from "modules/User/actionTypes"
import { UserUpdated } from "modules/User/actions"
import { User } from "modules/User/types"
import { UpdatedDate } from "./types"
import { OfferDB } from "database_types/offer"

const subscribed = {}
export function* subscribeToOfferWorker(offerId: string) {
  if (subscribed[offerId]) {
    return
  }
  yield (subscribed[offerId] = true)

  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(offerId)
  const channel = yield call(getEventChannelForFirebaseRef, ref)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const { values: offer }: { values: OfferDB | undefined } = yield take(
      channel,
    )
    if (offer) {
      yield put(
        offerUpdated({
          ...offer,
          id: offerId,
        }),
      )
    }
  }
}

export function* subscribeToUsersOffersWorker(offerIds: User["offers"]) {
  if (!offerIds) {
    return
  }
  yield all(Object.keys(offerIds).map(id => call(subscribeToOfferWorker, id)))
}

export function* subscribeToOfferSaga() {
  yield takeEvery(USER_UPDATED, (action: UserUpdated) =>
    subscribeToUsersOffersWorker(action.user.offers),
  )
}

export function* updateOfferStatusWorker(action: OfferStatusUpdate) {
  // tslint:disable-next-line:no-unsafe-any
  const offerRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offer.id)

  yield offerRef.child("status").set(action.offer.status)
  yield offerRef.child("seenByBuyer").set(action.offer.seenByBuyer)

  yield put(offerStatusUpdated(action.offer))
}

export function* updateOfferStatusSaga() {
  yield takeEvery(OFFER_STATUS_UPDATE, updateOfferStatusWorker)
}

export function* markOfferAsSeenWorker(action: MarkOfferAsSeen) {
  // tslint:disable-next-line:no-unsafe-any
  const offerStatusRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offerId)
    .child("seenBySeller")

  yield offerStatusRef.set(action.seenBySeller)
}

export function* markOfferAsSeenSaga() {
  yield takeEvery(MARK_OFFER_AS_SEEN, markOfferAsSeenWorker)
}

export function* startJobWorker(action: StartJob) {
  // tslint:disable-next-line:no-unsafe-any
  const offerRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offer.id)

  const timestamp = firebase.database.ServerValue.TIMESTAMP

  yield offerRef.update({
    actualStartTime: timestamp,
    isPaused: false,
    workTime: [
      {
        startTime: timestamp,
        endTime: null,
      },
    ],
  })
}

export function* startJobSaga() {
  yield takeEvery(START_JOB, startJobWorker)
}

export function* pauseJobWorker({ offer, isPaused }: PauseJob) {
  const updatedDate: UpdatedDate = { isPaused, workTime: offer.workTime || [] }
  const isOfferPaused = !offer.isPaused

  // tslint:disable-next-line:no-unsafe-any
  const offerRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(offer.id)

  const timestamp = firebase.database.ServerValue.TIMESTAMP
  if (isOfferPaused && updatedDate.workTime) {
    const { workTime } = updatedDate
    workTime[workTime.length - 1].endTime = timestamp
  } else if (updatedDate.workTime) {
    const { workTime } = updatedDate
    const time: number | object | null = workTime[workTime.length - 1].endTime
    const startTime = typeof time === "number" ? moment(time) : moment()
    const endTime = moment()

    const pauseDuration = endTime.diff(startTime)

    updatedDate.pauseDuration = offer.pauseDuration
      ? pauseDuration + offer.pauseDuration
      : pauseDuration

    updatedDate.workTime.push({
      startTime: timestamp,
      endTime: null,
    })
  }

  yield offerRef.update(updatedDate)
}

export function* pauseJobSaga() {
  yield takeEvery(PAUSE_JOB, pauseJobWorker)
}

export function* extendOfferDurationWorker(
  action: RequestToExtendOfferDuration,
) {
  // tslint:disable-next-line:no-unsafe-any
  const offerRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offer.id)
  yield offerRef.update({
    timeToExtend: action.extendedDuration,
  })
}

export function* extendOfferDurationSaga() {
  yield takeEvery(REQUEST_OFFER_DURATION_EXTENSION, extendOfferDurationWorker)
}

export function* stopJobWorker(action: StopJob) {
  const timestamp = firebase.database.ServerValue.TIMESTAMP

  const updatedDate: UpdatedDate = {
    actualCompletedTime: timestamp,
    status: OFFER_STATUS.COMPLETED,
    isPaused: false,
    workTime: action.offer.workTime || [],
  }
  const { workTime } = updatedDate

  if (!action.offer.isPaused && workTime) {
    workTime[workTime.length - 1].endTime = timestamp
  }

  // tslint:disable-next-line:no-unsafe-any
  const offerRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offer.id)

  yield offerRef.update(updatedDate)
}

export function* stopJobSaga() {
  yield takeEvery(STOP_JOB, stopJobWorker)
}

export function* markPastJobCompletedWorker(action: MarkPastJobCompleted) {
  // tslint:disable-next-line:no-unsafe-any
  const offerRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offerId)

  yield offerRef.update({
    actualStartTime: action.actualStartTime,
    actualCompletedTime: action.actualCompletedTime,
    status: OFFER_STATUS.COMPLETED,
  })
}

export function* markPastJobCompletedSaga() {
  yield takeEvery(MARK_PAST_JOB_COMPLETED, markPastJobCompletedWorker)
}

export default function*() {
  yield all([
    fork(subscribeToOfferSaga),
    fork(updateOfferStatusSaga),
    fork(markOfferAsSeenSaga),
    fork(startJobSaga),
    fork(extendOfferDurationSaga),
    fork(stopJobSaga),
    fork(pauseJobSaga),
    fork(markPastJobCompletedSaga),
  ])
}
