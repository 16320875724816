import { connect } from "react-redux"
import _ from "lodash"
import {
  getSelectedOfferRequestForSeller,
  getDefaultValueForFixedOffer,
} from "modules/Seller/OfferRequests/selectors"
import {
  createOffer,
  setDefaultOfferValue,
  DefaultValue,
} from "modules/Seller/CreateOffer/actions"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { getTempOffer } from "modules/Seller/CreateOffer/selectors"
import { Navigation } from "components/types"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const offerRequest = getSelectedOfferRequestForSeller(state)
  if (!offerRequest) {
    return {}
  }
  return {
    offerRequest,
    tempOffer: getTempOffer(state, offerRequest.id),
    defaultValue: getDefaultValueForFixedOffer(state),
  }
}

const mapDispatchToProps = {
  createOffer,
  setDefaultOfferValue,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  setDefaultValue: (defaultValue: DefaultValue) => {
    dispatchProps.setDefaultOfferValue({
      defaultValue,
      offerRequestId: stateProps.offerRequest ? stateProps.offerRequest.id : "",
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
