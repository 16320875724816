import { StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.containerBackground,
  },
  buttonWrap: {
    bottom: 15,
    left: 15,
    right: 15,
    position: "absolute",
  },
  button: {
    backgroundColor: Colors.brandGreen,
  },
  buttonDisabled: {
    backgroundColor: Colors.containerBackground,
    borderColor: Colors.borderColor,
  },
  buttonTextDisabled: {
    color: Colors.darkerGrey,
  },
  toast: {
    bottom: 100,
  },
  toastText: {
    textAlign: "center",
  },
})
