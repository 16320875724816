import { Platform, StyleProp} from "react-native"
import { ComponentType } from "react"

export const selectComponent = (props: {
  base: ComponentType<any>
  ios?: ComponentType<any>
  android?: ComponentType<any>
  web?: ComponentType<any>
}): ComponentType<any> => {
  const { base } = props
  return props[Platform.OS] ? props[Platform.OS] : base
}

export const buildStyle = (props: {
  base: StyleProp<any>
  ios?: StyleProp<any>
  android?: StyleProp<any>
  web?: StyleProp<any>
}): StyleProp<any> => {
  return props[Platform.OS]
    ? { ...props.base, ...props[Platform.OS] }
    : props.base
}
