const imageUploader = async (
  fieldname: string,
  localUri: string,
  filename: string,
) => {
  const body = (window as any).FormData ? new window.FormData() : undefined
  if (!body) {
    throw new Error("imageUploader: no window.FormData")
  }

  body.append(fieldname, localUri, filename)
  const res = await fetch(
    "https://us-central1-helpdor-f25dc.cloudfunctions.net/dbUploadImageOnUpload-api/picture",
    {
      method: "POST",
      body,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    },
  )
  return res.status
}

export default imageUploader
