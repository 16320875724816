import { StyleSheet, PixelRatio } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.containerBackground,
    paddingTop: 15,
  },
  buttonWrap: {
    padding: 15,
  },
  textStyle: {
    color: Colors.noteColor,
    fontSize: 14,
  },
  details: {
    marginVertical: 15,
    paddingHorizontal: 15,
    backgroundColor: Colors.containerBackground,
  },
  detailsRow: {
    flexDirection: "row",
    marginBottom: 15,
  },
  detailsLabel: {
    color: Colors.noteColor,
    fontSize: 12,
    flex: 1,
    paddingRight: 15,
    textAlign: "right",
  },
  detailsValue: {
    color: Colors.textColor,
    fontSize: 12,
    flex: 3,
  },
  duration: {
    marginVertical: 15,
  },
  timeRowContainer: {
    backgroundColor: Colors.contentBackground,
    borderColor: Colors.borderColor,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderTopWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    marginTop: 15,
    height: 45,
    justifyContent: "center",
  },
  timeRow: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    fontFamily: "open-sans-regular",
    paddingHorizontal: 15,
  },
  timeRowText: {
    color: Colors.darkerGrey,
    paddingRight: 30,
  },
  submitButton: {
    backgroundColor: Colors.brandYellow,
  },
  submitButtonDisabled: {
    backgroundColor: Colors.contentBackground,
  },
  submitButtonDisabledText: {
    color: Colors.darkerGrey,
  },
})
