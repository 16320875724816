import React from "react"
import { View, Text, TextInput, TouchableOpacity } from "react-native"
import { Container, Content, Button, Icon, Toast } from "native-base"
import I18n from "i18n"
import ProfileImage from "../ProfileImage"
import _ from "lodash"
import styles from "./styles"
import { SellerProfile } from "modules/SellerProfiles/types"

const STARS_COUNT = [1, 2, 3, 4, 5]
interface StarsProps {
  starCount: number
  selectStarCount: (stars: number) => void
}
const Stars = ({ starCount, selectStarCount }: StarsProps) => {
  return (
    <View style={styles.ratingContainer}>
      <Text style={styles.rating}>{I18n.t("rating")}</Text>
      {STARS_COUNT.map((x, idx) => {
        return (
          <TouchableOpacity
            onPress={() => {
              selectStarCount(x)
            }}
            key={`STARS-${x}`}
          >
            <Icon
              name="star"
              style={[
                styles.reviewRating,
                x <= starCount ? styles.reviewRatingActive : null,
              ]}
              active
            />
          </TouchableOpacity>
        )
      })}
    </View>
  )
}

interface ReviewFormProps {
  sellerProfile?: SellerProfile
  submitReview: (starCount: number, review: string) => void
}

interface ReviewFormState {
  review: string
  starCount: number
}

export default class ReviewForm extends React.Component<ReviewFormProps> {
  state: ReviewFormState
  textInput?: TextInput | null
  constructor(props: ReviewFormProps) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      review: "",
      starCount: 0,
    }
  }

  onSubmit = () => {
    const { starCount, review } = this.state
    if (starCount || review) {
      this.props.submitReview(starCount, review)
    } else {
      Toast.show({
        text: "Please provide ratings or reviews or both",
        position: "top",
        duration: 2000,
        style: { height: 40 },
      })
    }
  }

  starCount = (starCount: number) => {
    this.setState({ starCount })
  }

  render() {
    const { sellerProfile } = this.props
    if (!sellerProfile) {
      return null
    }
    return (
      <Container style={styles.container}>
        <View style={styles.profile}>
          <ProfileImage
            style={styles.profileImage}
            large
            source={sellerProfile.photoURL}
          />
          <Text style={styles.companyName}>{sellerProfile.companyName}</Text>
        </View>
        <Content>
          <Stars
            starCount={this.state.starCount}
            selectStarCount={this.starCount}
          />
          <View style={styles.inputContainer}>
            <TextInput
              ref={input => {
                this.textInput = input
              }}
              style={styles.input}
              onChangeText={value => this.setState({ review: value })}
              placeholder={I18n.t("write_a_review")}
              multiline
              blurOnSubmit
              numberOfLines={10}
              returnKeyType="done"
              underlineColorAndroid="transparent"
            />
          </View>
        </Content>
        <View style={styles.buttonWrap}>
          <Button block rounded onPress={this.onSubmit} style={styles.button}>
            <Text style={styles.buttonText}>{I18n.t("submit_review")}</Text>
          </Button>
        </View>
      </Container>
    )
  }
}
