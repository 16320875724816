import { StyleSheet, Dimensions } from "react-native"
import Colors from "constants/Colors"

const { height } = Dimensions.get("window")
export default StyleSheet.create({
  emptyView: {
    justifyContent: "center",
    alignItems: "center",
    padding: 15,
  },
  fullPage: {
    height: height - 250,
    flex: 1,
    padding: 0,
  },
  emptyTextMessage: {
    fontFamily: "open-sans-regular",
    color: Colors.noteColor,
    fontSize: 14,
    alignSelf: "flex-start",
  },
  emptyTextMessageFullPage: {
    alignSelf: "center",
    fontSize: 17,
  },
})
