import { StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  content: {
    marginTop: 15,
    marginHorizontal: 15,
    backgroundColor: Colors.containerBackground,
    flex: 1,
  },
  detailsRow: {
    flexDirection: "row",
    paddingBottom: 15,
    paddingHorizontal: 15,
  },
  detailsTitle: {
    flex: 2,
    alignItems: "flex-end",
    paddingRight: 15,
  },
  detailsContent: {
    flex: 5,
  },
  detailsTitleText: {
    fontFamily: "open-sans-regular",
    fontSize: 12,
    color: Colors.noteColor,
    textAlign: "right",
  },
  detailsContentWrap: {
    marginBottom: 5,
  },
  detailsContentText: {
    fontFamily: "open-sans-regular",
    fontSize: 14,
    color: Colors.textColor,
  },
  imagesContainer: {
    paddingTop: 3,
  },
  subQuestionRow: {
    flex: 1,
    flexDirection: "row",
  },
  subQuestionDotSeparator: {
    fontFamily: "open-sans-regular",
    alignItems: "flex-end",
    color: Colors.textColor,
    fontSize: 8,
    marginTop: 0,
    lineHeight: 18,
  },
  subQuestionValue: {
    marginLeft: 10,
  },
})
