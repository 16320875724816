import React from "react"
import { CommonActions , StackActions, StackActionType} from "@react-navigation/native"
import { connect } from "react-redux"
import { View } from "react-native"
import routeNames from "./constants"
import { Store } from "utilities/Redux/store"
import { isUserApprovedSeller } from "modules/User/selectors"

interface Props {
  isApprovedSeller?: boolean
  navigate: (action: CommonActions.Action | StackActionType) => void
  getCurrentRoute: () => string | undefined
}

class NavigateBasedOnReduxEvents extends React.Component<Props> {
  // check Redux state here and call navigation handler
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.isApprovedSeller !== undefined &&
      nextProps.isApprovedSeller !== this.props.isApprovedSeller
    ) {
      this.handleIsApprovedChanged(nextProps)
    }
  }

  // never render again
  shouldComponentUpdate() {
    return false
  }

  handleIsApprovedChanged(nextProps: Props) {
    if (
      nextProps.isApprovedSeller &&
      this.props.getCurrentRoute() ===
        routeNames.SELLER_SWITCHER.WAIT_FOR_APPROVAL
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.props.navigate(
        StackActions.replace(routeNames.SELLER_SWITCHER.SWITCH),
      )
    }
  }

  render() {
    return <View style={{ flex: 1 }}>{this.props.children}</View>
  }
}

interface OwnProps {
  navigate: (action: CommonActions.Action) => void
  getCurrentRoute: () => string | undefined
}

const mapStateToProps = (state: Store) => ({
  isApprovedSeller: isUserApprovedSeller(state),
})
const mapDispatchToProps = null
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...ownProps,
})

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default connect( mapStateToProps, mapDispatchToProps, mergeProps )(NavigateBasedOnReduxEvents)
