import React from "react"
import { headerStyles } from "./headerStyles"
import { Text } from "react-native"
import Colors from "constants/Colors"
import { TabBarLabelProps } from "../types"

interface Props extends TabBarLabelProps {
  label: string
  unseenCount?: number
}

export default ({ color, label, unseenCount }: Props) => {
  return (
    <Text
      style={[
        headerStyles.labelStyle,
        color
          ? {
              color: color,
            }
          : null,
      ]}
    >
      {label}
      {unseenCount ? (
        <Text style={{ color: Colors.brandYellow }}> ({unseenCount})</Text>
      ) : null}
    </Text>
  )
}
