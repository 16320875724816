import _ from "lodash"
import { connect } from "react-redux"
import View from "./view"
import { Offer } from "modules/Buyer/Offers/types"
import { chatOpenedByUser } from "modules/Chats/actions"
import { userUpdateActiveRoute } from "modules/User/actions"

interface OwnProps {
  offer: Offer
}

const mapStateToProps = null
const mapDispatchToProps = {
  chatOpenedByUser,
  userUpdateActiveRoute,
}
const mergeProps = (
  stateProps: null,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...dispatchProps,
  ...ownProps,
  chatId: ownProps.offer.chat,
  chatOpenedByUser: () => {
    if (ownProps.offer.chat) {
      dispatchProps.chatOpenedByUser(ownProps.offer.chat)
      dispatchProps.userUpdateActiveRoute({ chatId: ownProps.offer.chat })
    }
  },
  chatClosedByUser: () => {
    dispatchProps.userUpdateActiveRoute(undefined)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
