import { StyleSheet, PixelRatio } from "react-native"
import Colors from "constants/Colors"
import { separatorText } from "constants/Fonts"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.contentBackground,
    marginBottom: 15,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
  },
  separator: {
    backgroundColor: Colors.containerBackground,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
  },
  titleText: {
    ...separatorText,
  },
})
