import { all, takeEvery, fork, put } from "redux-saga/effects"
import { getStripeSession } from "api/stripe"
import { GET_STRIPE_SESSION } from "./actionTypes"
import { updateStripeSession, GetStripeSession } from "./actions"
import { updateCurrentTransaction } from "../Transaction/actions"

export function* stripeSessionWorker(action: GetStripeSession) {
  // tslint:disable-next-line:no-unsafe-any
  const response: Response = yield getStripeSession(action)
  const { status } = response
  if (status === 200) {
    // tslint:disable-next-line:no-unsafe-any
    const {
      sessionId,
      transactionId,
    }: { sessionId: string; transactionId: string } = yield response.json()
    yield put(updateStripeSession(sessionId))
    yield put(updateCurrentTransaction(transactionId))
  }
}

export function* stripeSessionSaga() {
  yield takeEvery(GET_STRIPE_SESSION, stripeSessionWorker)
}

export default function*() {
  yield all([fork(stripeSessionSaga)])
}
