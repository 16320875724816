import React from "react"
import _ from "lodash"
import { View } from "react-native"
import { Body, Icon, ListItem, Right, Text } from "native-base"
import ProfileImage from "components/views/Shared/ProfileImage"
import styles from "./styles"
import { BuyerProfile } from "modules/BuyerProfiles/types"
import { Category } from "modules/Buyer/Categories/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"
import { formatDateAndTime } from "utilities/time"

interface Props {
  onPress: () => void
  last: boolean
  buyerProfile?: BuyerProfile
  offerRequestPreferredTime?: number
  category?: Category
}

export default ({
  onPress,
  last,
  buyerProfile,
  offerRequestPreferredTime,
  category,
}: Props) => {
  if (!buyerProfile || !category) {
    return null
  }
  const photoUrl = buyerProfile.photoURL
  const preferredTime = offerRequestPreferredTime
    ? formatDateAndTime(offerRequestPreferredTime, "dateAndTimeFormat")
    : undefined
  return (
    <ListItem onPress={onPress} style={last ? styles.lastItem : null}>
      <ProfileImage style={styles.thumbnail} small source={photoUrl} />
      <Body style={styles.textWrap}>
        <Text style={styles.title}>{getLocaLizedName(category.name)}</Text>
        <View style={styles.info}>
          <Text style={styles.infoText}>{buyerProfile.displayName}</Text>
          {preferredTime ? <Text style={styles.infoSeparator}>•</Text> : null}
          {preferredTime ? (
            <Text style={styles.infoText}>{preferredTime}</Text>
          ) : null}
        </View>
      </Body>
      <Right>
        <View style={styles.rightWrap}>
          <Icon name="arrow-forward" style={styles.icon} />
        </View>
      </Right>
    </ListItem>
  )
}
