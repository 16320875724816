import { StyleSheet, PixelRatio } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  questionListWithSubQuestions: {
    flex: 1,
  },
  separator: {
    backgroundColor: "transparent",
    borderWidth: 0,
    height: "auto",
    paddingBottom: 10,
    paddingTop: 30,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  label: {
    color: Colors.darkerGrey,
    fontSize: 12,
  },
  questionBody: {
    backgroundColor: Colors.contentBackground,
    borderColor: Colors.borderColor,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderTopWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  questionBodyIsSubQuestion: {
    // borderBottomWidth: 0,
    // marginBottom: 30,
  },
  warningText: {
    color: Colors.red,
    fontSize: 10,
    lineHeight: 12,
    marginTop: 1,
    marginRight: 10,
  },
})
