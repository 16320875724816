import React from "react"
import { View, Text, TouchableOpacity } from "react-native"
import { Button } from "native-base"
import { SimpleLineIcons } from "@expo/vector-icons"
import I18n from "i18n"
import ReminderStyles from "../styles"
import styles from "./styles"
import { Category } from "modules/Buyer/Categories/types"
import { SellerProfile } from "modules/SellerProfiles/types"
import { Offer } from "modules/Buyer/Offers/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface Props {
  category?: Category
  offer?: Offer
  sellerProfile?: SellerProfile
  leaveReview: (sellerProfile: SellerProfile) => void
  onPressArrow: (category: Category, offer: Offer) => void
}

export default ({
  category,
  offer,
  sellerProfile,
  leaveReview,
  onPressArrow,
}: Props) => {
  if (!category || !offer || !sellerProfile) {
    return null
  }
  return (
    <View style={ReminderStyles.reminderContainer}>
      <View style={ReminderStyles.content}>
        <View style={styles.jobStatus}>
          <Text style={ReminderStyles.title}>
            {getLocaLizedName(category.name)} {I18n.t("completed")}
          </Text>
        </View>
        <TouchableOpacity onPress={() => onPressArrow(category, offer)}>
          <View style={ReminderStyles.iconContainer}>
            <SimpleLineIcons
              name="arrow-right-circle"
              size={30}
              style={ReminderStyles.icon}
            />
          </View>
        </TouchableOpacity>
      </View>
      <View style={ReminderStyles.extensionAction}>
        <Button
          rounded
          block
          success
          onPress={() => leaveReview(sellerProfile)}
          style={ReminderStyles.button}
        >
          <Text style={ReminderStyles.buttonText}>
            {I18n.t("request_review")}{" "}
            <Text style={ReminderStyles.highlighted}>
              {sellerProfile.companyName}
            </Text>
          </Text>
        </Button>
      </View>
    </View>
  )
}
