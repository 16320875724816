import { connect } from "react-redux"
import {
  getOpenOfferRequestIdsWithNoOwnOffers,
  getSelectedOfferRequestForSeller,
} from "modules/Seller/OfferRequests/selectors"
import routeNames from "components/navigators/constants"
import { createOffer } from "modules/Seller/CreateOffer/actions"
import _ from "lodash"
import { SendOfferButton } from "./view"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { NewOffer } from "modules/Seller/CreateOffer/types"
import { getTempOffer } from "modules/Seller/CreateOffer/selectors"
import { getUser } from "modules/User/selectors"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const openOfferRequests = getOpenOfferRequestIdsWithNoOwnOffers(state)
  const offerRequest = getSelectedOfferRequestForSeller(state)
  const user = getUser(state)
  if (!offerRequest || !user) {
    return {
      openOfferRequests,
    }
  }
  return {
    openOfferRequests,
    offerRequest,
    tempOffer: getTempOffer(state, offerRequest.id),
    sellerProfileId: user.id,
  }
}

const mapDispatchToProps = {
  createOffer,
}

let lock: boolean
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSendClicked: (offer: NewOffer) => {
    if (lock) {
      return
    }
    lock = true

    dispatchProps.createOffer(offer)

    ownProps.navigation.replace(routeNames.SELLER.TABS)

    setTimeout(() => {
      lock = false
    }, 500)
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(SendOfferButton)
