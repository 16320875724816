import React from "react"
import reduxStore, { Store } from "utilities/Redux/store"
import { connect, Provider } from "react-redux"
import Icon from "./OfferRequestsIcon"
import {
  getUnseenAcceptedOffersTotalForSeller,
  getSellerOffersWithStatus,
  getSellerOfferReminderCount,
} from "modules/Seller/Offers/selectors"
import { TabBarIconProps } from "components/types"
import { getUnseenMessagesCount } from "modules/ChatMessages/selectors"

const mapStateToProps = (state: Store) => ({
  unseenOffersCount: getUnseenAcceptedOffersTotalForSeller(state),
  unseenMessagesCount: getUnseenMessagesCount(
    state,
    getSellerOffersWithStatus(state, "accepted"),
  ),
  reminderCount: getSellerOfferReminderCount(state),
})
const mapDispatchToProps = null
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: TabBarIconProps,
) => ({
  ...stateProps,
  ...ownProps,
  unseenCount:
    stateProps.unseenOffersCount +
    stateProps.unseenMessagesCount +
    stateProps.reminderCount,
})

const ConnectedIcon = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Icon)

export default (props: TabBarIconProps) => (
  <Provider store={reduxStore}>
    <ConnectedIcon {...props} />
  </Provider>
)
