import React from "react"
import { createStackNavigator } from "@react-navigation/stack"
import Colors from "constants/Colors"
import routeNames from "../constants"
import CheckoutIndex from "components/views/CheckoutStripe"
import Payment from "components/views/CheckoutStripe/Payment"
import PaymentViewBackButton from "./PaymentViewBackButton"

const { Navigator, Screen } = createStackNavigator()

export default () => (
  <Navigator
    initialRouteName={routeNames.CHECKOUT_STRIPE.INDEX}
    headerMode="none"
    screenOptions={{
      headerBackTitle: undefined,
      headerTintColor: Colors.brandYellow,
      gestureEnabled: false,
      cardStyle: {
        backgroundColor: Colors.containerBackground,
      },
    }}
  >
    <Screen name={routeNames.CHECKOUT_STRIPE.INDEX} component={CheckoutIndex} />
    <Screen
      name={routeNames.CHECKOUT_STRIPE.PAYMENT}
      component={Payment}
      options={({ route, navigation }) => ({
        title: route.params && route.params["name"] ? route.params["name"] : "",
        headerLeft: (props: any) => (
          <PaymentViewBackButton {...{ ...props, navigation, route }} />
        ),
      })}
    />
  </Navigator>
)
