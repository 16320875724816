import { TransactionsState } from "./types"
import {
  UPDATE_CURRENT_TRANSACTION,
  TRANSACTION_UPDATED,
  UPDATE_ERROR_MESSAGE,
  UPDATE_PAYMENT_STATUS,
} from "./actionTypes"
import {
  UpdateCurrentTransaction,
  TransactionUpdated,
  UpdatePaymentStatus,
  UpdateErrorMessage,
} from "./actions"

export const INITIAL_STATE: TransactionsState = {
  currentTransaction: undefined,
  transactions: {},
  isSuccessful: undefined,
  errorMessage: undefined,
}

export default function transactionReducer(
  state = INITIAL_STATE,
  action:
    | UpdateCurrentTransaction
    | TransactionUpdated
    | UpdatePaymentStatus
    | UpdateErrorMessage,
): TransactionsState {
  switch (action.type) {
    case UPDATE_CURRENT_TRANSACTION:
      return {
        ...state,
        currentTransaction: action.transactionId || undefined,
      }
    case TRANSACTION_UPDATED:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [action.transaction.id]: action.transaction,
        },
      }
    case UPDATE_PAYMENT_STATUS:
      return {
        ...state,
        isSuccessful: action.isSuccessful,
      }
    case UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
