import "core-js/stable"
import "regenerator-runtime/runtime"
import { AppLoading } from "expo"
import React, { useState, useEffect } from "react"
import I18n from "ex-react-native-i18n"
import loadAssetsAsync from "./src/utilities/Assets/loadAssetsAsync"
import reduxStore from "./src/utilities/Redux/store"
import Application from "./src/components/Application/index.web"
import * as firebase from "firebase"
import { getFirebaseConfig } from "./config"
import Constants from "expo-constants"
import { useFonts } from "expo-font"
import { Ionicons } from "@expo/vector-icons"
import { Platform } from "react-native"
import * as WebBrowser from "expo-web-browser"

if (Platform.OS == "web") {
  WebBrowser.maybeCompleteAuthSession()
}

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false)
  const [expoPushToken, setExpoPushToken] = useState<string>()
  const [fontsLoaded] = useFonts({
    ...Ionicons.font,
    "open-sans-regular": require("./assets/fonts/OpenSans-Regular.ttf"),
    "open-sans-bold": require("./assets/fonts/OpenSans-Bold.ttf"),
    "open-sans-light": require("./assets/fonts/OpenSans-Light.ttf"),
    Roboto_medium: require("./assets/fonts/Roboto-Medium.ttf"),
  })
  useEffect(() => {
    async function init() {
      console.ignoredYellowBox = ["Remote debugger", "Setting a timer"]
      const getToken = async () => {
        let token = null
        if (Constants.isDevice && Platform.OS != "web") {
          //todo: fix notifications for web
          // TEMP: token = await getExpoPushTokenAsync()
          console.log('temprary comment getExpoPushTokenAsync =======================')
        }
        return token
      }
      const promises = await Promise.all([
        // Using 'any' because I18n is an outdated, abandoned library with wrong typings
        // https://github.com/xcarpentier/ex-react-native-i18n/pull/21
        // https://github.com/xcarpentier/ex-react-native-i18n/issues/22
        (I18n as any).initAsync(), // tslint:disable-line:no-unsafe-any
        loadAssetsAsync(),
        getToken(),
      ])

      if (!firebase.apps.length) {
        const firebaseConfig = await getFirebaseConfig()
        firebase.initializeApp(firebaseConfig)
      }

      setExpoPushToken(promises[2] || undefined) // Expo push token
      setAppIsReady(!!promises)
    }
    init()
  }, [])

  if (!appIsReady || !fontsLoaded) {
    return <AppLoading />
  } else {
    return <Application reduxStore={reduxStore} expoPushToken={expoPushToken} />
  }
}
