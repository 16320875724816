import { Store } from "utilities/Redux/store"

export const getCurrentTransaction = (state: Store) =>
  state.transactions.currentTransaction
export const getTransactionById = (state: Store, transactionId: string) =>
  state.transactions.transactions[transactionId]
    ? state.transactions.transactions[transactionId]
    : undefined
export const getPaymentStatus = (state: Store) =>
  state.transactions.isSuccessful
export const getErrorMessage = (state: Store) => state.transactions.errorMessage
