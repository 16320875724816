import React from "react"
import I18n from "i18n"
import { View, Text, SafeAreaView } from "react-native"
import { Container, Content } from "native-base"
import Logo from "components/navigators/Logo"
import styles from "./styles"

export default () => {
  return (
    <Container style={styles.container}>
      <SafeAreaView style={{ flex: 1 }}>
        <View style={styles.logoContainer}>
          <Logo />
        </View>
        <Content style={styles.wrapper}>
          <Text style={styles.text}>{I18n.t("approval_process")}</Text>
          <Text style={styles.titleText}>{I18n.t("waiting_for_approval")}</Text>
        </Content>
      </SafeAreaView>
    </Container>
  )
}
