export const OFFER_SELECTED = `sellerOffers/OFFER_SELECTED`
export const OFFER_UPDATED = `sellerOffers/OFFER_UPDATED`
export const OFFER_STATUS_UPDATE = `sellerOffers/OFFER_STATUS_UPDATE`
export const OFFER_STATUS_UPDATED = `sellerOffers/OFFER_STATUS_UPDATED`
export const MARK_OFFER_AS_SEEN = `sellerOffers/MARK_OFFER_AS_SEEN`
export const START_JOB = `sellerOffers/START_JOB`
export const PAUSE_JOB = `sellerOffers/PAUSE_JOB`
export const REQUEST_OFFER_DURATION_EXTENSION = `sellerOffers/REQUEST_OFFER_DURATION_EXTENSION`
export const STOP_JOB = `sellerOffers/STOP_JOB`
export const MARK_PAST_JOB_COMPLETED = `sellerOffers/MARK_PAST_JOB_COMPLETED`
