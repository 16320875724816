import _ from "lodash"
import { createSelector } from "reselect"
import { Store } from "utilities/Redux/store"
import { getChatById } from "../Chats/selectors"
import * as userSelectors from "../User/selectors"
import { Chat } from "../Chats/types"
import { Offer } from "../Buyer/Offers/types"
import { User } from "../User/types"

export const getById = (state: Store, id: string) =>
  state.chatMessages[id] ? state.chatMessages[id] : undefined

export const getMessagesForChat = createSelector(
  [getChatById, (state: Store) => state.chatMessages],
  (chat, allMessages) => {
    if (chat && chat.messages) {
      return Object.keys(chat.messages)
        .filter(id => allMessages[id])
        .map(id => allMessages[id])
    }
    return []
  },
)

const getUnseenCountForChat = (state: Store, chat: Chat, userId: string) => {
  const lastOpened =
    chat.members && chat.members[userId]
      ? chat.members[userId].lastOpened
      : false

  if (!chat.messages) {
    return 0
  } else if (!lastOpened) {
    return Object.keys(chat.messages).length
  }

  const chatMessages = Object.keys(chat.messages).map(messageId =>
    getById(state, messageId),
  )
  const unseenMessages = chatMessages.filter(
    message => message && message.createdAt > lastOpened,
  )
  return unseenMessages.length
}

export const getUnseenMessagesCount = (state: Store, offers: Offer[]) => {
  const user: User | undefined = userSelectors.getUser(state)

  let unseenTotal = 0
  offers.forEach(offer => {
    if (offer.chat && user) {
      const chat = getChatById(state, offer.chat)
      if (chat) {
        const unseenCountForChat = getUnseenCountForChat(state, chat, user.id)
        unseenTotal += unseenCountForChat
      }
    }
  })

  return unseenTotal
}

export const getUnseenMessagesCountForChat = (state: Store, id?: string) => {
  if (!id) {
    return 0
  }
  const chat = getChatById(state, id)
  if (!chat) {
    return 0
  }
  const user: User | undefined = userSelectors.getUser(state)
  if (user) {
    return getUnseenCountForChat(state, chat, user.id)
  }
  return 0
}
