import React from "react"
import { View } from "react-native"
import { List, Text, Separator } from "native-base"
import I18n from "i18n"
import _ from "lodash"
import styles from "./styles"
import { TempOffer } from "modules/Seller/CreateOffer/types"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import { PriceInput } from "./PriceInput"
import DurationInput from "./DurationInput"
import DateTimeInput from "./DateTimeInput"
import { OfferRequestDateQuestion } from "database_types/offerRequestQuestions"

const SeparatorElement = ({ text }: { text: string }) => (
  <Separator style={styles.separator}>
    <Text style={styles.separatorText}>{text}</Text>
  </Separator>
)

interface Props {
  offerRequest?: OfferRequest
  tempOffer?: TempOffer
  onPriceChanged: (pricePerHour: number, offerRequest: OfferRequest) => void
  onDurationChanged: (duration: number, offerRequest: OfferRequest) => void
  openCalendar: (question: OfferRequestDateQuestion) => void
}

export default ({
  offerRequest,
  tempOffer,
  onPriceChanged,
  onDurationChanged,
  openCalendar,
}: Props) => {
  if (!offerRequest) {
    return null
  }

  const durations = [...Array(30)].map((item, idx) => 0.5 * (idx + 1))
  let dateQuestion: OfferRequestDateQuestion | false = false
  Object.values(offerRequest.questions).forEach(question => {
    if (question.type === "date") {
      dateQuestion = question
    }
  })
  return (
    <List style={styles.form}>
      <View>
        <SeparatorElement
          text={
            dateQuestion !== false
              ? I18n.t("hourly_rate").toUpperCase()
              : I18n.t("price").toUpperCase()
          }
        />
        <PriceInput
          tempOffer={tempOffer}
          onPriceChanged={(price: number) => {
            onPriceChanged(price, offerRequest)
          }}
          currencyPlaceholder={dateQuestion !== false ? "€/h" : "€"}
        />
      </View>
      {dateQuestion !== false ? (
        <View>
          <SeparatorElement text={I18n.t("estimate_duration").toUpperCase()} />
          <DurationInput
            items={durations}
            onDurationChanged={(duration: number) =>
              onDurationChanged(duration, offerRequest)
            }
            selectedValue={tempOffer ? tempOffer.duration : undefined}
            isFixed={tempOffer ? !!tempOffer.isFixedDuration : false}
          />
        </View>
      ) : null}
      {dateQuestion !== false ? (
        <View>
          <SeparatorElement text={I18n.t("select_date_time").toUpperCase()} />
          <DateTimeInput
            tempOffer={tempOffer}
            openCalendar={() => {
              if (dateQuestion !== false) {
                openCalendar(dateQuestion)
              }
            }}
          />
        </View>
      ) : null}
    </List>
  )
}
