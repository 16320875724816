import { connect } from "react-redux"
import { durationChange } from "modules/Buyer/Questions/actions"
import DurationInput from "./view"
import { ListPicker } from "modules/Buyer/Questions/types"

interface OwnProps {
  question: ListPicker
}

const mapStateToProps = null

const mapDispatchToProps = {
  durationChange,
}

const mergeProps = (
  stateProps: typeof mapStateToProps,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...dispatchProps,
  ...ownProps,
  onDurationChanged: (duration: number) => {
    dispatchProps.durationChange({
      questionId: ownProps.question.id,
      value: duration,
    })
  },
  selectedValue: ownProps.question.value || null,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DurationInput)
