import { connect } from "react-redux"
import routeNames from "components/navigators/constants"
import View from "./view"
import { Navigation } from "components/types"
import { CommonActions } from "@react-navigation/native"
interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = null
const mapDispatchToProps = null
const mergeProps = (
  stateProps: typeof mapStateToProps,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...ownProps,
  createNow: () => {
    ownProps.navigation.dispatch(
      CommonActions.navigate(routeNames.SELLER_CREATE.FIELD_OF_EXPERTISE),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
