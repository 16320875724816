import { all, takeEvery, fork, put } from "redux-saga/effects"
import { FIREBASE_PATH as FIREBASE_PATH_FOR_OFFERS } from "../Offers/constants"
import firebase from "firebase"
import { offerCreated, CreateOffer } from "./actions"
import { CREATE_OFFER } from "./actionTypes"
import { OfferDB } from "database_types/offer"

export function* createOfferWorker(action: CreateOffer) {
  // tslint:disable-next-line:no-unsafe-any
  const createRef: firebase.database.ThenableReference = yield firebase
    .database()
    .ref(FIREBASE_PATH_FOR_OFFERS)
    .push()

  const offer: OfferDB = {
    createdAt: firebase.database.ServerValue.TIMESTAMP as number,
    status: "open",
    seenByBuyer: false,
    seenBySeller: true,
    agreedStartTime: action.offer.startTime,
    original: {
      pricePerHour: action.offer.pricePerHour,
      purchaseDuration: action.offer.duration,
      createdAt: firebase.database.ServerValue.TIMESTAMP as number,
    },
    buyerProfile: action.offer.buyerProfile,
    sellerProfile: action.offer.sellerProfile,
    pricePerHour: action.offer.pricePerHour,
    offerRequest: action.offer.offerRequest,
  }

  yield createRef.set(offer)

  if (createRef.key) {
    // tslint:disable-next-line:no-unsafe-any
    const createdRef: firebase.database.Reference = yield firebase
      .database()
      .ref(FIREBASE_PATH_FOR_OFFERS)
      .child(createRef.key)
    // tslint:disable-next-line:no-unsafe-any
    const snapshot: firebase.database.DataSnapshot = yield createdRef.once(
      "value",
    )

    yield put(
      offerCreated({
        id: createdRef.key,
        ...snapshot.val(),
      }),
    )
  } else {
    throw new Error(
      `createOfferWorker: could not write to database ${createRef.key}`,
    )
  }
}

export function* createOfferSaga() {
  yield takeEvery(CREATE_OFFER, createOfferWorker)
}

export default function*() {
  yield all([fork(createOfferSaga)])
}
