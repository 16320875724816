import { StyleSheet } from "react-native"
import Colors from "constants/Colors"
import { spinnerText } from "constants/Fonts"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.containerBackground,
  },
  spinner: {
    marginTop: 100,
  },
  text: {
    textAlign: "center",
    marginTop: 15,
    ...spinnerText,
  },
})
