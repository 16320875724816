import React from "react"
import { View, Text } from "react-native"
import { Container, Content, Header } from "native-base"
import Logo from "components/navigators/Logo"
import I18n from "i18n"
import styles from "./styles"

export default () => {
  return (
    <Container>
      <Header>
        <Logo />
      </Header>
      <Content>
        <View style={styles.container}>
          <Text style={styles.title}>{I18n.t("blocked")}</Text>
          <Text style={styles.message}>{I18n.t("user_blocked_message")}</Text>
          <Text style={styles.instruction}>{I18n.t("contact_message")}</Text>
        </View>
      </Content>
    </Container>
  )
}
