import React from "react"
import { View } from "react-native"
import { ListItem, Text, Button } from "native-base"
import styles from "./styles"
import { NumberQuestion } from "modules/Buyer/Questions/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface Props {
  question: NumberQuestion
  decreaseValue: () => void
  increaseValue: () => void
}

export default ({ question, decreaseValue, increaseValue }: Props) => {
  return (
    <ListItem style={styles.listItem}>
      <Text>{`${question.value || 0} ${question.placeholder &&
        getLocaLizedName(question.placeholder)}`}</Text>
      <View style={styles.buttonsContainer}>
        <Button
          light
          style={[styles.button, styles.buttonLeft]}
          onPress={decreaseValue}
        >
          <Text style={styles.buttonText}>-</Text>
        </Button>
        <Button
          light
          style={[styles.button, styles.buttonRight]}
          onPress={increaseValue}
        >
          <Text style={styles.buttonText}>+</Text>
        </Button>
      </View>
    </ListItem>
  )
}
