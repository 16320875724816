import { StyleSheet, Dimensions } from "react-native"

const windowWidth = Dimensions.get("window").width

export default StyleSheet.create({
  container: {
    backgroundColor: "black",
    opacity: 0.85,
    alignSelf: "center",
    paddingVertical: 15,
    paddingHorizontal: 30,
    width: windowWidth,
  },
  titleRow: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 10,
  },
  title: {
    color: "white",
    fontFamily: "open-sans-bold",
    fontSize: 16,
  },
  bodyRow: {
    flex: 1,
    alignItems: "flex-start",
    marginBottom: 10,
  },
  body: {
    color: "white",
    fontFamily: "open-sans-regular",
  },
})
