import { connect } from "react-redux"
import routeNames from "components/navigators/constants"
import View from "./view"
import { FeedItem } from "modules/Buyer/Feed/types"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { getCategoryById } from "modules/Buyer/Categories/selectors"
import { categorySelected } from "modules/Buyer/Categories/actions"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface OwnProps {
  item: FeedItem
  navigation: Navigation
}

const mapStateToProps = (state: Store, { item: { categoryId } }: OwnProps) => ({
  category: getCategoryById(state, categoryId),
})

const mapDispatchToProps = {
  categorySelected,
}
let lock: boolean
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => {
  return {
    ...ownProps,
    onPress: () => {
      if (lock || !stateProps.category) {
        return
      }
      lock = true
      dispatchProps.categorySelected(ownProps.item.categoryId)
      ownProps.navigation.navigate(
        routeNames.BUYER.CREATE_OFFER_REQUEST_QUESTIONS,
        {
          name: getLocaLizedName(stateProps.category.name),
        },
      )
      setTimeout(() => {
        lock = false
      }, 500)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
