import { connect } from "react-redux"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { getSelectedOfferRequestForSeller } from "modules/Seller/OfferRequests/selectors"

const mapStateToProps = (state: Store) => {
  const offerRequest = getSelectedOfferRequestForSeller(state)
  return {
    offerRequest,
  }
}

export default connect(mapStateToProps)(View)
