import React, { useEffect } from "react"
import { Text, TouchableOpacity, View } from "react-native"
import { Ionicons } from "@expo/vector-icons"
import _ from "lodash"
import Colors from "constants/Colors"
import styles from "./styles"
import {
  TextAreaQuestion,
  NumberInputQuestion,
} from "modules/Buyer/Questions/types"

interface Props {
  question: TextAreaQuestion | NumberInputQuestion
  value?: string | number | null
  openInputTextModal: () => void
  setDefaultValue: () => void
}

export default ({
  question,
  value,
  openInputTextModal,
  setDefaultValue,
}: Props) => {
  useEffect(() => {
    setDefaultValue()
  }, [])

  return (
    <TouchableOpacity onPress={openInputTextModal} style={styles.listItem}>
      <View style={styles.iconContainer}>
        <Ionicons name="md-create" style={styles.icon} size={20} />
      </View>
      <Text
        style={[
          styles.textarea,
          question.value ? null : { color: Colors.grey },
        ]}
      >
        {value}
      </Text>
    </TouchableOpacity>
  )
}
