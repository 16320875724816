import React from "react"
import moment from "moment"
import { View, Dimensions } from "react-native"
import { Button, Container, Content, Text, Picker, Icon } from "native-base"
import I18n from "i18n"
import Colors from "constants/Colors"
import styles from "./styles"
import { Offer } from "modules/Buyer/Offers/types"

const { width } = Dimensions.get("window")

interface SelectDurationProps {
  selectedDuration: number
  onDurationChanged: (duration: number) => void
}
const SelectDuration = ({
  selectedDuration,
  onDurationChanged,
}: SelectDurationProps) => {
  const durations = [...Array(30)].map((item, idx) => 0.5 * (idx + 1))
  return (
    <View style={styles.duration}>
      <Text style={styles.durationLabel}>{I18n.t("select_to_extend")}</Text>
      <Picker
        note={false}
        iosIcon={<Icon name="arrow-down" />}
        style={[styles.picker, { width }]}
        onValueChange={duration => onDurationChanged(duration as number)}
        placeholder={I18n.t("select_duration")}
        placeholderStyle={{ color: Colors.noteColor, width }}
        selectedValue={selectedDuration}
      >
        {durations.map(duration => (
          <Picker.Item
            label={`${duration} ${
              duration <= 1 ? I18n.t("hour") : I18n.t("hours")
            }`}
            value={duration}
            key={duration}
          />
        ))}
      </Picker>
    </View>
  )
}

interface DetailsProps {
  offer: Offer
  categoryName?: string
  displayName?: string
}
const Details = ({ offer, categoryName, displayName }: DetailsProps) => {
  return (
    <View style={styles.details}>
      <View style={styles.detailsRow}>
        <Text style={styles.detailsLabel}>{I18n.t("buyer")}</Text>
        <Text style={styles.detailsValue}>{displayName}</Text>
      </View>
      <View style={styles.detailsRow}>
        <Text style={styles.detailsLabel}>{I18n.t("service")}</Text>
        <Text style={styles.detailsValue}>{categoryName}</Text>
      </View>
      <View style={styles.detailsRow}>
        <Text style={styles.detailsLabel}>{I18n.t("actualStartTime")}</Text>
        <Text style={styles.detailsValue}>
          {moment(offer.actualStartTime).format("kk:mm")}
        </Text>
      </View>
      <View style={styles.detailsRow}>
        <Text style={styles.detailsLabel}>{I18n.t("date")}</Text>
        <Text style={styles.detailsValue}>
          {moment(offer.actualStartTime).format("dddd D.M.")}
        </Text>
      </View>
      <View style={[styles.detailsRow, { marginBottom: 0 }]}>
        <Text style={styles.detailsLabel}>{I18n.t("hour_price")}</Text>
        <Text style={styles.detailsValue}>{`${offer.pricePerHour}€`}</Text>
      </View>
    </View>
  )
}

interface ExtendOfferProps {
  offer?: Offer
  displayName?: string
  categoryName?: string
  requestJobExtension: (duration: number) => void
}

class ExtendOffer extends React.PureComponent<ExtendOfferProps> {
  state: {
    selectedDuration: number
  }
  constructor(props: ExtendOfferProps) {
    super(props)
    this.state = {
      selectedDuration: 1,
    }
  }

  onDurationChanged = (duration: number) => {
    this.setState({
      selectedDuration: duration,
    })
  }

  onConfirmExtend = () => {
    const { selectedDuration } = this.state
    this.props.requestJobExtension(selectedDuration)
  }

  render() {
    const { offer, displayName, categoryName } = this.props
    if (!offer) {
      return null
    }

    const { selectedDuration } = this.state
    const totalAmount = (selectedDuration * offer.pricePerHour).toFixed(2)
    return (
      <Container style={styles.container}>
        <Content>
          <Details
            offer={offer}
            categoryName={categoryName}
            displayName={displayName}
          />
          <SelectDuration
            selectedDuration={selectedDuration}
            onDurationChanged={this.onDurationChanged}
          />
        </Content>
        <View style={styles.buttonWrap}>
          <Button
            block
            rounded
            style={styles.button}
            onPress={this.onConfirmExtend}
          >
            <Text>
              {`${I18n.t("confirm")}`} {totalAmount && `${totalAmount}€`}
            </Text>
          </Button>
        </View>
      </Container>
    )
  }
}
export default ExtendOffer
