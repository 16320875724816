import { StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.contentBackground,
  },
  dateTimeContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  iconTextContainer: {
    alignItems: "center",
    margin: 10,
  },
  dateTimeText: {
    fontSize: 30,
    margin: 10,
    color: Colors.grey,
  },
})
