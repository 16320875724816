import React from "react"
import I18n from "i18n"
import { Dimensions, View } from "react-native"
import { Icon, Text, ActionSheet, ListItem } from "native-base"
import { Ionicons } from "@expo/vector-icons"
import Carousel from "react-native-snap-carousel"
import RemovableImage from "components/views/Shared/Images/RemovableImage"
import styles from "./styles"
import { ImageQuestion } from "modules/Buyer/Questions/types"

const windowWidth = Dimensions.get("window").width
const thumbnailSize = 100

const ImageCarousel = ({
  images,
  removeImage,
}: {
  images: ImageQuestion["images"]
  removeImage: (imageId: string) => void
}) => {
  if (!images) {
    return null
  }
  const ids = Object.keys(images)
  if (!ids.length) {
    return null
  }
  const Item = ({ item }: { item: string }) => (
    <RemovableImage
      imageId={item}
      remove={() => removeImage(item)}
      width={thumbnailSize}
      height={thumbnailSize}
      style={styles.carouselImage}
    />
  )
  return (
    <View style={[styles.carousel, { height: thumbnailSize }]}>
      <Carousel
        data={ids}
        renderItem={Item}
        sliderWidth={windowWidth}
        itemWidth={windowWidth / 2.5}
        activeSlideAlignment="start"
        inactiveSlideOpacity={1}
        inactiveSlideScale={1}
        swipeThreshold={1}
      />
    </View>
  )
}

interface Props {
  question: ImageQuestion
  addImageFromCamera: () => void
  addImageFromGallery: () => void
  removeImage: (props: { imageId: string; questionId: string }) => void
}

export default ({
  question,
  addImageFromCamera,
  addImageFromGallery,
  removeImage,
}: Props) => {
  const addImagePicker = () => {
    ActionSheet.show(
      {
        options: ["Ota kuva kameralla", "Valitse galleriasta", "Peruuta"],
        cancelButtonIndex: 3,
        title: "Lisää kuva",
      },
      index => {
        if (index === 0) {
          addImageFromCamera()
        } else if (index === 1) {
          addImageFromGallery()
        }
      },
    )
  }
  return (
    <View>
      <ListItem button onPress={addImagePicker} style={styles.item}>
        <View style={styles.iconContainer}>
          <Ionicons name="ios-images" style={styles.icon} />
        </View>
        <Text style={styles.label}>{I18n.t("add_images")}</Text>
        <View style={styles.iconContainer}>
          <Icon name="arrow-forward" style={styles.icon} />
        </View>
      </ListItem>
      <ImageCarousel
        images={question.images}
        removeImage={id =>
          removeImage({
            imageId: id,
            questionId: question.id,
          })
        }
      />
    </View>
  )
}
