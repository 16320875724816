import _ from "lodash"
import { Store } from "utilities/Redux/store"
import { Category } from "../Categories/types"
import { Question, DateQuestion, QuestionOption } from "./types"
import { sortByOrder } from "utilities/sortBy"
import {
  OfferRequestQuestion,
  OfferRequestQuestionOption,
} from "database_types/offerRequestQuestions"

export const getSuccessModalVisible = (state: Store) =>
  state.questions.successModalVisible

export const getInputModalQuestionId = (state: Store) =>
  state.questions.inputModalQuestionId

export const getQuestionById = (state: Store, id: string) =>
  state.questions.questions[id] ? state.questions.questions[id] : undefined

export const getScrollStatus = (state: Store) => state.questions.scrollToBottom

export const getScrollEnabled = (state: Store) => state.questions.scrollEnabled
export const getScrollPosition = (state: Store) =>
  state.questions.scrollPosition

export const getSortedQuestionsIdsForCategory = (
  state: Store,
  category: Category,
) => {
  const questions: Question[] = []
  category.questions.forEach(id => {
    const question = getQuestionById(state, id)
    if (question) {
      questions.push(question)
    }
  })
  const orderedQuestions = sortByOrder(questions, "desc")
  return orderedQuestions.map(question => question.id)
}

export const getQuestionsForCategory = (state: Store, category: Category) => {
  const questions: Question[] = []
  category.questions.forEach(id => {
    const question = getQuestionById(state, id)
    if (question) {
      questions.push(question)
    }
  })
  return questions
}

const getOfferRequestQuestionOptionQuestions: (
  state: Store,
  questions: QuestionOption["questions"],
) => OfferRequestQuestion[] | undefined = (state, questions) => {
  if (!questions) {
    return
  } else {
    const questionOptionQuestions: OfferRequestQuestion[] = []
    questions.forEach(id => {
      const question = getQuestionById(state, id)
      if (question) {
        const questionNested = getOfferRequestQuestion(state, question)
        if (questionNested) {
          questionOptionQuestions.push(questionNested)
        }
      }
    })
    return questionOptionQuestions
  }
}

const getOfferRequestQuestionOption: (
  state: Store,
  option: QuestionOption,
) => OfferRequestQuestionOption = (state, option) => {
  return {
    id: option.id,
    checked: option.checked || null,
    name: option.name || null,
    order: option.order || null,
    questions: option.questions
      ? getOfferRequestQuestionOptionQuestions(state, option.questions) || null
      : null,
  }
}

const getOfferRequestQuestion: (
  state: Store,
  question: Question,
) => OfferRequestQuestion = (state, question) => {
  if (question.type === "radio") {
    return {
      ...question,
      name: question.name || null,
      selectedOption: question.selectedOption || null,
      options: Object.values(question.options).map(option =>
        getOfferRequestQuestionOption(state, option),
      ),
    }
  } else if (question.type === "checkbox") {
    return {
      ...question,
      name: question.name || null,
      options: Object.values(question.options).map(option =>
        getOfferRequestQuestionOption(state, option),
      ),
    }
  } else if (question.type === "date") {
    return {
      ...question,
      name: question.name || null,
      preferredTime: question.preferredTime || null,
      suitableTimesCount: question.suitableTimesCount || null,
      suitableTimes: question.suitableTimes || null,
    }
  } else if (question.type === "image") {
    return {
      ...question,
      name: question.name || null,
      images: question.images || null,
      imageCount: question.imageCount || null,
    }
  } else if (question.type === "number") {
    return {
      ...question,
      name: question.name || null,
      placeholder: question.placeholder || null,
      value: question.value || question.value === 0 ? question.value : null,
    }
  } else if (question.type === "list") {
    return {
      ...question,
      name: question.name || null,
      placeholder: question.placeholder || null,
      value: question.value || null,
    }
  } else {
    return {
      ...question,
      name: question.name || null,
      placeholder: question.placeholder || null,
      value: question.value || null,
    }
  }
}

export const getQuestionsForOfferRequest: (
  state: Store,
  category: Category,
) => OfferRequestQuestion[] = (state, category) => {
  const questions = getQuestionsForCategory(state, category)
  let offerRequestQuestions: OfferRequestQuestion[] = []
  offerRequestQuestions = Object.values(questions).map(question =>
    getOfferRequestQuestion(state, question),
  )
  return offerRequestQuestions
}

export const getSelectedDateQuestion = (state: Store) =>
  state.questions.selectedDateQuestionId
    ? (getQuestionById(
        state,
        state.questions.selectedDateQuestionId,
      ) as DateQuestion)
    : undefined

export const isNotValid = (state: Store, questionId: string) => {
  const question = getQuestionById(state, questionId)

  if (!question || !!question.optional) {
    return false
  }

  switch (question.type) {
    case "checkbox":
      return _.filter(question.options, { checked: true }).length < 1
    case "radio":
      return !question.selectedOption
    case "number_input":
    case "textarea":
    case "number":
      return !question.value && question.value !== 0
    case "date":
      return !question.suitableTimesCount || question.suitableTimesCount < 1
    default:
      return false
  }
}

export const getErrorsForQuestions = (state: Store, questions: Question[]) =>
  Object.values(
    questions
      .filter(question => isNotValid(state, question.id))
      .map(question => question.id),
  )

export const getErrorsForCategory = (state: Store, category: Category) => {
  const categoryQuestions = getQuestionsForCategory(state, category)
  if (!categoryQuestions) {
    return
  }

  const errors = getErrorsForQuestions(state, categoryQuestions)

  categoryQuestions.forEach(question => {
    if (question.type === "checkbox" || question.type === "radio") {
      Object.values(question.options).forEach(option => {
        if (option.checked && option.questions) {
          const optionQuestions: Question[] = []
          Object.values(option.questions).forEach(id => {
            const optionQuestion = getQuestionById(state, id)
            if (optionQuestion) {
              optionQuestions.push(optionQuestion)
            }
          })
          errors.concat(getErrorsForQuestions(state, optionQuestions))
        }
      })
    }
  })

  return errors
}

export const categoryHasInvalidQuestions = (
  state: Store,
  category: Category,
) => {
  const errors = getErrorsForCategory(state, category)
  return errors && errors.length > 0
}
