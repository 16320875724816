import { getEventChannelForFirebaseRef } from "utilities/Redux/sagaHelpers"
import firebase from "firebase"
import {
  all,
  call,
  take,
  takeEvery,
  fork,
  put,
  select,
} from "redux-saga/effects"
import { getUser } from "../User/selectors"
import { OFFER_UPDATED as BUYER_OFFER_UPDATED } from "../Buyer/Offers/actionTypes"
import { OFFER_UPDATED as SELLER_OFFER_UPDATED } from "../Seller/Offers/actionTypes"
import { OFFER_CREATED as SELLER_OFFER_CREATED } from "../Seller/CreateOffer/actionTypes"
import { chatCreated, chatUpdated, ChatOpenedByUser } from "./actions"
import { CHAT_OPENED_BY_USER } from "./actionTypes"
import { CHAT_MESSAGE_SENT } from "../ChatMessages/actionTypes"
import { FIREBASE_PATH } from "./constants"
import { ChatMessageSent } from "../ChatMessages/actions"
import { OfferCreated } from "../Seller/CreateOffer/actions"
import { OfferUpdated as SellerOfferUpdated } from "../Seller/Offers/actions"
import { OfferUpdated as BuyerOfferUpdated } from "../Buyer/Offers/actions"
import { User } from "../User/types"
import { ChatDB } from "database_types/chat"

const subscribed = {}
export function* subscribeToChatWorker(
  action: SellerOfferUpdated | BuyerOfferUpdated,
) {
  if (!action.offer.chat || subscribed[action.offer.chat]) {
    return
  }
  yield (subscribed[action.offer.chat] = true)

  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offer.chat)
  const channel = yield call(getEventChannelForFirebaseRef, ref)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const { values: chat }: { values: ChatDB } = yield take(channel)
    if (chat) {
      yield put(
        chatUpdated({
          id: action.offer.chat,
          ...chat,
        }),
      )
    }
  }
}

export function* subscribeToChatSaga() {
  yield all([
    takeEvery(BUYER_OFFER_UPDATED, subscribeToChatWorker),
    takeEvery(SELLER_OFFER_UPDATED, subscribeToChatWorker),
  ])
}

export function* createChatWorker(action: OfferCreated) {
  // tslint:disable-next-line:no-unsafe-any
  const chatRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .push()
  yield chatRef.set({
    createdAt: firebase.database.ServerValue.TIMESTAMP,
    offer: action.offer.id,
    members: {
      [action.offer.sellerProfile]: {
        role: "seller",
      },
      [action.offer.buyerProfile]: {
        role: "buyer",
      },
    },
  })
  if (chatRef.key) {
    yield put(chatCreated({ chatId: chatRef.key, offerId: action.offer.id }))
  }
}

export function* createChatSaga() {
  yield takeEvery(SELLER_OFFER_CREATED, createChatWorker)
}

export function* chatOpenedByUserWorker(action: ChatOpenedByUser) {
  // tslint:disable-next-line:no-unsafe-any
  const user: User | undefined = yield select(getUser)
  if (user) {
    yield firebase
      .database()
      .ref(FIREBASE_PATH)
      .child(action.chatId)
      .child("members")
      .child(user.id)
      .child("lastOpened")
      .set(firebase.database.ServerValue.TIMESTAMP)
  }
}

export function* chatOpenedByUserSaga() {
  yield takeEvery(CHAT_OPENED_BY_USER, chatOpenedByUserWorker)
}

export function* addMessageToChatWorker(action: ChatMessageSent) {
  // tslint:disable-next-line:no-unsafe-any
  const user: User | undefined = yield select(getUser)
  if (user) {
    // tslint:disable-next-line:no-unsafe-any
    const chatRef: firebase.database.Reference = yield firebase
      .database()
      .ref(FIREBASE_PATH)
      .child(action.message.chat)
    const updates = {}
    updates[`/messages/${action.message.id}/createdAt`] =
      action.message.createdAt
    updates[`/members/${user.id}/lastOpened`] =
      firebase.database.ServerValue.TIMESTAMP
    yield chatRef.update(updates)
  }
}

export function* sendMessageSaga() {
  yield takeEvery(CHAT_MESSAGE_SENT, addMessageToChatWorker)
}

export default function*() {
  yield all([
    fork(subscribeToChatSaga),
    fork(createChatSaga),
    fork(chatOpenedByUserSaga),
    fork(sendMessageSaga),
  ])
}
