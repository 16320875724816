import { StyleSheet } from "react-native"
import Colors from "constants/Colors"
import { buttonText, titleText } from "constants/Fonts"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.contentBackground,
  },
  text: {
    ...titleText,
    textAlign: "center",
    marginTop: 90,
    padding: 30,
  },
  button: {
    backgroundColor: Colors.brandYellow,
    margin: 15,
  },
  buttonText: {
    ...buttonText,
    color: "#ffffff",
  },
})
