import React from "react"
import _ from "lodash"
import { Dimensions, View } from "react-native"
import { Text } from "native-base"
import moment, { Moment } from "moment"
import styles from "./styles"
import CalendarButton from "./CalendarButton"

const windowWidth = Dimensions.get("window").width
const itemWidth = windowWidth / 2.2
const dateFormat = "YYYY-MM-DD"
const timeSlots = [
  ["08", "12"],
  ["12", "16"],
  ["16", "20"],
  ["20", "22"],
]

interface Props {
  day: Moment
  selectedDates: string[]
  onTimeSelected: (props: {
    dateKey: string
    day: string
    startTime: Moment
    endTime: Moment
  }) => void
  preferredDay: Moment
  preferredTimeHour: number
  isFirst: boolean
}

export default ({
  day,
  selectedDates,
  onTimeSelected,
  preferredDay,
  preferredTimeHour,
  isFirst,
}: Props) => {
  const formattedDate = day.format(dateFormat)
  const currentHour = moment().hour()
  return (
    <View style={[styles.column, { width: itemWidth }]}>
      <View style={styles.row}>
        <Text style={styles.rowTitle}>
          {_.capitalize(day.format("dddd D.M."))}
        </Text>
      </View>
      {_.map(timeSlots, time => {
        const startHour = time[0]
        const endHour = time[1]
        const dateKey = `${formattedDate}_${startHour}_${endHour}`
        return (
          <View style={styles.row} key={dateKey}>
            <CalendarButton
              enabled={!isFirst || currentHour < Number(endHour)}
              selected={
                _.includes(selectedDates, dateKey) ||
                (preferredDay.isSame(day) &&
                  preferredTimeHour >= Number(startHour) &&
                  preferredTimeHour < Number(endHour))
              }
              onPress={() =>
                onTimeSelected({
                  dateKey,
                  day: formattedDate,
                  startTime: day
                    .clone()
                    .set("hour", Number(startHour))
                    .set("minute", 0)
                    .set("second", 0)
                    .set("millisecond", 0),
                  endTime: day
                    .clone()
                    .set("hour", Number(endHour))
                    .set("minute", 0)
                    .set("second", 0)
                    .set("millisecond", 0),
                })
              }
              label={`${startHour}-${endHour}`}
            />
          </View>
        )
      })}
    </View>
  )
}
