import React from "react"
import _ from "lodash"
import { Button, Container, Text } from "native-base"
import { View } from "react-native"
import I18n from "i18n"
import EmptyList from "components/views/Shared/EmptyList"
import styles from "./styles"
import TimeSlots from "./TimeSlots"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"

interface Props {
  confirmStartTime: (offerRequest: OfferRequest) => void
  offerRequest?: OfferRequest
  selectedStartTime?: string
  isOverlapping?: boolean
}

export default ({
  confirmStartTime,
  offerRequest,
  selectedStartTime,
  isOverlapping,
}: Props) => {
  if (!offerRequest) {
    return null
  }

  if (offerRequest.status !== "open") {
    return (
      <EmptyList
        fullPage
        emptyTextMessage={I18n.t("offer_request_not_available")}
      />
    )
  }

  return (
    <Container style={styles.container}>
      <TimeSlots />
      <View style={styles.buttonWrap}>
        {selectedStartTime && !isOverlapping ? (
          <Button
            block
            rounded
            onPress={() => confirmStartTime(offerRequest)}
            style={styles.button}
          >
            <Text>{I18n.t("confirm_time")}</Text>
          </Button>
        ) : (
          <Button block rounded bordered style={styles.buttonDisabled}>
            <Text style={styles.buttonTextDisabled}>
              {isOverlapping ? I18n.t("overlapping") : I18n.t("select_time")}
            </Text>
          </Button>
        )}
      </View>
    </Container>
  )
}
