import React from "react"
import _ from "lodash"
import {
  View,
  Dimensions,
  TouchableHighlight,
  Modal,
  ScrollView,
} from "react-native"
import { Text } from "native-base"
import Carousel, { Pagination } from "react-native-snap-carousel"
import Image from "../BasicImage"
import styles from "./styles"

const cardHeight = Dimensions.get("window").height / 1.5
const cardWidth = Dimensions.get("window").width
const thumbnailSize = 100

const CarouselItem = ({ item }: { item: string }) => {
  return (
    <Image
      key={item}
      imageId={item}
      width={cardWidth}
      height={cardHeight}
      style={{ padding: 10 }}
    />
  )
}

const Thumbnails = ({
  imageIds,
  onPress,
}: {
  imageIds: string[]
  onPress: (index: number) => void
}) => {
  return (
    <ScrollView horizontal>
      {_.map(imageIds, (id, idx) => {
        return (
          <Image
            key={id}
            imageId={id}
            width={thumbnailSize}
            height={thumbnailSize}
            style={{ marginRight: 5 }}
            onPress={() => onPress(idx)}
          />
        )
      })}
    </ScrollView>
  )
}

interface ImageRowProps {
  imageIds: string[]
}

interface State {
  showCarousel: boolean
  activeSlide: number
}

export default class ImageRow extends React.Component<ImageRowProps> {
  state: State
  constructor(props: ImageRowProps) {
    super(props)
    this.state = {
      showCarousel: false,
      activeSlide: 0,
    }
  }

  onPress = (index: number) => {
    this.setState({ showCarousel: true, activeSlide: index })
  }

  onClose = () => {
    this.setState({ showCarousel: false })
  }

  renderModalWithCarousel() {
    return (
      <Modal animationType="fade" transparent visible={this.state.showCarousel}>
        <View style={styles.modal} />
        <View style={styles.carouselContainer}>
          <TouchableHighlight
            onPress={this.onClose}
            style={styles.closeTextTouchable}
          >
            <Text style={styles.closeText}>Close</Text>
          </TouchableHighlight>
          <View style={{ width: cardWidth, height: cardHeight }}>
            <Carousel
              data={this.props.imageIds}
              renderItem={CarouselItem}
              sliderWidth={cardWidth}
              itemWidth={cardWidth}
              activeSlideAlignment="start"
              inactiveSlideOpacity={1}
              inactiveSlideScale={1}
              swipeThreshold={1}
              firstItem={this.state.activeSlide}
              onSnapToItem={(index: number) =>
                this.setState({ activeSlide: index })
              }
            />
          </View>
        </View>
        <Pagination
          dotsLength={this.props.imageIds.length}
          activeDotIndex={this.state.activeSlide}
          containerStyle={styles.dotsContainer}
          dotStyle={styles.dot}
          inactiveDotStyle={
            {
              // Define styles for inactive dots here
            }
          }
          inactiveDotOpacity={0.4}
          inactiveDotScale={0.6}
        />
      </Modal>
    )
  }

  render() {
    const { imageIds } = this.props
    return (
      <View>
        {this.renderModalWithCarousel()}
        <Thumbnails imageIds={imageIds} onPress={this.onPress} />
      </View>
    )
  }
}
