import Colors from "constants/Colors"
import { separatorText } from "constants/Fonts"
import {
  StyleSheet,
  ViewStyle,
  ImageStyle,
  TextStyle,
  PixelRatio,
} from "react-native"

interface Styles {
  flexContainer: ViewStyle
  iconContainer: ViewStyle
  icon: ImageStyle
  label: TextStyle
  button: ViewStyle
  buttonText: TextStyle
  value: TextStyle
  placeholder: TextStyle
  buttonIcon: ImageStyle
  separator: ViewStyle
  separatorText: TextStyle
}

export default StyleSheet.create<Styles>({
  flexContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
    backgroundColor: Colors.contentBackground,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
  },
  iconContainer: {
    marginLeft: 15,
  },
  icon: {
    color: Colors.grey,
  },
  label: {
    marginLeft: 15,
    color: Colors.grey,
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
    marginLeft: "auto",
  },
  buttonText: {
    color: Colors.darkerGrey,
    paddingRight: 30,
  },
  value: {
    color: Colors.textColor,
    paddingRight: 30,
  },
  placeholder: {
    color: Colors.grey,
  },
  buttonIcon: {
    color: Colors.darkerGrey,
    fontSize: 18,
  },
  separator: {
    backgroundColor: Colors.containerBackground,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
    marginTop: 15,
  },
  separatorText: {
    ...separatorText,
  },
})
