import React from "react"
import { View, Button, Container, Content, Text, Toast, Textarea } from "native-base"
import { validateEmail, validateBirthdate } from "utilities/validator"
import I18n from "i18n"
import ProfileImage from "components/views/Shared/ProfileImage"
import {
  TextRow,
  TextArea,
  DateRow,
  PickerRow,
  NumberRow,
} from "components/views/Shared/Input"
import styles from "./styles"
import { SellerProfilePersonalInfo } from "modules/SellerProfiles/types"
import { Navigation } from "components/types"

export interface Props extends SellerProfilePersonalInfo {
  next: (data: SellerProfilePersonalInfo) => void
  photoUrl?: string // not editable on this page, hence not on state
  navigation: Navigation
  langOptions: []
}

export class CreateSellerProfile extends React.PureComponent<Props> {
  state: SellerProfilePersonalInfo

  constructor(props: Props) {
    super(props)
    const {
      companyEmail,
      companyName,
      birthDate,
      ssn,
      yearsOfExperience,
      langs,
      
    } = this.props
    this.state = {
      companyEmail,
      ssn,
      companyName,
      birthDate,
      yearsOfExperience,
      langs,
    
    }
    this.nextPressed = this.nextPressed.bind(this)
    this.hasEmptyValues = this.hasEmptyValues.bind(this)
    
  }

  nextPressed() {
    const sellerProfile: SellerProfilePersonalInfo = this.state
    if (
      sellerProfile.companyName.trim().length &&
      validateEmail(sellerProfile.companyEmail)
    ) {
      if (validateBirthdate(sellerProfile.birthDate)) {
        this.props.next(sellerProfile)
      } else {
        Toast.show({
          text: I18n.t("Sorry, you must be at least 18 to apply"),
          position: "bottom",
          duration: 1500,
          style: styles.toast,
          textStyle: styles.toastText,
        })
      }
    } else {
      Toast.show({
        text: I18n.t("fill_missing_details"),
        position: "bottom",
        duration: 1000,
        style: styles.toast,
        textStyle: styles.toastText,
      })
    }
  }

  hasEmptyValues() {
    return (
      !this.state.companyEmail ||
      !this.state.companyName ||
      !this.state.birthDate ||
      this.state.langs.length === 0
    )
  }

  render() {
    return (
      <Container style={styles.container}>
        <Content style={styles.wrapper}>
         
          <ProfileImage
            style={styles.thumbnail}
            large
            source={this.props.photoUrl}
          />

        <Text style={styles.textStyle}>DISPALY NAME</Text>

          <Textarea
            placeholder={I18n.t("Your company display name")}
            value={this.state.companyName}
            style={styles.textarea}
            onChangeText={(text: string) => {
              this.setState({ companyName: text })  

            }}
          />
          
          <Text style={styles.textStyle}>YOUR COMPANY EMAIL</Text>

          <Textarea
            placeholder={I18n.t("Your company email")}
            value={this.state.companyEmail}
            style={styles.textarea}
            onChangeText={(text: string) => {

              this.setState({ companyEmail: text })
            }}
          />

            <Text style={styles.textStyle}>YOUR SOCIAL SECURITY NUMBER </Text>

          <Textarea
            placeholder={I18n.t("Your social security number (or other id)")}
            value={this.state.ssn}
            style={styles.textarea}
            onChangeText={(text: string) => {

 
              this.setState({ ssn: text })
            }}
          />
      
            <DateRow
              placeholder={I18n.t("Your birth date")}
              value={this.state.birthDate}
              header={I18n.t("Birth date")}
              onChange={(date: Date) => {
                this.setState({ birthDate: date })
              }}
            />
            <NumberRow 
              header={I18n.t("Years of work experience")}
              min={0}
              max={30}
              value={this.state.yearsOfExperience}
              onChange={(value: number) => {
                this.setState({ yearsOfExperience: value })
              }}
            />

            <PickerRow
              title={I18n.t("Pick languages that you speak")}
              options={this.props.langOptions}
              value={this.state.langs}
              placeholder={I18n.t("Press to select languages")}
              header={I18n.t("Languages")}
              onChange={(selected: string[]) => {
                this.setState({ langs: selected }, () => {
                  this.forceUpdate()
                })
              }}
            />

            <View style={styles.buttonWrapper}>
              <Button
                block
                rounded
                onPress={this.nextPressed}
                style={
                  this.hasEmptyValues() ? styles.buttonDisabled : styles.button
                }
                disabled={this.hasEmptyValues()}
              >
                <Text
                  style={
                    this.hasEmptyValues()
                      ? styles.buttonTextDisabled
                      : styles.buttonText
                  }
                >
                  {I18n.t("next")}
                </Text>
              </Button>
            </View>
        </Content>
      </Container>
    )
  }
}

