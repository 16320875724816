import { connect } from "react-redux"
import { getOfferStartDateString } from "utilities/time"
import {
  acceptExtension,
  bookingSelected,
  declineExtension,
  offerSelected,
} from "modules/Buyer/Offers/actions"
import routeNames from "components/navigators/constants"
import { offerRequestSelectedByBuyer } from "modules/Buyer/OfferRequests/actions"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { Offer } from "modules/Buyer/Offers/types"
import { getSellerProfileForOffer } from "modules/SellerProfiles/selectors"
import { getUser } from "modules/User/selectors"
import { getAcceptedOfferForOfferRequest } from "modules/Buyer/Offers/selectors"
import { getCategoryById } from "modules/Buyer/Categories/selectors"
import { getOfferRequestByIdForBuyer } from "modules/Buyer/OfferRequests/selectors"
import { Category } from "modules/Buyer/Categories/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"
import { getStripeSession } from "modules/Payment/Stripe/actions"
import { Linking } from "expo"
import { CommonActions } from "@react-navigation/native"

interface OwnProps {
  offerRequestId: string
  navigation: Navigation
}

const mapStateToProps = (state: Store, { offerRequestId }: OwnProps) => {
  const offerRequest = getOfferRequestByIdForBuyer(state, offerRequestId)
  const offer =
    offerRequest && getAcceptedOfferForOfferRequest(state, offerRequest)
  return {
    offerRequest,
    offer,
    category: offerRequest && getCategoryById(state, offerRequest.category),
    sellerProfile: offer ? getSellerProfileForOffer(state, offer) : undefined,
    user: getUser(state),
  }
}

const mapDispatchToProps = {
  bookingSelected,
  acceptExtension,
  declineExtension,
  offerRequestSelected: offerRequestSelectedByBuyer,
  offerSelected,
  getStripeSession,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onPress: (offer: Offer, category: Category) => {
    dispatchProps.bookingSelected(offer.id)

    ownProps.navigation.navigate(routeNames.BUYER.BOOKING_DETAILS, {
      name: `${getLocaLizedName(category.name)} ${getOfferStartDateString(
        offer,
      )}`,
    })
  },
  acceptExtension: (offer: Offer) => {
    if (stateProps.offerRequest && stateProps.user && stateProps.category) {
      const {
        offerRequest: { id: offerRequestId },
      } = stateProps

      dispatchProps.getStripeSession({
        user: stateProps.user,
        categoryName: getLocaLizedName(stateProps.category.name),
        offerId: offer.id,
        offerRequestId: offer.offerRequest,
        isExtending: true,
        successUrl: Linking.makeUrl("stripe/success"), // tslint:disable-line:no-unsafe-any
        cancelUrl: Linking.makeUrl("stripe/cancel"), // tslint:disable-line:no-unsafe-any
      })

      dispatchProps.offerRequestSelected(offerRequestId)
      dispatchProps.offerSelected(offer.id)
      ownProps.navigation.dispatch(
        CommonActions.navigate(routeNames.ROOT.CHECKOUT_STRIPE),
      )
    }
  },
  declineExtension: (offer: Offer) => {
    dispatchProps.declineExtension(offer.id)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
