import React, { useState, useEffect } from "react"
import { View, Text, TouchableOpacity } from "react-native"
import { Button } from "native-base"
import { SimpleLineIcons } from "@expo/vector-icons"
import I18n from "i18n"
import moment, { Moment } from "moment"
import {
  getTimeRemainingString,
  getTimeRemainingInMinutes,
} from "utilities/time"
import styles from "./styles"
import ReminderStyles from "../styles"
import { Offer } from "modules/Buyer/Offers/types"
import { SellerProfile } from "modules/SellerProfiles/types"
import { Category } from "modules/Buyer/Categories/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

const NewTotalPrice = ({ offer }: { offer: Offer }) => {
  if (offer.timeToExtend) {
    const { pricePerHour, timeToExtend } = offer
    const totalPrice = (timeToExtend * pricePerHour).toFixed(2)
    return (
      <Text style={ReminderStyles.title}>
        {I18n.t("additional_cost")}:{" "}
        <Text style={ReminderStyles.highlighted}>{totalPrice} €</Text>
      </Text>
    )
  }
  return null
}

const ExtensionAction = ({
  acceptExtension,
  declineExtension,
  sellerProfile,
  offer,
}: {
  acceptExtension: () => void
  declineExtension: () => void
  sellerProfile: SellerProfile
  offer: Offer
}) => {
  const seller = sellerProfile.companyName
  const additionalRequestedDuration = offer.timeToExtend || 1
  return (
    <View style={ReminderStyles.extensionAction}>
      {sellerProfile ? (
        <Text style={ReminderStyles.title}>
          <Text style={ReminderStyles.highlighted}>{seller} </Text>
          {I18n.t("requested_for_extension")}
          <Text style={ReminderStyles.highlighted}>
            {" "}
            {additionalRequestedDuration}h
          </Text>
        </Text>
      ) : null}
      <NewTotalPrice offer={offer} />
      <View style={styles.buttonWrap}>
        <Button
          rounded
          block
          success
          onPress={acceptExtension}
          style={[ReminderStyles.button, { marginRight: 15 }]}
        >
          <Text style={ReminderStyles.buttonText}>
            {I18n.t("accept_extension")}
          </Text>
        </Button>
        <Button
          rounded
          block
          danger
          onPress={declineExtension}
          style={ReminderStyles.button}
        >
          <Text style={ReminderStyles.buttonText}>
            {I18n.t("decline_extension")}
          </Text>
        </Button>
      </View>
    </View>
  )
}

const CurrentJob = ({
  onPress,
  name,
  timeRemaining,
  offer,
  timePause,
}: {
  onPress: () => void
  name: string
  timeRemaining: number | undefined
  offer: Offer
  timePause: string
}) => {
  const timeRemainingString: string = timeRemaining
    ? getTimeRemainingString(timeRemaining)
    : ""
  const offerIsPaused: boolean | undefined =
    offer && offer.isPaused ? offer.isPaused : false

  return (
    <View style={ReminderStyles.content}>
      <View style={styles.messageContent}>
        <Text style={ReminderStyles.title}>
          {I18n.t("service")}:{" "}
          <Text style={ReminderStyles.highlighted}>{name}</Text>
        </Text>
        {offer && offer.actualStartTime ? (
          <>
            <Text style={ReminderStyles.title}>
              {I18n.t("time_left")}:{" "}
              <Text style={ReminderStyles.highlighted}>
                {timeRemainingString}
              </Text>
            </Text>
            <Text style={ReminderStyles.title}>
              {I18n.t("status")}:{" "}
              {offerIsPaused ? (
                <Text style={ReminderStyles.highlighted}>{`${I18n.t(
                  "paused_job",
                )} (${timePause})`}</Text>
              ) : (
                <Text style={ReminderStyles.highlighted}>
                  {I18n.t("on_going_job")}
                </Text>
              )}
            </Text>
          </>
        ) : (
          <Text style={ReminderStyles.highlighted}>
            {I18n.t("waiting_start")}
          </Text>
        )}
      </View>
      <TouchableOpacity style={ReminderStyles.iconContainer} onPress={onPress}>
        <SimpleLineIcons
          name="arrow-right-circle"
          size={30}
          style={ReminderStyles.icon}
        />
      </TouchableOpacity>
    </View>
  )
}

interface Props {
  offer?: Offer
  category?: Category
  sellerProfile?: SellerProfile
  onPress: (offer: Offer, category: Category) => void
  acceptExtension: (offer: Offer) => void
  declineExtension: (offer: Offer) => void
}

interface Intervals {
  pause: number
  remaining: number
}
const intervals: Intervals = {
  pause: 0,
  remaining: 0,
}

const OnGoingJob = (props: Props) => {
  const { offer } = props
  if (!offer) {
    return null
  }

  const [timeRemaining, setTimeRemaining] = useState<number>()
  const [timePause, setTimePause] = useState<string>("")

  const updateTimeRemaining = (endTime: Moment): number => {
    return window.setInterval(() => {
      const diff: number = moment(endTime, "x").diff(moment(), "minutes")
      if (diff > 0) {
        setTimeRemaining(getTimeRemainingInMinutes(endTime))
      } else {
        clearInterval(intervals.remaining)
      }
    }, 60000)
  }

  const getEndTimeJob = (): Moment => {
    const { actualStartTime, original, extensions, pauseDuration } = offer
    const purchasedDuration =
      original.purchaseDuration +
      (extensions
        ? extensions.reduce((purchasedDurationTotal, extension) => {
            return purchasedDurationTotal + extension.purchaseDuration
          }, 0)
        : 0)
    const startedTime: Moment = moment(actualStartTime)
    const endTime: Moment = startedTime
      .clone()
      .add(purchasedDuration * 60, "minutes")
      .add(pauseDuration || 0, "ms")
    return endTime
  }

  const setTimeLeftToCompleteJob = (): void => {
    const endTime: Moment = getEndTimeJob()
    if (!intervals.remaining) {
      intervals.remaining = updateTimeRemaining(endTime)
    }
    setTimeRemaining(getTimeRemainingInMinutes(endTime))
  }

  const getCurrentPauseDuration = (): number => {
    const { workTime } = offer
    const diff: number = workTime
      ? Number(moment().diff(moment(workTime[workTime.length - 1].endTime)))
      : Number(moment())
    return diff
  }

  const updateTimePauseJob = (): void => {
    if (!offer.isPaused) {
      return
    }

    if (!intervals.pause) {
      intervals.pause = window.setInterval(() => {
        const pauseDuration: number = getCurrentPauseDuration()

        setTimePause(
          getTimeRemainingString(
            Number(
              moment
                .duration(pauseDuration)
                .asMinutes()
                .toFixed(),
            ),
          ),
        )
      }, 60000)
    }
  }

  const setInitialTimeLeftToCompleteJob = (): void => {
    const endTime: Moment = getEndTimeJob()
    const pauseDuration: number = getCurrentPauseDuration()
    const time: Moment = moment(endTime).add(pauseDuration)
    setTimeRemaining(getTimeRemainingInMinutes(time))
  }

  const setInitialPauseTime = () => {
    const pauseDuration = getCurrentPauseDuration()

    setTimePause(
      getTimeRemainingString(
        Number(
          moment
            .duration(pauseDuration)
            .asMinutes()
            .toFixed(),
        ),
      ),
    )
  }

  useEffect(() => {
    if (offer && offer.isPaused) {
      updateTimePauseJob()
      setInitialPauseTime()
      setInitialTimeLeftToCompleteJob()
    } else if (offer && offer.actualStartTime && !offer.isPaused) {
      setTimeLeftToCompleteJob()
    }

    return () => {
      if (offer && offer.hasOwnProperty("isPaused") && !offer.isPaused) {
        clearInterval(intervals.remaining)
        intervals.remaining = 0
      } else if (offer && offer.isPaused) {
        clearInterval(intervals.pause)
        intervals.pause = 0
      }
    }
  }, [props.offer])

  const clearAllIntervals = (): void => {
    clearInterval(intervals.remaining)
    clearInterval(intervals.pause)
    intervals.pause = 0
    intervals.remaining = 0
  }

  // tslint:disable-next-line: no-shadowed-variable
  const onAcceptExtension = (offer: Offer): void => {
    clearAllIntervals()
    props.acceptExtension(offer)
  }

  // tslint:disable-next-line: no-shadowed-variable
  const onDeclineExtension = (offer: Offer): void => {
    clearAllIntervals()
    props.declineExtension(offer)
  }

  const { category, onPress, sellerProfile } = props
  const categoryName: string =
    category && category.name ? getLocaLizedName(category.name) : ""
  const timeToExtend: number =
    offer && offer.timeToExtend ? offer.timeToExtend : 0
  const extensionRequested: any = timeToExtend && timeToExtend > 0

  if (!offer || !sellerProfile || !category) {
    return null
  }

  return (
    <View style={ReminderStyles.reminderContainer}>
      <CurrentJob
        onPress={() => onPress(offer, category)}
        name={categoryName}
        offer={offer}
        timeRemaining={timeRemaining}
        timePause={timePause}
      />
      {extensionRequested ? (
        <ExtensionAction
          acceptExtension={() => onAcceptExtension(offer)}
          declineExtension={() => onDeclineExtension(offer)}
          sellerProfile={sellerProfile}
          offer={offer}
        />
      ) : null}
    </View>
  )
}

export default OnGoingJob
