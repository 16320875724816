import { StyleSheet, PixelRatio } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.containerBackground,
  },
  content: {
    paddingVertical: 15,
  },
  buttonWrap: {
    position: "absolute",
    left: 15,
    right: 15,
    bottom: 15,
  },
  button: {
    backgroundColor: Colors.brandYellow,
  },
  textStyle: {
    color: Colors.noteColor,
    fontSize: 14,
  },
  details: {
    marginVertical: 15,
    paddingHorizontal: 15,
    backgroundColor: Colors.containerBackground,
  },
  detailsRow: {
    flexDirection: "row",
    marginBottom: 15,
  },
  detailsLabel: {
    color: Colors.noteColor,
    fontSize: 12,
    flex: 1,
    paddingRight: 15,
    textAlign: "right",
  },
  detailsValue: {
    color: Colors.textColor,
    fontSize: 12,
    flex: 3,
  },
  duration: {
    marginVertical: 15,
  },
  durationLabel: {
    fontFamily: "open-sans-regular",
    fontSize: 12,
    color: Colors.noteColor,
    paddingHorizontal: 15,
    paddingBottom: 10,
  },
  picker: {
    backgroundColor: Colors.contentBackground,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderTopWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderRadius: 0,
    borderColor: Colors.borderColor,
  },
})
