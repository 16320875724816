import { StyleSheet, PixelRatio } from "react-native"
import { separatorText } from "constants/Fonts"
import Colors from "constants/Colors"

export default StyleSheet.create({
  container: {
    paddingBottom: 75,
    backgroundColor: Colors.containerBackground,
  },
  separator: {
    backgroundColor: Colors.containerBackground,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
    marginTop: 15,
  },
  separatorText: {
    ...separatorText,
  },
  groupItems: {
    backgroundColor: Colors.contentBackground,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
  },
})
