import React from "react"
import { connect } from "react-redux"
import { CommonActions, StackActions } from "@react-navigation/native"
import { Navigation } from "components/types"
import routeNames from "components/navigators/constants"
import { Loader } from "./Loader"
import { Store } from "utilities/Redux/store"
import { isUserLoggedIn } from "modules/User/selectors"
import { getSessionId } from "modules/Payment/Stripe/selectors"

interface Props {
  navigation: Navigation
  isLoggedIn?: boolean
  sessionId?: string
}

class CheckoutFlow extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    this.flowController(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.isLoggedIn !== this.props.isLoggedIn ||
      nextProps.sessionId !== this.props.sessionId
    ) {
      this.flowController(nextProps)
    }
  }

  flowController({ navigation, isLoggedIn, sessionId }: Props) {
    // first decide where to go
    if (isLoggedIn) {
      if (sessionId) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        navigation.dispatch(
          StackActions.replace(routeNames.CHECKOUT_STRIPE.PAYMENT),
        )
      }
    } else {
      navigation.dispatch(CommonActions.navigate(routeNames.ROOT.SIGN_IN))
    }
  }

  render() {
    return <Loader />
  }
}

const mapStateToProps = (state: Store) => {
  return {
    isLoggedIn: isUserLoggedIn(state),
    sessionId: getSessionId(state),
  }
}

export default connect(mapStateToProps)(CheckoutFlow)
