import React from "react"
import { Platform } from "react-native"
import { Picker, Icon } from "native-base"
import I18n from "i18n"
import _ from "lodash"
import styles from "./styles"

interface Props {
  onDurationChanged: (duration: number) => void
  items: number[]
  selectedValue?: number | null
  isFixed: boolean
}

export default class DurationInput extends React.Component<Props> {
  componentDidMount() {
    if (Platform.OS === "android" && !this.props.selectedValue) {
      this.props.onDurationChanged(this.props.items[0])
    }
  }
  render() {
    return (
      <Picker
        note={false}
        enabled={!this.props.isFixed}
        iosIcon={<Icon name="arrow-down" />}
        style={styles.formItem}
        onValueChange={this.props.onDurationChanged}
        placeholder={I18n.t("select_duration")}
        placeholderStyle={[styles.inputPlaceholder, styles.pickerInput]}
        textStyle={[styles.inputText, styles.pickerInput]}
        selectedValue={this.props.selectedValue}
      >
        {this.props.items.map(duration => (
          <Picker.Item
            label={`${duration} ${
              duration <= 1 ? I18n.t("hour") : I18n.t("hours")
            }`}
            value={duration}
            key={duration}
          />
        ))}
      </Picker>
    )
  }
}
