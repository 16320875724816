import React from "react"
import _ from "lodash"
import { View, TouchableHighlight } from "react-native"
import { Button, Container, Text } from "native-base"
import I18n from "i18n"
import SelectTime from "./SelectTime"
import styles from "./styles"
import { Offer } from "modules/Buyer/Offers/types"
import { formatDateAndTime, getMoment, getOfferDuration } from "utilities/time"

interface DetailsProps {
  offer?: Offer
  categoryName?: string
  displayName?: string
}
const Details = ({ offer, categoryName, displayName }: DetailsProps) => {
  if (!offer) {
    return null
  }

  return (
    <View style={styles.details}>
      <View style={styles.detailsRow}>
        <Text style={styles.detailsLabel}>{I18n.t("service")}</Text>
        <Text style={styles.detailsValue}>{categoryName} h</Text>
      </View>
      <View style={styles.detailsRow}>
        <Text style={styles.detailsLabel}>{I18n.t("buyer")}</Text>
        <Text style={styles.detailsValue}>{displayName}</Text>
      </View>
      <View style={styles.detailsRow}>
        <Text style={styles.detailsLabel}>{I18n.t("date")}</Text>
        <Text style={styles.detailsValue}>
          {formatDateAndTime(
            offer.actualStartTime || offer.agreedStartTime,
            "dateAndTimeFormatWithDay",
          )}
        </Text>
      </View>
    </View>
  )
}

interface SelectTimeRowProps {
  label: string
  timeValue?: number
  onPress: () => void
}
const SelectTimeRow = ({ label, timeValue, onPress }: SelectTimeRowProps) => {
  return (
    <TouchableHighlight onPress={onPress} style={styles.timeRowContainer}>
      <View style={styles.timeRow}>
        <Text style={styles.label}>{label}</Text>
        <Text style={styles.timeRowText}>
          {timeValue ? formatDateAndTime(timeValue, "dateAndTimeFormat") : null}
        </Text>
      </View>
    </TouchableHighlight>
  )
}

interface Props {
  offer?: Offer
  categoryName?: string
  displayName?: string
  onConfirm: (props: {
    actualStartTime: number
    actualCompletedTime: number
  }) => void
}

class MarkPastJobCompleted extends React.PureComponent<Props> {
  state: {
    actualStartTime?: number
    actualCompletedTime?: number
    showStartTimeModal?: boolean
    showactualCompletedTimeModal?: boolean
  }
  constructor(props: Props) {
    super(props)
    if (this.props.offer) {
      const { offer } = this.props
      const startTime = offer.actualStartTime || offer.agreedStartTime
      const duration = getOfferDuration(offer)
      this.state = {
        actualStartTime: startTime,
        actualCompletedTime:
          offer.actualCompletedTime ||
          +getMoment(startTime).add(duration, "hour"),
      }
    } else {
      this.state = {}
    }
  }

  onActualStartTimeChanged = (value: Date) => {
    this.setState({
      actualStartTime: value,
      actualCompletedTime:
        this.state.actualCompletedTime &&
        this.state.actualCompletedTime > value.getTime()
          ? this.state.actualCompletedTime
          : value,
    })
  }

  onActualCompletedTimeChanged = (date: Date) => {
    this.setState({
      actualCompletedTime: date.getTime(),
    })
  }

  openStartTimePickerModal = () => {
    this.setState({
      showStartTimeModal: true,
      showactualCompletedTimeModal: false,
    })
  }

  openCompletedTimePickerModal = () => {
    this.setState({
      showStartTimeModal: false,
      showactualCompletedTimeModal: true,
    })
  }

  onModalClosePressed = () => {
    this.setState({
      showStartTimeModal: false,
      showactualCompletedTimeModal: false,
    })
  }

  onConfirm = () => {
    const { actualStartTime, actualCompletedTime } = this.state
    if (actualStartTime && actualCompletedTime) {
      this.props.onConfirm({
        actualStartTime,
        actualCompletedTime,
      })
    }
  }

  renderButton() {
    const { actualStartTime, actualCompletedTime } = this.state
    if (actualStartTime && actualCompletedTime) {
      return (
        <View style={styles.buttonWrap}>
          {actualStartTime < actualCompletedTime ? (
            <Button
              block
              rounded
              style={styles.submitButton}
              onPress={this.onConfirm}
            >
              <Text>{`${I18n.t("confirm")}`}</Text>
            </Button>
          ) : (
            <Button
              disabled
              block
              rounded
              style={[styles.submitButton, styles.submitButtonDisabled]}
            >
              <Text style={styles.submitButtonDisabledText}>
                {I18n.t("can_not_finish_before_started")}
              </Text>
            </Button>
          )}
        </View>
      )
    }
    return null
  }

  render() {
    const {
      actualStartTime,
      actualCompletedTime,
      showactualCompletedTimeModal,
      showStartTimeModal,
    } = this.state
    return (
      <Container style={styles.container}>
        {showStartTimeModal && this.props.offer ? (
          <SelectTime
            title={I18n.t("when_did_you_start")}
            value={actualStartTime}
            minimumDate={this.props.offer.agreedStartTime - 60 * 60 * 1000} // possible to start an hour before
            maximumDate={this.props.offer.agreedStartTime + 12 * 60 * 60 * 1000} // possible to start 12 hours late
            onValueChanged={this.onActualStartTimeChanged}
            onModalClosePressed={this.onModalClosePressed}
          />
        ) : null}
        {showactualCompletedTimeModal && this.props.offer ? (
          <SelectTime
            title={I18n.t("when_did_you_finish")}
            value={actualCompletedTime}
            minimumDate={
              actualStartTime
                ? actualStartTime + 30 * 60 * 1000 // can not end more than 30min after started
                : undefined
            }
            maximumDate={
              actualStartTime && this.props.offer
                ? actualStartTime +
                  getOfferDuration(this.props.offer) +
                  12 * 60 * 60 * 1000
                : undefined
            }
            onValueChanged={this.onActualCompletedTimeChanged}
            onModalClosePressed={this.onModalClosePressed}
          />
        ) : null}
        <Details
          categoryName={this.props.categoryName}
          displayName={this.props.displayName}
          offer={this.props.offer}
        />
        <SelectTimeRow
          label={I18n.t("started")}
          timeValue={actualStartTime}
          onPress={this.openStartTimePickerModal}
        />
        <SelectTimeRow
          label={I18n.t("finished")}
          timeValue={actualCompletedTime}
          onPress={this.openCompletedTimePickerModal}
        />
        {this.renderButton()}
      </Container>
    )
  }
}
export default MarkPastJobCompleted
