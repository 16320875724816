import { StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.containerBackground,
  },
  spinner: {
    marginTop: 100,
  },
})
