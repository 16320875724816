import React from "react"
import { connect } from "react-redux"
import routeNames from "components/navigators/constants"
import { CommonActions, StackActions } from "@react-navigation/native"
import ActivityIndicatorView from "./view"
import { Navigation } from "components/types"
import { Store } from "utilities/Redux/store"
import {
  isUserBlocked,
  isFetchingUser,
  hasUserBuyerProfile,
  isUserLoggedIn,
} from "modules/User/selectors"
import {
  getBuyerProfileForUser,
  isFetchingBuyerProfile,
} from "modules/BuyerProfiles/selectors"

interface SignInFlowProps {
  isFetching?: boolean
  isBlocked?: boolean
  isLoggedIn?: boolean
  hasBuyerProfile?: boolean
  navigation: Navigation
}

//todo

class SignInFlow extends React.Component<SignInFlowProps> {
  UNSAFE_componentWillMount() {
    this.flowController(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps: SignInFlowProps) {
    if (
      nextProps.isFetching !== this.props.isFetching ||
      nextProps.isBlocked !== this.props.isBlocked ||
      nextProps.isLoggedIn !== this.props.isLoggedIn ||
      nextProps.hasBuyerProfile !== this.props.hasBuyerProfile
    ) {
      this.flowController(nextProps)
    }
  }

  flowController({
    navigation,
    isLoggedIn,
    hasBuyerProfile,
    isFetching,
    isBlocked,
  }: SignInFlowProps) {
    if (isFetching) {
      return
    }

    // first decide where to go
    let navigateAction
    if (!isLoggedIn) {
      navigateAction = StackActions.replace(routeNames.SIGN_IN.SIGNUP)
    } else if (isBlocked) {
      navigateAction = CommonActions.navigate(routeNames.SIGN_IN.BLOCKED)
    } else if (!hasBuyerProfile) {
      navigateAction = CommonActions.navigate(
        routeNames.SIGN_IN.CONFIRM_USER_INFO,
      )
    } else {
      navigateAction = StackActions.replace(routeNames.ROOT.TABS)
    }

    // then navigate
    navigation.dispatch(navigateAction)
  }

  render() {
    return <ActivityIndicatorView />
  }
}

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const isLoggedIn = isUserLoggedIn(state)
  return {
    isLoggedIn,
    hasBuyerProfile:
      isLoggedIn && getBuyerProfileForUser(state) && hasUserBuyerProfile(state),
    isFetching: isFetchingBuyerProfile(state) || isFetchingUser(state),
    isBlocked: isUserBlocked(state),
  }
}
const mapDispatchToProps = null
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: null,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...ownProps,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(SignInFlow)
