import { connect } from "react-redux"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { getOpenSellerOfferIds } from "modules/Seller/Offers/selectors"

const mapStateToProps = (state: Store) => ({
  offerIds: getOpenSellerOfferIds(state),
})

export default connect(mapStateToProps)(View)
