import { PixelRatio, StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  listItem: {
    borderBottomWidth: 0,
    flex: 1,
    flexDirection: "row",
    alignContent: "flex-start",
    justifyContent: "space-between",
  },
  buttonsContainer: {
    flexDirection: "row",
    alignSelf: "flex-end",
  },
  button: {
    borderColor: Colors.borderColor,
    marginVertical: 0,
    height: 28,
    paddingHorizontal: 15,
    borderWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  buttonLeft: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  buttonRight: {
    borderLeftWidth: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  buttonText: {
    color: Colors.brandGreen,
    fontSize: 16,
    lineHeight: 16,
  },
})
