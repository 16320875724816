import { connect } from "react-redux"
import { getOfferStartDateString } from "utilities/time"
import { bookingSelected } from "modules/Buyer/Offers/actions"
import { offerRequestSelectedByBuyer } from "modules/Buyer/OfferRequests/actions"
import { sellerProfileSelected } from "modules/SellerProfiles/actions"
import routeNames from "components/navigators/constants"
import _ from "lodash"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { Category } from "modules/Buyer/Categories/types"
import { Offer } from "modules/Buyer/Offers/types"
import { SellerProfile } from "modules/SellerProfiles/types"
import { getSellerProfileById } from "modules/SellerProfiles/selectors"
import { getCategoryById } from "modules/Buyer/Categories/selectors"
import { getOfferRequestByIdForBuyer } from "modules/Buyer/OfferRequests/selectors"
import { getCompletedOfferForOfferRequest } from "modules/Buyer/Offers/selectors"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface OwnProps {
  offerRequestId: string
  navigation: Navigation
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => {
  const { offerRequestId } = ownProps
  const offerRequest = getOfferRequestByIdForBuyer(state, offerRequestId)
  if (!offerRequest) {
    return {
      offerRequest,
    }
  }
  const offer = getCompletedOfferForOfferRequest(state, offerRequest)
  return {
    offerRequest,
    offer,
    category: getCategoryById(state, offerRequest.category),
    sellerProfile: offer
      ? getSellerProfileById(state, offer.sellerProfile)
      : undefined,
  }
}

const mapDispatchToProps = {
  bookingSelected,
  offerRequestSelected: offerRequestSelectedByBuyer,
  sellerProfileSelected,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  onPressArrow: (category: Category, offer: Offer) => {
    dispatchProps.bookingSelected(offer.id)

    ownProps.navigation.navigate(routeNames.BUYER.BOOKING_DETAILS, {
      name: `${getLocaLizedName(category.name)} ${getOfferStartDateString(
        offer,
      )}`,
    })
  },
  leaveReview: (sellerProfile: SellerProfile) => {
    if (stateProps.offerRequest) {
      dispatchProps.sellerProfileSelected(sellerProfile.id)
      dispatchProps.offerRequestSelected(stateProps.offerRequest.id)
      ownProps.navigation.navigate(routeNames.BUYER.GIVE_REVIEW)
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
