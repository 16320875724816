import _ from "lodash"
import { Store } from "utilities/Redux/store"
import { Offer } from "../Buyer/Offers/types"

export const getSellerProfileById = (state: Store, id: string) =>
  state.sellerProfiles.profiles[id]
    ? state.sellerProfiles.profiles[id]
    : undefined

export const getSellerProfileForOffer = (state: Store, offer: Offer) =>
  offer && offer.sellerProfile
    ? getSellerProfileById(state, offer.sellerProfile)
    : undefined

export const getReviews = (state: Store, id: string) =>
  state.sellerProfiles.reviews[id]
    ? state.sellerProfiles.reviews[id]
    : undefined

export const getTempSellerProfile = (state: Store) =>
  state.sellerProfiles.tempProfile

export const hasSelectedSellerCategory = (state: Store, categoryId: string) => {
  const { tempProfile } = state.sellerProfiles
  if (tempProfile && tempProfile.categories) {
    return Object.keys(tempProfile.categories).includes(categoryId)
  }
  return false
}

export const getSelectedSellerProfile = (state: Store) =>
  state.sellerProfiles.selected
    ? getSellerProfileById(state, state.sellerProfiles.selected)
    : undefined
