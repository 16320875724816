import React from "react"
import * as ImagePicker from "expo-image-picker"
import * as Permissions from "expo-permissions"
import ExpoPermission from "constants/ExpoPermission"
import { View, Separator, Text, ListItem, Icon, ActionSheet } from "native-base"
import { Ionicons } from "@expo/vector-icons"
import I18n from "i18n"
import styles from "./styles"

interface Props {
  onChange: (result: ImagePicker.ImagePickerResult) => void
  header: string
  placeholder?: string
}

interface State {
  selectedFileName: string | null
}

export default class ImagePickerRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.addImagePicker = this.addImagePicker.bind(this)

    this.state = {
      selectedFileName: null,
    }
  }

  getFileName(uri: string) {
    return uri.substring(uri.lastIndexOf("/") + 1)
  }

  async checkCameraRollPermissions() {
    const { status } = await Permissions.getAsync(Permissions.CAMERA_ROLL)
    if (status !== ExpoPermission.GRANTED) {
      const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL)
      if (status !== ExpoPermission.GRANTED) {
        return false
      }
    }
    return true
  }

  async checkCameraPermissions() {
    const { status } = await Permissions.getAsync(Permissions.CAMERA)
    if (status !== ExpoPermission.GRANTED) {
      const { status } = await Permissions.askAsync(Permissions.CAMERA)
      if (status !== ExpoPermission.GRANTED) {
        return false
      }
    }
    return true
  }

  async addImageFromCamera() {
    const hasCameraRollPermission = await this.checkCameraRollPermissions()
    const hasCameraPermission = await this.checkCameraPermissions()

    if (hasCameraPermission && hasCameraRollPermission) {
      const result = await ImagePicker.launchCameraAsync({
        base64: true,
      })

      if (result.cancelled === false) {
        this.setState(
          { selectedFileName: this.getFileName(result.uri) },
          () => {
            this.props.onChange(result)
          },
        )
      }
    }
  }

  async addImageFromGallery() {
    const hasCameraRollPermission = await this.checkCameraRollPermissions()
    if (hasCameraRollPermission) {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        base64: true,
      })
      if (result.cancelled === false) {
        this.setState(
          { selectedFileName: this.getFileName(result.uri) },
          () => {
            this.props.onChange(result)
          },
        )
      }
    }
  }

  addImagePicker() {
    ActionSheet.show(
      {
        options: [
          I18n.t("Open Camera"),
          I18n.t("Photo Library"),
          I18n.t("Cancel"),
        ],
        cancelButtonIndex: 3,
        title: I18n.t("Add a picture"),
      },
      index => {
        switch (index) {
          case 0:
            this.addImageFromCamera()
            break
          case 1:
            this.addImageFromGallery()
            break
          default:
            break
        }
      },
    )
  }

  render() {
    return (
      <View>
        <Separator style={styles.separator}>
          <Text>{this.props.header.toUpperCase()}</Text>
        </Separator>
        <ListItem button onPress={this.addImagePicker} style={styles.container}>
          <View style={[styles.iconContainer]}>
            <Ionicons name="ios-images" style={styles.icon} />
          </View>

          <Text
            style={[
              styles.flexItem,
              styles.fileName,
              this.state.selectedFileName ? styles.regular : styles.placeholder,
            ]}
          >
            {this.state.selectedFileName || this.props.placeholder}
          </Text>

          <View style={[styles.iconContainer, styles.right]}>
            <Icon name="arrow-forward" style={styles.icon} />
          </View>
        </ListItem>
      </View>
    )
  }
}
