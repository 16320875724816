export const SELLER_PROFILE_UPDATED = `sellerProfiles/SELLER_PROFILE_UPDATED`
export const SELLER_PROFILE_CREATE = `sellerProfiles/SELLER_PROFILE_CREATE`
export const TEMP_SELLER_PROFILE_UPDATE = `sellerProfiles/TEMP_SELLER_PROFILE_UPDATE`
export const TEMP_SELLER_PROFILE_UPDATE_CATEGORIES = `sellerProfiles/TEMP_SELLER_PROFILE_UPDATE_CATEGORIES`
export const TEMP_SELLER_PROFILE_UPDATE_PERSONAL_INFO = `sellerProfiles/TEMP_SELLER_PROFILE_UPDATE_PERSONAL_INFO`
export const REVIEW_UPDATED = `sellerProfiles/REVIEW_UPDATED`
export const SELLER_PROFILE_SELECTED = `sellerProfiles/SELLER_PROFILE_SELECTED`
export const ADD_REVIEW = `sellerProfiles/ADD_REVIEW`
export const FILE_UPLOAD = `sellerProfiles/FILE_UPLOAD`
export const IMAGE_ADDED = `sellerProfiles/IMAGE_ADDED`
export const IMAGE_REMOVED = `sellerProfiles/IMAGE_REMOVED`
