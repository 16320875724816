import React, { useState } from "react"
import { ActivityIndicator } from "react-native"
import { Button, Text, Toast } from "native-base"
import I18n from "i18n"
import _ from "lodash"
import Colors from "constants/Colors"
import styles from "../styles"
import { TempOffer, NewOffer } from "modules/Seller/CreateOffer/types"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"

const ActiveButton = (
  price: number,
  duration: number,
  onSendClicked: () => void,
) => {
  return (
    <Button block rounded onPress={onSendClicked} style={styles.button}>
      <Text style={styles.buttonText}>
        {I18n.t("send_offer")} {price * duration} €
      </Text>
    </Button>
  )
}

const InactiveButton = (
  label: string,
  updating: boolean | null,
  onPress?: () => void,
) => {
  return (
    <Button block rounded bordered style={styles.errorButton} onPress={onPress}>
      <Text style={styles.errorButtonText}>{label}</Text>
      {updating ? (
        <ActivityIndicator size="small" color={Colors.brandYellow} />
      ) : null}
    </Button>
  )
}

interface ButtonProps {
  sellerProfileId?: string
  offerRequest?: OfferRequest
  tempOffer?: TempOffer
  onSendClicked: (tempOffer: NewOffer) => void
  openOfferRequests: string[]
}

export const SendOfferButton = (props: ButtonProps) => {
  const { offerRequest, tempOffer, sellerProfileId } = props
  if (!offerRequest) {
    return null
  }

  const isOfferSent = !props.openOfferRequests.includes(offerRequest.id)
  if (isOfferSent) {
    return InactiveButton(I18n.t("offer_submitted"), false)
  }

  const [isSubmitted, setIsSubmitted] = useState(false)
  if (isSubmitted) {
    return InactiveButton(I18n.t("submitting_offer"), true)
  }

  const { pricePerHour: price, duration, startTime } = tempOffer || {}
  if (sellerProfileId && price && duration && startTime) {
    return ActiveButton(price, duration, () => {
      props.onSendClicked({
        offerRequest: offerRequest.id,
        sellerProfile: sellerProfileId,
        buyerProfile: offerRequest.buyerProfile,
        pricePerHour: price,
        duration: duration,
        startTime: startTime,
      })
      setIsSubmitted(true)
    })
  }

  return InactiveButton(I18n.t("send_offer"), false, () => {
    Toast.show({
      text: I18n.t("fill_missing_details"),
      position: "bottom",
      duration: 2000,
      style: styles.toast,
      textStyle: styles.toastText,
    })
  })
}
