import React from "react"
import { Button, Text } from "native-base"
import styles from "./styles"

interface Props {
  enabled: boolean
  selected: boolean
  onPress: () => void
  label: string
}

export default ({ enabled, selected, onPress, label }: Props) => {
  if (selected) {
    return (
      <Button style={styles.buttonSelected} rounded block onPress={onPress}>
        <Text style={styles.buttonSelectedText}>{label}</Text>
      </Button>
    )
  }
  if (enabled) {
    return (
      <Button
        style={styles.buttonUnselected}
        block
        rounded
        onPress={onPress}
        bordered
        light
      >
        <Text style={styles.buttonUnselectedText}>{label}</Text>
      </Button>
    )
  }
  return <Button rounded block transparent />
}
