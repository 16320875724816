import React from "react"
import _ from "lodash"
import { View } from "react-native"
import { Badge, Body, Icon, Right, ListItem, Text } from "native-base"
import ProfileImage from "components/views/Shared/ProfileImage"
import styles from "./styles"
import { Offer } from "modules/Buyer/Offers/types"
import { SellerProfile } from "modules/SellerProfiles/types"
import { Category } from "modules/Buyer/Categories/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"
import { formatDateAndTime } from "utilities/time"

interface Props {
  offer?: Offer
  sellerProfile?: SellerProfile
  category?: Category
  unseenMessages: number
  onPress: (offer: Offer, category: Category) => void
  last: boolean
}

export default (props: Props) => {
  const { offer, sellerProfile, category } = props
  if (!offer || !sellerProfile || !category) {
    return null
  }

  const agreedDate = formatDateAndTime(
    offer.agreedStartTime,
    "dateAndTimeFormat",
  )
  const photoUrl = sellerProfile.photoURL
  return (
    <ListItem
      onPress={() => props.onPress(offer, category)}
      style={props.last ? styles.lastItem : null}
    >
      <ProfileImage style={styles.thumbnail} small source={photoUrl} />
      <Body style={styles.textWrap}>
        <Text style={styles.title}>{sellerProfile.companyName}</Text>
        <View style={styles.info}>
          <Text style={styles.infoText}>{getLocaLizedName(category.name)}</Text>
          <Text style={styles.infoSeparator}>•</Text>
          <Text style={styles.infoText}>{agreedDate}</Text>
        </View>
      </Body>
      <Right>
        <View style={styles.rightWrap}>
          {props.unseenMessages ? (
            <Badge style={styles.notification}>
              <Text style={styles.notificationText}>
                {props.unseenMessages}
              </Text>
            </Badge>
          ) : null}
          <Icon name="arrow-forward" style={styles.icon} />
        </View>
      </Right>
    </ListItem>
  )
}
