import React from "react"
import { Text, View } from "react-native"
import Svg, { Path } from "react-native-svg"
import styles from "./styles"
import { TabBarIconProps } from "components/types"

interface Props extends TabBarIconProps {
  unseenCount: number
}

const Icon = (props: Props) => {
  return (
    <View style={{ height: 24, width: 24 }}>
      <Svg height={24} width={24} viewBox="0 0 447.3 512">
        <Path
          fill={props.color ? props.color : undefined}
          d="M447.2 436.2l-27.4-308c-1-11.1-10.2-19.5-21.4-19.5H334C333.1 48.4 284 0 223.7 0S114.3 48.4 113.3 108.7H48.8c-11.1 0-20.4 8.5-21.4 19.5L0 436.2c0 .5-.1.9-.1 1.4 0 41 37.6 74.4 83.9 74.4h279.6c46.3 0 83.9-33.4 83.9-74.4 0-.5-.1-.9-.1-1.4zM223.7 30.9c43.2 0 78.6 34.6 79.5 77.8H144.1c1-43.3 36.3-77.8 79.6-77.8zm139.7 450.2H83.9c-29 0-52.6-19.2-53-42.9L53 189.1l4.4-49.5h55.8v46.9c0 8.5 6.9 15.4 15.4 15.4S144 195 144 186.5v-46.9h159.3v46.9c0 8.5 6.9 15.4 15.4 15.4s15.4-6.9 15.4-15.4v-46.9H390l4.4 49.5 22.1 249.1c-.5 23.7-24 42.9-53.1 42.9z"
        />
      </Svg>
      {props.unseenCount ? (
        <View style={styles.indicatorContainer}>
          <Text style={styles.indicatorText}>{props.unseenCount}</Text>
        </View>
      ) : null}
    </View>
  )
}

export default Icon
