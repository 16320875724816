import { StyleSheet, Dimensions, PixelRatio } from "react-native"
import Colors from "constants/Colors"

const windowWidth = Dimensions.get("window").width

export default StyleSheet.create({
  content: {
    paddingVertical: 15,
    backgroundColor: Colors.containerBackground,
    flex: 1,
  },
  detailsRow: {
    flexDirection: "row",
    paddingBottom: 15,
    paddingHorizontal: 15,
  },
  detailsTitle: {
    flex: 2,
    alignItems: "flex-end",
    paddingRight: 15,
  },
  detailsContent: {
    flex: 5,
  },
  detailsTitleText: {
    fontFamily: "open-sans-regular",
    fontSize: 11,
    color: Colors.noteColor,
    textAlign: "right",
  },
  detailsContentWrap: {
    marginBottom: 5,
  },
  detailsContentText: {
    fontFamily: "open-sans-regular",
    fontSize: 13,
    color: Colors.textColor,
  },
  detailsContentExtraText: {
    fontSize: 12,
    color: Colors.noteColor,
    fontWeight: "500",
  },
  imagesContainer: {
    paddingTop: 3,
  },
  subQuestionRow: {
    flex: 1,
    flexDirection: "row",
  },
  subQuestionSeparator: {
    alignItems: "flex-end",
    color: Colors.noteColor,
    fontSize: 8,
    marginTop: 0,
    lineHeight: 18,
  },
  subQuestionValue: {
    marginLeft: 10,
  },
  separator: {
    flexDirection: "row",
    width: windowWidth,
    marginTop: 15,
    marginBottom: 30,
    marginHorizontal: 15,
  },
  separatorNoBorder: {
    flex: 2,
  },
  separatorWithBorder: {
    flex: 5,
  },
  separatorBorder: {
    flex: 1,
    marginRight: 30,
    marginLeft: 3,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
  },
  offerSubRow: {
    flex: 1,
    flexDirection: "row",
  },
  offerSubRowTitleContainer: {
    flex: 2,
    alignItems: "flex-start",
  },
  offerSubRowTitle: {
    fontFamily: "open-sans-regular",
    fontSize: 13,
    color: Colors.textColor,
  },
  offerSubRowValueContainer: {
    flex: 2,
    alignItems: "flex-end",
  },
  offerSubRowValue: {
    fontFamily: "open-sans-regular",
    fontSize: 13,
    color: Colors.textColor,
  },
  emptyBlock: {
    height: 90,
  },
})
