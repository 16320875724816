import _ from "lodash"
import { connect } from "react-redux"
import { getOfferStartDateString } from "utilities/time"
import routeNames from "components/navigators/constants"
import { offerRequestSelected } from "modules/Seller/OfferRequests/actions"
import { offerSelected } from "modules/Seller/Offers/actions"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import { Navigation } from "components/types"
import { Category } from "modules/Buyer/Categories/types"
import { Offer } from "modules/Buyer/Offers/types"
import { getSellerOfferById } from "modules/Seller/Offers/selectors"
import { getBuyerProfileForId } from "modules/BuyerProfiles/selectors"
import { getCategoryById } from "modules/Buyer/Categories/selectors"
import { getOfferRequestByIdForSeller } from "modules/Seller/OfferRequests/selectors"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface OwnProps {
  id: string
  navigation: Navigation
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => {
  const offer = getSellerOfferById(state, ownProps.id)
  if (!offer) {
    return {}
  }
  const offerRequest = getOfferRequestByIdForSeller(state, offer.offerRequest)
  return {
    offer,
    offerRequest,
    category: offerRequest && getCategoryById(state, offerRequest.category),
    buyerProfile:
      offerRequest && getBuyerProfileForId(state, offerRequest.buyerProfile),
  }
}

const mapDispatchToProps = {
  offerRequestSelected,
  offerSelected,
}

let lock: boolean
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onPress: (offer: Offer, offerRequest: OfferRequest, category: Category) => {
    if (lock) {
      return
    }
    lock = true
    dispatchProps.offerRequestSelected(offerRequest.id)
    dispatchProps.offerSelected(ownProps.id)

    ownProps.navigation.navigate(routeNames.SELLER.OPEN_OFFER_REQUEST_DETAILS, {
      name: `${getLocaLizedName(category.name)} ${getOfferStartDateString(
        offer,
      )}`,
    })
    setTimeout(() => {
      lock = false
    }, 500)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
