import React from "react"
import { View, Text } from "react-native"
import Colors from "constants/Colors"
import { headerStyles } from "../headerStyles"

export default () => (
  <View style={{ flex: 1 }}>
    <View style={headerStyles.logoContainerStyle}>
      <Text
        style={{
          fontFamily: "open-sans-bold",
          color: Colors.brandYellow,
          fontSize: 29,
          letterSpacing: -1.5,
        }}
      >
        help
      </Text>
      <Text
        style={{
          fontFamily: "open-sans-bold",
          color: Colors.textColor,
          fontSize: 29,
          letterSpacing: -1.5,
          marginLeft: -1.5,
        }}
      >
        dor
      </Text>
    </View>
  </View>
)
