import _ from "lodash"
import firebase from "firebase"
import {
  all,
  call,
  fork,
  put,
  take,
  takeEvery,
  select,
} from "redux-saga/effects"
import { getEventChannelForFirebaseRef } from "utilities/Redux/sagaHelpers"
import * as questionsActionTypes from "../Buyer/Questions/actionTypes"
import imageUploader from "api/imageUploader"
import {
  UPLOAD_IMAGES,
  IMAGE_UPLOADED,
  SUBSCRIBE_TO_IMAGE,
} from "./actionTypes"
import {
  imageUpdated,
  imageUploaded,
  ImageUploaded,
  UploadImages,
  SubscribeToImage,
} from "./actions"
import { getImageById } from "./selectors"
import { FIREBASE_PATH, STORAGE_PATHS } from "./constants"
import { ImageRemoved, ImageAdded } from "../Buyer/Questions/actions"
import { Image } from "./types"
import { ImageDB } from "database_types/image"
import * as sellerActionTypes from "../SellerProfiles/actionTypes"
import { FileUpload } from "../SellerProfiles/actions"

export function* getDownloadURL(storagePath: string) {
  if (!storagePath) {
    return
  }
  // tslint:disable-next-line:no-unsafe-any
  const imageRef: firebase.storage.Reference = yield firebase
    .storage()
    .ref(storagePath)
  // tslint:disable-next-line:no-unsafe-any
  const downloadURL: string = yield imageRef.getDownloadURL()
  return downloadURL
}

const subscribed = {}
export function* subscribeToImageWorker(action: SubscribeToImage) {
  if (subscribed[action.imageId]) {
    return
  }
  yield (subscribed[action.imageId] = true)
  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.imageId)
  const channel = yield call(getEventChannelForFirebaseRef, ref)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const { values: image }: { values: ImageDB | undefined } = yield take(
      channel,
    )
    if (image && image.storagePath) {
      // tslint:disable-next-line:no-unsafe-any
      const downloadURL: string | undefined = yield image.storagePath
        ? getDownloadURL(image.storagePath)
        : undefined
      yield put(
        imageUpdated({
          id: action.imageId,
          downloadURL,
          ...image,
        }),
      )
    }
  }
}

export function* subscribeToImageSaga() {
  yield takeEvery(SUBSCRIBE_TO_IMAGE, subscribeToImageWorker)
}

export function* uploadImage(props: {
  imageId: string
  offerRequestId: string
}) {
  // tslint:disable-next-line:no-unsafe-any
  const image: Image = yield select(getImageById, props.imageId)
  if (image.localUri) {
    const fieldname = `${STORAGE_PATHS.IMAGES}/${STORAGE_PATHS.OFFER_REQUEST_IMAGES}/${props.offerRequestId}/`
    const status = yield imageUploader(fieldname, image.localUri, props.imageId)
    if (status === 200) {
      // tslint:disable-next-line:no-unsafe-any
      const imageStorageRef: firebase.storage.Reference = yield firebase
        .storage()
        .ref()
        .child(STORAGE_PATHS.IMAGES)
        .child(STORAGE_PATHS.OFFER_REQUEST_IMAGES)
        .child(props.offerRequestId)
        .child(props.imageId)
      yield put(
        imageUploaded({
          imageId: props.imageId,
          storagePath: imageStorageRef.fullPath,
        }),
      )
    }
  }
  // TODO: Replace with the this code when React native has support for blob
  // const { base64 } = yield select(getById, imageId);
  // if (!base64) {
  //     return;
  // }
  //
  // const imageStorageRef = yield firebase.storage().ref()
  // .child(STORAGE_PATHS.IMAGES)
  // .child(STORAGE_PATHS.OFFER_REQUEST_IMAGES)
  // .child(offerRequestId)
  // .child(imageId);
  //
  // const snapshot = yield call([imageStorageRef, imageStorageRef.putString], base64, "base64", {
  //     contentType: "image/jpeg"
  // });
  // console.log("SNAPSHOT", snapshot);
  // if (snapshot && snapshot.state === "success") {
  //     yield put(imageUploaded({ imageId, storagePath: imageStorageRef.fullPath }));
  // }
}

export function* uploadImagesWorker(action: UploadImages) {
  yield all(
    _.map(action.imageIds, imageId =>
      call(uploadImage, {
        imageId,
        offerRequestId: action.offerRequestId,
      }),
    ),
  )
}

export function* uploadOfferRequestImagesSaga() {
  yield takeEvery(UPLOAD_IMAGES, uploadImagesWorker)
}

export function* saveImageWorker(action: ImageAdded) {
  // tslint:disable-next-line:no-unsafe-any
  const imageRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.id)
  yield imageRef.set({ width: action.width, height: action.height })
}

export function* imageAddedSaga() {
  yield takeEvery(questionsActionTypes.QUESTION_IMAGE_ADDED, saveImageWorker)
}

export function* removeImageWorker(action: ImageRemoved) {
  // tslint:disable-next-line:no-unsafe-any
  const imageRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.imageId)
  yield imageRef.set(null)
}

export function* imageRemovedSaga() {
  yield takeEvery(
    questionsActionTypes.QUESTION_IMAGE_REMOVED,
    removeImageWorker,
  )
}

export function* imageUploadedWorker(action: ImageUploaded) {
  // tslint:disable-next-line:no-unsafe-any
  const imageRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.imageId)
  yield imageRef.child("storagePath").set(action.storagePath)
}

export function* imageUploadedSaga() {
  yield takeEvery(IMAGE_UPLOADED, imageUploadedWorker)
}

export function* sellerImageAddedSaga() {
  yield takeEvery(sellerActionTypes.IMAGE_ADDED, saveImageWorker)
}

export function* sellerImageRemovedSaga() {
  yield takeEvery(sellerActionTypes.IMAGE_REMOVED, removeImageWorker)
}

// Similar to upload image, but not connected to request id
export function* uploadFileWorker(action: FileUpload) {
  // Save file to the images object
  const imageRef = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.file.id)
  yield imageRef.set({ width: action.file.width, height: action.file.height })

  const field = `${STORAGE_PATHS.IMAGES}/${STORAGE_PATHS.SELLER_FILES}/${action.userId}/${action.file.name}`
  const status = yield imageUploader(field, action.file.uri, action.file.id)
  if (status === 200) {
    const imageStorageRef = yield firebase
      .storage()
      .ref()
      .child(STORAGE_PATHS.IMAGES)
      .child(STORAGE_PATHS.SELLER_FILES)
      .child(action.userId)
      .child(action.file.name)
    yield put(
      imageUploaded({
        imageId: action.file.id,
        storagePath: imageStorageRef.fullPath,
      }),
    )
  } else {
    console.error("Failed to upload file: HTTP status " + status)
  }
}

export function* uploadFileSaga() {
  yield takeEvery(sellerActionTypes.FILE_UPLOAD, uploadFileWorker)
}

export default function*() {
  yield all([
    fork(sellerImageAddedSaga),
    fork(sellerImageRemovedSaga),
    fork(imageAddedSaga),
    fork(imageRemovedSaga),
    fork(imageUploadedSaga),
    fork(uploadOfferRequestImagesSaga),
    fork(subscribeToImageSaga),
    fork(uploadFileSaga),
  ])
}
