import Colors from "constants/Colors"
import { buttonText } from "constants/Fonts"
import { StyleSheet, ViewStyle, ImageStyle, TextStyle } from "react-native"

interface Styles {
  container: ViewStyle
  thumbnail: ImageStyle
  item: ViewStyle
  buttonWrapper: ViewStyle
  button: ViewStyle
  buttonText: ViewStyle
  toast: ViewStyle
  toastText: TextStyle
  buttonDisabled: ViewStyle
  buttonTextDisabled: TextStyle
  textStyle: TextStyle
  textarea: ViewStyle

}

export default StyleSheet.create<Styles>({
  container: {
    backgroundColor: Colors.containerBackground,
    paddingHorizontal: 0,
  },
  thumbnail: {
    margin: 30,
    alignSelf: "center",
  },
  item: {
    right: 10,
  },
  buttonWrapper: {
    margin: 15,
    marginTop: 25,
  },
  button: {
    backgroundColor: Colors.brandYellow,
  },
  buttonText: {
    ...buttonText,
    color: "#ffffff",
  },
  toast: {
    bottom: 60,
  },
  toastText: {
    textAlign: "center",
  },
  buttonDisabled: {
    backgroundColor: Colors.containerBackground,
    borderColor: Colors.borderColor,
    borderWidth: 1,
  },
  buttonTextDisabled: {
    color: Colors.darkerGrey,
  },
  textStyle:{
    marginTop:10,
    fontSize:12,
    marginLeft:10,
    marginBottom:2,
    color:Colors.textColor,
  },
  textarea:{
    color: Colors.black,
    backgroundColor: Colors.white,
    width:1700,
    height:60,
    borderColor: Colors.black,
    paddingBottom:20,
    marginBottom:10,
  },
})
