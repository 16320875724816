import {
  BOOKING_SELECTED,
  MARK_OFFER_AS_SEEN,
  ACCEPT_EXTENDED_DURATION,
  DECLINE_EXTENDED_DURATION,
  OFFER_UPDATED,
  OFFER_SELECTED,
  OFFER_STATUS_UPDATED,
} from "./actionTypes"
import { OFFER_STATUS } from "constants/Status"
import { Offer } from "./types"

export interface OfferUpdated {
  type: typeof OFFER_UPDATED
  offer: Offer
}
export const offerUpdated: (offer: Offer) => OfferUpdated = offer => {
  return {
    type: OFFER_UPDATED,
    offer,
  }
}

export interface OfferSelected {
  type: typeof OFFER_SELECTED
  offerId: string
}
export const offerSelected: (offerId: string) => OfferSelected = offerId => {
  return {
    type: OFFER_SELECTED,
    offerId,
  }
}

export interface BookingSelected {
  type: typeof BOOKING_SELECTED
  offerId: string
}
export const bookingSelected: (
  offerId: string,
) => BookingSelected = offerId => {
  return {
    type: BOOKING_SELECTED,
    offerId,
  }
}

interface OfferStatusUpdatedProps {
  offerId: string
  offerRequestId: string
  status: OFFER_STATUS
}
export interface OfferStatusUpdated extends OfferStatusUpdatedProps {
  type: typeof OFFER_STATUS_UPDATED
}
export const offerStatusUpdated: (
  props: OfferStatusUpdatedProps,
) => OfferStatusUpdated = props => {
  return {
    type: OFFER_STATUS_UPDATED,
    ...props,
  }
}

export interface MarkOfferAsSeen {
  type: typeof MARK_OFFER_AS_SEEN
  offerId: string
  seenByBuyer: true
}
export const markOfferAsSeen: (props: {
  offerId: string
  seenByBuyer: true
}) => MarkOfferAsSeen = props => {
  return {
    type: MARK_OFFER_AS_SEEN,
    ...props,
  }
}

interface AcceptExtensionProps {
  offerId: string
  duration: number
  totalPrice: number
}
export interface AcceptExtension extends AcceptExtensionProps {
  type: typeof ACCEPT_EXTENDED_DURATION
}
export const acceptExtension: (
  props: AcceptExtensionProps,
) => AcceptExtension = props => {
  return {
    type: ACCEPT_EXTENDED_DURATION,
    ...props,
  }
}

export interface DeclineExtension {
  type: typeof DECLINE_EXTENDED_DURATION
  offerId: string
}
export const declineExtension: (
  offerId: string,
) => DeclineExtension = offerId => {
  return {
    type: DECLINE_EXTENDED_DURATION,
    offerId,
  }
}
