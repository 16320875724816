import { connect } from "react-redux"
import firebase from "firebase"
import { StackActions } from "@react-navigation/native"
import { getVersion } from "api/environment"
import routeNames from "components/navigators/constants"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { logOut } from "modules/User/actions"
import { getPushToken } from "modules/Notifications/selectors"
import { getBuyerProfileForUser } from "modules/BuyerProfiles/selectors"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => ({
  expoPushToken: getPushToken(state),
  buyerProfile: getBuyerProfileForUser(state),
})

const mapDispatchToProps = {
  logOut,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...ownProps,
  versionData: getVersion(),
  logOut: () => {
    const prevUser = firebase.auth().currentUser
    if (prevUser) {
      dispatchProps.logOut({
        userId: prevUser.uid,
        expoPushToken: stateProps.expoPushToken,
      })
    }
    ownProps.navigation.dispatch(StackActions.replace(routeNames.ROOT.SIGN_IN))
  },
})

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
