import { connect } from "react-redux"
import View from "./view"
import { Navigation } from "components/types"
import { Store } from "utilities/Redux/store"
import { getUserInfoValidated, getUser } from "modules/User/selectors"
import { updateUser } from "modules/User/actions"
import { createBuyerProfile } from "modules/BuyerProfiles/actions"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const user = getUser(state)
  if (!user) {
    return {}
  }
  const validated = getUserInfoValidated(state)
  const name = user.name
  const splittedName = name.split(" ")
  const firstName = splittedName[0]
  return {
    user: {
      ...user,
      firstName: user.firstName || firstName,
      lastName: user.lastName || name.slice(firstName.length).trim(),
    },
    validated,
  }
}

const mapDispatchToProps = {
  updateUser,
  createBuyerProfile,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  firstNameChanged: (firstName: string) => {
    if (stateProps.user) {
      dispatchProps.updateUser({
        ...stateProps.user,
        firstName,
      })
    }
  },
  lastNameChanged: (lastName: string) => {
    if (stateProps.user) {
      dispatchProps.updateUser({
        ...stateProps.user,
        lastName,
      })
    }
  },
  emailChanged: (email: string) => {
    if (stateProps.user) {
      dispatchProps.updateUser({
        ...stateProps.user,
        email,
      })
    }
  },
  updatePhone: (phone: string) => {
    if (stateProps.user) {
      dispatchProps.updateUser({
        ...stateProps.user,
        phone,
      })
    }
  },
  confirm: () => {
    const { user } = stateProps
    if (user) {
      // Mark info confirmed
      dispatchProps.updateUser({
        ...user,
        hasBuyerProfile: true,
      })

      dispatchProps.createBuyerProfile({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        photoURL: user.photoURL,
      })

      // Navigate back
      ownProps.navigation.goBack()
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
