import { connect } from "react-redux"
import { offerRequestStatusUpdate } from "modules/Buyer/OfferRequests/actions"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import { Navigation } from "components/types"
import { getSelectedOfferRequestForBuyer } from "modules/Buyer/OfferRequests/selectors"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => ({
  offerRequest: getSelectedOfferRequestForBuyer(state),
})

const mapDispatchToProps = {
  offerRequestStatusUpdate,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...ownProps,
  onRemoveClicked: (offerRequest: OfferRequest) => {
    dispatchProps.offerRequestStatusUpdate({
      offerRequestId: offerRequest.id,
      status: "cancelled",
    })
    ownProps.navigation.goBack()
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
