import _ from "lodash"
import { normalizeQuestionsFromCategories } from "utilities/Redux/normalizeHelpers"
import {
  CheckboxOptionChecked,
  RadioOptionChecked,
  TextChanged,
  DurationChange,
  NumberChanged,
  OnSuitableTimeSelected,
  PreferredTimeChanged,
  ImageAdded,
  ImageRemoved,
  SetSuccessModalVisibility,
  OpenInputModal,
  CloseInputModal,
  ShouldScrollToBottom,
  SetAsSelectedDateQuestion,
  ToggleScrollEnabled,
  ResetSuitableTimes,
  UpdateErrorVisibility,
  UpdateScrollPosition,
  UpdateQuestionPosition,
} from "./actions"
import {
  QuestionsState,
  CheckBoxQuestion,
  RadioQuestion,
  TextAreaQuestion,
  NumberQuestion,
  DateQuestion,
  ListPicker,
  ImageQuestion,
  NumberInputQuestion,
} from "./types"
import {
  QUESTION_CHECKBOX_OPTION_CHECKED,
  QUESTION_RADIO_OPTION_CHECKED,
  QUESTION_TEXT_CHANGED,
  QUESTION_DURATION_CHANGED,
  QUESTION_NUMBER_CHANGED,
  QUESTION_SUITABLE_TIME_SELECTED,
  QUESTION_PREFFERED_TIME_CHANGED,
  QUESTION_IMAGE_ADDED,
  QUESTION_IMAGE_REMOVED,
  SET_SUCCESS_MODAL_VISIBILITY,
  OPEN_INPUT_MODAL,
  CLOSE_INPUT_MODAL,
  SCROLL_TO_BOTTOM,
  SET_AS_SELECTED_DATE_QUESTION,
  TOGGLE_SCROLL_ENABLED,
  UPDATE_QUESTION_POSITION,
  UPDATE_SCROLL_POSITION,
  UPDATE_ERROR_VISIBILITY,
  RESET_SUITABLE_TIMES,
} from "./actionTypes"
import { CATEGORIES_UPDATED } from "../Categories/actionTypes"
import { CategoriesUpdated } from "../Categories/actions"

export const INITIAL_STATE: QuestionsState = {
  questions: {},
  successModalVisible: false,
  scrollToBottom: false,
  inputModalQuestionId: undefined,
  scrollEnabled: true,
  scrollPosition: 0,
  selectedDateQuestionId: undefined,
}

type Actions =
  | CheckboxOptionChecked
  | RadioOptionChecked
  | TextChanged
  | DurationChange
  | NumberChanged
  | OnSuitableTimeSelected
  | PreferredTimeChanged
  | ImageAdded
  | ImageRemoved
  | SetSuccessModalVisibility
  | OpenInputModal
  | CloseInputModal
  | ShouldScrollToBottom
  | CategoriesUpdated
  | SetAsSelectedDateQuestion
  | ToggleScrollEnabled
  | ResetSuitableTimes
  | UpdateErrorVisibility
  | UpdateScrollPosition
  | UpdateQuestionPosition

export default function questionsReducer(
  state = INITIAL_STATE,
  action: Actions,
): QuestionsState {
  switch (action.type) {
    case QUESTION_CHECKBOX_OPTION_CHECKED:
      if (state.questions[action.questionId].type === "checkbox") {
        const existingQuestion = state.questions[
          action.questionId
        ] as CheckBoxQuestion
        const updatedQuestion: CheckBoxQuestion = {
          ...existingQuestion,
          options: {
            ...existingQuestion.options,
            [action.optionId]: {
              ...existingQuestion.options[action.optionId],
              checked: action.checked,
            },
          },
        }
        return {
          ...state,
          questions: {
            ...state.questions,
            [action.questionId]: updatedQuestion,
          },
        }
      }
      return state
    case QUESTION_RADIO_OPTION_CHECKED:
      if (state.questions[action.questionId].type === "radio") {
        return {
          ...state,
          questions: {
            ...state.questions,
            [action.questionId]: {
              ...(state.questions[action.questionId] as RadioQuestion),
              selectedOption: action.optionId,
            },
          },
        }
      }
      return state
    case QUESTION_TEXT_CHANGED:
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.questionId]: {
            ...(state.questions[action.questionId] as
              | TextAreaQuestion
              | NumberInputQuestion),
            value: action.value,
          },
        },
      }
    case QUESTION_DURATION_CHANGED:
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.questionId]: {
            ...(state.questions[action.questionId] as ListPicker),
            value: action.value,
          },
        },
      }
    case QUESTION_NUMBER_CHANGED:
      if (state.questions[action.questionId].type === "number") {
        return {
          ...state,
          questions: {
            ...state.questions,
            [action.questionId]: {
              ...(state.questions[action.questionId] as NumberQuestion),
              value: action.value,
            },
          },
        }
      }
      return state
    case QUESTION_SUITABLE_TIME_SELECTED:
      if (state.questions[action.questionId].type === "date") {
        const existingQuestion = state.questions[
          action.questionId
        ] as DateQuestion
        const currentSuitableTimes = existingQuestion.suitableTimes || {}
        const updatedSuitableTimes = currentSuitableTimes[action.dateKey]
          ? _.omit(currentSuitableTimes, action.dateKey)
          : _.set(currentSuitableTimes, action.dateKey, {
              day: action.day,
              startTime: action.startTime,
              endTime: action.endTime,
            })
        return {
          ...state,
          questions: {
            ...state.questions,
            [action.questionId]: {
              ...existingQuestion,
              suitableTimes: updatedSuitableTimes,
              suitableTimesCount: Object.keys(updatedSuitableTimes).length,
            },
          },
        }
      }
      return state
    case QUESTION_PREFFERED_TIME_CHANGED:
      if (state.questions[action.questionId].type === "date") {
        const existingQuestion = state.questions[
          action.questionId
        ] as DateQuestion
        return {
          ...state,
          questions: {
            ...state.questions,
            [action.questionId]: {
              ...existingQuestion,
              preferredTime: action.timestamp,
            },
          },
        }
      }
      return state
    case QUESTION_IMAGE_ADDED:
      if (state.questions[action.questionId].type === "image") {
        const existingQuestion = state.questions[
          action.questionId
        ] as ImageQuestion
        return {
          ...state,
          questions: {
            ...state.questions,
            [action.questionId]: {
              ...existingQuestion,
              images: {
                ...existingQuestion.images,
                [action.id]: true,
              },
              imageCount: (existingQuestion.imageCount || 0) + 1,
            },
          },
        }
      }
      return state
    case QUESTION_IMAGE_REMOVED:
      if (state.questions[action.questionId].type === "image") {
        const existingQuestion = state.questions[
          action.questionId
        ] as ImageQuestion
        if (!existingQuestion.images) {
          return state
        }
        const images = existingQuestion.images[action.imageId]
        if (images[action.imageId]) {
          const updatedImages = Object.fromEntries(
            Object.entries(images).filter(
              imageKeyVal => imageKeyVal[0] !== action.imageId,
            ),
          )
          return {
            ...state,
            questions: {
              ...state.questions,
              [action.questionId]: {
                ...existingQuestion,
                images: updatedImages,
                imageCount: existingQuestion.imageCount
                  ? existingQuestion.imageCount - 1
                  : 0,
              },
            },
          }
        }
      }
      return state
    case SET_SUCCESS_MODAL_VISIBILITY:
      return {
        ...state,
        successModalVisible: action.visible,
      }
    case OPEN_INPUT_MODAL:
      return {
        ...state,
        inputModalQuestionId: action.questionId,
      }
    case CLOSE_INPUT_MODAL:
      return {
        ...state,
        inputModalQuestionId: undefined,
      }
    case SCROLL_TO_BOTTOM:
      return {
        ...state,
        scrollToBottom: action.scrollStatus || false,
      }
    case CATEGORIES_UPDATED:
      return {
        ...state,
        questions: normalizeQuestionsFromCategories(action.categories),
      }
    case SET_AS_SELECTED_DATE_QUESTION:
      return {
        ...state,
        selectedDateQuestionId: action.questionId,
      }
    case TOGGLE_SCROLL_ENABLED:
      return {
        ...state,
        scrollEnabled: action.scrollEnabled,
      }
    case RESET_SUITABLE_TIMES:
      if (state.questions[action.questionId].type === "date") {
        const existingQuestion = state.questions[
          action.questionId
        ] as DateQuestion
        return {
          ...state,
          questions: {
            ...state.questions,
            [action.questionId]: {
              ...existingQuestion,
              suitableTimes: undefined,
              suitableTimesCount: undefined,
              preferredTime: undefined,
            },
          },
        }
      }
      return state
    case UPDATE_ERROR_VISIBILITY:
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.questionId]: {
            ...state.questions[action.questionId],
            showError: action.showError,
          },
        },
      }
    case UPDATE_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.scrollPosition,
      }
    case UPDATE_QUESTION_POSITION:
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.questionId]: {
            ...state.questions[action.questionId],
            position: action.position,
          },
        },
      }
    default:
      return state
  }
}
