import { PixelRatio, StyleSheet, ViewStyle, TextStyle } from "react-native"
import Colors from "constants/Colors"

interface Styles {
  logoContainerStyle: ViewStyle
  headerStyle: ViewStyle
  headerStyleNoBorder: ViewStyle
  headerTitleStyle: TextStyle
  tabStyle: ViewStyle
  tabIndicatorStyle: ViewStyle
  labelStyle: TextStyle
}

export const headerStyles = StyleSheet.create<Styles>({
  logoContainerStyle: {
    flexDirection: "row",
    height: 40,
    alignSelf: "center",
    borderWidth: 0,
  },
  headerStyle: {
    backgroundColor: Colors.headerBackground,
    shadowOpacity: 0,
    elevation: 0,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderBottomColor: Colors.borderColor,
    borderTopWidth: 0,
    marginTop: 0,
  },
  headerStyleNoBorder: {
    backgroundColor: Colors.headerBackground,
    shadowOpacity: 0,
    elevation: 0,
    borderBottomWidth: 0,
  },
  headerTitleStyle: {
    color: Colors.textColor,
    fontSize: 17,
    fontFamily: "open-sans-bold",
    flex: 1,
  },
  tabStyle: {
    paddingTop: 4,
    paddingBottom: 12,
    opacity: 1,
  },
  tabIndicatorStyle: {
    backgroundColor: Colors.brandYellow,
    height: 2,
  },
  labelStyle: {
    fontSize: 14,
    fontFamily: "open-sans-regular",
  },
})
