import { getEventChannelForFirebaseRef } from "utilities/Redux/sagaHelpers"
import firebase from "firebase"
import {
  all,
  call,
  take,
  takeEvery,
  fork,
  put,
  select,
} from "redux-saga/effects"
import { AUTH_STATUS_CHANGED, LOG_OUT } from "../User/actionTypes"
import { OPEN_OFFER_REQUEST_UPDATED } from "../Seller/OfferRequests/actionTypes"
import { FIREBASE_PATH } from "./constants"
import { getBuyerProfileForId } from "./selectors"
import {
  buyerProfileUpdated,
  buyerProfileAdd,
  CreateBuyerProfile,
} from "./actions"
import { BUYER_PROFILE_CREATE } from "./actionTypes"
import { LogOut, AuthStatusChanged } from "../User/actions"
import { OfferRequestUpdated } from "../Seller/OfferRequests/actions"
import { BuyerProfile } from "./types"
import { BuyerProfileDB } from "database_types/buyerProfile"

const subscribed = {}
export function* subscribeToBuyerProfileWorker(action: AuthStatusChanged) {
  if (!action.user || subscribed[action.user.id]) {
    return
  }
  yield (subscribed[action.user.id] = true)

  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.user.id)
  const channel = yield call(getEventChannelForFirebaseRef, ref)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const { values: buyer }: { values: BuyerProfileDB } = yield take(channel)
    yield put(
      buyerProfileUpdated({
        id: action.user.id,
        ...buyer,
      }),
    )
  }
}

export function* subscribeToBuyerProfileSaga() {
  yield takeEvery(AUTH_STATUS_CHANGED, subscribeToBuyerProfileWorker)
}

export function* unsubscribeToBuyerProfileWorker(action: LogOut) {
  if (!subscribed[action.userId]) {
    return
  }
  yield (subscribed[action.userId] = false)

  firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.userId)
    .off()
}

export function* unsubscribeToBuyerProfileSaga() {
  yield takeEvery(LOG_OUT, unsubscribeToBuyerProfileWorker)
}

export function* createBuyerProfileWorker(action: CreateBuyerProfile) {
  // tslint:disable-next-line:no-unsafe-any
  const buyerRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.buyerProfile.id)
  // tslint:disable-next-line:no-unsafe-any
  const snapshot: firebase.database.DataSnapshot = yield buyerRef.once("value")
  // if Buyer does not exist
  if (snapshot.val() === null) {
    yield snapshot.ref.set({
      createdAt: firebase.database.ServerValue.TIMESTAMP,
      ...action.buyerProfile,
    })
  } else {
    yield snapshot.ref.update({
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
      ...action.buyerProfile,
    })
  }
}

export function* createBuyerProfileSaga() {
  yield takeEvery(BUYER_PROFILE_CREATE, createBuyerProfileWorker)
}

export function* subscribeToAddBuyerProfileWorker(action: OfferRequestUpdated) {
  // tslint:disable-next-line:no-unsafe-any
  const buyerProfile: BuyerProfile | undefined = yield select(
    getBuyerProfileForId,
    action.offerRequest.buyerProfile,
  )
  if (!buyerProfile) {
    // tslint:disable-next-line:no-unsafe-any
    const buyerRef: firebase.database.Reference = yield firebase
      .database()
      .ref(FIREBASE_PATH)
      .child(action.offerRequest.buyerProfile)
    // tslint:disable-next-line:no-unsafe-any
    const snapshot: firebase.database.DataSnapshot = yield buyerRef.once(
      "value",
    )
    yield put(
      buyerProfileAdd({
        id: action.offerRequest.buyerProfile,
        ...snapshot.val(),
      }),
    )
  }
}

export function* addBuyerProfileSaga() {
  yield takeEvery(OPEN_OFFER_REQUEST_UPDATED, subscribeToAddBuyerProfileWorker)
}

export default function*() {
  yield all([
    fork(createBuyerProfileSaga),
    fork(subscribeToBuyerProfileSaga),
    fork(unsubscribeToBuyerProfileSaga),
    fork(addBuyerProfileSaga),
  ])
}
