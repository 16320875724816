import { StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  modal: {
    flex: 1,
    backgroundColor: "black",
    opacity: 0.9,
  },
  closeTextTouchable: {
    position: "absolute",
    top: 20,
    right: 0,
  },
  closeText: {
    color: Colors.contentBackground,
    textAlign: "right",
    margin: 15,
  },
  carouselContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  dotsContainer: {
    position: "absolute",
    bottom: 45,
    left: 0,
    right: 0,
  },
  dot: {
    backgroundColor: "white",
    width: 6,
    height: 6,
    borderRadius: 3,
    marginHorizontal: 0,
  },
})
