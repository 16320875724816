import React from "react"
import { ActivityIndicator, Text } from "react-native"
import { Container, Content } from "native-base"
import I18n from "i18n"
import styles from "./styles"

export default () => (
  <Container style={styles.container}>
    <Content>
      <ActivityIndicator style={styles.spinner} />
      <Text style={styles.text}>{`${I18n.t("updating")}...`}</Text>
    </Content>
  </Container>
)
