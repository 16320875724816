import {
  CREATE_OFFER,
  OFFER_CREATED,
  SET_DEFAULT_OFFER_VALUE,
  CALENDAR_DATE_SELECTED,
  START_TIME_SELECTED,
  START_TIME_IS_OVERLAPPING,
  OFFER_VALUE_CHANGED,
} from "./actionTypes"
import { NewOffer, TempOffer } from "./types"
import { Offer } from "Buyer/Offers/types"
import { SuitableTime } from "Buyer/Questions/types"

export interface CreateOffer {
  type: typeof CREATE_OFFER
  offer: NewOffer
}
export const createOffer: (offer: NewOffer) => CreateOffer = offer => {
  return {
    type: CREATE_OFFER,
    offer,
  }
}

export interface OfferCreated {
  type: typeof OFFER_CREATED
  offer: Required<Offer>
}
export const offerCreated: (offer: Required<Offer>) => OfferCreated = offer => {
  return {
    type: OFFER_CREATED,
    offer,
  }
}

export interface CalendarDateSelected {
  type: typeof CALENDAR_DATE_SELECTED
  date: SuitableTime["day"] | null
}
export const calendarDateSelected: (
  date: SuitableTime["day"] | null,
) => CalendarDateSelected = date => {
  return {
    type: CALENDAR_DATE_SELECTED,
    date,
  }
}

export interface StartTimeSelected {
  type: typeof START_TIME_SELECTED
  selectedStartTime: string | null
}
export const startTimeSelected: (
  selectedStartTime: string | null,
) => StartTimeSelected = selectedStartTime => {
  return {
    type: START_TIME_SELECTED,
    selectedStartTime,
  }
}

export interface StartTimeIsOverlapping {
  type: typeof START_TIME_IS_OVERLAPPING
  isOverlapping: boolean
}
export const startTimeIsOverlapping: (
  isOverlapping: boolean,
) => StartTimeIsOverlapping = isOverlapping => {
  return {
    type: START_TIME_IS_OVERLAPPING,
    isOverlapping,
  }
}

export interface OfferValueChangedProps {
  key: keyof Pick<TempOffer, "pricePerHour" | "duration" | "startTime">
  value: number
  offerRequestId: string
}
export interface OfferValueChanged extends OfferValueChangedProps {
  type: typeof OFFER_VALUE_CHANGED
}
export const offerValueChanged: (
  props: OfferValueChangedProps,
) => OfferValueChanged = props => {
  return {
    type: OFFER_VALUE_CHANGED,
    ...props,
  }
}

export interface DefaultValue {
  duration?: number | undefined
  isFixedDuration?: boolean
  price?: number | undefined
  isFixedPrice?: boolean
}
export interface SetDefaultOfferValueProps {
  defaultValue: DefaultValue
  offerRequestId: string
}

export interface SetDefaultOfferValue extends SetDefaultOfferValueProps {
  type: typeof SET_DEFAULT_OFFER_VALUE
}

export const setDefaultOfferValue: (
  props: SetDefaultOfferValueProps,
) => SetDefaultOfferValue = props => {
  return {
    type: SET_DEFAULT_OFFER_VALUE,
    ...props,
  }
}
