import {
  OFFER_UPDATED,
  OFFER_STATUS_UPDATED,
  OFFER_STATUS_UPDATE,
  OFFER_SELECTED,
  MARK_OFFER_AS_SEEN,
  START_JOB,
  REQUEST_OFFER_DURATION_EXTENSION,
  MARK_PAST_JOB_COMPLETED,
  STOP_JOB,
  PAUSE_JOB,
} from "./actionTypes"
import { Offer } from "Buyer/Offers/types"

export interface OfferUpdated {
  type: typeof OFFER_UPDATED
  offer: Offer
}
export const offerUpdated: (offer: Offer) => OfferUpdated = offer => {
  return {
    type: OFFER_UPDATED,
    offer,
  }
}

export interface OfferStatusUpdated {
  type: typeof OFFER_STATUS_UPDATED
  offerId: string
  offerRequestId: string
}
export const offerStatusUpdated: (
  offer: Offer,
) => OfferStatusUpdated = offer => {
  return {
    type: OFFER_STATUS_UPDATED,
    offerId: offer.id,
    offerRequestId: offer.offerRequest,
  }
}

export interface OfferStatusUpdate {
  type: typeof OFFER_STATUS_UPDATE
  offer: Offer
}
export const offerStatusUpdate: (offer: Offer) => OfferStatusUpdate = offer => {
  return {
    type: OFFER_STATUS_UPDATE,
    offer,
  }
}

export interface OfferSelected {
  type: typeof OFFER_SELECTED
  offerId: string
}
export const offerSelected: (offerId: string) => OfferSelected = offerId => {
  return {
    type: OFFER_SELECTED,
    offerId,
  }
}

export interface MarkOfferAsSeen {
  type: typeof MARK_OFFER_AS_SEEN
  offerId: string
  seenBySeller: true
}
export const markOfferAsSeen: (
  offerId: string,
) => MarkOfferAsSeen = offerId => {
  return {
    type: MARK_OFFER_AS_SEEN,
    offerId,
    seenBySeller: true,
  }
}

export interface StartJob {
  type: typeof START_JOB
  offer: Offer
}
export const startJob: (offer: Offer) => StartJob = offer => {
  return {
    type: START_JOB,
    offer,
  }
}

interface PauseJobProps {
  offer: Offer
  isPaused: boolean
}

export interface PauseJob {
  type: typeof PAUSE_JOB
  offer: Offer
  isPaused: boolean
}

export const pauseJob: ({ offer, isPaused }: PauseJobProps) => PauseJob = ({
  offer,
  isPaused,
}) => {
  return {
    type: PAUSE_JOB,
    offer,
    isPaused,
  }
}

export interface RequestToExtendOfferDurationProps {
  offer: Offer
  extendedDuration: number
}
export interface RequestToExtendOfferDuration
  extends RequestToExtendOfferDurationProps {
  type: typeof REQUEST_OFFER_DURATION_EXTENSION
}
export const requestToExtendOfferDuration: (
  props: RequestToExtendOfferDurationProps,
) => RequestToExtendOfferDuration = props => {
  return {
    type: REQUEST_OFFER_DURATION_EXTENSION,
    ...props,
  }
}

export interface StopJob {
  type: typeof STOP_JOB
  offer: Offer
}
export const stopJob: (offer: Offer) => StopJob = offer => {
  return {
    type: STOP_JOB,
    offer,
  }
}

export interface MarkPastJobCompletedProps {
  offerId: string
  actualStartTime: number
  actualCompletedTime: number
}
export interface MarkPastJobCompleted extends MarkPastJobCompletedProps {
  type: typeof MARK_PAST_JOB_COMPLETED
}
export const markPastJobCompleted: (
  props: MarkPastJobCompletedProps,
) => MarkPastJobCompleted = props => {
  return {
    type: MARK_PAST_JOB_COMPLETED,
    ...props,
  }
}
