import _ from "lodash"
import React from "react"
import { Button, Container, Content, Text } from "native-base"
import { View } from "react-native"
import I18n from "i18n"
import AddressForm from "./AddressForm"
import styles from "./styles"
import { Category } from "modules/Buyer/Categories/types"

interface Props {
  hasErrors: boolean
  category?: Category
  
  onButtonClicked: () => void
  
}

const AddressMain = () => {
  return (
    <View>
      <AddressForm addressType="main" />
    </View>
  )
}

const AddressFromTo = () => (
  <View>
    <View style={{ marginBottom: 15 }}>
      <Text style={styles.formTitle}>{I18n.t("start_address")}</Text>
      <AddressForm addressType="from" />
    </View>
    <View>
      <Text style={styles.formTitle}>{I18n.t("destination_address")}</Text>
      <AddressForm addressType="to" />
    </View>
  </View>
)

export default (props: Props) => {
  if (!props.category) {
    return null
  }
  return (
    <Container style={styles.container}>
      <Content contentContainerStyle={{ paddingBottom: 60 }}>
        {props.category.addresses.type === "basic" ? <AddressMain /> : null}
        {props.category.addresses.type === "delivery" ? (
          <AddressFromTo />
        ) : null}
      </Content>
      { !props.hasErrors ? (
        <Button
          rounded
          block
          style={styles.button}
          onPress={props.onButtonClicked}
        >
          <Text style={styles.buttonText}>{I18n.t("send_offer_request")}</Text>
        </Button>
      ) : null}
     
    </Container>
  )
}
