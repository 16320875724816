import { StyleSheet } from "react-native"
import Colors from "constants/Colors"
import { buttonText } from "constants/Fonts"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.containerBackground,
  },
  buttonWrapper: {
    bottom: 15,
    left: 15,
    right: 15,
    position: "absolute",
  },
  button: {
    backgroundColor: Colors.brandYellow,
  },
  buttonText: {
    ...buttonText,
    color: "white",
  },
})
