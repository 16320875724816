import React from "react"
import { View } from "react-native"
import { Button, Container, Tab, Tabs, Text } from "native-base"
import OrderDetails from "components/views/Shared/OrderDetails"
import Profile from "components/views/Shared/BuyerProfile"
import Chat from "components/views/Shared/Chat"
import I18n from "i18n"
import { showChat } from "utilities/chat"
import { OFFER_REQUEST_STATUS } from "constants/Status"
import styles from "./styles"
import { Offer } from "modules/Buyer/Offers/types"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import { BuyerProfile } from "modules/BuyerProfiles/types"

interface Props {
  markOfferAsSeen: () => void
  offer?: Offer
  offerRequest?: OfferRequest
  buyerProfile?: BuyerProfile
  onRemoveOfferClicked: () => void
}

class OfferView extends React.Component<Props> {
  componentDidMount() {
    this.props.markOfferAsSeen()
  }

  render() {
    const {
      offer,
      offerRequest,
      buyerProfile,
      onRemoveOfferClicked,
    } = this.props
    if (!offer || !buyerProfile || !offerRequest) {
      return null
    }
    const shouldShowChat = showChat(offer)
    return (
      <Container style={styles.container}>
        <Profile buyerProfile={buyerProfile} />
        <Tabs
          scrollWithoutAnimation={false}
          tabBarUnderlineStyle={styles.tabBarUnderlineStyle}
        >
          {shouldShowChat ? (
            <Tab
              heading="Chat"
              tabStyle={styles.tabStyle}
              activeTabStyle={styles.activeTabStyle}
              textStyle={styles.textStyle}
              activeTextStyle={styles.activeTextStyle}
            >
              <Chat offer={offer} />
            </Tab>
          ) : null}
          <Tab
            heading={I18n.t("offer_details")}
            tabStyle={styles.tabStyle}
            activeTabStyle={styles.activeTabStyle}
            textStyle={styles.textStyle}
            activeTextStyle={styles.activeTextStyle}
          >
            <OrderDetails offerRequest={offerRequest} offer={offer} />
          </Tab>
        </Tabs>
        {offerRequest.status === OFFER_REQUEST_STATUS.OPEN ? (
          <View style={styles.buttonWrap}>
            <Button
              block
              rounded
              onPress={onRemoveOfferClicked}
              style={styles.button}
            >
              <Text style={styles.buttonText}>{I18n.t("remove_offer")}</Text>
            </Button>
          </View>
        ) : null}
      </Container>
    )
  }
}
export default OfferView
