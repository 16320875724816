import React from "react"
import { ListItem, Text, Toast } from "native-base"
import I18n from "i18n"
import _ from "lodash"
import styles from "./styles"
import { TempOffer } from "modules/Seller/CreateOffer/types"
import { formatDateAndTime, getMoment } from "utilities/time"

interface Props {
  tempOffer?: TempOffer
  openCalendar: () => void
}

export default ({ tempOffer, openCalendar }: Props) => {
  if (tempOffer && tempOffer.duration) {
    return (
      <ListItem button onPress={openCalendar} style={styles.formItem}>
        {tempOffer.startTime ? (
          <Text style={styles.inputText}>
            {formatDateAndTime(tempOffer.startTime, "dateAndTimeFormat")}-
            {getMoment(tempOffer.startTime)
              .add(tempOffer.duration, "hours")
              .format("HH:mm")}
          </Text>
        ) : (
          <Text style={styles.inputPlaceholder}>{I18n.t("date_and_time")}</Text>
        )}
      </ListItem>
    )
  }
  return (
    <ListItem
      button
      onPress={() => {
        Toast.show({
          text: I18n.t("first_duration"),
          position: "bottom",
          duration: 1000,
          style: { bottom: 100 },
          textStyle: { textAlign: "center" },
        })
      }}
      style={[styles.formItem, styles.formItemDisabled]}
    >
      <Text style={styles.inputPlaceholder}>{I18n.t("select_date_time")}</Text>
    </ListItem>
  )
}
