import { connect } from "react-redux"
import { offerRequestSelectedByBuyer } from "modules/Buyer/OfferRequests/actions"
import { CommonActions } from "@react-navigation/native"
import routeNames from "components/navigators/constants"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import { Category } from "modules/Buyer/Categories/types"
import { Navigation } from "components/types"
import { Offer } from "modules/Buyer/Offers/types"
import { getCategoryById } from "modules/Buyer/Categories/selectors"
import {
  getOpenOffersForRequestForBuyer,
  getUnseenOffersCountForOfferRequestSelector,
} from "modules/Buyer/Offers/selectors"
import { getOfferRequestByIdForBuyer } from "modules/Buyer/OfferRequests/selectors"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface OwnProps {
  offerRequestId: string
  navigation: Navigation
}

interface StateProps {
  category?: Category
  offerRequest?: OfferRequest
  offerCount: number
  offers?: Offer[]
}

const mapStateToProps = (initialState: Store, ownProps: OwnProps) => {
  const getUnseenOffersCountSelector = getUnseenOffersCountForOfferRequestSelector()
  const { offerRequestId } = ownProps
  return (state: Store): StateProps => {
    const offerRequest = getOfferRequestByIdForBuyer(state, offerRequestId)
    const category =
      offerRequest && getCategoryById(state, offerRequest.category)
    const offerCount = offerRequest
      ? getUnseenOffersCountSelector(state, offerRequest)
      : 0
    const offers =
      offerRequest && getOpenOffersForRequestForBuyer(state, offerRequest)
    return {
      category,
      offerRequest,
      offerCount,
      offers,
    }
  }
}

const mapDispatchToProps = {
  offerRequestSelected: offerRequestSelectedByBuyer,
}

let lock: boolean
const mergeProps = (
  stateProps: StateProps,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onPress: (offerRequest: OfferRequest, category: Category) => {
    if (lock) {
      return
    }
    lock = true
    dispatchProps.offerRequestSelected(offerRequest.id)
    ownProps.navigation.dispatch(
      CommonActions.navigate(routeNames.BUYER.REQUEST_OFFERS, {
        name: getLocaLizedName(category.name),
      }),
    )
    setTimeout(() => {
      lock = false
    }, 500)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
