import { OFFER_SELECTED, BOOKING_SELECTED, OFFER_UPDATED } from "./actionTypes"
import { OfferSelected, OfferUpdated, BookingSelected } from "./actions"
import { OfferRequestUpdated } from "../OfferRequests/actions"
import { OFFER_REQUEST_UPDATED } from "../OfferRequests/actionTypes"
import { BuyerOffersState } from "./types"

export const INITIAL_STATE: BuyerOffersState = {
  offers: {},
}

export default function buyerOfferReducer(
  state = INITIAL_STATE,
  action: OfferSelected | BookingSelected | OfferUpdated | OfferRequestUpdated,
): BuyerOffersState {
  switch (action.type) {
    case OFFER_SELECTED:
      return {
        ...state,
        selectedOffer: action.offerId,
      }
    case BOOKING_SELECTED:
      return {
        ...state,
        selectedBooking: action.offerId,
      }
    case OFFER_UPDATED:
      return {
        ...state,
        offers: {
          ...state.offers,
          [action.offer.id]: action.offer,
        },
      }
    case OFFER_REQUEST_UPDATED:
      if (!action.offerRequest.offers) {
        return state
      }
      const newOffers = Object.keys(action.offerRequest.offers)
        .filter(id => !state.offers[id])
        .reduce((offers, id) => {
          offers[id] = true
          return offers
        }, {})
      return {
        ...state,
        offers: {
          ...state.offers,
          ...newOffers,
        },
      }
    default:
      return state
  }
}
