import { connect } from "react-redux"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { getFeed } from "modules/Buyer/Feed/selectors"

const mapStateToProps = (state: Store) => ({
  feed: getFeed(state),
})

export default connect(mapStateToProps)(View)
