import { StyleSheet, ViewStyle, ImageStyle, TextStyle } from "react-native"
import Colors from "constants/Colors"
import { buttonText } from "constants/Fonts"
import { blacklist } from "validator"
import { relativeTimeRounding } from "moment"

interface Styles {
  container: ViewStyle
  thumbnail: ImageStyle
  wrapper: ViewStyle
  item: ViewStyle
  buttonWrapper: ViewStyle
  button: ViewStyle
  buttonText: ViewStyle
  toast: ViewStyle
  toastText: TextStyle
  buttonDisabled: ViewStyle
  buttonTextDisabled: TextStyle
  textStyle: TextStyle
  textarea: ViewStyle
}

export default StyleSheet.create<Styles>({
  container: {
    backgroundColor: Colors.containerBackground,
    paddingHorizontal: 0,
  },
 
  thumbnail: {
    margin: 30,
    alignSelf: "center",
  },

 
  wrapper: {},
 
  item: {
    right: 10,
    padding:100,
  },
  buttonWrapper: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 25,
    marginBottom: 15,
  },
  button: {
    backgroundColor: Colors.brandYellow,
  },
  buttonText: {
    ...buttonText,
    color: "#ffffff",
  },
  toast: {
    bottom: 60,
  },
  toastText: {
    textAlign: "center",
  },
  buttonDisabled: {
    backgroundColor: Colors.containerBackground,
    borderColor: Colors.borderColor,
    borderWidth: 1,
  },
  buttonTextDisabled: {
    backgroundColor: Colors.containerBackground,
    borderColor: Colors.borderColor,
    borderWidth: 1,
  },
  textStyle:{
    fontSize:8,
    marginLeft:10,
    marginBottom:2,
    color:Colors.textColor,
  },
  textarea:{
    color: Colors.black,
    backgroundColor: Colors.white,
    width:1700,
    height:60,
    borderColor: Colors.black,
    paddingBottom:20,
    marginBottom:10,
  },

})
