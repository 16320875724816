import React from "react"
import { Container, Tabs, Tab } from "native-base"
import Chat from "components/views/Shared/Chat"
import OrderDetails from "components/views/Shared/OrderDetails"
import Reviews from "components/views/Shared/Reviews"
import Profile from "components/views/Shared/SellerProfile"
import I18n from "i18n"
import { showChat } from "utilities/chat"
import styles from "./styles"
import { Offer } from "modules/Buyer/Offers/types"
import { SellerProfile, Review } from "modules/SellerProfiles/types"
import { OfferRequestDB } from "database_types/offerRequest"

interface Props {
  markOfferAsSeen: (offer: Offer) => void
  offer?: Offer
  offerRequest?: OfferRequestDB
  sellerProfile?: SellerProfile
  reviews?: Review[]
}

export default class BookingDetails extends React.Component<Props> {
  componentDidMount() {
    if (this.props.offer) {
      this.props.markOfferAsSeen(this.props.offer)
    }
  }
  render() {
    const { offer, offerRequest, sellerProfile, reviews } = this.props
    if (!sellerProfile || !offerRequest || !offer) {
      return null
    }
    const shouldShowChat = showChat(offer)
    return (
      <Container style={styles.container}>
        <Profile sellerProfile={sellerProfile} price={offer.pricePerHour} />
        <Tabs
          tabBarUnderlineStyle={styles.tabBarUnderlineStyle}
          contentProps={{ keyboardShouldPersistTaps: "always" }}
          scrollWithoutAnimation
        >
          {shouldShowChat ? (
            <Tab
              heading={I18n.t("chat")}
              tabStyle={styles.tabStyle}
              activeTabStyle={styles.activeTabStyle}
              textStyle={styles.textStyle}
              activeTextStyle={styles.activeTextStyle}
            >
              <Chat offer={offer} />
            </Tab>
          ) : null}
          <Tab
            heading={I18n.t("order_details")}
            tabStyle={styles.tabStyle}
            activeTabStyle={styles.activeTabStyle}
            textStyle={styles.textStyle}
            activeTextStyle={styles.activeTextStyle}
          >
            <OrderDetails offerRequest={offerRequest} offer={offer} />
          </Tab>
          <Tab
            heading={I18n.t("reviews")}
            tabStyle={styles.tabStyle}
            activeTabStyle={styles.activeTabStyle}
            textStyle={styles.textStyle}
            activeTextStyle={styles.activeTextStyle}
          >
            <Reviews
              reviews={reviews}
              sellerProfile={sellerProfile}
              withoutButton
            />
          </Tab>
        </Tabs>
      </Container>
    )
  }
}
