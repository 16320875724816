import { connect } from "react-redux"
import { getUser } from "modules/User/selectors"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { ChatMessage } from "modules/ChatMessages/types"

interface OwnProps {
  previousDate?: number
  message: ChatMessage
}

const mapStateToProps = (state: Store) => ({
  loggedUser: getUser(state),
})

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: null,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...ownProps,
})

export default connect(mapStateToProps, null, mergeProps)(View)
