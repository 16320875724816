import {
  all,
  call,
  takeEvery,
  fork,
  put,
  take,
  select,
} from "redux-saga/effects"
import { getEventChannelForFirebaseRef } from "utilities/Redux/sagaHelpers"
import { USER_UPDATED } from "modules/User/actionTypes"
import { OFFER_UPDATED } from "../Offers/actionTypes"
import { getUser } from "modules/User/selectors"
import firebase from "firebase"
import _ from "lodash"
import {
  FIREBASE_PATH_OFFER_REQUESTS,
  FIREBASE_PATH_OPEN_OFFER_REQUESTS,
} from "./constants"
import { offerRequestUpdated } from "./actions"
import { UserUpdated } from "modules/User/actions"
import { User } from "modules/User/types"
import { OfferUpdated } from "../Offers/actions"
import { OfferRequestDB } from "database_types/offerRequest"

const subscribedOfferRequests = {}
export function* subscribeToOfferRequestWorker(offerRequestId: string) {
  if (subscribedOfferRequests[offerRequestId]) {
    return
  }
  yield (subscribedOfferRequests[offerRequestId] = true)

  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH_OFFER_REQUESTS)
    .child(offerRequestId)
  const channel = yield call(getEventChannelForFirebaseRef, ref)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const {
      values: offerRequest,
    }: { values: OfferRequestDB | undefined } = yield take(channel)
    // tslint:disable-next-line:no-unsafe-any
    const user: User | undefined = yield select(getUser)
    if (
      user &&
      offerRequest &&
      offerRequest.isApproved &&
      offerRequest.buyerProfile !== user.id
    ) {
      yield put(
        offerRequestUpdated({
          id: offerRequestId,
          ...offerRequest,
        }),
      )
    }
  }
}

export function* subscribeToOffersRequestsSaga() {
  yield takeEvery(OFFER_UPDATED, (action: OfferUpdated) =>
    subscribeToOfferRequestWorker(action.offer.offerRequest),
  )
}

let subscribedToOpen = false
export function* subscribeToOpenOfferRequestsWorker(action: UserUpdated) {
  if (subscribedToOpen || !action.user.isApprovedSeller) {
    return
  }
  yield (subscribedToOpen = true)
  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH_OPEN_OFFER_REQUESTS)
  const channel = yield call(getEventChannelForFirebaseRef, ref)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const {
      values: offerRequests,
    }: { values: { [id: string]: true } | undefined } = yield take(channel)
    if (offerRequests) {
      yield all(
        Object.keys(offerRequests).map(offerRequestId =>
          fork(subscribeToOfferRequestWorker, offerRequestId),
        ),
      )
    }
  }
}

export function* subscribeToOfferRequestsSaga() {
  yield takeEvery(USER_UPDATED, subscribeToOpenOfferRequestsWorker)
}

export default function*() {
  yield all([
    fork(subscribeToOfferRequestsSaga),
    fork(subscribeToOffersRequestsSaga),
  ])
}
