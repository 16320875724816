import React from "react"
import {
  TextInput,
  Modal,
  View,
  Text,
  TouchableOpacity,
  SafeAreaView,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from "react-native"
import { Ionicons } from "@expo/vector-icons"
import _ from "lodash"
import Colors from "constants/Colors"
import styles from "./styles"
import {
  NumberInputQuestion,
  TextAreaQuestion,
  Question,
} from "modules/Buyer/Questions/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface Props {
  closeInputTextModal: () => void
  confirm: () => void
  updateText: (value: string) => void
  question?: TextAreaQuestion | NumberInputQuestion
}

export default class ModalInput extends React.Component<Props> {
  textInput: TextInput | undefined | null
  state: {
    height: number
    maxHeight: number
    error?: string
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      height: 0,
      maxHeight: 100,
    }
    this.onShow = this.onShow.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }
  keyboardType(question: Question) {
    const type = question.type
    if (type === "number_input") {
      return "numeric"
    }
    return "default"
  }
  handleKeyPress(event: NativeSyntheticEvent<TextInputKeyPressEventData>) {
    if (event.nativeEvent.key === "Enter") {
      this.props.confirm()
    }
  }
  onShow() {
    if (this.textInput) {
      this.textInput.focus()
    }
  }
  render() {
    const { question } = this.props
    if (!question) {
      return null
    }
    const inputType = this.keyboardType(question)
    const { height: textHeight, maxHeight } = this.state
    const textInputHeight = Math.min(maxHeight, textHeight + 20)
    const textInputContainerHeight = textInputHeight
    const containerHeight = textInputHeight + 50
    return (
      <Modal
        animationType="slide"
        transparent={false}
        onRequestClose={this.props.closeInputTextModal}
        onShow={this.onShow}
      >
        <SafeAreaView>
          <View style={styles.buttonWrap}>
            <TouchableOpacity onPress={this.props.closeInputTextModal}>
              <Ionicons name="ios-close" size={45} color={Colors.borderColor} />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={this.props.confirm}
              style={styles.confirmButton}
            >
              <Ionicons
                name="ios-checkmark"
                size={45}
                color={Colors.brandGreen}
              />
            </TouchableOpacity>
          </View>
          <View style={[styles.container, { height: containerHeight }]}>
            <Text style={styles.questionTitle}>
              {question.name && getLocaLizedName(question.name).toUpperCase()}
            </Text>
            <View
              style={[
                styles.inputContainer,
                { height: textInputContainerHeight },
              ]}
            >
              <TextInput
                ref={input => {
                  this.textInput = input
                }}
                style={[styles.input, { height: textInputHeight }]}
                onChangeText={this.props.updateText}
                onContentSizeChange={({
                  nativeEvent: {
                    contentSize: { width, height },
                  },
                }) => {
                  this.setState({ height })
                }}
                keyboardType={inputType}
                placeholder={
                  question.placeholder
                    ? getLocaLizedName(question.placeholder)
                    : undefined
                }
                placeholderTextColor={Colors.noteColor}
                value={question.value ? question.value.toString() : undefined}
                multiline
                onKeyPress={this.handleKeyPress}
                blurOnSubmit
                returnKeyType="done"
                underlineColorAndroid="transparent"
              />
            </View>
          </View>
        </SafeAreaView>
      </Modal>
    )
  }
}
