import {
  SUBSCRIBE_TO_IMAGE,
  IMAGE_UPLOADED,
  IMAGE_UPDATED,
  UPLOAD_IMAGES,
} from "./actionTypes"

export interface SubscribeToImage {
  type: typeof SUBSCRIBE_TO_IMAGE
  imageId: string
}
export const subscribeToImage: (
  imageId: string,
) => SubscribeToImage = imageId => ({
  type: SUBSCRIBE_TO_IMAGE,
  imageId,
})

export interface ImageUploaded {
  type: typeof IMAGE_UPLOADED
  imageId: string
  storagePath: string
}
export const imageUploaded: (props: {
  imageId: string
  storagePath: string
}) => ImageUploaded = props => ({
  type: IMAGE_UPLOADED,
  ...props,
})

interface ImageProps {
  id: string
  downloadURL?: string
  height: number
  width: number
}
export interface ImageUpdated {
  type: typeof IMAGE_UPDATED
  image: ImageProps
}
export const imageUpdated: (props: ImageProps) => ImageUpdated = props => ({
  type: IMAGE_UPDATED,
  image: {
    ...props,
  },
})

export interface UploadImages {
  type: typeof UPLOAD_IMAGES
  imageIds: string[]
  offerRequestId: string
}
export const uploadImages: (props: {
  imageIds: string[]
  offerRequestId: string
}) => UploadImages = props => ({
  type: UPLOAD_IMAGES,
  ...props,
})
