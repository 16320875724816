import _ from "lodash"
import { connect } from "react-redux"
import { offerRequestSelected } from "modules/Seller/OfferRequests/actions"
import { offerSelected } from "modules/Seller/Offers/actions"
import routeNames from "components/navigators/constants"
import View from "./view"
import { Offer } from "modules/Buyer/Offers/types"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { getCategoryById } from "modules/Buyer/Categories/selectors"
import { getOfferRequestByIdForSeller } from "modules/Seller/OfferRequests/selectors"

interface OwnProps {
  offer: Offer
  navigation: Navigation
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => {
  const { offer } = ownProps
  const offerRequest = getOfferRequestByIdForSeller(state, offer.offerRequest)
  return {
    offer,
    offerRequest,
    category: offerRequest && getCategoryById(state, offerRequest.category),
  }
}

const mapDispatchToProps = {
  offerRequestSelected,
  offerSelected,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  markJobAsDone: () => {
    if (stateProps.offerRequest) {
      dispatchProps.offerRequestSelected(stateProps.offerRequest.id)
      dispatchProps.offerSelected(stateProps.offer.id)

      ownProps.navigation.navigate(routeNames.SELLER.MARK_JOB_COMPLETED)
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
