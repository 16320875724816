import { SellerOfferRequestState } from "./types"
import {
  OFFER_REQUEST_SELECTED,
  OPEN_OFFER_REQUEST_UPDATED,
} from "./actionTypes"
import { OfferRequestSelected, OfferRequestUpdated } from "./actions"

export const INITIAL_STATE: SellerOfferRequestState = {
  offerRequests: {},
  selected: undefined,
}

export default function sellerOfferRequestsReducer(
  state = INITIAL_STATE,
  action: OfferRequestSelected | OfferRequestUpdated,
): SellerOfferRequestState {
  switch (action.type) {
    case OFFER_REQUEST_SELECTED:
      return {
        ...state,
        selected: action.offerRequestId,
      }
    case OPEN_OFFER_REQUEST_UPDATED:
      return {
        ...state,
        offerRequests: {
          ...state.offerRequests,
          [action.offerRequest.id]: action.offerRequest,
        },
      }
    default:
      return state
  }
}
