import React from "react"
import I18n from "i18n"
import DateTimePicker, { Event } from "@react-native-community/datetimepicker"
import { Container, Content } from "native-base"
import styles from "./styles"

interface Props {
  date: Date
  updateBottomScrollStatus: () => void
  dateChanged: (date: Date) => void
}

export default class PreferredTimePicker extends React.Component<Props> {
  state: {
    date: Date
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      date: this.props.date,
    }
    this.onDateChange = this.onDateChange.bind(this)
  }

  componentDidMount() {
    this.props.updateBottomScrollStatus()
  }

  onDateChange(event: Event, date?: Date) {
    if (date) {
      this.setState(
        {
          date,
        },
        () => {
          this.props.dateChanged(date)
        },
      )
    }
  }

  render() {
    return (
      <Container style={styles.container}>
        <Content>
          <DateTimePicker
            mode="datetime"
            value={this.state.date}
            minuteInterval={30}
            minimumDate={new Date()}
            locale={I18n.locale}
            onChange={this.onDateChange}
          />
        </Content>
      </Container>
    )
  }
}
