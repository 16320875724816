import React, { useEffect, useRef } from "react"
import { useNavigationState } from "@react-navigation/native"
import _ from "lodash"
import { View } from "react-native"
import { Container, Content } from "native-base"
import Reminders from "../Reminders"
import Group from "./Group"
import styles from "./styles"
import { Navigation } from "components/types"
import { Feed } from "modules/Buyer/Feed/types"
import { sortByOrder } from "utilities/sortBy"
import routeNames from "components/navigators/constants"

interface Props {
  navigation: Navigation
  feed: Feed
}

export default ({ navigation, feed }: Props) => {
  const activeTab = useNavigationState(state => state.routes[state.index])
  const listRef = useRef(null)
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const tabPress = navigation.addListener("tabPress", e => {
      if (
        activeTab.name == routeNames.BUYER.DISCOVER_TAB &&
        e.target == activeTab.key
      ) {
        //todo add scroll to top
      }
    })
    return tabPress
  }, [navigation])
  const orderedFeed = sortByOrder(Object.values(feed), "desc")
  return (
    <Container style={styles.container}>
      <Content>
        <Reminders navigation={navigation} />
        <View style={styles.carouselWrap} ref={listRef}>
          {_.map(orderedFeed, (group, idx) => (
            <Group navigation={navigation} key={idx} group={group} />
          ))}
        </View>
      </Content>
    </Container>
  )
}
