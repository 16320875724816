import React from "react"
import _ from "lodash"
import { View } from "react-native"
import { Content, Body, Icon, List, ListItem, Text } from "native-base"
import ProfileImage from "../ProfileImage"
import moment from "moment"
import EmptyList from "../EmptyList"
import I18n from "i18n"
import styles from "./styles"
import { Review, SellerProfile } from "modules/SellerProfiles/types"

interface Props {
  reviews?: Review[]
  withoutButton?: boolean
  sellerProfile: SellerProfile
}

export default ({ reviews, withoutButton, sellerProfile }: Props) => {
  return (
    <Content
      style={[
        styles.content,
        withoutButton ? styles.contentWithoutButton : null,
      ]}
    >
      <List>
        {_.size(reviews) > 0 ? (
          _.map(reviews, (review, key) => {
            const photoUrl = sellerProfile.photoURL
            const reviewedDate = review.createdAt
              ? moment(review.createdAt).format("DD.MM.YYYY")
              : ""
            return (
              <ListItem key={key} style={styles.review}>
                <ProfileImage
                  style={styles.thumbnail}
                  small
                  source={photoUrl}
                />
                <Body style={styles.textWrap}>
                  <Text style={styles.reviewName}>{review.name}</Text>
                  <View style={styles.reviewInfo}>
                    <Text>
                      {[...Array(5)].map((x, idx2) => {
                        return (
                          <Icon
                            name="star"
                            style={[
                              styles.reviewRating,
                              idx2 + 1 <= review.rating
                                ? styles.reviewRatingActive
                                : null,
                            ]}
                            active
                            key={`${idx2}-${review.rating}`}
                          />
                        )
                      })}
                      <Text style={styles.reviewText}> {reviewedDate}</Text>
                    </Text>
                  </View>
                  <Text style={styles.reviewText}>{review.text}</Text>
                </Body>
              </ListItem>
            )
          })
        ) : (
          <EmptyList emptyTextMessage={I18n.t("has_no_reviews")} />
        )}
      </List>
    </Content>
  )
}
