import React from "react"
import { Linking } from "expo"
import * as WebBrowser from "expo-web-browser"
import { getStripePaymentPageUrl } from "api/stripe"
import { Loader } from "../Loader"
import { TransactionStatus } from "database_types/transaction"

interface Props {
  transactionStatus?: TransactionStatus
  paymentFinished: (value: "success" | "cancel") => void
  sessionId?: string
}

class StripeCheckout extends React.Component<Props> {
  browserOpened?: boolean
  constructor(props: Props) {
    super(props)
    this.urlChanged = this.urlChanged.bind(this)
  }

  async componentDidMount() {
    await this.openPaymentPage()
    this.props.paymentFinished("success")
  }

  async componentDidUpdate(prevProps: Props) {
    await this.openPaymentPage()
    if (
      this.props.transactionStatus === "paid" &&
      this.props.transactionStatus !== prevProps.transactionStatus
    ) {
      WebBrowser.dismissBrowser()
      this.props.paymentFinished("success")
    }
  }

  urlChanged(event: { url: string }) {
    if (event.url) {
      // tslint:disable-next-line:no-unsafe-any
      const { path }: { path: string } = Linking.parse(event.url)
      if (path.indexOf("stripe/success") !== -1) {
        this.props.paymentFinished("success")
        WebBrowser.dismissBrowser()
      } else if (path.indexOf("stripe/cancel")) {
        this.props.paymentFinished("cancel")
        WebBrowser.dismissBrowser()
      }
    }
  }

  async openPaymentPage() {
    if (!this.browserOpened && this.props.sessionId) {
      this.browserOpened = true
      this.props.paymentFinished("cancel")
      // tslint:disable-next-line:no-unsafe-any
      Linking.addEventListener("url", this.urlChanged)
      const url = await getStripePaymentPageUrl(this.props.sessionId)
      await WebBrowser.openBrowserAsync(url)
      // tslint:disable-next-line:no-unsafe-any
      Linking.removeEventListener("url", this.urlChanged)
    }
  }

  render() {
    return <Loader />
  }
}

export default StripeCheckout
