import * as firebase from "firebase"
import _ from "lodash"
import { all, takeEvery, fork, put } from "redux-saga/effects"
import { LoadSettings, settingsLoaded } from "./actions"
import { LOAD_SETTINGS } from "./actionTypes"
import { Settings } from "./types"

export function* loadSettingsWorker(action: LoadSettings) {
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref("settings")
  const snapshot: firebase.database.DataSnapshot = yield ref.once("value")
  yield put(settingsLoaded(snapshot.val() as Settings))
}

export function* loadSettingsSaga() {
  yield takeEvery(LOAD_SETTINGS, loadSettingsWorker)
}

export default function*() {
  yield all([fork(loadSettingsSaga)])
}
