import { StyleSheet, PixelRatio } from "react-native"
import Colors from "constants/Colors"
import { buttonText } from "constants/Fonts"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.contentBackground,
    flex: 1,
  },
  profile: {
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  profileImage: {
    marginTop: 10,
  },
  companyName: {
    fontFamily: "open-sans-bold",
    color: Colors.textColor,
    marginTop: 10,
  },
  ratingContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: 15,
  },
  rating: {
    fontFamily: "open-sans-bold",
    color: Colors.textColor,
    marginRight: 15,
  },
  reviewRating: {
    color: Colors.borderColor,
  },
  reviewRatingActive: {
    color: Colors.starYellow,
  },
  inputContainer: {
    borderColor: Colors.borderColor,
    borderWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    marginLeft: 15,
    marginRight: 15,
    height: 120,
  },
  input: {
    fontFamily: "open-sans-regular",
    padding: 10,
    color: Colors.textColor,
    fontSize: 16,
    height: 100,
  },
  buttonWrap: {
    bottom: 15,
    left: 15,
    right: 15,
    position: "absolute",
  },
  button: {
    backgroundColor: Colors.brandYellow,
  },
  buttonText: {
    ...buttonText,
    color: Colors.contentBackground,
  },
})
