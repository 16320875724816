import { StyleSheet, Platform } from "react-native"
import Colors from "constants/Colors"
import { titleText, bodyText } from "constants/Fonts"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.contentBackground,
    padding: 15,
  },
  logoContainer: {
    paddingTop: 4,
    height: 40,
    ...Platform.select({
      ios: {},
      android: {
        top: 15,
      },
    }),
  },
  wrapper: {
    flex: 1,
    paddingTop: 60,
  },
  titleText: {
    ...titleText,
    textAlign: "center",
    flex: 1,
  },
  text: {
    ...bodyText,
    textAlign: "center",
    paddingBottom: 60,
    paddingHorizontal: 15,
  },
})
