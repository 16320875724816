import React from "react"
import { Container, Content, Button } from "native-base"
import { View, Text } from "react-native"
import I18n from "i18n"
import CategoryList from "./CategoryList"
import styles from "./styles"

interface Props {
  confirm: () => void
  buttonEnabled: boolean
}

export default ({ confirm, buttonEnabled }: Props) => (
  <Container style={styles.container}>
    <Content>
      <CategoryList />
    </Content>
    <View style={styles.buttonWrapper}>
      {buttonEnabled && (
        <Button onPress={confirm} block rounded style={styles.button}>
          <Text style={styles.buttonText}>{I18n.t("next")}</Text>
        </Button>
      )}
    </View>
  </Container>
)
