import React from "react"
import { Image, TouchableHighlight, View, ViewStyle } from "react-native"

interface ImageWrapperProps {
  onPress?: () => void
  style: ViewStyle[]
}

const ImageWrapper: React.FunctionComponent<ImageWrapperProps> = props => {
  return props.onPress ? (
    <TouchableHighlight onPress={props.onPress} style={props.style}>
      {props.children}
    </TouchableHighlight>
  ) : (
    <View style={props.style}>{props.children}</View>
  )
}

interface ImageComponentProps {
  willMount: () => void
  uri?: string
  height?: number
  width?: number
  onPress?: () => void
  style?: ViewStyle
}

export default class ImageComponent extends React.Component<
  ImageComponentProps
> {
  UNSAFE_componentWillMount() {
    this.props.willMount()
  }

  render() {
    const { uri, width, height } = this.props
    return (
      <ImageWrapper
        onPress={this.props.onPress}
        style={[this.props.style || {}, { width, height }]}
      >
        <Image source={{ uri }} style={{ flex: 1 }} />
      </ImageWrapper>
    )
  }
}
