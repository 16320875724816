import { connect } from "react-redux"
import _ from "lodash"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { Navigation } from "components/types"
import { getSelectedSellerOffer } from "modules/Seller/Offers/selectors"
import { getBuyerProfileForId } from "modules/BuyerProfiles/selectors"
import {
  offerStatusUpdate,
  markOfferAsSeen,
} from "modules/Seller/Offers/actions"
import { getSelectedOfferRequestForSeller } from "modules/Seller/OfferRequests/selectors"

interface OwnProps {
  navigation: Navigation
}

const mapStateToProps = (state: Store) => {
  const offerRequest = getSelectedOfferRequestForSeller(state)
  if (!offerRequest) {
    return {}
  }
  const buyerProfile = getBuyerProfileForId(state, offerRequest.buyerProfile)
  const offer = getSelectedSellerOffer(state)

  return {
    buyerProfile,
    offerRequest,
    offer,
  }
}

const mapDispatchToProps = {
  offerStatusUpdate,
  markOfferAsSeen,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  markOfferAsSeen: () => {
    if (stateProps.offer) {
      dispatchProps.markOfferAsSeen(stateProps.offer.id)
    }
  },
  onRemoveOfferClicked: () => {
    if (stateProps.offer) {
      dispatchProps.offerStatusUpdate({
        ...stateProps.offer,
        status: "cancelled",
      })
      ownProps.navigation.goBack()
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
