import React from "react"
import {
  TextInput,
  Modal as ReactModal,
  View,
  Text,

  SafeAreaView,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from "react-native"

import Colors from "constants/Colors"
import styles from "./styles"

interface Props {
  title: string
  value: string
  placeholder: string

  confirm: (value: string) => void

}

interface State {
  height: number
  maxHeight: number
  value: string
}

export default class TextModal extends React.Component<Props, State> {
  textInput!: TextInput

  constructor(props: Props) {
    super(props)
    this.state = {
      height: 0,
      maxHeight: 100,
      value: props.value,
    }
  }



  handleKeyPress = (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>,
  ) => {
    if (event.nativeEvent.key === "Enter") {

      this.confirm()

    }
  }

  render() {
    const { height: textHeight, maxHeight } = this.state
    const textInputHeight = Math.min(maxHeight, textHeight + 20)
    const textInputContainerHeight = textInputHeight
    const containerHeight = textInputHeight + 50
    return (
      <ReactModal
        animationType="slide"
        transparent={false}
        onShow={() => {
          this.textInput.focus()
        }}

      >
        <SafeAreaView>
          <View style={styles.buttonWrap}>
            <TouchableOpacity onPress={this.close}>
              <Ionicons name="ios-close" size={45} color={Colors.borderColor} />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={this.confirm}
              style={styles.confirmButton}
            >
              <Ionicons
                name="ios-checkmark"
                size={45}
                color={Colors.brandGreen}
              />
            </TouchableOpacity>
          </View>
          <View style={[styles.container, { height: containerHeight }]}>
            <Text style={styles.questionTitle}>{this.props.title || ""}</Text>
            <View
              style={[
                styles.inputContainer,
                { height: textInputContainerHeight },
              ]}
            >
              <TextInput
                ref={(input: TextInput) => {
                  this.textInput = input
                }}
                style={[styles.input, { height: textInputHeight }]}
                onChangeText={text => {
                  this.setState({ value: text })
                }}
                onContentSizeChange={({
                  nativeEvent: {
                    contentSize: { width, height },
                  },
                }) => {
                  this.setState({ height })
                }}
                placeholder={this.props.placeholder}
                placeholderTextColor={Colors.noteColor}
                value={this.state.value}
                multiline={true}
                onKeyPress={this.handleKeyPress}
                blurOnSubmit={true}
                returnKeyType="done"
                underlineColorAndroid="transparent"
              />
            </View>
          </View>
        </SafeAreaView>
      </ReactModal>
    )
  }
}
