import { connect } from "react-redux"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { FeedItem } from "modules/Buyer/Feed/types"
import { hasSelectedSellerCategory } from "modules/SellerProfiles/selectors"

interface OwnProps {
  item: Pick<FeedItem, "categoryId" | "name">
  toggleCategorySelection: (id: string) => void
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => ({
  id: ownProps.item.categoryId,
  name: ownProps.item.name,
  isSelected: hasSelectedSellerCategory(state, ownProps.item.categoryId),
})

const mapDispatchToProps = null

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...ownProps,
  toggleSelect: () => {
    const { id } = stateProps
    ownProps.toggleCategorySelection(id)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
