import { createStore, applyMiddleware, combineReducers, compose } from "redux"
import createSagaMiddleware from "redux-saga"

import FeedReducer from "modules/Buyer/Feed/reducer"
import CategoriesReducer from "modules/Buyer/Categories/reducer"
import QuestionsReducer from "modules/Buyer/Questions/reducer"
import OffersReducer from "modules/Buyer/Offers/reducer"
import OfferRequestsReducer from "modules/Buyer/OfferRequests/reducer"
import BuyerProfilesReducer from "modules/BuyerProfiles/reducer"
import SellerCreateOfferReducer from "modules/Seller/CreateOffer/reducer"
import SellerOfferRequestsReducer from "modules/Seller/OfferRequests/reducer"
import SellerOffersReducer from "modules/Seller/Offers/reducer"
import SellerProfilesReducer from "modules/SellerProfiles/reducer"
import UserReducer from "modules/User/reducer"
import NotificationsReducer from "modules/Notifications/reducer"
import ImagesReducer from "modules/Images/reducer"
import ChatsReducer from "modules/Chats/reducer"
import ChatMessagesReducer from "modules/ChatMessages/reducer"
import TransactionReducer from "modules/Payment/Transaction/reducer"
import StripeReducer from "modules/Payment/Stripe/reducer"
import SettingsReducer from "modules/Settings/reduser"

import FeedSagas from "modules/Buyer/Feed/sagas"
import CategoriesSagas from "modules/Buyer/Categories/sagas"
import OffersSagas from "modules/Buyer/Offers/sagas"
import OfferRequestsSagas from "modules/Buyer/OfferRequests/sagas"
import BuyerProfilesSagas from "modules/BuyerProfiles/sagas"
import SellerCreateOfferSagas from "modules/Seller/CreateOffer/sagas"
import SellerOfferRequestsSagas from "modules/Seller/OfferRequests/sagas"
import SellerOffersSagas from "modules/Seller/Offers/sagas"
import SellerProfilesSagas from "modules/SellerProfiles/sagas"
import UserSagas from "modules/User/sagas"
import NotificationsSagas from "modules/Notifications/sagas"
import ImagesSagas from "modules/Images/sagas"
import ChatsSagas from "modules/Chats/sagas"
import ChatMessagesSagas from "modules/ChatMessages/sagas"
import TransactionSagas from "modules/Payment/Transaction/sagas"
import StripeSagas from "modules/Payment/Stripe/sagas"
import SettingsSagas from "modules/Settings/sagas"

import { FeedState } from "modules/Buyer/Feed/types"
import { CategoriesState } from "modules/Buyer/Categories/types"
import { QuestionsState } from "modules/Buyer/Questions/types"
import { BuyerOffersState } from "modules/Buyer/Offers/types"
import { BuyerOfferRequestsState } from "modules/Buyer/OfferRequests/types"
import { BuyerProfilesState } from "modules/BuyerProfiles/types"
import { SellerOfferRequestState } from "modules/Seller/OfferRequests/types"
import { SellerOffersState } from "modules/Seller/Offers/types"
import { SellerCreateOfferState } from "modules/Seller/CreateOffer/types"
import { SellerProfileState } from "modules/SellerProfiles/types"
import { UserState } from "modules/User/types"
import { NotificationsState } from "modules/Notifications/types"
import { ImagesState } from "modules/Images/types"
import { ChatsState } from "modules/Chats/types"
import { ChatMessagesState } from "modules/ChatMessages/types"
import { TransactionsState } from "modules/Payment/Transaction/types"
import { StripeState } from "modules/Payment/Stripe/types"
import { Settings } from "modules/Settings/types"

export interface Store {
  settings: Settings
  feed: FeedState
  categories: CategoriesState
  questions: QuestionsState
  offers: BuyerOffersState
  offerRequests: BuyerOfferRequestsState
  buyerProfiles: BuyerProfilesState
  sellerOfferRequests: SellerOfferRequestState
  sellerOffers: SellerOffersState
  sellerCreateOffer: SellerCreateOfferState
  sellerProfiles: SellerProfileState
  user: UserState
  notifications: NotificationsState
  images: ImagesState
  chats: ChatsState
  chatMessages: ChatMessagesState
  transactions: TransactionsState
  stripe: StripeState
}

export const createReduxStore = () => {
  const initialState = {}
  const combinedReducers = combineReducers<Store>({
    feed: FeedReducer,
    categories: CategoriesReducer,
    questions: QuestionsReducer,
    offers: OffersReducer,
    offerRequests: OfferRequestsReducer,
    buyerProfiles: BuyerProfilesReducer,
    sellerOfferRequests: SellerOfferRequestsReducer,
    sellerOffers: SellerOffersReducer,
    sellerCreateOffer: SellerCreateOfferReducer,
    sellerProfiles: SellerProfilesReducer,
    user: UserReducer,
    notifications: NotificationsReducer,
    images: ImagesReducer,
    chats: ChatsReducer,
    chatMessages: ChatMessagesReducer,
    transactions: TransactionReducer,
    stripe: StripeReducer,
    settings: SettingsReducer,
  })

  const sagaMiddleware = createSagaMiddleware()
  const middlewares = applyMiddleware(sagaMiddleware)
  const composeEnhancers =
    (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) ||
    compose // tslint:disable-line:no-string-literal
  const store = createStore(
    combinedReducers,
    initialState,
    composeEnhancers(middlewares),
  )
  /* Buyer */
  sagaMiddleware.run(FeedSagas)
  sagaMiddleware.run(CategoriesSagas)
  sagaMiddleware.run(OfferRequestsSagas)
  sagaMiddleware.run(OffersSagas)
  sagaMiddleware.run(BuyerProfilesSagas)

  /* Seller */
  sagaMiddleware.run(SellerOfferRequestsSagas)
  sagaMiddleware.run(SellerOffersSagas)
  sagaMiddleware.run(SellerCreateOfferSagas)
  sagaMiddleware.run(SellerProfilesSagas)

  /* Shared */
  sagaMiddleware.run(UserSagas)
  sagaMiddleware.run(ImagesSagas)
  sagaMiddleware.run(ChatsSagas)
  sagaMiddleware.run(ChatMessagesSagas)
  sagaMiddleware.run(NotificationsSagas)
  sagaMiddleware.run(SettingsSagas)

  /* Payment */
  sagaMiddleware.run(TransactionSagas)
  sagaMiddleware.run(StripeSagas)

  return store
}

const reduxStore = createReduxStore()

export default reduxStore
