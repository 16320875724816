import { connect } from "react-redux"
import View from "./view"
import { Store } from "utilities/Redux/store"
import {
  getOnGoingSellerJobs,
  getUpcomingSellerJobs,
  getPastSellerJobsNotStarted,
} from "modules/Seller/Offers/selectors"

const mapStateToProps = (state: Store) => ({
  onGoingJobs: getOnGoingSellerJobs(state),
  upcomingJobs: getUpcomingSellerJobs(state),
  pastJobsNotStarted: getPastSellerJobsNotStarted(state),
})

export default connect(mapStateToProps)(View)
