import { PixelRatio, StyleSheet } from "react-native"
import { separatorText } from "constants/Fonts"
import Colors from "constants/Colors"

export default StyleSheet.create({
  list: {
    backgroundColor: Colors.contentBackground,
    borderColor: Colors.borderColor,
    borderTopWidth: 0,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    marginBottom: 15,
  },
  separator: {
    backgroundColor: Colors.containerBackground,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
  },
  separatorText: {
    ...separatorText,
  },
})
