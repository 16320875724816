import { connect } from "react-redux"
import _ from "lodash"
import moment, { Moment } from "moment"
import {
  startTimeSelected,
  startTimeIsOverlapping,
} from "modules/Seller/CreateOffer/actions"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { DateQuestion, isDateQuestion } from "modules/Buyer/Questions/types"
import { getOpenAndAcceptedSellerOffersForSelectedDate } from "modules/Seller/Offers/selectors"
import {
  getSelectedDate,
  getIsOverlapping,
  getSelectedStartTime,
  getTempOffer,
} from "modules/Seller/CreateOffer/selectors"
import { getBuyerProfileForId } from "modules/BuyerProfiles/selectors"
import { getCategoryById } from "modules/Buyer/Categories/selectors"
import { getSelectedOfferRequestForSeller } from "modules/Seller/OfferRequests/selectors"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

const mapStateToProps = (state: Store) => {
  const offerRequest = getSelectedOfferRequestForSeller(state)
  const selectedDate = getSelectedDate(state)
  const isOverlapping = getIsOverlapping(state)
  const selectedStartTime = getSelectedStartTime(state)
  if (!offerRequest || !selectedDate) {
    return {
      selectedDate,
      isOverlapping,
      selectedStartTime,
    }
  }

  let dateQuestion: DateQuestion | undefined
  offerRequest.questions.forEach(q => {
    if (isDateQuestion(q)) {
      dateQuestion = q
    }
  })

  const suitableTimesForTheDay =
    dateQuestion && dateQuestion.suitableTimes
      ? Object.values(dateQuestion.suitableTimes).filter(
          time => time.day === selectedDate,
        )
      : undefined

  const openAndAcceptedOffersForSelectedDate = getOpenAndAcceptedSellerOffersForSelectedDate(
    state,
    selectedDate,
  )
  const offer = getTempOffer(state, offerRequest.id)
  const category = getCategoryById(state, offerRequest.category)
  const categoryName = category && getLocaLizedName(category.name)
  const buyerProfile = getBuyerProfileForId(state, offerRequest.buyerProfile)
  const buyerName = buyerProfile ? buyerProfile.displayName : undefined
  return {
    selectedDate,
    isOverlapping,
    openAndAcceptedOffersForSelectedDate,
    selectedStartTime,
    suitableTimesForTheDay,
    offer,
    categoryName,
    buyerName,
  }
}

const mapDispatchToProps = {
  startTimeSelected,
  startTimeIsOverlapping,
}

const getTimeSlots = (startTime: Moment, duration: number) => {
  let startInMilliseconds = startTime.valueOf()
  const halfHourInMilliSeconds = 30 * 60 * 1000
  const numberOfhalfhourSlots = (duration * 60) / 30
  const timeSlots = [startInMilliseconds]
  for (let i = 0; i < numberOfhalfhourSlots - 1; i += 1) {
    startInMilliseconds += halfHourInMilliSeconds
    timeSlots.push(startInMilliseconds)
  }
  return timeSlots
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
) => ({
  selectedDate: stateProps.selectedDate,
  selectedStartTime: stateProps.selectedStartTime,
  suitableTimesForTheDay: stateProps.suitableTimesForTheDay,
  categoryName: stateProps.categoryName,
  buyerName: stateProps.buyerName,
  offer: stateProps.offer,
  isOverlapping: stateProps.isOverlapping,
  selectStartTime: (startTime: string, duration: number) => {
    const { openAndAcceptedOffersForSelectedDate, offer } = stateProps
    if (!openAndAcceptedOffersForSelectedDate || !offer || !offer.duration) {
      return
    }
    const startTimeMoment = moment(`${stateProps.selectedDate}T${startTime}`)
    const selectedTimeSlots = getTimeSlots(startTimeMoment, offer.duration)
    let isOverlapping = false
    openAndAcceptedOffersForSelectedDate.some(data => {
      const offerStartTimeMoment = moment(data.offer.agreedStartTime, "x")
      const bookedTimeSlots = getTimeSlots(offerStartTimeMoment, duration)
      if (_.intersection(selectedTimeSlots, bookedTimeSlots).length) {
        isOverlapping = true
        return true
      }
      return false
    })
    dispatchProps.startTimeIsOverlapping(isOverlapping)
    dispatchProps.startTimeSelected(startTime)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
