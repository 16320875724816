import { StripeState } from "./types"
import { UPDATE_STRIPE_SESSION } from "./actionTypes"
import { UpdateStripeSession } from "./actions"

export const INITIAL_STATE: StripeState = {}

export default function stripeReducer(
  state = INITIAL_STATE,
  action: UpdateStripeSession,
): StripeState {
  switch (action.type) {
    case UPDATE_STRIPE_SESSION:
      return {
        ...state,
        sessionId: action.sessionId,
      }
    default:
      return state
  }
}
