import {
  UPDATE_CURRENT_TRANSACTION,
  TRANSACTION_UPDATED,
  UPDATE_PAYMENT_STATUS,
  UPDATE_ERROR_MESSAGE,
} from "./actionTypes"
import { Transaction } from "./types"

export interface UpdateCurrentTransaction {
  type: typeof UPDATE_CURRENT_TRANSACTION
  transactionId: string | null
}
export const updateCurrentTransaction: (
  transactionId: string | null,
) => UpdateCurrentTransaction = transactionId => {
  return {
    type: UPDATE_CURRENT_TRANSACTION,
    transactionId,
  }
}

export interface TransactionUpdated {
  type: typeof TRANSACTION_UPDATED
  transaction: Transaction
}
export const transactionUpdated: (
  transaction: Transaction,
) => TransactionUpdated = transaction => {
  return {
    type: TRANSACTION_UPDATED,
    transaction,
  }
}

export interface UpdatePaymentStatus {
  type: typeof UPDATE_PAYMENT_STATUS
  isSuccessful: boolean
}
export const updatePaymentStatus: (
  isSuccessful: boolean,
) => UpdatePaymentStatus = isSuccessful => {
  return {
    type: UPDATE_PAYMENT_STATUS,
    isSuccessful,
  }
}

export interface UpdateErrorMessage {
  type: typeof UPDATE_ERROR_MESSAGE
  errorMessage: string
}
export const updateErrorMessage: (
  errorMessage: string,
) => UpdateErrorMessage = errorMessage => {
  return {
    type: UPDATE_ERROR_MESSAGE,
    errorMessage,
  }
}
