import { connect } from "react-redux"
import View from "./view.web"
import { Store } from "utilities/Redux/store"
import { Offer } from "modules/Buyer/Offers/types"
import {
  getSellerProfileForOffer,
  getReviews,
} from "modules/SellerProfiles/selectors"
import { getSelectedBookingForBuyer } from "modules/Buyer/Offers/selectors"
import { markOfferAsSeen } from "modules/Buyer/Offers/actions"
import { getOfferRequestByIdForBuyer } from "modules/Buyer/OfferRequests/selectors"

const mapStateToProps = (state: Store) => {
  const offer = getSelectedBookingForBuyer(state)
  const offerRequest = offer
    ? getOfferRequestByIdForBuyer(state, offer.offerRequest)
    : undefined
  const sellerProfile = offer
    ? getSellerProfileForOffer(state, offer)
    : undefined
  const reviews = sellerProfile
    ? getReviews(state, sellerProfile.id)
    : undefined
  return {
    offer,
    offerRequest,
    sellerProfile,
    reviews: reviews ? Object.values(reviews) : undefined,
  }
}

const mapDispatchToProps = {
  markOfferAsSeen,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
) => ({
  ...stateProps,
  markOfferAsSeen: (offer: Offer) => {
    dispatchProps.markOfferAsSeen({
      offerId: offer.id,
      seenByBuyer: true,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
