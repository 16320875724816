import { StyleSheet } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.containerBackground,
    flex: 1,
  },
  discussionWrapper: {
    flex: 1,
    paddingHorizontal: 10,
  },
})
