import React from "react"
import { List, Text, Separator } from "native-base"
import I18n from "i18n"
import HistoryListItem from "../HistoryListItem"
import EmptyList from "components/views/Shared/EmptyList"
import _ from "lodash"
import styles from "./styles"
import { Navigation } from "components/types"

interface Props {
  offerIds: string[]
  navigation: Navigation
}

export default class HistoryList extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !_.isEqual(nextProps.offerIds, this.props.offerIds)
  }
  render() {
    const { offerIds, navigation } = this.props
    return (
      <List style={styles.list}>
        <Separator style={styles.separator}>
          <Text style={styles.separatorText}>
            {I18n.t("seller_job_history").toUpperCase()}
          </Text>
        </Separator>
        {offerIds.length ? (
          offerIds.map((item, idx) => (
            <HistoryListItem
              last={offerIds.length === idx + 1}
              key={`offer_history_list_item_${item}`}
              id={item}
              navigation={navigation}
            />
          ))
        ) : (
          <EmptyList emptyTextMessage={I18n.t("empty_completed_offer_list")} />
        )}
      </List>
    )
  }
}
