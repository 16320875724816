import React from "react"
import { View } from "react-native"
import OnGoingJob from "./OnGoingJob"
import UpcomingJob from "./UpcomingJob"
import PastJobNotStarted from "./PastJobNotStarted"
import styles from "./styles"
import { Offer } from "modules/Buyer/Offers/types"
import { Navigation } from "components/types"

interface Props {
  upcomingJobs: Offer[]
  onGoingJobs: Offer[]
  pastJobsNotStarted: Offer[]
  navigation: Navigation
}

export default ({
  upcomingJobs,
  onGoingJobs,
  pastJobsNotStarted,
  navigation,
}: Props) => {
  return (
    <View style={styles.remindersContainer}>
      {onGoingJobs.map((item, idx) => (
        <OnGoingJob
          key={`current_${item.id}`}
          offer={item}
          navigation={navigation}
        />
      ))}
      {upcomingJobs.map((item, idx) => (
        <UpcomingJob
          key={`current_${item.id}`}
          offer={item}
          navigation={navigation}
        />
      ))}
      {pastJobsNotStarted.map((item, idx) => (
        <PastJobNotStarted
          key={`current_${item.id}`}
          offer={item}
          navigation={navigation}
        />
      ))}
    </View>
  )
}
