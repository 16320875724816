import React from "react"
import _ from "lodash"
import TouchableInput from "../TouchableInput"
import { NumberInputQuestion } from "modules/Buyer/Questions/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface Props {
  question: NumberInputQuestion
}

export default ({ question }: Props) => {
  const value =
    question.value ||
    (question.placeholder && getLocaLizedName(question.placeholder))
  return <TouchableInput value={value} question={question} />
}
