// import Sentry from "sentry-expo"
import _ from "lodash"
import I18n from "i18n"
import {
  AUTH_STATUS_CHANGED,
  USER_UPDATE,
  USER_UPDATED,
  AUTHENTICATING,
  AUTH_FAILED,
} from "./actionTypes"
import {
  AuthStatusChanged,
  UserUpdated,
  UpdateUser,
  Authenticating,
  AuthenticationFailed,
} from "./actions"
import { UserState } from "./types"

export const INITIAL_STATE: UserState = {
  user: undefined,
  validated: {},
  fetching: true,
}

export default function userReducer(
  state = INITIAL_STATE,
  action:
    | AuthStatusChanged
    | UpdateUser
    | UserUpdated
    | Authenticating
    | AuthenticationFailed,
): UserState {
  switch (action.type) {
    case AUTH_STATUS_CHANGED:
      return {
        ...state,
        user: action.user || undefined,
        fetching: action.user ? true : false,
      }
    case USER_UPDATE:
      return {
        ...state,
        user: action.user,
        validated: action.validated,
      }
    case USER_UPDATED:
      return {
        ...state,
        user: action.user,
        validated: action.validated,
        authenticating: false,
        authenticationFailed: false,
        authenticationFailedMessage: undefined,
        fetching: false,
      }
    case AUTHENTICATING:
      return {
        ...state,
        authenticating: true,
        authenticationFailed: false,
        authenticationFailedMessage: undefined,
        fetching: false,
      }
    case AUTH_FAILED:
      //TODO
      // Sentry.captureException(action.error, {
      //   extra: state,
      // })
      return {
        ...state,
        fetching: false,
        authenticating: false,
        authenticationFailed: true,
        authenticationFailedMessage: action.error.message
          ? action.error.message
          : I18n.t("login_failed"),
      }
    default:
      return state
  }
}
