import { connect } from "react-redux"
import View from "./view"
import { NumberQuestion } from "modules/Buyer/Questions/types"
import { numberChanged } from "modules/Buyer/Questions/actions"

interface OwnProps {
  question: NumberQuestion
}

const mapStateToProps = null

const mapDispatchToProps = {
  numberChanged,
}

const mergeProps = (
  stateProps: typeof mapStateToProps,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...dispatchProps,
  ...ownProps,
  decreaseValue: () => {
    dispatchProps.numberChanged({
      questionId: ownProps.question.id,
      value:
        ownProps.question.value && ownProps.question.value > 0
          ? ownProps.question.value - 1
          : 0,
    })
  },
  increaseValue: () => {
    dispatchProps.numberChanged({
      questionId: ownProps.question.id,
      value: ownProps.question.value ? ownProps.question.value + 1 : 1,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
