import firebase from "firebase"
import { getEventChannelForFirebaseRef } from "utilities/Redux/sagaHelpers"
import { all, takeEvery, fork, put, call, take } from "redux-saga/effects"
import { transactionUpdated, UpdateCurrentTransaction } from "./actions"
import { FIREBASE_PATH } from "./constants"
import { USER_UPDATED } from "modules/User/actionTypes"
import { UserUpdated } from "modules/User/actions"
import { UPDATE_CURRENT_TRANSACTION } from "./actionTypes"
import { TransactionDB } from "database_types/transaction"

const subscribed = {}
export function* subscribeToTransactionWorker(transactionId: string) {
  if (subscribed[transactionId]) {
    return
  }
  yield (subscribed[transactionId] = true)

  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(transactionId)
  const channel = yield call(getEventChannelForFirebaseRef, ref)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const { values: transaction }: { values: TransactionDB } = yield take(
      channel,
    )
    if (transaction) {
      yield put(
        transactionUpdated({
          id: transactionId,
          ...transaction,
        }),
      )
    }
  }
}

export function* subscribeToTransactionUpdateSaga() {
  yield takeEvery(
    UPDATE_CURRENT_TRANSACTION,
    (action: UpdateCurrentTransaction) =>
      action.transactionId &&
      subscribeToTransactionWorker(action.transactionId),
  )
}

export function* subscribeToAllTransactionsWorkers(action: UserUpdated) {
  if (!action.user.transactions) {
    return
  }
  yield all(
    Object.keys(action.user.transactions).map(transactionId =>
      call(subscribeToTransactionWorker, transactionId),
    ),
  )
}

export function* subscribeToTransactionSaga() {
  yield takeEvery(USER_UPDATED, subscribeToAllTransactionsWorkers)
}

export default function*() {
  yield all([
    fork(subscribeToTransactionSaga),
    fork(subscribeToTransactionUpdateSaga),
  ])
}
