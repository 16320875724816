import { StyleSheet } from "react-native"

export default StyleSheet.create({
  contentContainer: {
    flexDirection: "row",
  },
  textContainer: {
    flex: 1,
  },
  buttonWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
})
