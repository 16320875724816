import _ from "lodash"
import { normalizeCategories } from "utilities/Redux/normalizeHelpers"
import { CategoriesState } from "./types"
import {
  CATEGORY_SELECTED,
  CATEGORIES_UPDATED,
  CATEGORIES_ADDRESS_UPDATED,
} from "./actionTypes"
import { CategorySelected, CategoriesUpdated, AddressUpdated } from "./actions"

export const INITIAL_STATE: CategoriesState = {
  categories: {},
  selected: undefined,
}

export default function categoriesReducer(
  state = INITIAL_STATE,
  action: CategorySelected | CategoriesUpdated | AddressUpdated,
): CategoriesState {
  switch (action.type) {
    case CATEGORY_SELECTED:
      return {
        ...state,
        selected: action.categoryId,
      }
    case CATEGORIES_UPDATED:
      return {
        ...state,
        categories: normalizeCategories(action.categories),
      }
    case CATEGORIES_ADDRESS_UPDATED:
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.categoryId]: {
            ...state.categories[action.categoryId],
            addresses: {
              ...state.categories[action.categoryId].addresses,
              [action.addressType]: action.address,
            },
          },
        },
      }
    default:
      return state
  }
}
