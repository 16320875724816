import React from "react"
import moment, { Moment } from "moment"
import _ from "lodash"
import { View, TouchableHighlight } from "react-native"
import { Icon, Text } from "native-base"
import { Ionicons } from "@expo/vector-icons"
import SuitableTimesPicker from "components/views/Shared/SuitableTimesPicker"
import I18n from "ex-react-native-i18n"
import styles from "./styles"
import { DateQuestion } from "modules/Buyer/Questions/types"

const getPreferredTimeString = (question: DateQuestion) => {
  if (!question.preferredTime) {
    return I18n.t("choose_date")
  }
  const momentDate = moment(question.preferredTime, "x")
  return `${_.capitalize(momentDate.format("dddd"))} ${momentDate.format(
    "D[.]M[,] kk:mm",
  )}`
}

interface Props {
  question: DateQuestion
  onSuitableTimeSelected: (props: {
    dateKey: string
    day: string
    startTime: Moment
    endTime: Moment
  }) => void
  selectPreferredTime: () => void
  resetSuitableTimes: () => void
  isSubQuestion?: boolean
}

export default class DateQuestionView extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    this.props.resetSuitableTimes()
  }

  render() {
    const {
      question,
      onSuitableTimeSelected,
      selectPreferredTime,
      isSubQuestion,
    } = this.props
    return (
      <View>
        <View>
          <TouchableHighlight onPress={selectPreferredTime}>
            <View style={styles.flexContainer}>
              <View style={styles.iconContainer}>
                <Ionicons name="md-time" size={20} style={styles.icon} />
              </View>
              <Text style={styles.label}>{I18n.t("preferred_time")}</Text>
              <View style={styles.button}>
                <Text style={styles.buttonText}>
                  {getPreferredTimeString(question)}
                </Text>
                <Icon name="arrow-forward" style={styles.buttonIcon} />
              </View>
            </View>
          </TouchableHighlight>
        </View>
        <SuitableTimesPicker
          isSubQuestion={isSubQuestion}
          question={question}
          onSuitableTimeSelected={onSuitableTimeSelected}
          isSelectionAllowed
        />
      </View>
    )
  }
}
