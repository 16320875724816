import { StyleSheet } from "react-native"
import Colors from "constants/Colors"
import FontSizes from "constants/FontSizes"

export default StyleSheet.create({
  flexContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
  },
  iconContainer: {
    marginLeft: 15,
  },
  icon: {
    color: Colors.grey,
  },
  label: {
    marginLeft: 15,
    color: Colors.grey,
    fontSize: FontSizes.questionPlaceholderSize,
    fontFamily: "open-sans-regular",
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
    marginLeft: "auto",
  },
  buttonText: {
    color: Colors.darkerGrey,
    paddingRight: 30,
  },
  buttonIcon: {
    color: Colors.darkerGrey,
    fontSize: 18,
  },
})
