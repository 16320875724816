import { normalizedFeeds } from "utilities/Redux/normalizeHelpers"
import { FeedUpdated } from "./actions"
import { FEED_UPDATED } from "./actionTypes"
import { FeedState } from "./types"

export const INITIAL_STATE: FeedState = {}

export default function feedReducer(
  state = INITIAL_STATE,
  action: FeedUpdated,
): FeedState {
  switch (action.type) {
    case FEED_UPDATED:
      return normalizedFeeds(action.feed)
    default:
      return state
  }
}
