import { getDatabaseUrl } from "api/environment"

export const getFirebaseConfig = async () => {
  const databaseURL = await getDatabaseUrl()
  return {
    apiKey: "AIzaSyC0p69YUY9IuaNIrP7O5pxyj6LunEtXui8",
  authDomain: "kolf-mp.firebaseapp.com",
  databaseURL: "https://kolf-mp.firebaseio.com",
  projectId: "kolf-mp",
  storageBucket: "kolf-mp.appspot.com",
  messagingSenderId: "295774867870",
  appId: "1:295774867870:web:4e2ab1db085a05432c068f",
  measurementId: "G-CYCPJG79EQ"
  }
}

export const authIds = {
  androidClientId: "866348151270-28e34cim9iu0gspeu023gp2pccam89jl.apps.googleusercontent.com",
  androidStandaloneAppClientId: "866348151270-6tatovsu0u8vuuo82lcndc43qtmturij.apps.googleusercontent.com",
  iosClientId: "866348151270-94ih2u8pthmnoqrg0l837u08n5ggsann.apps.googleusercontent.com",
  iosStandaloneAppClientId: "com.googleusercontent.apps.866348151270-3a6278l6487n5ajjjqkq40djundheugn",
  webClientId: "295774867870-n5fgthanvg5ceeb2cp05lo2au3755jhh.apps.googleusercontent.com",
  facebookAppId: "721656735058621",
}
