import { StyleSheet, PixelRatio } from "react-native"
import Colors from "constants/Colors"

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.containerBackground,
    borderTopWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: Colors.borderColor,
  },
  carouselWrap: {
    marginBottom: 15,
  },
})
