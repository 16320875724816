import _ from "lodash"
import React from "react"
import { Platform, View } from "react-native"
import NotificationNavigator from "./Navigator"
import InAppNotification from "./InAppNotifications"

import { PushNotificationData } from "database_types/notification"

interface Props {
  pushNotificationSelected: (pushNotification: PushNotificationData) => void
  inAppNotificationDismissed: () => void
  inAppNotificationSelected: (pushNotification: PushNotificationData) => void
  pushNotification?: PushNotificationData
  inAppNotification?: PushNotificationData
  navigate: (action: any) => void
  children: JSX.Element
}

class NotificationWrapper extends React.Component<Props> {
  componentDidMount() {
    this.handlePushNotificationSelected()
  }

  componentDidUpdate() {
    this.handlePushNotificationSelected()
  }

  handlePushNotificationSelected() {
    if (this.props.pushNotification) {
      this.props.pushNotificationSelected(this.props.pushNotification)
    }
  }

  render() {
    const {
      children,
      navigate,
      inAppNotificationSelected,
      inAppNotificationDismissed,
      inAppNotification,
    } = this.props
    return (
      <View style={{ flex: 1 }}>
        {inAppNotification && Platform.OS === "ios" && (
          <InAppNotification
            notification={inAppNotification}
            onDismiss={inAppNotificationDismissed}
            onPress={inAppNotificationSelected}
          />
        )}
        <NotificationNavigator navigate={navigate} />
        {children}
      </View>
    )
  }
}

export default NotificationWrapper
