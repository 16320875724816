import React from "react"
import _ from "lodash"
import {
  View,
  ImageBackground,
  TextInput,
  ImageRequireSource,
} from "react-native"
import { Button, Container, Content, Text, Thumbnail } from "native-base"
import I18n from "i18n"
import Colors from "constants/Colors"
import styles from "./styles"
import { User } from "modules/User/types"
import Modal from "../VerificationCodeModal"

interface Props {
  user?: User & { firstName: string; lastName: string }
  firstNameChanged: (name: string) => void
  lastNameChanged: (name: string) => void
  emailChanged: (name: string) => void
  confirm: () => void
  validated?: {
    firstNameIsValid?: boolean
    lastNameIsValid?: boolean
    emailIsValid?: boolean
  }
  updatePhone: (phone: string) => void
}

interface State {
  areaCode: string
  phone: string
}

export default class ConfirmUserInfo extends React.Component<Props, State> {
  // tslint:disable-next-line:no-unsafe-any
  backgroundImage: ImageRequireSource = require("assets/images/wants_to_create_seller_profile.png")
  firstNameChanged: Props["firstNameChanged"]
  lastNameChanged: Props["lastNameChanged"]
  emailChanged: Props["emailChanged"]

  constructor(props: Props) {
    super(props)
    this.firstNameChanged = _.debounce(this.props.firstNameChanged, 500)
    this.lastNameChanged = _.debounce(this.props.lastNameChanged, 500)
    this.emailChanged = _.debounce(this.props.emailChanged, 500)

    // Save phone to state first, update only once on confirm pressed
    // so that to avoid multiple SMS sent on phone changing
    this.state = {
      areaCode: "+358",
      phone: (this.props.user && this.props.user.phone) || "",
     
    }

    this.proceedPressed = this.proceedPressed.bind(this)
  }

  proceedPressed(phoneVerified: boolean) {
    const phoneVerifiedPreviously =
      (this.props.user && this.props.user.phoneVerified) || false
    if (phoneVerified || phoneVerifiedPreviously) {
      this.props.confirm()
    } else {
      if (this.props.user) {
        this.props.updatePhone(this.state.areaCode + this.state.phone)
      }
    }
  }

  componentDidMount() {
    const { user } = this.props
    if (user) {
      this.props.emailChanged(user.email)
      this.props.firstNameChanged(user.firstName)
      setTimeout(() => {
        this.props.lastNameChanged(user.lastName)
      }, 500)
    }
  }

  render() {
    const { user, validated } = this.props
    if (!user) {
      return null
    }
    const { firstName, lastName, email, photoURL } = user
    const allValidated =
      validated &&
      validated.emailIsValid &&
      validated.firstNameIsValid &&
      validated.lastNameIsValid
    const buttonProps = allValidated ? {} : { light: true, disabled: true }
    const buttonTextStyles = {
      fontFamily: "open-sans-regular",
      color: allValidated ? Colors.contentBackground : Colors.noteColor,
    }
    return (
      <Container style={styles.container}>
        <Modal
          uid={user.id}
          onConfirm={() => {
            this.proceedPressed(true)
          }}
        />

        <ImageBackground
          source={this.backgroundImage}
          style={styles.imageContainer}
          imageStyle={styles.image}
        >
          <Content style={styles.content}>
            <Thumbnail
              large
              source={{ uri: photoURL }}
              style={styles.thumbnail}
            />
            <TextInput
              style={styles.input}
              onChangeText={this.firstNameChanged}
              autoCapitalize="words"
              defaultValue={firstName}
              placeholder={I18n.t("first_name")}
              placeholderTextColor={Colors.noteColor}
              returnKeyType="next"
              underlineColorAndroid="transparent"
            />
            <TextInput
              style={styles.input}
              onChangeText={this.lastNameChanged}
              autoCapitalize="words"
              defaultValue={lastName}
              placeholder={I18n.t("last_name")}
              placeholderTextColor={Colors.noteColor}
              returnKeyType="next"
              underlineColorAndroid="transparent"
            />
            <TextInput
              style={styles.input}
              keyboardType="email-address"
              onChangeText={this.emailChanged}
              autoCapitalize="none"
              defaultValue={email}
              placeholder={I18n.t("email")}
              placeholderTextColor={Colors.noteColor}
              returnKeyType="done"
              underlineColorAndroid="transparent"
            />

            <View style={{ flex: 1, flexDirection: "row" }}>
              <TextInput
                style={[styles.input, { flex: 1, marginRight: 25 }]}
                keyboardType="numeric"
                autoCapitalize="none"
                placeholder="+91"
                placeholderTextColor={Colors.noteColor}
                returnKeyType="done"
                value={this.state.areaCode}
                onChangeText={text => {
                  this.setState({ areaCode: text })
                }}
                underlineColorAndroid="transparent"
              />
              <TextInput
                style={[styles.input, { flex: 4 }]}
                keyboardType="numeric"
                autoCapitalize="none"
                placeholder={I18n.t("Phone number")}
                placeholderTextColor={Colors.noteColor}
                returnKeyType="done"
                value={this.state.phone}
                onChangeText={text => {
                  this.setState({ phone: text })
                }}
                underlineColorAndroid="transparent"
              />
            </View>
          </Content>
          <View style={styles.buttonWrapper}>
            <Button
              style={styles.button}
              block
              rounded
              {...buttonProps}
              onPress={() => {
                this.proceedPressed(false)
              }}
            >
              <Text style={buttonTextStyles}>{I18n.t("create_profile")}</Text>
            </Button>
          </View>
        </ImageBackground>
      </Container>
    )
  }
}

