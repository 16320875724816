import Colors from "constants/Colors"
import FontSizes from "constants/FontSizes"
import {
  Dimensions,
  StyleSheet,
  ViewStyle,
  TextStyle,
  PixelRatio,
} from "react-native"

interface Styles {
  buttonWrap: ViewStyle
  container: ViewStyle
  title: TextStyle
  inputContainer: ViewStyle
  input: TextStyle
  confirmButton: ViewStyle
  item: ViewStyle
  itemBody: ViewStyle
  iconContainer: ViewStyle
  itemText: TextStyle
  checkeditemText: TextStyle
  checked: ViewStyle
  unchecked: ViewStyle
  list: ViewStyle
}

const { width } = Dimensions.get("window")
export default StyleSheet.create<Styles>({
  buttonWrap: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 15,
    height: 45,
    width,
  },
  container: {
    paddingHorizontal: 15,
    width,
  },
  title: {
    fontFamily: "open-sans-bold",
    color: Colors.noteColor,
    fontSize: 12,
    paddingBottom: 10,
    textAlign: "center",
  },
  inputContainer: {
    borderTopWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderTopColor: Colors.borderColor,
  },
  input: {
    fontFamily: "open-sans-regular",
    color: Colors.textColor,
    fontSize: FontSizes.questionPlaceholderSize,
    lineHeight: FontSizes.questionPlaceholderSize,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 0,
    borderBottomWidth: 0,
  },
  confirmButton: {
    paddingLeft: 15,
  },
  item: {
    alignItems: "flex-start",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderBottomWidth: 0,
  },
  itemBody: {
    flex: 1,
    justifyContent: "center",
    minHeight: 47,
    marginLeft: 15,
    borderColor: Colors.borderColor,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  },
  iconContainer: {
    justifyContent: "center",
    minHeight: 47,
  },
  itemText: {
    color: Colors.darkerGrey,
    marginLeft: 0,
  },
  checkeditemText: {
    color: Colors.textColor,
  },
  checked: {
    color: Colors.brandGreen,
  },
  unchecked: {
    color: Colors.grey,
  },
  list: {
    borderColor: Colors.grey,
    borderBottomWidth: 1,
  },
})
