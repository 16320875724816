import { connect } from "react-redux"
import View from "./view"
import { Store } from "utilities/Redux/store"
import { ViewStyle } from "react-native"
import { getImageById } from "modules/Images/selectors"
import { subscribeToImage } from "modules/Images/actions"

export interface OwnProps {
  onPress?: () => void
  imageId: string
  style?: ViewStyle
  height?: number
  width?: number
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => {
  const { imageId } = ownProps
  const { localUri, downloadURL, height, width } =
    getImageById(state, imageId) || {}
  return {
    uri: localUri || downloadURL,
    height: ownProps.height || height,
    width: ownProps.width || width,
  }
}

const mapDispatchToProps = {
  subscribeToImage,
}
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  onPress: ownProps.onPress,
  style: ownProps.style,
  willMount: () => {
    dispatchProps.subscribeToImage(ownProps.imageId)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
