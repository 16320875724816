import { FEED_SUBSCRIBE, FEED_UPDATED } from "./actionTypes"
import { FeedDB } from "database_types/feed"

export interface SubscribeToFeed {
  type: typeof FEED_SUBSCRIBE
}
export const subscribeToFeed: () => SubscribeToFeed = () => {
  return {
    type: FEED_SUBSCRIBE,
  }
}

export interface FeedUpdated {
  type: typeof FEED_UPDATED
  feed: FeedDB
}
export const feedUpdated: (feed: FeedDB) => FeedUpdated = feed => {
  return {
    type: FEED_UPDATED,
    feed,
  }
}
