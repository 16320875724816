import React from "react"
import _ from "lodash"
import { View, Text } from "react-native"
import { Button } from "native-base"
import I18n from "i18n"
import { getOfferStartDateString } from "utilities/time"
import ReminderStyles from "../styles"
import styles from "./styles"
import { Category } from "modules/Buyer/Categories/types"
import { Offer } from "modules/Buyer/Offers/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface Props {
  category?: Category
  offer: Offer
  markJobAsDone: () => void
}

export default class PastJobNotStarted extends React.PureComponent<Props> {
  render() {
    const { category, offer } = this.props
    if (!category) {
      return null
    }
    return (
      <View style={ReminderStyles.reminderContainer}>
        <View style={styles.contentContainer}>
          <View style={styles.textContainer}>
            <Text style={ReminderStyles.title}>
              {I18n.t("did_you_complete")}
            </Text>
            <Text style={ReminderStyles.highlighted}>
              {getLocaLizedName(category.name)} {getOfferStartDateString(offer)}
            </Text>
          </View>
        </View>
        <View style={styles.buttonWrap}>
          <Button
            success
            rounded
            block
            onPress={this.props.markJobAsDone}
            style={ReminderStyles.button}
          >
            <Text style={ReminderStyles.buttonText}>
              {I18n.t("mark_as_done")}
            </Text>
          </Button>
        </View>
      </View>
    )
  }
}
