import React from "react"
import _ from "lodash"
import { View } from "react-native"
import { Badge, Body, Icon, ListItem, Right, Text } from "native-base"
import I18n from "i18n"
import styles from "./styles"
import { Category } from "modules/Buyer/Categories/types"
import { Offer } from "modules/Buyer/Offers/types"
import { OfferRequest } from "modules/Buyer/OfferRequests/types"
import { getLocaLizedName } from "utilities/Redux/normalizeHelpers"

interface Props {
  category?: Category
  offerRequest?: OfferRequest
  offerCount: number
  offers?: Offer[]
  onPress: (offerRequest: OfferRequest, category: Category) => void
  last: boolean
}

export default ({
  category,
  offerRequest,
  offerCount,
  offers,
  onPress,
  last,
}: Props) => {
  if (!category || !offers || !offerRequest) {
    return null
  }
  const totalOffers = offers.length
  return (
    <ListItem
      onPress={() => onPress(offerRequest, category)}
      style={last ? styles.lastItem : null}
    >
      <Body style={styles.textWrap}>
        <Text
          style={[styles.title, offerCount ? styles.titleHighlighted : null]}
        >
          {getLocaLizedName(category.name)}
        </Text>
        <View style={styles.info}>
          {offerCount ? (
            <Text style={[styles.infoText, styles.infoTextHighlighted]}>
              {offerCount}{" "}
              {offerCount === 1
                ? I18n.t("new").toLowerCase()
                : I18n.t("multiple_new").toLowerCase()}
            </Text>
          ) : null}
          {offerCount ? <Text style={styles.infoSeparator}>•</Text> : null}
          {offerCount ? (
            <Text style={styles.infoText}>
              {offerCount}{" "}
              {offerCount === 1
                ? I18n.t("one_open_offer").toLowerCase()
                : I18n.t("multiple_open_offers").toLowerCase()}
            </Text>
          ) : null}
          {!offerCount && totalOffers ? (
            <Text style={[styles.infoText, styles.infoTextHighlighted]}>
              {totalOffers}{" "}
              {totalOffers === 1
                ? I18n.t("offer").toLowerCase()
                : I18n.t("offers").toLowerCase()}
            </Text>
          ) : null}
          {!totalOffers ? (
            <Text style={styles.infoText}>{I18n.t("waiting_for_offers")}</Text>
          ) : null}
        </View>
      </Body>
      <Right>
        <View style={styles.rightWrap}>
          {offerCount ? (
            <Badge style={styles.notification}>
              <Text style={styles.notificationText}>{offerCount}</Text>
            </Badge>
          ) : null}
          <Icon name="arrow-forward" style={styles.icon} />
        </View>
      </Right>
    </ListItem>
  )
}
