import { all, call, takeEvery, fork, put, take } from "redux-saga/effects"
import { getEventChannelForFirebaseRef } from "utilities/Redux/sagaHelpers"
import firebase from "firebase"
import { CHAT_CREATED } from "modules/Chats/actionTypes"
import {
  offerUpdated,
  DeclineExtension,
  AcceptExtension,
  MarkOfferAsSeen,
  OfferStatusUpdated,
} from "./actions"
import { FIREBASE_PATH } from "./constants"
import { OFFER_REQUEST_UPDATED } from "../OfferRequests/actionTypes"
import {
  DECLINE_EXTENDED_DURATION,
  ACCEPT_EXTENDED_DURATION,
  MARK_OFFER_AS_SEEN,
  OFFER_STATUS_UPDATED,
} from "./actionTypes"
import { OfferRequestUpdated } from "../OfferRequests/actions"
import { ChatCreated } from "modules/Chats/actions"
import { OfferDB } from "database_types/offer"

const subscribed = {}
export function* subscribeToOfferWorker(id: string) {
  if (subscribed[id]) {
    return
  }
  yield (subscribed[id] = true)

  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(id)
  const channel = yield call(getEventChannelForFirebaseRef, ref)
  while (true) {
    // tslint:disable-next-line:no-unsafe-any
    const { values: offer }: { values: OfferDB } = yield take(channel)
    yield put(
      offerUpdated({
        id,
        ...offer,
      }),
    )
  }
}

export function* subscribeOffersWorker(action: OfferRequestUpdated) {
  if (!action.offerRequest.offers) {
    return
  }

  yield all(
    Object.keys(action.offerRequest.offers).map(id =>
      call(subscribeToOfferWorker, id),
    ),
  )
}

export function* subscribeToOffersSaga() {
  yield takeEvery(OFFER_REQUEST_UPDATED, subscribeOffersWorker)
}

export function* saveOfferStatusWorker(action: OfferStatusUpdated) {
  // tslint:disable-next-line:no-unsafe-any
  const offerRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offerId)

  yield offerRef.child("status").set(action.status)
  yield offerRef.child("seenBySeller").set(false)
}

export function* saveOfferStatusSaga() {
  yield takeEvery(OFFER_STATUS_UPDATED, saveOfferStatusWorker)
}

export function* markOfferAsSeenWorker(action: MarkOfferAsSeen) {
  // tslint:disable-next-line:no-unsafe-any
  const offerStatusRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offerId)
    .child("seenByBuyer")

  yield offerStatusRef.set(action.seenByBuyer)
}

export function* markOfferAsSeenSaga() {
  yield takeEvery(MARK_OFFER_AS_SEEN, markOfferAsSeenWorker)
}

export function* addChatToOfferWorker(action: ChatCreated) {
  yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offerId)
    .child("chat")
    .set(action.chatId)
}

export function* chatCreatedSaga() {
  yield takeEvery(CHAT_CREATED, addChatToOfferWorker)
}

export function* acceptExtendOfferWorker(action: AcceptExtension) {
  // tslint:disable-next-line:no-unsafe-any
  const offerRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offerId)
  yield offerRef.update({
    timeToExtend: 0,
    duration: action.duration,
    totalPrice: action.totalPrice,
  })
}

export function* acceptExtendOfferSaga() {
  yield takeEvery(ACCEPT_EXTENDED_DURATION, acceptExtendOfferWorker)
}

export function* declineExtendOfferWorker(action: DeclineExtension) {
  // tslint:disable-next-line:no-unsafe-any
  const offerRef: firebase.database.Reference = yield firebase
    .database()
    .ref(FIREBASE_PATH)
    .child(action.offerId)
  yield offerRef.update({
    timeToExtend: 0,
  })
}

export function* declineExtendOfferSaga() {
  yield takeEvery(DECLINE_EXTENDED_DURATION, declineExtendOfferWorker)
}

export default function*() {
  yield all([
    fork(subscribeToOffersSaga),
    fork(saveOfferStatusSaga),
    fork(markOfferAsSeenSaga),
    fork(chatCreatedSaga),
    fork(acceptExtendOfferSaga),
    fork(declineExtendOfferSaga),
  ])
}
