import _ from "lodash"
import { OFFER_STATUS } from "../constants/Status"
import { getTimePassedInHours } from "./time"
import { Offer } from "../modules/Buyer/Offers/types"

const shouldShowChat = (offer: Offer) => {
  return (
    offer.actualCompletedTime &&
    getTimePassedInHours(offer.actualCompletedTime) <= 48
  )
}

export const showChat = (offer: Offer) => {
  const status = offer.status
  return (
    status === OFFER_STATUS.ACCEPTED ||
    (status === OFFER_STATUS.COMPLETED && shouldShowChat(offer))
  )
}
