import React, { useEffect } from "react"
import {
  ActivityIndicator,
  View,
  ImageBackground,
  ImageRequireSource,
  Platform,
} from "react-native"
import {
  makeRedirectUri,
  ResponseType,
  useAuthRequest,
} from "expo-auth-session"
import { Button, Container, Content, Text, Toast } from "native-base"
import { authIds } from "config"
import I18n from "i18n"
import styles from "./styles"
import { AuthSessionResult } from "expo-auth-session/src/AuthSession.types"

interface SignupProps {
  isLoggedIn?: boolean
  ready: () => void
  isAuthenticating?: boolean
  fbAuth: () => void
  fbAuthWeb: (response: AuthSessionResult | null) => void
  googleAuth: () => void
  authErrorMessage?: string
}

// Endpoint
const discovery = {
  authorizationEndpoint: "https://www.facebook.com/v6.0/dialog/oauth",
  tokenEndpoint: "https://graph.facebook.com/v6.0/oauth/access_token",
}

const useProxy = Platform.select({ web: false, default: false })

export default (props: SignupProps) => {
  const { isAuthenticating, isLoggedIn, ready, googleAuth } = props
  useEffect(() => {
    if (isLoggedIn) {
      ready()
    }
  }, [isLoggedIn])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [request, response, promptAsync] = useAuthRequest(
    {
      responseType: ResponseType.Token,
      clientId: authIds.facebookAppId,
      scopes: ["public_profile", "email"],
      // For usage in managed apps using the proxy
      redirectUri: makeRedirectUri({
        useProxy,
        // For usage in bare and standalone
        // Use your FBID here. The path MUST be `authorize`.
        native: `fb${authIds.facebookAppId}:721656735058621`,
      }),
      extraParams: {  
        display: Platform.select({ web: "popup" }),
        auth_type: "rerequest",
      },
    },
    discovery,
  )
  useEffect(() => {
    props.fbAuthWeb(response)
  }, [response])
  if (isLoggedIn) {
    return null
  }
  console.log('cursor2 =============')


  if (isAuthenticating) {
    return (
      <Container style={styles.container}>
      {/* eslint-disable global-require */}
      <ImageBackground
        source={
          require("assets/images/wants_to_create_seller_profile.png") as ImageRequireSource
        }
        style={styles.imageContainer}
        imageStyle={styles.image}
      >
        <View style={styles.textWrapper}>
          <Text style={styles.text}>{I18n.t("what_this_is")}</Text>
        </View>

        {/* eslint-enable global-require */}
        <View style={styles.buttonWrapper}>
          <Button
            onPress={googleAuth}
            block
            rounded
            bordered
            style={[styles.facebookButton, styles.button]}
          >
            <Text style={styles.buttonText}>{I18n.t("login_with_google")}</Text>
          </Button>
          <Button
            onPress={() => {
              promptAsync({
                useProxy,
                windowFeatures: { width: 700, height: 600 },
              })
            }}
            block
            rounded
            bordered
            style={styles.button}
          >
            <Text style={styles.buttonText}>
              {I18n.t("login_with_facebook")}
            </Text>
          </Button>
        </View>
      </ImageBackground>
    </Container>
    )
  }
  if (props.authErrorMessage) {
    Toast.show({
      text: props.authErrorMessage,
      position: "bottom",
      duration: 15000,
      style: {
        bottom: 100,
      },
      textStyle: {
        textAlign: "center",
      },
    })
  }

  return (
    <Container style={styles.container}>
      {/* eslint-disable global-require */}
      <ImageBackground
        source={
          require("assets/images/wants_to_create_seller_profile.png") as ImageRequireSource
        }
        style={styles.imageContainer}
        imageStyle={styles.image}
      >
        <View style={styles.textWrapper}>
          <Text style={styles.text}>{I18n.t("what_this_is")}</Text>
        </View>

        {/* eslint-enable global-require */}
        <View style={styles.buttonWrapper}>
          <Button
            onPress={googleAuth}
            block
            rounded
            bordered
            style={[styles.facebookButton, styles.button]}
          >
            <Text style={styles.buttonText}>{I18n.t("login_with_google")}</Text>
          </Button>
          <Button
            onPress={() => {
              promptAsync({
                useProxy,
                windowFeatures: { width: 700, height: 600 },
              })
            }}
            block
            rounded
            bordered
            style={styles.button}
          >
            <Text style={styles.buttonText}>
              {I18n.t("login_with_facebook")}
            </Text>
          </Button>
        </View>
      </ImageBackground>
    </Container>
  )
}
