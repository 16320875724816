import React from "react"
import _ from "lodash"
import { Text } from "native-base"
import { View, ViewStyle, TextStyle } from "react-native"
import styles from "./styles"

interface Props {
  name: string
  details: string
  style?: ViewStyle
  styleName?: TextStyle
  styleDetails?: TextStyle
}

export default ({ name, details, style, styleName, styleDetails }: Props) => (
  <View style={[styles.selectedBlock, style]}>
    <Text style={[styles.blockName, styleName]}>{name}</Text>
    <Text style={[styles.blockDetails, styleDetails]}>{details}</Text>
  </View>
)
