import React from "react"
import { ActivityIndicator } from "react-native"
import { Container, Content } from "native-base"
import styles from "./styles"

export const Loader = () => {
  return (
    <Container style={styles.container}>
      <Content>
        <ActivityIndicator style={styles.spinner} />
      </Content>
    </Container>
  )
}
